import { Injectable } from '@angular/core';
import { FNOL_STEPS, PRIVACY_POLICY } from '../config/fnol-constants';
import { URL_PARAMETERS } from '../config/urlParameters.constant';
import { DataStoreService } from './data-store.service';
import { CommonUtils } from '../shared/utils/common-utils';
import { CountryObject } from '../shared/model/country';
import { FnolHomeUSApiService } from '../fnol-home-US/fnol-home-us-api.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class FnolModelUsService {

    stateArray: CountryObject[] = [];
    stateCode: string;
    policyToggle: string;
    insuredDetails = null;
    policyData = null;

    constructor(private _dataStoreService: DataStoreService,
        private fnolHomeUSApiService: FnolHomeUSApiService) { 
            this.fnolHomeUSApiService.policyToggle.subscribe((res) => {
                this.policyToggle = res["policyToggle"] === "" ? "Yes" : res["policyToggle"];
            });
            this.fnolHomeUSApiService.policyHolderDetails.subscribe(data => {
                this.insuredDetails = data;
            });
            this.fnolHomeUSApiService.policyDetails.subscribe(policyData => {
                this.policyData = policyData;
            });
        }

    setStateData(stateData): void {
        this.stateArray = stateData;
    }

    preparePdfData(): any {
        const pdfGenerationData = [];

        const policyObj = {
          title: 'Privacy Policy and Terms of Use',
          attribute: this.getPrivacyPolicySectionInfo()
        };
        pdfGenerationData.push(policyObj);
       
        const policyDetailObj = {
            title: 'Summary of Claim Submission',
            attribute: this.policyHolderData()
        };
        pdfGenerationData.push(policyDetailObj);

        const policyClaimantDetailObj = {
            title: 'Policy and Claimant Information',
            attribute: this.policyClaimantData()
        }
        pdfGenerationData.push(policyClaimantDetailObj);

        const incidentDetailObj = {
            title: 'Incident Details',
            attribute: this.incidentData()
        }
        pdfGenerationData.push(incidentDetailObj);

        const claimTypeDetailObj = {
            title: 'Claim Type Details',
            attribute: this.claimTypeData()
        }
        pdfGenerationData.push(claimTypeDetailObj);

        const uploadDocDetailObj = {
            title: 'Upload Supporting Documents',
            attribute: this.uploadDocData()
        }
        pdfGenerationData.push(uploadDocDetailObj);

        return pdfGenerationData;
    }

    getPrivacyPolicySectionInfo(): any {
        return [
          {
            name: "Privacy Policy:",
            value: "Yes",
          },
          {
            name: "Terms of Use:",
            value: "Yes",
          },
          {
            name: "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
            value: "Yes",
          },
        ];
    }

    policyHolderData(): any {
        const policyHolderDataArray = [{
            name: "",
            value: ""
        }];
        if (this.insuredDetails) {
                policyHolderDataArray.push(
                    {
                        name: "Policy Number:",
                        value: this.insuredDetails.policyNo ? this.insuredDetails.policyNo : 'Detail not provided'
                    },
                    {
                        name: "Policy Holder's Name:",
                        value: this.insuredDetails.policyHolderName
                    }
                );
                return policyHolderDataArray;
        }
    }

    policyClaimantData(): any {
        const claimantData = this._dataStoreService.get(FNOL_STEPS.CLAIMANT_INFO.name).uiData;
        const policyClaimantDataArray = [{
            name: "",
            value: ""
        }];
        policyClaimantDataArray.push(
            {
                name: "Policy Details",
                value: ""
            },
            {
                name: "",
                value: ""
            },
            {
                name: "I am submitting this claim as:",
                value: this.getDetail("submitterRole")
            }
        )
        if (this.insuredDetails) {
            policyClaimantDataArray.push(
                {
                    name: "Policy Number:",
                    value: this.insuredDetails.policyNo ? this.insuredDetails.policyNo : 'Detail not provided'
                }
            )
        }

                policyClaimantDataArray.push(
                    {
                        name: "Policy Holder's Name:",
                        value: this.insuredDetails?.policyHolderName ? this.insuredDetails.policyHolderName : 'Detail not provided'
                    }
                )

        if (this.insuredDetails?.policyFromDate) {
            policyClaimantDataArray.push(
                {
                    name: "Period of Insurance From:",
                    value: moment(this.insuredDetails.policyFromDate).format('MM/DD/YYYY')
                }
            )
        }
        if (this.insuredDetails?.policyToDate) {
            policyClaimantDataArray.push(
                {
                    name: "Period of Insurance To:",
                    value: moment(this.insuredDetails.policyToDate).format('MM/DD/YYYY')
                }
            )
        }
            policyClaimantDataArray.push(
                {
                    name: "",
                    value: ""
                },
                {
                    name: "Broker Details",
                    value: ""
                },
                {
                    name: "",
                    value: ""
                }
            );
            policyClaimantDataArray.push(
                {
                    name: "Name:",
                    value: this.getDetail('brokerName') !== '' ? this.getDetail('brokerName') : 'Detail not provided'
                },
                {
                    name: "Business Name:",
                    value: this.getDetail('brokerBusinessName') !== '' ? this.getDetail('brokerBusinessName') : 'Detail not provided'
                },
                {
                    name: "Primary Contact Number:",
                    value: this.getDetail('brokerPrimaryPhone') !== '' ? this.getDetail('brokerPrimaryPhone') : 'Detail not provided'
                }
            );
            if (this.getDetail('brokerPrimaryPhone') !== '' && this.getDetail('brokerPrimaryPhoneExtn') !== '') {
                policyClaimantDataArray.push(
                    {
                        name: "Primary Contact Number - Extension:",
                        value: this.getDetail('brokerPrimaryPhoneExtn')
                    }
                );
            } else if (this.getDetail('brokerPrimaryPhone') !== '' && this.getDetail('brokerPrimaryPhoneExtn') === '') {
                policyClaimantDataArray.push(
                    {
                        name: "Primary Contact Number - Extension:",
                        value: 'Detail not provided'
                    }
                );
            }
            policyClaimantDataArray.push(
                {
                    name: "Secondary Contact Number:",
                    value: this.getDetail('brokerSecondaryPhone') !== '' ? this.getDetail('brokerSecondaryPhone') : 'Detail not provided'
                },
                {
                    name: "Email:",
                    value: this.getDetail('brokerEmail') !== '' ? this.getDetail('brokerEmail') : 'Detail not provided'
                }
            );
            policyClaimantDataArray.push(
                {
                    name: "",
                    value: ""
                },
                {
                    name: "Claimant / Reporting Party Information",
                    value: ""
                },
                {
                    name: "",
                    value: ""
                },
                {
                    name: "Claimant / Reporting Party Information:",
                    value: claimantData.identify
                }
            );
        if (claimantData.identify === 'Individual') {
            policyClaimantDataArray.push(
                {
                    name: "First Name:",
                    value: claimantData.claimantFirstName
                },
                {
                    name: "Last Name:",
                    value: claimantData.claimantLastName
                },
            );
        }
        if (claimantData.identify === 'Company') {
            policyClaimantDataArray.push(
                {
                    name: "First Name:",
                    value: claimantData.claimantFirstName ? claimantData.claimantFirstName : 'Detail not provided'
                },
                {
                    name: "Last Name:",
                    value: claimantData.claimantLastName ? claimantData.claimantLastName : 'Detail not provided'
                },
                {
                    name: "Company Name:",
                    value: claimantData.claimantCompanyName
                }
            );
        }
        policyClaimantDataArray.push(
            {
                name: "Address Line 1:",
                value: this.getDetail('address1')
            },
            {
                name: "Address Line 2:",
                value: this.getDetail('address2') !== '' ? this.getDetail('address2') : 'Detail not provided'
            },
            {
                name: "Town/City:",
                value: this.getDetail('city')
            },
            {
                name: "State/Province:",
                value: this.getDetail('statePDF')
            },
            {
                name: "Zip/Postal Code:",
                value: this.getDetail('postalCode')
            },
            {
                name: "Country:",
                value: claimantData.claimantCountry.name
            },
            {
                name: "Primary Contact Number:",
                value: (claimantData.claimantPrimaryNumber['phoneCode'].label ? claimantData.claimantPrimaryNumber['phoneCode'].label : claimantData.claimantPrimaryNumber['phoneCode']) + '-' + this.getDetail('claimantPrimary')
            },
            {
                name: "Primary Contact Number - Extension:",
                value: claimantData.claimantPrimaryNumberExtn ? claimantData.claimantPrimaryNumberExtn : 'Detail not provided'
            },
            {
                name: "Secondary Contact Number:",
                value: this.getDetail('claimantSecondary') !== '' ? 
                        (claimantData.claimantSecondaryNumber['phoneCode'].label ? claimantData.claimantSecondaryNumber['phoneCode'].label : claimantData.claimantSecondaryNumber['phoneCode']) + '-' + this.getDetail('claimantSecondary') : 
                        'Detail not provided'
            },
            {
                name: "Email:",
                value: this.getDetail('claimantEmail')
            }
        );
        return policyClaimantDataArray;
    }

    incidentData(): any {
        const incidentDataArray = [{
            name: "",
            value: ""
        }];
        incidentDataArray.push(
            {
              name: "Certificate Number:",
              value: this.getDetail('certNo') !== "" ? this.getDetail('certNo') : 'Detail not provided'
            },
        )
        incidentDataArray.push(
            {
                name: "Date of Loss:",
                value: moment(this._dataStoreService.get(FNOL_STEPS.INCIDENT_DETAILS.name).uiData.dateLoss).format('MM/DD/YYYY') 
            }
        )
        incidentDataArray.push(
            {
                name: "Estimated Loss Amount:",
                value: this.getDetail('estimateLoss') !== '' ? CommonUtils.commaFormatCurrency(this.getDetail('estimateLoss'), 'us', 'estimateLoss') : 'Detail not provided'
            },
        )
        incidentDataArray.push(
            {
                name: "Estimated Cargo Value:",
                value: this.getDetail('cargoValue') !== '' ? CommonUtils.commaFormatCurrency(this.getDetail('cargoValue'), 'us', 'estimateCargo') : 'Detail not provided'
            },
        )
        incidentDataArray.push(
            {
                name: "Third Party Claim Reference:",
                value: this.getDetail('claimRef') !== '' ? this.getDetail('claimRef') : 'Detail not provided'
            },
        )
        incidentDataArray.push(
            {
                name: "Description of Loss:",
                value: this.getDetail('lossDesc')
            }
        )
        incidentDataArray.push(
            {
                name: "",
                value: ""
            },
            {
                name: "Location of Loss Details",
                value: ""
            },
            {
                name: "",
                value: ""
            }
        )
        incidentDataArray.push(
            {
                name: "Address Line 1:",
                value: this.getDetail('lossLocation') !== '' ? this.getDetail('lossLocation') : 'Detail not provided'
            }
        )
        incidentDataArray.push(
            {
                name: "Address Line 2:",
                value: this.getDetail('lossLocationAddress2') !== '' ? this.getDetail('lossLocationAddress2') : 'Detail not provided'
            }
        )
        incidentDataArray.push(
            {
                name: "Town/City:",
                value: this.getDetail('lossLocationCity') !== '' ? this.getDetail('lossLocationCity') : 'Detail not provided'
            },
            {
                name: "State/Province:",
                value: this.getDetail('lossLocationStatePDF') !== '' ? this.getDetail('lossLocationStatePDF') : 'Detail not provided'
            },
            {
                name: "Zip/Postal Code:",
                value: this.getDetail('lossLocationZipcode') !== '' ? this.getDetail('lossLocationZipcode') : 'Detail not provided'
            },
            {
                name: "Country:",
                value: this.getDetail('lossLocationCountry') !== '' ? this.getDetail('lossLocationCountry') : 'Detail not provided'
            }
        )
        return incidentDataArray;
    }

    claimTypeData(): any {
        const claimTypeDataArray = [{
            name: "",
            value: ""
        }];
        claimTypeDataArray.push(
            {
                name:"Claim Type:",
                value: this.getDetail('claimType')
            },
            {
                name: "",
                value: ""
            }
        );
        if (this.getDetail('claimType') === "Marine Cargo") {
            claimTypeDataArray.push(
                {
                    name: "Cargo Contact Details",
                    value: ""
                },
                {
                    name: "",
                    value: ""
                },
                {
                    name: "Type of Loss:",
                    value: this.getDetail('cargoLoss')
                },
                {
                    name: "Individual/Company:",
                    value: this.getDetail('cargoIdentify')
                },
                {
                    name: "First Name:",
                    value: this.getDetail('cargoFirstName') !== '' ? this.getDetail('cargoFirstName') : 'Detail not provided'
                },
                {
                    name: "Last Name:",
                    value: this.getDetail('cargoLastName') !== '' ? this.getDetail('cargoLastName') : 'Detail not provided'
                }
            );
            if (this.getDetail('cargoIdentify') === 'Company') {
                claimTypeDataArray.push(
                    {
                        name: "Company Name:",
                        value: this.getDetail('cargoCompanyName')
                    }
                );
            }
            claimTypeDataArray.push(
                {
                    name: "Primary Contact Number:",
                    value: this.getDetail('cargoPrimaryNumber')
                },
                {
                    name: "Primary Contact Number - Extension:",
                    value: this.getDetail('cargoPrimaryNumberExtn') != "" ? this.getDetail('cargoPrimaryNumberExtn') : "Detail not provided"
                },
                {
                    name: "Secondary Contact Number:",
                    value: this.getDetail('cargoSecondaryNumber') != "" ? this.getDetail('cargoSecondaryNumber') : "Detail not provided"
                },
                {
                    name: "Email:",
                    value: this.getDetail('cargoEmail')
                },
                {
                    name: "",
                    value: ""
                },
                {
                    name: "Cargo Location",
                    value: ""
                },
                {
                    name: "",
                    value: ""
                },
                {
                    name: "Address Line 1:",
                    value: this.getDetail('cargoAddress1')
                },
                {
                    name: "Address Line 2:",
                    value: this.getDetail('cargoAddress2')
                },
                {
                    name: "Town/City:",
                    value: this.getDetail('cargoCity')
                },
                {
                    name: "State/Province:",
                    value: this.getDetail('cargoStatePDF')
                },
                {
                    name: "Zip/Postal Code:",
                    value: this.getDetail('cargoPostalCode')
                },
                {
                    name: "Country:",
                    value: this.getDetail('cargoCountryMarine')
                },
                {
                    name: "Phone Number:",
                    value: this.getDetail('cargoPhoneNumber') != "" ? this.getDetail('cargoPhoneNumber') : "Detail not provided"
                },
                {
                    name: "Phone Number - Extension:",
                    value: this.getDetail('cargoPhoneNumberExtn') != "" ? this.getDetail('cargoPhoneNumberExtn') : "Detail not provided"
                },
                {
                    name: "Arrival Date:",
                    value: this.getDetail('arrivalDate') != "" ? this.getDetail('arrivalDate') : "Detail not provided"
                },
                {
                    name: "Additional Information/Special Instructions:",
                    value: this.getDetail('cargoAdditional') != "" ? this.getDetail('cargoAdditional') : "Detail not provided"
                }
            );

            if (this.getDetail('cargoLoss') === "In Transit Loss") {
                claimTypeDataArray.push(
                    {
                        name: "",
                        value: ""
                    },
                    {
                        name: "Transit Information",
                        value: ""
                    },
                    {
                        name: "",
                        value: ""
                    }
                )
                claimTypeDataArray.push(
                    {
                        name: "Conveyance:",
                        value: this.getDetail('cargoConveyance') != "" ? this.getDetail('cargoConveyance') : "Detail not provided"
                    },
                    {
                        name: "Carrier:",
                        value: this.getDetail('carrier') != "" ? this.getDetail('carrier') : "Detail not provided"
                    },
                    {
                        name: "Vessel:",
                        value: this.getDetail('vessel') != "" ? this.getDetail('vessel') : "Detail not provided"
                    },
                    {
                        name: "Voyage:",
                        value: this.getDetail('voyage') != "" ? this.getDetail('voyage') : "Detail not provided"
                    },
                    {
                        name: "Description of Cargo:",
                        value: this.getDetail('descriptionCargo')
                    },
                    {
                        name: "Bill of Lading Number:",
                        value: this.getDetail('lading') != "" ? this.getDetail('lading') : "Detail not provided"
                    },
                    {
                        name: "Bill of Lading Date or Shipping Date:",
                        value: this.getDetail('landingDate')
                    },
                    {
                        name: "Origin Town:",
                        value: this.getDetail('origin') != "" ? this.getDetail('origin') : "Detail not provided"
                    },
                    {
                        name: "Origin Country:",
                        value: this.getDetail('destination') != "" ? this.getDetail('destination') : "Detail not provided"
                    }
                )
            }
        }
            if(this.getDetail('claimType') === "Inland Marine") {
                claimTypeDataArray.push(
                    {
                        name: "Contact Details",
                        value: ""
                    },
                    {
                        name: "",
                        value: ""
                    },
                    {
                        name: "Type of Loss:",
                        value: this.getDetail('inlandLoss')
                    },
                    {
                        name: "Individual/Company:",
                        value: this.getDetail('inlandIdentify')
                    },
                    {
                        name: "First Name:",
                        value: this.getDetail('inlandFirstName') !== '' ? this.getDetail('inlandFirstName') : 'Detail not provided'
                    },
                    {
                        name: "Last Name:",
                        value: this.getDetail('inlandLastName') !== '' ? this.getDetail('inlandLastName') : 'Detail not provided'
                    }
                );
                if (this.getDetail('inlandIdentify') === 'Company') {
                    claimTypeDataArray.push(
                        {
                            name: "Company Name:",
                            value: this.getDetail('inlandCompanyName')
                        }
                    );
                }
                claimTypeDataArray.push(
                    {
                        name: "Primary Contact Number:",
                        value: this.getDetail('inlandPrimaryNumber')
                    },
                    {
                        name: "Primary Contact Number - Extension:",
                        value: this.getDetail('inlandPrimaryNumberExtn') != "" ? this.getDetail('inlandPrimaryNumberExtn') : "Detail not provided"
                    },
                    {
                        name: "Secondary Contact Number:",
                        value: this.getDetail('inlandSecondaryNumber') != "" ? this.getDetail('inlandSecondaryNumber') : "Detail not provided"
                    },
                    {
                        name: "Email:",
                        value: this.getDetail('inlandEmail')
                    }
                );
                if (this.getDetail("inlandLoss") === "CRD") {
                    claimTypeDataArray.push(
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name:"Vehicle Information",
                            value:""
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "VIN:",
                            value: this.getDetail('vehicle2CRD')
                        },
                        {
                            name: "Year:",
                            value: this.getDetail('year2CRD')
                        },
                        {
                            name: "Make:",
                            value: this.getDetail('make2CRD')
                        },
                        {
                            name: "Model:",
                            value: this.getDetail('model2CRD')!= "" ? this.getDetail('model2CRD') : "Detail not provided"
                        },
                        {
                            name: "Unit Number:",
                            value: this.getDetail('unit2CRD')!= "" ? this.getDetail('unit2CRD') : "Detail not provided"
                        }
                    )
                }
                if (this.getDetail('inlandLoss') === "VPDMTC" && this.getDetail('howManyVPD') !== "none" && this.getDetail('howManyVPD') !== "") {
                    claimTypeDataArray.push(
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name:"How many VPD?:",
                            value: this.getDetail('howManyVPD')
                        },
                        {
                            name: "",
                            value: ""
                        }
                        
                    )
                }
                if (this.getDetail('howManyVPD') === "one") {
                    claimTypeDataArray.push(
                        {
                            name:"Vehicle Information",
                            value:""
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "VIN:",
                            value: this.getDetail('vehicle')
                        },
                        {
                            name: "Year:",
                            value: this.getDetail('year')
                        },
                        {
                            name: "Make:",
                            value: this.getDetail('make')
                        },
                        {
                            name: "Model:",
                            value: this.getDetail('model')!= "" ? this.getDetail('model') : "Detail not provided"
                        },
                        {
                            name: "Unit Number:",
                            value: this.getDetail('unit')!= "" ? this.getDetail('unit') : "Detail not provided"
                        }
                    )
                } else if (this.getDetail('howManyVPD') === "two") {
                    claimTypeDataArray.push(
                        
                        {
                            name:"First Vehicle Information",
                            value: ""
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "VIN:",
                            value: this.getDetail('vehicle')
                        },
                        {
                            name: "Year:",
                            value: this.getDetail('year')
                        },
                        {
                            name: "Make:",
                            value: this.getDetail('make')
                        },
                        {
                            name: "Model:",
                            value: this.getDetail('model')!= "" ? this.getDetail('model') : "Detail not provided"
                        },
                        {
                            name: "Unit Number:",
                            value: this.getDetail('unit')!= "" ? this.getDetail('unit') : "Detail not provided"
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name:"Second Vehicle Information",
                            value: ""
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "VIN:",
                            value: this.getDetail('vehicle2')
                        },
                        {
                            name: "Year:",
                            value: this.getDetail('year2')
                        },
                        {
                            name: "Make:",
                            value: this.getDetail('make2')
                        },
                        {
                            name: "Model:",
                            value: this.getDetail('model2')!= "" ? this.getDetail('model2') : "Detail not provided"
                        },
                        {
                            name: "Unit Number:",
                            value: this.getDetail('unit2')!= "" ? this.getDetail('unit2') : "Detail not provided"
                        }
                    )
                }
                if (this.getDetail("mtcInvolved") === "Yes") {
                    claimTypeDataArray.push (
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "Is MTC involved?:",
                            value: this.getDetail('mtcInvolved')
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "Transit Information",
                            value: ""
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "Carrier:",
                            value: this.getDetail("carrierInland")!= "" ? this.getDetail('carrierInland') : "Detail not provided"
                        },
                        {
                            name: "Bill of Lading Number:",
                            value: this.getDetail("ladingInland")!= "" ? this.getDetail('carrierInland') : "Detail not provided"
                        },
                        {
                            name: "Bill of Lading Date:",
                            value: this.getDetail("landingDateInland") != "" ? this.getDetail('landingDateInland') : "Detail not provided"
                        },
                        {
                            name: "Cargo Product:",
                            value: this.getDetail("cargoProduct")!= "" ? this.getDetail('cargoProduct') : "Detail not provided"
                        },
                        {
                            name: "Description of Cargo:",
                            value: this.getDetail("descriptionCargoInland")
                        },
                        {
                            name: "Origin:",
                            value: this.getDetail("originInland")!= "" ? this.getDetail('originInland') : "Detail not provided"
                        },
                        {
                            name: "Destination:",
                            value: this.getDetail("destinationInland")!= "" ? this.getDetail('destinationInland') : "Detail not provided"
                        },
                    )
                }
                if (this.getDetail("mtcInvolved")==="Yes" && this.getDetail('howManyVPD') !== "none" && this.getDetail('howManyVPD') !== "") {
                    claimTypeDataArray.push({
                         name: "VPD and MTC location is same?:",
                         value: this.getDetail('sameLocation')
                     })
                }
                claimTypeDataArray.push(
                    {
                        name: "",
                        value: ""
                    },
                    {
                        name: this.getDetail("mtcInvolved")==="Yes" && this.getDetail('sameLocation') === "No" ? "VPD Location" : "Vehicle/Cargo Location",
                        value: ""
                    },
                    {
                        name: "",
                        value: ""
                    },
                    {
                        name: "Address Line 1:",
                        value: this.getDetail('inlandAddress1') !== "" ? this.getDetail("inlandAddress1") : "Detail not provided"
                    },
                    {
                        name: "Address Line 2:",
                        value: this.getDetail('inlandAddress2') !== "" ? this.getDetail("inlandAddress2") : "Detail not provided"
                    },
                    {
                        name: "Town/City:",
                        value: this.getDetail('inlandCity') !== "" ? this.getDetail("inlandCity") : "Detail not provided"
                    },
                    {
                        name: "State/Province:",
                        value: this.getDetail('inlandStatePdf') !== "" ? this.getDetail("inlandStatePdf") : "Detail not provided"
                    },
                    {
                        name: "Zip/Postal Code:",
                        value: this.getDetail('inlandPostalCode') !== "" ? this.getDetail("inlandPostalCode") : "Detail not provided"
                    },
                    {
                        name: "Country:",
                        value: this.getDetail('inlandCountry') !== "" ? this.getDetail("inlandCountry") : "Detail not provided"
                    },
                    {
                        name: "Phone Number:",
                        value: this.getDetail('inlandPhoneNumber') !== "" ? this.getDetail('inlandPhoneNumber') : "Detail not provided"
                    },
                    {
                        name: "Phone Number - Extension:",
                        value: this.getDetail('inlandPhoneNumberExtn') !== "" ? this.getDetail('inlandPhoneNumberExtn') : "Detail not provided"
                    },
                    {
                        name: "Arrival Date:",
                        value: this.getDetail('inlandArrivalDate') !== "" ? this.getDetail('inlandArrivalDate') : "Detail not provided"
                    },
                    {
                        name: "Additional Information/Special Instructions:",
                        value: this.getDetail('inlandAdditional') !== "" ? this.getDetail('inlandAdditional') : "Detail not provided"
                    }
                );
                if(this.getDetail("mtcInvolved") ===  "Yes" && this.getDetail('sameLocation') === "No"){
                    claimTypeDataArray.push(
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name:"MTC Location",
                            value:""
                        },
                        {
                            name: "",
                            value: ""
                        },
                        {
                            name: "Address Line 1:",
                            value: this.getDetail('inlandAddress1MTC') !== "" ? this.getDetail("inlandAddress1MTC") : "Detail not provided"
                        },
                        {
                            name: "Address Line 2:",
                            value: this.getDetail('inlandAddress2MTC') !== "" ? this.getDetail("inlandAddress2MTC") : "Detail not provided"
                        },
                        {
                            name: "Town/City:",
                            value: this.getDetail('inlandCityMTC') !== "" ? this.getDetail("inlandCityMTC") : "Detail not provided"
                        },
                        {
                            name: "State/Province:",
                            value: this.getDetail('inlandStateMTCPDF') !== "" ? this.getDetail("inlandStateMTCPDF") : "Detail not provided"
                        },
                        {
                            name: "Zip/Postal Code:",
                            value: this.getDetail('inlandPostalCodeMTC') !== "" ? this.getDetail("inlandPostalCodeMTC") : "Detail not provided"
                        },
                        {
                            name: "Country:",
                            value: this.getDetail('inlandCountryMTC') !== "" ? this.getDetail("inlandCountryMTC") : "Detail not provided"
                        },
                        {
                            name: "Phone Number:",
                            value: this.getDetail('inlandPhoneNumberMTC') !== "" ? this.getDetail('inlandPhoneNumberMTC') : "Detail not provided"
                        },
                        {
                            name: "Phone Number - Extension:",
                            value: this.getDetail('inlandPhoneNumberMTCExtn') !== "" ? this.getDetail('inlandPhoneNumberMTCExtn') : "Detail not provided"
                        },
                        {
                            name: "Arrival Date:",
                            value: this.getDetail('inlandArrivalDateMTC') !== "" ? this.getDetail('inlandArrivalDateMTC') : "Detail not provided"
                        },
                        {
                            name: "Additional Information/Special Instructions:",
                            value: this.getDetail('inlandAdditionalMTC') !== "" ? this.getDetail('inlandAdditionalMTC') : "Detail not provided"
                        }
                    )
                }
            }
        return claimTypeDataArray;
    }

    uploadDocData(): any {
        const uploadData = this._dataStoreService.get(FNOL_STEPS.UPLOAD_DOCS.name);
        const uploadArray = uploadData.uiData.customFileUpload?.uploads;
        const uploadDataArray = [{
            name: "",
            value: ""
        }];
        if (uploadArray && uploadArray.length) {
            uploadArray.forEach((upload, i) => {
                uploadDataArray.push({
                    name: uploadArray.length === 1 ? `Upload:` : `Upload ${i + 1}:`,
                    value: upload.name
                });
            });
        } else {
            uploadDataArray.push({
                name: "Upload:",
                value: "No Supporting Documents Found"
            })
        }
        uploadDataArray.push({
            name: "",
            value: ""
        })
        return uploadDataArray;
    }

    getDetail(requestName: string): any {
        /* step 1 details */
        const claimantData = this._dataStoreService.get(FNOL_STEPS.CLAIMANT_INFO.name);
        const claimTypeData1 = this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name);

        if (requestName === 'submitterRole') {
            return claimantData.uiData.submitting?.name
        }
        if (requestName === 'reportingFirstName' || requestName === 'reportingLastName' || 
            requestName === 'cargoFirstNameCaps' || requestName === 'cargoLastNameCaps' || 
            requestName === 'inlandFirstNameCaps' || requestName === 'inlandLastNameCaps') {
            let nameValue;
            switch (requestName) {
                case 'reportingFirstName':
                    nameValue = claimantData.uiData.claimantFirstName;
                break;
                case 'reportingLastName':
                    nameValue = claimantData.uiData.claimantLastName;
                break;
                case 'cargoFirstNameCaps':
                    nameValue = claimTypeData1.uiData.cargoFirstName;
                break;
                case 'cargoLastNameCaps':
                    nameValue = claimTypeData1.uiData.cargoLastName;
                break;
                case 'inlandFirstNameCaps':
                    nameValue = claimTypeData1.uiData.inlandFirstName;
                break;
                case 'inlandLastNameCaps':
                    nameValue = claimTypeData1.uiData.inlandLastName;
                break;
            }
            return nameValue ? nameValue : '';
        }
        if (requestName === 'firstName') { 
            return claimantData.uiData.claimantCompanyName !== '' ? '' : claimantData.uiData.claimantFirstName;
        }
        if (requestName === 'lastName') { 
            return claimantData.uiData.claimantLastName; 
        }
        if (requestName === 'surName') {
            return claimantData.uiData.claimantCompanyName !== '' ? claimantData.uiData.claimantCompanyName : claimantData.uiData.claimantLastName;
        }
        if (requestName === 'companyName') {
            return claimantData.uiData.claimantCompanyName ? claimantData.uiData.claimantCompanyName : '';
        }
        if (requestName === 'claimantPrimary') { 
            return claimantData.uiData.claimantPrimaryNumber['phoneNumber']; 
        }
        if (requestName === 'claimantSecondary') {
            if (claimantData.uiData.claimantSecondaryNumber['phoneNumber']) {
                return claimantData.uiData.claimantSecondaryNumber['phoneNumber'];
            } else { 
                return ''; 
            }
        }
        if (requestName === 'phoneArray') {
            const phoneArray = [];
                const primaryNo = claimantData.uiData.claimantPrimaryNumber['phoneNumber'];
                const secondaryNo = claimantData.uiData.claimantSecondaryNumber['phoneNumber'];
                if (primaryNo) {
                    phoneArray.push(
                        {
                            'm1:PhoneTypeCd': 'Home/Work Phone',
                            'm1:PhoneNumber': claimantData.uiData.claimantPrimaryNumber['phoneNumber'],
                            'm1:PhoneExtn': claimantData.uiData.claimantPrimaryNumberExtn ? claimantData.uiData.claimantPrimaryNumberExtn : '',
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                if (secondaryNo) {
                    phoneArray.push(
                        {
                            'm1:PhoneTypeCd': 'Mobile Phone',
                            'm1:PhoneNumber': claimantData.uiData.claimantSecondaryNumber['phoneNumber'],
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                return phoneArray;
        }
        if (requestName === 'mail') {
               return claimantData.uiData.policyEmail ? claimantData.uiData.policyEmail : '';
        }
        if (requestName === 'claimantEmail') {
            return claimantData.uiData.claimantEmail;
        }
        if (requestName === 'address1') {
            if (claimantData.uiData.claimantAddress1.name) {
                return claimantData.uiData.claimantAddress1.name;
            } else {
                return claimantData.uiData.claimantAddress1;
            }
        }
        if (requestName === 'address2') {
            if (claimantData.uiData.claimantAddress2 && claimantData.uiData.claimantAddress2 !== '') {
                return claimantData.uiData.claimantAddress2;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'city') {
            if (claimantData.uiData.claimantCity) {
                return claimantData.uiData.claimantCity;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'state') {
            if (claimantData.uiData.claimantState) {
               return this.getStateCodeMarine(claimantData.uiData.claimantState, this.getDetail('country'));
            } else { 
                return ''; 
            }
        }
        if (requestName === 'statePDF') {
            return claimantData.uiData.claimantState ? claimantData.uiData.claimantState : 'Detail not provided'
        }
        if (requestName === 'postalCode') {
            if (claimantData.uiData.claimantPostalCode) {
                return claimantData.uiData.claimantPostalCode;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'country' || requestName === 'countryCode') {
            if (claimantData.uiData.claimantCountry && claimantData.uiData.claimantCountry.code) {
                return claimantData.uiData.claimantCountry.code;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'policyNumber') {
            return this.insuredDetails.policyNo ? this.insuredDetails.policyNo : ''
        }
        if (requestName === 'havePolicyNoCheck') {
            return this.policyToggle === "Yes" ? "No" : "Yes";
        }
        if (requestName === 'policyHolderName') {
            if (claimantData.uiData.policyNameInsured) {
                return claimantData.uiData.policyNameInsured;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'policyHolderNameForMail') {
            return this.insuredDetails['policyHolderName'];
        }
        if (requestName === 'policyVersion') {
            return this.policyData['response']?.policyDetails ? this.policyData['response'].policyDetails.policyVersion : '';
        }
        if (requestName === 'policyStatus') {
            return this.policyData['response']?.policyDetails ? this.policyData['response'].policyDetails.policyStatus : '';
        }
        if (requestName === 'insuranceStartDate') {
            return claimantData.uiData.insuranceStart ? moment(claimantData.uiData.insuranceStart).format('MM/DD/YYYY') : '';
        }
        if (requestName === 'insuranceExpiryDate') {
            return claimantData.uiData.insuranceExpiry ? moment(claimantData.uiData.insuranceExpiry).format('MM/DD/YYYY') : '';
        }
        if (requestName === 'policyEffectiveDate') {
            if (this.policyData['response']?.policyDetails && this.policyData['response'].policyDetails.startDate) {
                return CommonUtils.convertDateGMT(this.policyData['response'].policyDetails.startDate, 'YYYYMMDD');
            } else {
                if (claimantData.uiData.insuranceStartInsured) {
                    return CommonUtils.convertDateGMT(claimantData.uiData.insuranceStartInsured, 'YYYYMMDD');
                } else { 
                    return ''; 
                }
            }
        }
        if (requestName === 'policyExpiryDate') {
            if (this.policyData['response']?.policyDetails && this.policyData['response'].policyDetails.plannedEndDate) {
                return CommonUtils.convertDateGMT(this.policyData['response'].policyDetails.plannedEndDate, 'YYYYMMDD');
            } else {
                if (claimantData.uiData.insuranceExpiryInsured) {
                    return CommonUtils.convertDateGMT(claimantData.uiData.insuranceExpiryInsured, 'YYYYMMDD');
                } else { 
                    return ''; 
                }
            }
        }
        if (requestName === 'annualCountryCode') {
            return this.policyData['response']?.policyDetails ? this.policyData['response'].policyDetails.annualStatementCompanyCode : '';
        }
        if (requestName === 'brokerPrimaryNumber') {
            if (claimantData.uiData.brokerPrimary) {
                return claimantData.uiData.brokerPrimary['phoneNumber'];
            } else { 
                return ''; 
            }
        }
        if (requestName === 'brokerPrimaryNumberExtn') {
            if (claimantData.uiData.brokerPrimaryExtn) {
                return claimantData.uiData.brokerPrimaryExtn;
            } else { 
                return ''; 
            }
        }
        if (requestName === 'brokerSecondaryNumber') {
            if (claimantData.uiData.brokerSecondary) {
                return claimantData.uiData.brokerSecondary['phoneNumber'];
            } else { 
                return ''; 
            }
        }
        if (requestName === 'brokerPrimaryPhone') {
            return claimantData.uiData.brokerPrimary['phoneNumber'] ? 
                    (claimantData.uiData.brokerPrimary['phoneCode'].label ? claimantData.uiData.brokerPrimary['phoneCode'].label : claimantData.uiData.brokerPrimary['phoneCode']) + '-' + claimantData.uiData.brokerPrimary['phoneNumber'] : 
                    '';
        }
        if (requestName === 'brokerPrimaryPhoneExtn') {
            return claimantData.uiData.brokerPrimaryExtn ? claimantData.uiData.brokerPrimaryExtn : '';
        }
        if (requestName === 'brokerSecondaryPhone') {
            return claimantData.uiData.brokerSecondary['phoneNumber'] ? 
                    (claimantData.uiData.brokerSecondary['phoneCode'].label ? claimantData.uiData.brokerSecondary['phoneCode'].label : claimantData.uiData.brokerSecondary['phoneCode']) + '-' + claimantData.uiData.brokerSecondary['phoneNumber'] :
                     '';
        }
        if (requestName === 'brokerName' || requestName === 'brokerNumber' ||
             requestName === 'brokerEmail' || requestName === 'brokerBusinessName') {
            if (requestName === 'brokerNumber') {
                const brokerPhone = [];
                if (claimantData.uiData.brokerPrimary) {
                    brokerPhone.push(
                        {
                            'm1:PhoneTypeCd': 'Home/Work Phone',
                            'm1:PhoneNumber': claimantData.uiData.brokerPrimary['phoneNumber'],
                            'm1:PhoneExtn': claimantData.uiData.brokerPrimaryExtn ? claimantData.uiData.brokerPrimaryExtn : '',
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                if (claimantData.uiData.brokerSecondary) {
                    brokerPhone.push(
                        {
                            'm1:PhoneTypeCd': 'Mobile Phone',
                            'm1:PhoneNumber': claimantData.uiData.brokerSecondary['phoneNumber'],
                            'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                        }
                    );
                }
                return brokerPhone;
            } else {
                return claimantData.uiData[requestName] ? claimantData.uiData[requestName] : '';
            }
        }

        /* step 2 details */
        const incidentData = this._dataStoreService.get(FNOL_STEPS.INCIDENT_DETAILS.name);
        if(incidentData){
            if (requestName === 'certNo') {
                if (incidentData.uiData.certificate) {
                    return incidentData.uiData.certificate;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'lossDate') {
                return CommonUtils.convertDateGMT(incidentData.uiData.dateLoss, 'YYYYMMDD');
            }
            if (requestName === 'estimateLoss') {
                if (incidentData.uiData.estimateLoss) {
                    return incidentData.uiData.estimateLoss;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'cargoValue') {
                if (incidentData.uiData.estimateCargo) {
                    return incidentData.uiData.estimateCargo;
                } else { return ''; }
            }
            if (requestName === 'claimRef') {
                if (incidentData.uiData.tpClaim) {
                    return incidentData.uiData.tpClaim;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'lossDesc') {
                return incidentData.uiData.loss;
            }
            if (requestName === 'lossLocation') {
                return incidentData.uiData.lossLocationAddress1 ? incidentData.uiData.lossLocationAddress1 : '';
            }
            if (requestName === 'lossLocationAddress2') {
                return incidentData.uiData.lossLocationAddress2 ? incidentData.uiData.lossLocationAddress2 : '';
            }
            if (requestName === 'lossLocationCity') {
                return incidentData.uiData.lossLocationCity ? incidentData.uiData.lossLocationCity : '';
            }
            if (requestName === 'lossLocationState') {
                return incidentData.uiData.lossLocationState ? this.getStateCodeMarine(incidentData.uiData.lossLocationState, this.getDetail('lossLocationCountry')!=""?this.getDetail('lossLocationCountry'):this.getDetail('lossLocationCountryCode')) : '';
            }
            if (requestName === 'lossLocationStatePDF') {
                return incidentData.uiData.lossLocationState ? incidentData.uiData.lossLocationState : '';
            }
            if (requestName === 'lossLocationZipcode') {
                return incidentData.uiData.lossLocationPostalCode ? incidentData.uiData.lossLocationPostalCode : '';
            }
            if (requestName === 'lossLocationCountry') {
                if (incidentData.uiData.lossLocationCountry && incidentData.uiData.lossLocationCountry['name']) {
                    return incidentData.uiData.lossLocationCountry['name'];
                } else {
                    return '';
                }
            } 
            if (requestName === 'lossLocationCountryCode') {
                if (incidentData.uiData.lossLocationCountry && incidentData.uiData.lossLocationCountry['code']) {
                    return incidentData.uiData.lossLocationCountry['code'];
                } else {
                    return '';
                }
            }
        }

        /* step 3 details */
        const claimTypeData = this._dataStoreService.get(FNOL_STEPS.CLAIM_TYPE_DETAILS.name);
        if (URL_PARAMETERS.TYPE === 'CAT' && requestName === 'claimTypeCode') {
          return '';
        }
        if (claimTypeData) {
            if (requestName === 'claimType') {
                if (claimTypeData.uiData.claimBenefits) {
                    return claimTypeData.uiData.claimBenefits;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'claimTypeCode') {
                if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return 'MC';
                } else if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return 'MI';
                } else {
                    return '';
                }
            }
            if (requestName === 'cargoLoss') {
                return claimTypeData.uiData.cargoLoss; 
            }
            if (requestName === 'cargoIdentify') {
                return claimTypeData.uiData.cargoIdentify;
            }
            if (requestName === 'inlandIdentify') {
                return claimTypeData.uiData.inlandIdentify;;
            }
            if (requestName === 'addressLookupCargo') {
                return claimTypeData.uiData.addressLookupDetails;
            }
            if (requestName === 'cargoFirstName') {
                return claimTypeData.uiData.cargoFirstName;
            }
            if (requestName === 'cargoLastName') {
                return claimTypeData.uiData.cargoLastName;
            }
            if (requestName === 'cargoCompanyName') {
                return claimTypeData.uiData.cargoCompanyName;
            }
            if (requestName === 'cargoPrimaryNumber') {
                return claimTypeData.uiData.cargoPrimaryNumber['phoneNumber'] ? 
                        (claimTypeData.uiData.cargoPrimaryNumber['phoneCode'].label ? claimTypeData.uiData.cargoPrimaryNumber['phoneCode'].label : claimTypeData.uiData.cargoPrimaryNumber['phoneCode']) + '-' + claimTypeData.uiData.cargoPrimaryNumber['phoneNumber'] :
                         '';
            }
            if (requestName === 'cargoPrimaryNumberExtn') {
                return claimTypeData.uiData.cargoPrimaryNumberExtn;
            }
            if (requestName === 'cargoSecondaryNumber') {
                return claimTypeData.uiData.cargoSecondaryNumber['phoneNumber'] ? 
                        (claimTypeData.uiData.cargoSecondaryNumber['phoneCode'].label ? claimTypeData.uiData.cargoSecondaryNumber['phoneCode'].label : claimTypeData.uiData.cargoSecondaryNumber['phoneCode']) + '-' + claimTypeData.uiData.cargoSecondaryNumber['phoneNumber'] : 
                         '';
            }
            if (requestName === 'cargoEmail') {
                return claimTypeData.uiData.cargoEmail;
            }
            if (requestName === 'cargoAddress1') {
                if (claimTypeData.uiData.cargoEntryAddress1.name) {
                    return claimTypeData.uiData.cargoEntryAddress1.name;
                } else if (claimTypeData.uiData.cargoEntryAddress1){
                    return claimTypeData.uiData.cargoEntryAddress1;
                } else if (claimTypeData.uiData.cargoAddress1.name) {
                    return claimTypeData.uiData.cargoAddress1.name;
                } else {
                    return claimTypeData.uiData.cargoAddress1;
                }
            }
            if (requestName === 'cargoAddress2') {
                if (claimTypeData.uiData.cargoEntryAddress2) {
                   return claimTypeData.uiData.cargoEntryAddress2; 
                } else if (claimTypeData.uiData.cargoAddress2) {
                    return claimTypeData.uiData.cargoAddress2;
                } else { 
                    return 'Detail not provided'; 
                }
            }
            if (requestName === 'cargoCity') {
                if (claimTypeData.uiData.cargoEntryCity) {
                    return claimTypeData.uiData.cargoEntryCity;
                } else {
                    return claimTypeData.uiData.cargoCity;
                }
            }
            if (requestName === 'cargoState') {
                if (claimTypeData.uiData.cargoEntryState) {
                    return this.getStateCodeMarine(claimTypeData.uiData.cargoEntryState, this.getDetail('cargoCountry'));
                } else {
                    return this.getStateCodeMarine(claimTypeData.uiData.cargoState, this.getDetail('cargoCountry'));
                }
            }
            if (requestName === 'cargoStatePDF') {
                return claimTypeData.uiData.cargoEntryState ?  claimTypeData.uiData.cargoEntryState : claimTypeData.uiData.cargoState;
            }
            if (requestName === 'cargoPostalCode') {
                if (claimTypeData.uiData.cargoEntryState) {
                    return claimTypeData.uiData.cargoEntryPostalCode;
                }
                else {
                    return claimTypeData.uiData.cargoPostalCode;
                }
            }
            if (requestName === 'originDestination') {
                if (claimTypeData.uiData.destination && claimTypeData.uiData.destination['key']) {
                    return claimTypeData.uiData.destination['key'];
                } else if (claimTypeData.uiData.destination && claimTypeData.uiData.destination['code']) {
                    return claimTypeData.uiData.destination['code'];
                } else if (claimTypeData.uiData.destination && claimTypeData.uiData.destination.code) { 
                    return claimTypeData.uiData.destination.code; 
                } else {
                    return '';
                }
            }
            if (requestName === 'cargoCountry') {
                if (claimTypeData.uiData.cargoEntryCountry && claimTypeData.uiData.cargoEntryCountry.code) {
                    return claimTypeData.uiData.cargoEntryCountry.code
                } else
                    if (claimTypeData.uiData.cargoCountry && claimTypeData.uiData.cargoCountry.code) {
                        return claimTypeData.uiData.cargoCountry.code;
                    } else { 
                        return ''; 
                    }
            }
            if (requestName === 'cargoCountryMarine') {
                if (claimTypeData.uiData.cargoEntryCountry && claimTypeData.uiData.cargoEntryCountry.name) {
                    return claimTypeData.uiData.cargoEntryCountry.name
                } else
                    if (claimTypeData.uiData.cargoCountry && claimTypeData.uiData.cargoCountry.name) {
                        return claimTypeData.uiData.cargoCountry.name;
                    } else { 
                        return ''; 
                    }
            }
            if (requestName === 'inlandLoss') {
                return claimTypeData.uiData.cargoInlandLoss;
            }
            if (requestName === 'howManyVPD' || requestName === 'noOfVPD') {
                if (claimTypeData.uiData.howManyVPD) {
                    return claimTypeData.uiData.howManyVPD;
                } else { 
                    return ''; 
                }
            }
           
            if (requestName === 'cargoPhoneNumber') {
                return claimTypeData.uiData.cargoPhoneNumber['phoneNumber'] ? 
                    (claimTypeData.uiData.cargoPhoneNumber['phoneCode'].label ? claimTypeData.uiData.cargoPhoneNumber['phoneCode'].label : claimTypeData.uiData.cargoPhoneNumber['phoneCode']) + '-' + claimTypeData.uiData.cargoPhoneNumber['phoneNumber']: 
                    '';
            }
            if (requestName === 'cargoPhoneNumberExtn') {
                return claimTypeData.uiData.cargoPhoneNumberExtn ? claimTypeData.uiData.cargoPhoneNumberExtn : '';
            }
            if (requestName === 'arrivalDate') {
                if (claimTypeData.uiData.arrivalDate) {
                    return moment(claimTypeData.uiData.arrivalDate).format('MM/DD/YYYY');
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'arrivalDateOC') {
                return claimTypeData.uiData.arrivalDate ? CommonUtils.convertDateGMT(claimTypeData.uiData.arrivalDate, 'YYYYMMDD') : '';
            }
            if (requestName === 'cargoAdditional') {
                return claimTypeData.uiData.cargoAdditional;
            }
            if (requestName === 'conveyance') {
                if (claimTypeData.uiData.conveyance && claimTypeData.uiData.conveyance['code']) {
                    return claimTypeData.uiData.conveyance.code;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'cargoConveyance') {
                if (claimTypeData.uiData.conveyance && claimTypeData.uiData.conveyance['name']) {
                    return claimTypeData.uiData.conveyance.name;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'carrier') {
                if (claimTypeData.uiData.carrier) {
                    return claimTypeData.uiData.carrier;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'carrierInland') {
                if (claimTypeData.uiData.carrierInland) {
                    return claimTypeData.uiData.carrierInland;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'vessel') {
                if (claimTypeData.uiData.vessel) {
                    return claimTypeData.uiData.vessel;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'voyage') {
                if (claimTypeData.uiData.voyage) {
                    return claimTypeData.uiData.voyage;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'descriptionCargo') {
                return claimTypeData.uiData.descriptionCargo;
            }
            if (requestName === 'descriptionCargoInland') {
                return claimTypeData.uiData.descriptionCargoInland;
            }
            if (requestName === 'lading') {
                if (claimTypeData.uiData.lading) {
                    return claimTypeData.uiData.lading;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'ladingInland') {
                if (claimTypeData.uiData.ladingInland) {
                    return claimTypeData.uiData.ladingInland;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'landingDate') {
                if (claimTypeData.uiData.landingDate!=null) {
                    return claimTypeData.uiData.landingDate!="" ? moment(claimTypeData.uiData.landingDate).format('MM/DD/YYYY') : '';
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'ladingShippingDate') {
                return CommonUtils.convertDateGMT(claimTypeData.uiData.landingDate, 'YYYYMMDD');
            }
            if (requestName === 'landingDateInland') {
                if (claimTypeData.uiData.landingDateInland) {
                    return moment(claimTypeData.uiData.landingDateInland).format('MM/DD/YYYY');
                } else {
                    return '';
                }
            }
            if (requestName === 'origin') {
                if (claimTypeData.uiData.origin) {
                    return claimTypeData.uiData.origin;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'originInland') {
                if (claimTypeData.uiData.originInland) {
                    return claimTypeData.uiData.originInland;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'destination') {
                if (claimTypeData.uiData.destination['value']) {
                    return claimTypeData.uiData.destination['value'];
                } else if (claimTypeData.uiData.destination['name']) { 
                    return claimTypeData.uiData.destination['name']; 
                } else {
                    return '';
                }
            }
            if (requestName === 'destinationInland') {
                if (claimTypeData.uiData.destinationInland) {
                    return claimTypeData.uiData.destinationInland;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'cargoProduct') {
                if (claimTypeData.uiData.cargoProduct) {
                    return claimTypeData.uiData.cargoProduct;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'inlandFirstName') {
                return claimTypeData.uiData.inlandFirstName;
            }
            if (requestName === 'inlandLastName') {
                return claimTypeData.uiData.inlandLastName;
            }
            if (requestName === 'inlandCompanyName') {
                return claimTypeData.uiData.inlandCompanyName;
            }
            if (requestName === 'inlandPrimaryNumber') {
                return claimTypeData.uiData.inlandPrimaryNumber['phoneNumber'] ? 
                (claimTypeData.uiData.inlandPrimaryNumber['phoneCode'].label ? claimTypeData.uiData.inlandPrimaryNumber['phoneCode'].label : claimTypeData.uiData.inlandPrimaryNumber['phoneCode']) + '-' + claimTypeData.uiData.inlandPrimaryNumber['phoneNumber'] :
                 "";
            }
            if (requestName === 'inlandPrimaryNumberExtn') {
                return claimTypeData.uiData.inlandPrimaryNumberExtn ? claimTypeData.uiData.inlandPrimaryNumberExtn : '';
            }
            if (requestName === 'inlandSecondaryNumber') {
                return claimTypeData.uiData.inlandSecondaryNumber['phoneNumber'] ? 
                (claimTypeData.uiData.inlandSecondaryNumber['phoneCode'].label ? claimTypeData.uiData.inlandSecondaryNumber['phoneCode'].label : claimTypeData.uiData.inlandSecondaryNumber['phoneCode']) + '-' + claimTypeData.uiData.inlandSecondaryNumber['phoneNumber'] :
                 "";
            }
            if (requestName === 'inlandEmail') {
                return claimTypeData.uiData.inlandEmail;
            }
            if (requestName === 'inlandAddress1') {
                if (claimTypeData.uiData.inlandEntryAddress1) {
                    return claimTypeData.uiData.inlandEntryAddress1;
                } else if (claimTypeData.uiData.inlandAddress1) {
                    return claimTypeData.uiData.inlandAddress1;
                } else {
                    return '';
                }
            }
            if (requestName === 'inlandAddress2') {
                if (claimTypeData.uiData.inlandEntryAddress2) {
                    return claimTypeData.uiData.inlandEntryAddress2; 
                 } else if (claimTypeData.uiData.inlandAddress2) {
                     return claimTypeData.uiData.inlandAddress2;
                 } else { 
                     return ''; 
                 }
            }
            if (requestName === 'inlandCity') {
                if (claimTypeData.uiData.inlandEntryCity) {
                    return claimTypeData.uiData.inlandEntryCity;
                } else if (claimTypeData.uiData.inlandCity) {
                    return claimTypeData.uiData.inlandCity;
                } else {
                    return '';
                }
            }
            if (requestName === 'inlandState') {
                if (claimTypeData.uiData.inlandState) {
                     return this.getStateCodeMarine(claimTypeData.uiData.inlandState, this.getDetail('inlandCountry')!=""?this.getDetail('inlandCountry'):this.getDetail('inlandCountryCode'));
                } else {
                    return this.getStateCodeMarine(claimTypeData.uiData.inlandEntryState, this.getDetail('inlandCountry')!=""?this.getDetail('inlandCountry'):this.getDetail('inlandCountryCode')); 
                }
            }
            if (requestName === 'inlandStatePdf') {
                if (claimTypeData.uiData.inlandState) {
                    return claimTypeData.uiData.inlandState;
                } else if (claimTypeData.uiData.inlandEntryState) { 
                    return claimTypeData.uiData.inlandEntryState; 
                } else {
                    return '';
                }
            }            
            if (requestName === 'inlandPostalCode') {
                if (claimTypeData.uiData.inlandPostalCode) {
                    return claimTypeData.uiData.inlandPostalCode;
                } else if (claimTypeData.uiData.inlandEntryPostalCode) { 
                    return claimTypeData.uiData.inlandEntryPostalCode; 
                } else {
                    return '';
                }
            }
            if (requestName === 'inlandCountry') {
                if (claimTypeData.uiData.inlandEntryCountry && claimTypeData.uiData.inlandEntryCountry.name) {
                    return claimTypeData.uiData.inlandEntryCountry.name
                } else if (claimTypeData.uiData.inlandCountry && claimTypeData.uiData.inlandCountry.name) {
                    return claimTypeData.uiData.inlandCountry.name;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'inlandCountryMTCCode') {
                if (claimTypeData.uiData.inlandEntryCountryMTC) {
                    return claimTypeData.uiData.inlandEntryCountryMTC.code ? claimTypeData.uiData.inlandEntryCountryMTC.code : claimTypeData.uiData.inlandEntryCountryMTC.key; 
                } else if (claimTypeData.uiData.inlandCountryMTC) {
                    return claimTypeData.uiData.inlandCountryMTC.code ? claimTypeData.uiData.inlandCountryMTC.code : claimTypeData.uiData.inlandCountryMTC.key;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'inlandCountryCode') {
                if (claimTypeData.uiData.inlandEntryCountry) {
                    return claimTypeData.uiData.inlandEntryCountry.code ? claimTypeData.uiData.inlandEntryCountry.code : claimTypeData.uiData.inlandEntryCountry.key;
                } else if (claimTypeData.uiData.inlandCountry) {
                    return claimTypeData.uiData.inlandCountry.code ? claimTypeData.uiData.inlandCountry.code : claimTypeData.uiData.inlandCountry.key;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'inlandPhoneNumber') {
                return claimTypeData.uiData.inlandPhoneNumber['phoneNumber'] ? 
                (claimTypeData.uiData.inlandPhoneNumber['phoneCode'].label ? claimTypeData.uiData.inlandPhoneNumber['phoneCode'].label : claimTypeData.uiData.inlandPhoneNumber['phoneCode'])  +'-'+ claimTypeData.uiData.inlandPhoneNumber['phoneNumber'] :
                 '';
            }
            if (requestName === 'inlandPhoneNumberExtn') {
                return claimTypeData.uiData.inlandPhoneNumberExtn ? claimTypeData.uiData.inlandPhoneNumberExtn : '';
            }
            if (requestName === 'inlandArrivalDate') {
                if (claimTypeData.uiData.inlandArrivalDate) {
                    return moment(claimTypeData.uiData.inlandArrivalDate).format('MM/DD/YYYY');
                } else {
                    return '';
                } 
            }
            if (requestName === 'inlandArrivalDateOC') {
                return claimTypeData.uiData.inlandArrivalDate ? CommonUtils.convertDateGMT(claimTypeData.uiData.inlandArrivalDate, 'YYYYMMDD') : '';                
            }
            if (requestName === 'inlandAdditional') {
                if (claimTypeData.uiData.inlandAdditional) {
                    return claimTypeData.uiData.inlandAdditional;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'inlandAddress1MTC') {
                if (claimTypeData.uiData.inlandEntryAddress1MTC !== null && claimTypeData.uiData.inlandEntryAddress1MTC.name) {
                    return claimTypeData.uiData.inlandEntryAddress1MTC.name;
                } else if (claimTypeData.uiData.inlandEntryAddress1MTC){
                    return claimTypeData.uiData.inlandEntryAddress1MTC;
                } else if (claimTypeData.uiData.inlandAddress1MTC.name) {
                    return claimTypeData.uiData.inlandAddress1MTC.name;
                } else {
                    return claimTypeData.uiData.inlandAddress1MTC;
                }
            }
            if (requestName === 'inlandAddress2MTC') {
                if (claimTypeData.uiData.inlandEntryAddress2MTC) {
                    return claimTypeData.uiData.inlandEntryAddress2MTC; 
                 } else if (claimTypeData.uiData.inlandAddress2MTC) {
                     return claimTypeData.uiData.inlandAddress2MTC;
                 } else { 
                     return ''; 
                 }
            }
            if (requestName === 'inlandCityMTC') {
                if (claimTypeData.uiData.inlandEntryCityMTC) {
                    return claimTypeData.uiData.inlandEntryCityMTC;
                } else {
                    return claimTypeData.uiData.inlandCityMTC;
                }
            }
            if (requestName === 'inlandStateMTC') {
                if (claimTypeData.uiData.inlandStateMTC) {
                    return this.getStateCodeMarine(claimTypeData.uiData.inlandStateMTC, this.getDetail('inlandCountryMTC'));
                } else { 
                    return this.getStateCodeMarine(claimTypeData.uiData.inlandEntryStateMTC, this.getDetail('inlandCountryMTC'));; 
                }
            }
            if (requestName === 'inlandStateMTCPDF') {
                if (claimTypeData.uiData.inlandStateMTC)  {
                    return claimTypeData.uiData.inlandStateMTC;
                } else if (claimTypeData.uiData.inlandEntryStateMTC) { 
                    return claimTypeData.uiData.inlandEntryStateMTC; 
                } else {
                    return '';
                }
            }
            if (requestName === 'inlandPostalCodeMTC') {
                if (claimTypeData.uiData.inlandPostalCodeMTC) {
                    return claimTypeData.uiData.inlandPostalCodeMTC;
                } else { 
                    return claimTypeData.uiData.inlandEntryPostalCodeMTC; 
                }
            }
            if (requestName === 'inlandCountryMTC') {
                if (claimTypeData.uiData.inlandEntryCountryMTC && claimTypeData.uiData.inlandEntryCountryMTC.name) {
                    return claimTypeData.uiData.inlandEntryCountryMTC.name
                } else if (claimTypeData.uiData.inlandCountryMTC && claimTypeData.uiData.inlandCountryMTC.name) {
                    return claimTypeData.uiData.inlandCountryMTC.name;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'inlandPhoneNumberMTC') {
                return claimTypeData.uiData.inlandPhoneNumberMTC['phoneNumber'] ? 
                        (claimTypeData.uiData.inlandPhoneNumberMTC['phoneCode'].label ? claimTypeData.uiData.inlandPhoneNumberMTC['phoneCode'].label : claimTypeData.uiData.inlandPhoneNumberMTC['phoneCode']) +'-'+ claimTypeData.uiData.inlandPhoneNumberMTC['phoneNumber'] :
                         '';
            }
            if (requestName === 'inlandPhoneNumberMTCExtn') {
                return claimTypeData.uiData.inlandPhoneNumberMTCExtn ? claimTypeData.uiData.inlandPhoneNumberMTCExtn : '';
            }
            if (requestName === 'inlandArrivalDateMTC') {
                if (claimTypeData.uiData.inlandArrivalDateMTC) {
                    return moment(claimTypeData.uiData.inlandArrivalDateMTC).format('MM/DD/YYYY');
                } else {
                    return '';
                }
            }
            if (requestName === 'inlandArrivalDateMTCOC') {
                return claimTypeData.uiData.inlandArrivalDateMTC ? CommonUtils.convertDateGMT(claimTypeData.uiData.inlandArrivalDateMTC, 'YYYYMMDD') : '';
            }
            if (requestName === 'inlandAdditionalMTC') {
                if (claimTypeData.uiData.inlandAdditionalMTC) {
                    return claimTypeData.uiData.inlandAdditionalMTC;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'vehicle') {
                return claimTypeData.uiData.vehicle;
            }
            if (requestName === 'year') {
                return claimTypeData.uiData.year;
            }
            if (requestName === 'make') {
                return claimTypeData.uiData.make;
            }
            if (requestName === 'model') {
                if (claimTypeData.uiData.model) {
                    return claimTypeData.uiData.model;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'vin') {
                return claimTypeData.uiData.vin;
            }
            if (requestName === 'unit') {
                if (claimTypeData.uiData.unit) {
                    return claimTypeData.uiData.unit;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'year2') {
                return claimTypeData.uiData.year2;
            }
            if (requestName === 'make2') {
                return claimTypeData.uiData.make2;
            }
            if (requestName === 'model2') {
                if (claimTypeData.uiData.model2) {
                    return claimTypeData.uiData.model2;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'vehicle2') {
                return claimTypeData.uiData.vehicle2;
            }
            if (requestName === 'unit2') {
                if (claimTypeData.uiData.unit2) {
                    return claimTypeData.uiData.unit2;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'year2CRD') {
                return claimTypeData.uiData.year2CRD;
            }
            if (requestName === 'make2CRD') {
                return claimTypeData.uiData.make2CRD;
            }
            if (requestName === 'model2CRD') {
                if (claimTypeData.uiData.model2CRD) {
                    return claimTypeData.uiData.model2CRD;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'vehicle2CRD') {
                return claimTypeData.uiData.vehicle2CRD;
            }
            if (requestName === 'unit2CRD') {
                if (claimTypeData.uiData.unit2CRD) {
                    return claimTypeData.uiData.unit2CRD;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'mtcInvolved') {
                if (claimTypeData.uiData.mtcAvailable) {
                    return claimTypeData.uiData.mtcAvailable;
                } else { 
                    return ''; 
                }
            }
            if (requestName === 'sameLocation') {
                if (claimTypeData.uiData.sameLocation) {
                    return claimTypeData.uiData.sameLocation;
                } else { 
                    return 'Yes'; 
                }
            }
            if (requestName === 'firstNameStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandFirstName;
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoFirstName ? claimTypeData.uiData.cargoFirstName : '';
                }
            }
            if (requestName === 'lastNameStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandLastName;
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoLastName ? claimTypeData.uiData.cargoLastName : '';
                }
            }
            if (requestName === 'address1Step3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    if (claimTypeData.uiData.inlandAddress1 && typeof claimTypeData.uiData.inlandAddress1 === 'string') {
                        return claimTypeData.uiData.inlandAddress1;
                    } else if (claimTypeData.uiData.inlandAddress1 && 
                                typeof claimTypeData.uiData.inlandAddress1 === 'object' &&
                                claimTypeData.uiData.inlandAddress1.hasOwnProperty('name')) {
                        return claimTypeData.uiData.inlandAddress1.name;
                    } else { 
                        return ''; 
                    }
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    if (claimTypeData.uiData.cargoAddress1 && typeof claimTypeData.uiData.cargoAddress1 === 'string') {
                        return claimTypeData.uiData.cargoAddress1;
                    } else if (claimTypeData.uiData.cargoAddress1 && 
                                typeof claimTypeData.uiData.cargoAddress1 === 'object' &&
                                claimTypeData.uiData.cargoAddress1.hasOwnProperty('name')) {
                        return claimTypeData.uiData.cargoAddress1.name;
                    } else { 
                        return '';
                    }
                }
            }
            if (requestName === 'address2Step3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandAddress2 ? claimTypeData.uiData.inlandAddress2 : '';
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoAddress2 ? claimTypeData.uiData.cargoAddress2 : '';
                } else {
                    return '';
                }
            }
            if (requestName === 'cityStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandCity ? claimTypeData.uiData.inlandCity : '';
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoCity ? claimTypeData.uiData.cargoCity : '';
                } else {
                    return '';
                }
            }
            if (requestName === 'stateStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandState ? claimTypeData.uiData.inlandState : '';
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoState ? claimTypeData.uiData.cargoState : '';
                } else {
                    return '';
                }
            }
            if (requestName === 'postalCodeStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandPostalCode ? claimTypeData.uiData.inlandPostalCode : '';
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoPostalCode ? claimTypeData.uiData.cargoPostalCode : '';
                } else {
                    return '';
                }
            }
            if (requestName === 'countryCodeStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandCountry ? claimTypeData.uiData.inlandCountry : '';
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoCountry ? claimTypeData.uiData.cargoCountry : '';
                } else {
                    return '';
                }
            }
            if (requestName === 'mailStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    return claimTypeData.uiData.inlandEmail ? claimTypeData.uiData.inlandEmail : '';
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    return claimTypeData.uiData.cargoEmail ? claimTypeData.uiData.cargoEmail : '';
                } else {
                    return '';
                }
            }
            if (requestName === 'phoneArrayStep3') {
                if (claimTypeData.uiData.claimBenefits === 'Inland Marine') {
                    const phoneArray = [];
                    const primaryNo = claimTypeData.uiData.inlandPrimaryNumber['phoneNumber'];
                    const secondaryNo = claimTypeData.uiData.inlandSecondaryNumber['phoneNumber'];
                    if (primaryNo) {
                        phoneArray.push(
                            {
                                'm1:PhoneTypeCd': 'Home/Work Phone',
                                'm1:PhoneNumber': claimTypeData.uiData.inlandPrimaryNumber['phoneNumber'],
                                'm1:PhoneExtn': claimTypeData.uiData.inlandPrimaryNumberExtn ? claimTypeData.uiData.inlandPrimaryNumberExtn : '',
                                'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                            }
                        );
                    }
                    if (secondaryNo) {
                        phoneArray.push(
                            {
                                'm1:PhoneTypeCd': 'Mobile Phone',
                                'm1:PhoneNumber': claimTypeData.uiData.inlandSecondaryNumber['phoneNumber'],
                                'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                            }
                        );
                    }
                    return phoneArray;
                } else if (claimTypeData.uiData.claimBenefits === 'Marine Cargo') {
                    const phoneArray = [];
                    const primaryNo = claimTypeData.uiData.cargoPrimaryNumber['phoneNumber'];
                    const secondaryNo = claimTypeData.uiData.cargoSecondaryNumber['phoneNumber'];
                    if (primaryNo) {
                        phoneArray.push(
                            {
                                'm1:PhoneTypeCd': 'Home/Work Phone',
                                'm1:PhoneNumber': claimTypeData.uiData.cargoPrimaryNumber['phoneNumber'],
                                'm1:PhoneExtn': claimTypeData.uiData.cargoPrimaryNumberExtn ? claimTypeData.uiData.cargoPrimaryNumberExtn : '',
                                'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                            }
                        );
                    }
                    if (secondaryNo) {
                        phoneArray.push(
                            {
                                'm1:PhoneTypeCd': 'Mobile Phone',
                                'm1:PhoneNumber': claimTypeData.uiData.cargoSecondaryNumber['phoneNumber'],
                                'm1:CountryPhoneCd': URL_PARAMETERS.COUNTRY
                            }
                        );
                    }
                    return phoneArray;
                }
            }
        }
    }

    getStateCode(stateName): string {
        this.stateCode = '';
        if (this.getDetail('countryCode') === 'US' || this.getDetail('countryCode') === 'CA') {
            this.stateArray.forEach(state => {
                if (state.name === stateName) {
                    this.stateCode =  state.code;
                }
            });
            if (this.stateCode !== '') {
                return this.stateCode;
            } else {
                return stateName;
            }
        } else {
            return stateName;
        }
    }

    getStateCodeMarine(stateName, country): string {
        this.stateCode = '';
        if (country === 'United States' || country.toLowerCase() === 'us' || 
            country === 'Canada' || country.toLowerCase() === 'ca') {
            this.stateArray.forEach(state => {
                if (state.name === stateName) {
                    this.stateCode =  state.code;
                }
            });
            if (this.stateCode !== '') {
                return this.stateCode;
            } else {
                return stateName;
            }
        } else {
            return stateName;
        }
    }

    getLossInfo(): any {
        let index = 0;
        const lossInfoArray = [];
        if (this.getDetail('claimType') === 'Inland Marine' && this.getDetail('inlandLoss') === 'VPDMTC') {
            if (this.getDetail('noOfVPD') === 'one' || this.getDetail('noOfVPD') === 'two') {
                let vpd1 = {
                    'm2:FeatureTypeCd': 'PD',
                    'm2:FeatureNumber': {
                        '@id': true,
                        '$': ++index
                    },
                    'm1:QuestionAnswer': this.featureQAData()
                };
                lossInfoArray.push(vpd1);
            }
            if (this.getDetail('noOfVPD') === 'two') {
                let vpd2 = {
                    'm2:FeatureTypeCd': 'PD',
                    'm2:FeatureNumber': {
                        '@id': true,
                        '$': ++index
                    },
                    'm1:QuestionAnswer': this.featureQAData()
                };
                lossInfoArray.push(vpd2);
            }
            if (this.getDetail('mtcInvolved') === 'Yes') {
                if (this.getDetail('sameLocation') === 'Yes') {
                    let mtc = {
                        'm2:FeatureTypeCd': 'TPD',
                        'm2:FeatureNumber': {
                            '@id': true,
                            '$': ++index
                        },
                        'm1:QuestionAnswer': this.featureQAData()
                    };
                    lossInfoArray.push(mtc);
                } else if (this.getDetail('sameLocation') === 'No') {
                    let mtc = {
                        'm2:FeatureTypeCd': 'TPD',
                        'm2:FeatureNumber': {
                            '@id': true,
                            '$': ++index
                        },
                        'm1:QuestionAnswer': this.featureQAData('mtcPresence')
                    };
                    lossInfoArray.push(mtc);
                }
            }
        } else if ((this.getDetail('claimType') === 'Inland Marine' && this.getDetail('inlandLoss') === 'Commercial Property') || 
                    (this.getDetail('claimType') === 'Inland Marine' && this.getDetail('inlandLoss') === 'CRD')) {
            let vpd1 = {
                'm2:FeatureTypeCd': 'PD',
                'm2:FeatureNumber': {
                    '@id': true,
                    '$': ++index
                },
                'm1:QuestionAnswer': this.featureQAData()
            };
            lossInfoArray.push(vpd1);
        } else if (this.getDetail('claimType') === 'Marine Cargo') {
            let vpd1 = {
                'm2:FeatureTypeCd': 'PD',
                'm2:FeatureNumber': {
                    '@id': true,
                    '$': ++index
                },
                'm1:QuestionAnswer': []
            };
            vpd1['m1:QuestionAnswer'].push(
                {
                    "m1:QuestionText": "Address Line 1",
                    "m1:YesNoCd": this.getDetail('cargoAddress1')
                },
                {
                    "m1:QuestionText": "Address Line 2",
                    "m1:YesNoCd": this.getDetail('cargoAddress2')
                },
                {
                    "m1:QuestionText": "City",
                    "m1:YesNoCd": this.getDetail('cargoCity')
                },
                {
                    "m1:QuestionText": "State",
                    "m1:YesNoCd": this.getDetail('cargoState')
                },
                {
                    "m1:QuestionText": "Zip/Postal Code",
                    "m1:YesNoCd": this.getDetail('cargoPostalCode')
                },
                {
                    "m1:QuestionText": "Country",
                    "m1:YesNoCd": this.getDetail('cargoCountry')
                }
            )
            lossInfoArray.push(vpd1);
        }
        return lossInfoArray;
    }

    featureQAData(mtcPresence?) {
        let featureQADataArray = [];
        featureQADataArray.push(
            {
                "m1:QuestionText": "Address Line 1",
                "m1:YesNoCd": mtcPresence ? this.getDetail('inlandAddress1MTC') : this.getDetail('inlandAddress1')
            },
            {
                "m1:QuestionText": "Address Line 2",
                "m1:YesNoCd": mtcPresence ? this.getDetail('inlandAddress2MTC') : this.getDetail('inlandAddress2')
            },
            {
                "m1:QuestionText": "City",
                "m1:YesNoCd": mtcPresence ? this.getDetail('inlandCityMTC') : this.getDetail('inlandCity')
            },
            {
                "m1:QuestionText": "State",
                "m1:YesNoCd": mtcPresence ? this.getDetail('inlandStateMTC') : this.getDetail('inlandState')
            },
            {
                "m1:QuestionText": "Zip/Postal Code",
                "m1:YesNoCd": mtcPresence ? this.getDetail('inlandPostalCodeMTC') : this.getDetail('inlandPostalCode')
            },
            {
                "m1:QuestionText": "Country",
                "m1:YesNoCd": mtcPresence ? this.getDetail('inlandCountryMTCCode') : this.getDetail('inlandCountryCode')
            }
        );
        return featureQADataArray;
    }

}
