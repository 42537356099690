import { Component, OnInit } from '@angular/core';
import { FnolService } from '../../services/fnol.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html'
})
export class ServiceUnavailableComponent implements OnInit {
  isJp: boolean;

  constructor(private fnolService: FnolService,
    private route: ActivatedRoute,
    private router: Router) {
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          history.back()
        }
      })

  }

  ngOnInit(): void {
    const res = this.route.snapshot.paramMap.get('country');
    if( res == "jp"){
      this.isJp =  true
    }else{
      this.isJp =  false
    }
     this.goTop();
  }
  /* methods */
  goTop() {  // reset the page when changing route
    window.scrollTo(0, 0);
  }
}
