<div class="axis-content-wrapper" data-bg="primary">
  <div class="axis-container-fluid">
    <div class="axis-grid _justify-content-center initial-empty-container">

      <div class="axis-col-md-7">
        <div class="_text-right">
          <a data-color="white" href="https://www.aig.co.jp/content/dam/aig/sonpo/jp/ja/documents/services/User_guide.pdf" 
          target="_blank"
          > ヘルプ </a>
        </div>
      <div class="axis-card">
        <div class=" axis-card-body _p-5">
          <img alt="logo" class="axis-img-responsive _w-10" src="assets/images/logos/aig-logo-blue.svg" />
          <img alt="success" class="axis-img-responsive _mlr-auto" src="assets/images/messages/BindSuccess.png" />
          <p class="_text-xxlarge _text-bold"><span i18n="@@thanksRegistration">ID{{ thanks }}</span></p>
          <p *ngIf="language == 'jp'; else notJP">{{ email }}{{ instructions }}</p>
          <ng-template #notJP>
            <p>{{ instructions }}</p>
            
          </ng-template>
          <!-- <div class="axis-btn-wrapper">
            <div class="axis-btn-wrapper-center">
              <button
              class="axis-btn"
  axis-button
  data-kind="secondary"
  >
  <span class="axis-btn-icon-text">Resend Email</span>
</button>
            </div>
          </div> -->
        </div>
        </div>
      </div>

    </div>
  </div>
</div>
