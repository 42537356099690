<div class="axis-content-wrapper">
  <div class="axis-container-fluid">
    <div class="axis-grid _justify-content-center initial-empty-container">
      <div class="axis-col-md-8">
        <img
          alt=""
          class="axis-img-responsive _mlr-auto"
          src="assets/images/messages/503.svg"
        />
        <ng-container *ngIf="isJp !== true">
          <!-- <h3 class="axis-h5">Notice of System Maintenance</h3>
          <p>The system is currently under maintenance we apologize for the inconvenience. Please try again later.</p>
          <p class="_nmb">Scheduled outage period</p>
          <h5 class="axis-h6 _nmt">{{time.startTime}} to {{time.endTime}}</h5> -->
          <h5 class="_text-xxxlarge _text-light _nm"  i18n="@@site">This site is under scheduled maintenance </h5>
          <h5 class="_text-xxlarge _text-light _nmt"> <span i18n="@@from">From</span> {{time.startTime}} <span i18n="@@to">to</span>  {{time.endTime}}</h5>
          <p class="_text-body" i18n="@@apologize">The system is currently under maintenance we apologize for the inconvenience.</p>

        </ng-container>
        <ng-container *ngIf="isJp === true">
          <h1 class="_text-xxxlarge _text-light _nmb">システムメンテナンスのお知らせ</h1>
          <h5 class="_text-xxxlarge _text-light _nm">停止予定時間帯</h5>
          <h5 class="_text-xxlarge _text-light _nmt">{{time.startTime}} ~ {{time.endTime}}</h5>
          <p class="_text-left _mt-xxlarge">現在、システムメンテナンスのため、インターネットによる事故等の受付を停止させていただいております。</p>
          <p class="_text-left">停止予定時間以降に再度アクセスしていただきますようお願い申し上げます。</p>
          <p class="_text-left _mt-xlarge _mb-xlarge">ご利用のお客さまには大変ご不便をおかけしますが、何卒ご理解いただきますようお願い申し上げます。</p>
          <p class="_text-left">※メンテナンスの状況により、停止予定時間が前後または延長する場合があります。ご了承ください。</p>
          <p class="_text-left">※お手続き中に本画面が表示されたお客さまは、サービス停止予定時間以降に、もう一度最初からお手続きをお願い申し上げます。</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
