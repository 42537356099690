import { APPLICATION_ID } from '../../config/fnol-constants';

export const icorrSubmit = {
    iCorrespondence: {
      requestHeader: {
        userID: 'O1111147',
        lineOfBusiness: '',
        requestApplicationID: 'FNOL',
        requestMessageID: '',
        language: '',
        countryCode: '',
        systemDate: ''
      },
      ApplicationContext: {
        applicationID: APPLICATION_ID,
        country: '',
        language: ''
      },
      correspondenceItem: {
        pdfGenerationData: {
          correspondenceItemID: 'FNOL_AH',
          claimDetails: {
            claimID: '',
            cdpDetails: {
              info: [
                {
                    title: 'Policy Information',
                    attribute: null
                 }
              ]
            },
          }
        },
        distributionGenerationData: {
          insuredID: '0000013039',
          clientName: '000000001',
          notificationType: 'FROI',
          country: '',
          lob: '',
          lossState: 'TX',
          lossAddressID: '000003641'
        },
        correspondenceGenerationData: {
          callerDistributionFax: '',
          callerDistributionEMAIL: '',
          claimantEmail: '',
          brokerEmail: '',
          sharedServiceEmail: '',
          isNotificationClaim: '',
          lossState: '',
          reportedByFirstName: '',
          reportedByLastName: '',
          mobile: '',
          businessPhone: '',
          originKey: '',
          dateReported: '',
          lossDate: '',
          insuredName: '',
          policyNumber: '',
          clientName: 'AIG'
        }
      }
    }
  };
