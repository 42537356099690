export const stepChangeArray ={
     "chooseInsurance": { "prevStep": null, "nextStep": null, nextContainer: "insuranceDetails",isClaimAmount:false },
     "insuranceDetails": { "prevStep": null, "nextStep": null, nextContainer: "personalDetails", prevContainer: "chooseInsurance",isClaimAmount:false },
     "personalDetails": { "prevStep": null, "nextStep": "fnol-claimType-details", prevContainer: "insuranceDetails", nextContainer: "tripDetails",isClaimAmount:false },
     "tripDetails": { "prevStep": "fnol-claimant-info", "nextStep": null, prevContainer: "personalDetails", nextContainer: "",isClaimAmount:false },
     "uploadDocumentDetails":{ "prevStep": "fnol-claimtype-details", "nextStep": "fnol-payment-details", prevContainer: "claimAmountDetails", nextContainer: "paymentDetails",isClaimAmount:false },
     "paymentDetails":{ "prevStep": "fnol-upload-details", "nextStep": "fnol-declaration", prevContainer: "uploadDocumentDetails", nextContainer: "declarationDetails",isClaimAmount:false },
     "declarationDetails":{ "prevStep": null, "nextStep": "fnol-declaration", prevContainer: "paymentDetails", nextContainer: "",isClaimAmount:false }

};
export const stepChangeArrayAH={
          "chooseInsuranceAccident": { "prevStep": null, "nextStep": null, nextContainer: "insuranceDetails"},
          "chooseInsuranceCriticalIllness": { "prevStep": null, "nextStep": null, nextContainer: "insuranceDetails"},
          "insuranceDetails": { "prevStep": null, "nextStep": null, nextContainer: "personalDetails", prevContainer: "chooseInsurance"},
          "personalDetails": { "prevStep": null, "nextStep": "fnol-claimType-details", prevContainer: "insuranceDetails", nextContainer: "personalAccidentDetails"},
          "uploadDocumentDetails":{ "prevStep": "fnol-claimtype-details", "nextStep": "fnol-payment-details", prevContainer: "claimAmountDetails", nextContainer: "paymentDetails"},
          "paymentDetails":{ "prevStep": "fnol-upload-details", "nextStep": "fnol-declaration", prevContainer: "uploadDocumentDetails", nextContainer: "declarationDetails"},
          "declarationDetails":{ "prevStep": null, "nextStep": "fnol-declaration", prevContainer: "paymentDetails", nextContainer: "" }
};
export const stepChangeArrayHLF = {
     "chooseInsurance": { "prevStep": null, "nextStep": null, nextContainer: "insuranceDetails", isClaimAmount: false },
     "insuranceDetails": { "prevStep": null, "nextStep": null, nextContainer: "personalDetails", prevContainer: "chooseInsurance", isClaimAmount: false },
     "personalDetails": { "prevStep": null, "nextStep": "fnol-claimtype-details", prevContainer: "insuranceDetails", nextContainer: "claimDetails", isClaimAmount: false },
     "claimDetails": { "prevStep": "fnol-claimant-info", "nextStep": null, prevContainer: "personalDetails", nextContainer: "supplemental", isClaimAmount: false },
     "lostClaimDetails": { "prevStep": "fnol-claimant-info", "nextStep": null, prevContainer: "personalDetails", nextContainer: "lostSupplemental", isClaimAmount: false },
     "lostSupplemental": { "prevStep": null, "nextStep": "fnol-upload-details", prevContainer: "lostClaimDetails", nextContainer: "uploadDocumentDetails", isClaimAmount: false },
     "damageClaimDetails": { "prevStep": "fnol-claimant-info", "nextStep": null, prevContainer: "personalDetails", nextContainer: "damageSupplemental", isClaimAmount: false },
     "damageSupplemental": { "prevStep": null, "nextStep": "fnol-upload-details", prevContainer: "damageClaimDetails", nextContainer: "uploadDocumentDetails", isClaimAmount: false },
     "stolenClaimDetails": { "prevStep": "fnol-claimant-info", "nextStep": null, prevContainer: "personalDetails", nextContainer: "stolenSupplemental", isClaimAmount: false },
     "stolenSupplemental": { "prevStep": null, "nextStep": "fnol-upload-details", prevContainer: "stolenClaimDetails", nextContainer: "uploadDocumentDetails", isClaimAmount: false },
     "uploadDocumentDetails": { "prevStep": "fnol-claimtype-details", "nextStep": "fnol-payment-details", prevContainer: "claimDetails", nextContainer: "paymentDetails", isClaimAmount: false },
     "paymentDetails": { "prevStep": "fnol-upload-details", "nextStep": "fnol-declaration", prevContainer: "uploadDocumentDetails", nextContainer: "declarationDetails", isClaimAmount: false },
     "declarationDetails": { "prevStep": null, "nextStep": "fnol-declaration", prevContainer: "paymentDetails", nextContainer: "", isClaimAmount: false },
};
export const landingPageLabelsAh = [
{ name: "personalAccident", rpaValue: "Personal Accident", heading: "Personal Accident", containerName: "personalAccidentDetails", supplementLabel: "Supplemental",checked: false },
{ name: "criticalIllness", rpaValue: "Critical Illness", heading: "Critical Illness", containerName: "criticalIllnessDetails", supplementLabel: "Supplemental",checked: false },
{ name: "accidentalDeath", rpaValue: "Accidental Death", heading: "Accidental Death", containerName: "accidentalDeathDetails", supplementLabel: "Supplemental",checked: false },
];
export const landingPageLabelsAhNO = [
     { name: "personalAccident", rpaValue: "Personal Accident", heading: "Personulykke", containerName: "personalAccidentDetails", supplementLabel: "Supplemental",checked: false },
     { name: "criticalIllness", rpaValue: "Critical Illness", heading: "Kritisk sykdom", containerName: "criticalIllnessDetails", supplementLabel: "Supplemental",checked: false },
     { name: "accidentalDeath", rpaValue: "Accidental Death", heading: "Dødsfall som følge av ulykke", containerName: "accidentalDeathDetails", supplementLabel: "Supplemental",checked: false },
     ];
     

export const landingPageLabelsHLF = [
     { name: "lost", heading: "Lost", rpaValue: "Lost", containerName: "lostClaimDetails", supplementLabel: "Supplemental", checked: false },
     { name: "damaged", heading: "Broken / Damaged", rpaValue: "Broken / Damaged", containerName: "damageClaimDetails", supplementLabel: "Supplemental", checked: false },
     { name: "stolen", heading: "Stolen",rpaValue: "Stolen", containerName: "stolenClaimDetails", supplementLabel: "Supplemental", checked: false }
];
export const landingPageLabelsHLFNO = [
     { name: "lost", heading: "Tapt / mistet", rpaValue: "Lost", containerName: "lostClaimDetails", supplementLabel: "Supplemental",checked: false },
     { name: "damaged", heading: "Ødelagt / skadet", rpaValue: "Broken / Damaged", containerName: "damageClaimDetails", supplementLabel: "Supplemental",checked: false },
     { name: "stolen", heading: "Tyveri",rpaValue: "Stolen", containerName: "stolenClaimDetails", supplementLabel: "Supplemental",checked: false }
     ];
     
    
export const landingPageLabels = [{ name: "damage",rpaValue: "Baggage damage", heading: "Baggage damage", containerName: "baggageDamageDetails", supplementLabel: "Supplemental",checked: false },
{ name: "airline", rpaValue: "Baggage delay / Loss of checked baggage",heading: "Baggage delay / Loss of checked baggage", containerName: "baggageDelayDetails", supplementLabel: "Supplemental",checked: false },
{ name: "theft", rpaValue: "Baggage theft", heading: "Theft/Loss of Baggage", containerName: "baggageTheftDetails", supplementLabel: "Supplemental",checked: false },
{ name: "rental",rpaValue: "Car rental / Collision damage waiver", heading: "Car rental / Collision damage waiver", containerName: "carRentalDetails", supplementLabel: "Supplemental",checked: false },
{ name: "travel",rpaValue: "Travel medical expenses", heading: "Travel medical expenses", containerName: "travelMedicalDetails", supplementLabel: "Supplemental",checked: false },
{ name: "cancel",rpaValue: "Trip cancellation", heading: "Trip cancellation", containerName: "tripCancelDetails", supplementLabel: "Supplemental",checked: false },
{ name: "delay", rpaValue: "Trip delay",heading: "Trip delay", containerName: "tripDelayDetails", supplementLabel: "Supplemental",checked: false }];


export const lossTypeCopyFields=['Date','Time','Country'];


export const CUSTOMPHONE= {
     "type": "custom",
     "name": "personalPhone",
     "questionCd": "phoneNumber",
     "label": "Phone Number",
     "placeholder": "Enter Phone Number",
     "element": "PhoneWithFlagComponent",
     "size": "b",
     "required":"true",
      "section":"mainSectionStep1",
     "custom": "",
     "minLength": 8,
     "maxLength": 15,

   }

   export const CUSTOMACCOUNT= {
    "type": "custom",
    "name": "accountnumber",
    "questionCd": "accountnumber",
    "label": "account number",
    "placeholder": "Enter account number",
    "element": "PhoneWithFlagComponent",
    "size": "b",
    "required":"true",
    "section":"mainSectionStep1",
    "custom": "",
    "minLength": 8,
    "maxLength": 15,

  }
  export const dataArray = {
     theft: [ "baggageTheftDetailsDate", "baggageTheftDetailsCountry" ],
     damage: ["baggageDamageDetailsDate", "baggageDamageDetailsCountry"],
     delay: ["scheduledDepartureDate"],
     airline: ["delayedBaggageDate", "delayedBaggageCountry", "lostBaggageDate", "lostBaggageCountry", "confirmedBaggageDate", "confirmedBaggageCountry"],
              travel: ["travelMedicalDetailsDate", "travelMedicalDetailsCountry","travelIllnessDetailsDate","travelIllnessDetailsCountry"],
              cancel: ["ownTripCancelDetailsDate", "kinsTripCancelDetailsDate", "otherTripCancelDetailsDate"],
              rental: ["carRentalDetailsDate", "carRentalDetailsCountry"]
    };
   

   export const FIELDNAMEWITHCOUNTRY=["yourselfCountry","employerCountry","someoneElseCountry","accidentalCountry","startLocation","travelDestination",
   "baggageDamageDetailsCountry","delayedBaggageCountry","countryN","countryN2","countryF","countryF2","lostBaggageCountry","confirmedBaggageCountryR","confirmedBaggageCountry","baggageTheftDetailsCountry","travelMedicalDetailsCountry","tripCancelDetailsCountry","travelIllnessDetailsCountry", "tripDelayDetails","tripDelayDetailsCountry","carRentalDetailsCountry","lostIncidentCountry-yes","lostCountry-no","lostCountry-yes","damageCountry-yes","damageCountry-no","stolenCountry-yes","stolenCountry-no","countryOfAccident",
"countryOfPreviousDiagnosis","countryOfPassing","otherCountry"];

   export const todayOrPastDates=["boxConfirmedDate","dateBirth","someoneElseDateBirth","employerDateBirth","tripDate","baggageDamageDetailsDate","delayedBaggageDate","lostBaggageDate","confirmedBaggageDate","baggageTheftDetailsDate","theftBaggageDate","carRentalDetailsDate","carRentalSupplemnetalStartDate","dateCurtailed","travelMedicalDetailsDate","attentionDate","bedRestStartDate","bedRestEndDate","travelIllnessDetailsDate","illnessAttentionDate","illnessBedRestStartDate","illnessBedRestEndDate","ownDate","ownDateAppointment","kinsDateAppointment","ownTripCancelDetailsDate","kinsTripCancelDetailsDate","otherTripCancelDetailsDate","cancellationDate","scheduledDepartureDate","boxBaggageDate","scheduledArrivalDate","actualDepartureDate","actualArrivalDate","departureDate",
   "dateOfDiagnosisPersonal",
   "dateOfFirstSymptoms",
   "diagnosisDate",
   "dateOfAccident",
   "treatmentAdmissionDate",
   "treatmentDischargeDate","dateOfMedicalTreatment","dateOfPassing",
   "lostDate",
   "dateOfIncidentLost",
   "lostRememberDate",
   "lostIssueDate",
   "dateOfIncidentDamage", 
   "damageDate",
   "damageIssueDate",
   "dateOfIncidentStolen",
   "stolenDate",
   "stolenRememberDate",
   "stolenIssueDate",
   "lostIncidentDate-Yes",
   "otherDate",
   "employerDateBirth",
   "purchaseDateBagWithoutEmp",
   "purchaseDateBagEmp",
   "purchaseDateBagDelayNoEmp",
   "purchaseDateBagDelayLostNoEmp",
   "purchaseDateBagDelayConfLostNoEmp",
   "purDateBagDelayConfLostNoEmp",
   "purchaseDateBagDelayEmp",
   "purDateBagDelayLostEmp",
   "purchaseDateBagDelayConfLostEmp",
   "purDateBagDelayConfLostEmp",
   "purchaseDateBagTheftNoEmp",
   "purchaseDateBagTheftEmp",
   "purchaseDateCarRentalNoEmp",
   "purchaseDateCarRentalEmp",
   "dateExpenseTravelMedicalNoEmp",
   "dateExpenseTravelMedicalWithEmp",
   "purchaseDateTripCancelNoEmp",
   "cancellationDateTripCancelNoEmp",
   "purchaseDateTripCancelEmp",
   "cancellationDateTripCancelEmp",
   "purchaseDateTripDelayWithoutEmp",
   "purchaseDateTripDelayEmp"
];

   export const DateValidateList = [
     { "destination": "boxConfirmedDate", "source": "confirmedBaggageDate", "futureDate": false,"type":"min" },     
     { "destination": "theftBaggageDate", "source": "baggageTheftDetailsDate", "futureDate": false, "type": "max" },
     { "destination": "returnDate", "source": "carRentalSupplemnetalStartDate", "futureDate": true,"type":"min" },
     { "destination": "attentionDate", "source": "travelMedicalDetailsDate", "futureDate": false,"type":"min" },
     { "destination": "bedRestEndDate", "source": "bedRestStartDate", "futureDate": false,"type":"min" },
     { "destination": "illnessBedRestEndDate", "source": "illnessBedRestStartDate", "futureDate": false,"type":"min" },
     { "destination": "illnessAttentionDate", "source": "travelIllnessDetailsDate", "futureDate": false,"type":"min" },
     { "destination": "ownDateAppointment", "source": "ownDate", "futureDate": false,"type":"min" },
     { "destination": "cancellationDate", "source": "ownTripCancelDetailsDate", "futureDate": false,"type":"min" },
     { "destination": "cancellationDate", "source": "kinsTripCancelDetailsDate", "futureDate": false,"type":"min" },
     { "destination": "cancellationDate", "source": "otherTripCancelDetailsDate", "futureDate": false,"type":"min" },
     //{ "destination": "cancellationDate", "source": "tripCancelDetailsDate", "futureDate": false,"type":"min" },
     { "destination": "kinsDateAppointment", "source": "kinsOwnDate", "futureDate": false,"type":"min" },
     { "destination": "scheduledArrivalDate", "source": "scheduledDepartureDate", "futureDate": false,"type":"min" },
     { "destination": "actualDepartureDate", "source": "scheduledDepartureDate", "futureDate": false,"type":"min" },
     { "destination": "actualArrivalDate", "source": "actualDepartureDate", "futureDate": false,"type":"min" },
     { "destination": "departureDate", "source": "scheduledArrivalDate", "futureDate": false,"type":"min" },
     { "destination": "medicalTreatmentDate", "source": "dateOfAccident", "futureDate": false,"type":"min" },
     { "destination": "treatmentDischargeDate","source":"treatmentAdmissionDate", "futureDate": false,"type":"min" },
     // { "destination": "dateOfMedicalTreatment","source":"dateOfFirstSymptoms", "futureDate": false,"type":"min" },
     { "destination": "dateOfPreviousDiagnosis","source":"diagnosisDate", "futureDate": false,"type":"max" }]

     export const DateValidateListHLF = [
     { "destination": "lostRememberDate", "source": "lostIncidentDate-Yes", "futureDate": false, "type": "max" },
     { "destination": "lostRememberDate", "source": "lostDate", "futureDate": false, "type": "max" },
     { "destination": "stolenRememberDate", "source": "stolenDate", "futureDate": false, "type": "max" }
]
  
export const DateValidateListAH = [
     { "destination": "medicalTreatmentDate", "source": "dateOfAccident", "futureDate": false, "type": "min" }
    
]
     export const landingPageLabelsNo = [{ name: "damage", rpaValue: "Baggage damage",heading: "Skade på reisegods", containerName: "baggageDamageDetails", supplementLabel: "Supplemental",checked: false },
{ name: "airline",rpaValue: "Baggage delay / Loss of checked baggage", heading: "Forsinket / tap av innsjekket bagasje", containerName: "baggageDelayDetails", supplementLabel: "Supplemental",checked: false },
{ name: "theft",  rpaValue: "Baggage theft",heading: "Tyveri/tap av reisegods", containerName: "baggageTheftDetails", supplementLabel: "Supplemental",checked: false },
{ name: "rental",rpaValue: "Car rental / Collision damage waiver", heading: "Egenandelsforsikring leiebil", containerName: "carRentalDetails", supplementLabel: "Supplemental",checked: false },
{ name: "travel", rpaValue: "Travel medical expenses", heading: "Utgifter til medisinsk behandling på reise", containerName: "travelMedicalDetails", supplementLabel: "Supplemental",checked: false },
{ name: "cancel", rpaValue: "Trip cancellation", heading: "Avbestilling av reise", containerName: "tripCancelDetails", supplementLabel: "Supplemental",checked: false },
{ name: "delay", rpaValue: "Trip delay", heading: "Forsinkelse på reise", containerName: "tripDelayDetails", supplementLabel: "Supplemental",checked: false }];
   
export const paymentAccountFields=["confirmAccountNumberN","accountNumberN","confirmAccountNumberN2",
"accountNumberN2","confirmAccountNumberF","accountNumberF","confirmAccountNumberF2","accountNumberF2"];
