export var submitClaim = {
	"applicationContext": {
		"countryCode": "JP",
		"language": "en",
		"lineOfBusiness": "7",
		"userId": " "
	  },
	"claimsDetails": {
		"userFilledData": {},
		"followUpClaimNo": null,
		"submission": {
			"acknowledgementNumber": "TEST_ACKNo",
			"submissionDate": "",
			"submissionTime": "",
			"orginatingSource": "AIG",
			"profileAuthentication":{name:'Yes', code:'1'},
            "pwd": {name:'Yes', code:'1'}
		},
		"incidentClaimInformation": {
			"dateofIncident": "",
			"timeofIncident": "",
			"lossLocation": {} // submit claim 1. code will have a value as discussed for CSV
		},
		"policyCoverage": [],
    "policySearch" :[],
		"insuredInformation": {
			"insuredLastNameKanji": "",
			"insuredFirstNameKanji": "",
			"insuredLastNameKatakana": "",
			"InsuredFirstNameKatakana": "",
			"gender": {}, // submit claim 1. code will have a value as discussed for CSV
			"insuredDOB": ""
		},
		"claimantInformation": {
			"claimNumber": "",
			"relationshipClaimantInsured": {}, // submit claim 1. code will have a value as discussed for CSV
			"relationshipOthers": "",
			"lastNameKanji": "",
			"firstNameKanji": "",
			"lastNameKatakana": "",
			"firstNameKatakana": "",
			"claimantAddressDetails": {
				"postalCode": {},
				"zipCode": {},
				"prefecture": {}, // submit claim 1. code will have a value as discussed for CSV
				"cityOrWardOrTownOrVillage": "",
				"condominiumOrBuildingOrFloorOrRoomNumber": "",
				"country": "JP",
				"phone": "",
				"mobile": "",
				"email": ""
			}
		},
		"claimBenefitType": {
			"isNotice":"N",
			"injuryDetails": {
				"causeOfInjuryCode": {}, // submit claim 1. code will have a value as discussed for CSV
				"causeOfInjuryDesc": "",
				"situationOfInjury": {}, // submit claim 1. code will have a value as discussed for CSV
				"descSituationOfInjury": "",
				"injuredPartandStatus": [],
				"medicalInstituion": [],
				"isTreatmentInfoAvailable": {}, // submit claim 1. code will have a value as discussed for CSV
				"injuryTreatmentDetails": {
					"injuryTreatment": {
						"hositalizationStatus": {}, // submit claim 1. code will have a value as discussed for CSV
						"hospitalAdmissionAndDischargeDates": []
					},
					"didSurgeryOccur": {}, // submit claim 1. code will have a value as discussed for CSV
					"surgeryDetails": [],
					"didOutpaientTreatmentOccurr": {}, // submit claim 1. code will have a value as discussed for CSV
					"opttotalNumberofDays": "",
					"optLastDayofTreatment": "",
					"outPatientTreatment": [],
					"wereFixturesUsed": {}, // submit claim 1. code will have a value as discussed for CSV
					"fixtures": [],
					"isAttOfSplClComAccMediExpens": {}, // submit claim 1. code will have a value as discussed for CSV
					"medicalExpenses": {
						"coverageofMedicalExpenses": {},
						"outofPocketMedicalExpenses": "",
						"meansofTransportation": {},
						"transportationExpensesIncurred": "",
						"distanceTraveled": "",
						"roadTollTotal": ""
					},
					"isOtherIsurance": {}, // submit claim 1. code will have a value as discussed for CSV
					"otherInsurance": [],
					"isthereDamagetoPersonalEffects": {}, // submit claim 1. code will have a value as discussed for CSV
					"personalEffectDamage": {
						"locationWhereDamageOccurred": {}, // submit claim 1. code will have a value as discussed for CSV
						"schoolManagement": {} // submit claim 1. code will have a value as discussed for CSV
					},
					"damagedItems": []
				}
			},
			"illnessDetails": {
				"nameofIllnessCausingHospitalization": "",
				"aboutTheSymptoms": "",
				"dateofOccurrence": "",
				"initialConsultationDate": "",
				"medicalInstituion": [],
				"isTreatmentInfoAvailable": {}, // submit claim 1. code will have a value as discussed for CSV
				"illnessTreatmentDetails": {
					"illnessSelectStatus": {}, // submit claim 1. code will have a value as discussed for CSV
					"illnessTreatment": {
						"hositalizationStatus": {}, // submit claim 1. code will have a value as discussed for CSV
						"hositalizationStatusDates": []
					},
					"diagnosedRequiredForMorethan60day": {}, // submit claim 1. code will have a value as discussed for CSV
					"hostipalizedWithSameIllnessAndClaimInsBenfts": {}, // submit claim 1. code will have a value as discussed for CSV
					"didSurgeryOccur": {}, // submit claim 1. code will have a value as discussed for CSV
					"surgeryDetails": [],
					"wasRadiationTherapyUsed": {}, // submit claim 1. code will have a value as discussed for CSV
					"radiationTherapy": []
				}
			},
			"personalEffectsDetails": {
				"incidentPersonalEffectInfo": {
					"dateofIncident": "",
					"timeofIncident": "",
					"lossLocation": {}, // submit claim 1. code will have a value as discussed for CSV
					"wheretheDamageOccurred": {}, // submit claim 1. code will have a value as discussed for CSV
					"accidentSituation": {}, // submit claim 1. code will have a value as discussed for CSV
					"accidentDetails": ""
				},
				"isSchoolManagement": {}, // submit claim 1. code will have a value as discussed for CSV
				"propertyDamageDetails": {
					"damageItems": [],
					"stolenItems": []
				},
				"reportPoliceStation": "",
				"receiptNumber": "",
				"notificationDate": "",
				"reportingParty": ""
			}
		},
		"paymentDetails": {
			"selectionAccount": {}, // submit claim 1. code will have a value as discussed for CSV
			"institutions": {},
			"finacialInstitutions": {
				"nameofFinancialInstitution": "",
				"typeofFinancialInstitution": {}, // submit claim 1. code will have a value as discussed for CSV
				"otherBankDetails": {
					"branchCode": "",
					"branchName": "",
					"accountType": {}, // submit claim 1. code will have a value as discussed for CSV
					"accountNo": "",
					"accountHolderName": ""
				}
			},
			"japanBankDetails": {
				"symbol": "",
				"number": "",
				"accountNameKatakana": ""
			}
		}
	},
	"Icorr": {
		"iCorrespondence": {
			"requestHeader": {
				"userID": "",
				"lineOfBusiness": 5,
				"requestApplicationID": "FNOL",
				"requestMessageID": "",
				"language": "en",
				"countryCode": "JP",
				"systemDate": ""
			},
			"correspondenceItem": {
				"pdfGenerationData": {
					"correspondenceItemID": "FNOL_AH",
					"claimDetails": {
						"claimID": "",
						"policydetailsJapan": {
							"info": []
						},
						"cdpDetails": {
							"info": []
						}
					}
				},
				"distributionGenerationData": {
					"insuredID": "0000013039",
					"clientName": "000000001",
					"notificationType": "FROI",
					"country": "JP",
					"lob": "AL",
					"lossState": "JP",
					"lossAddressID": "000003641"
				},
				"correspondenceGenerationData": {
					"callerDistributionFax": "",
					"callerDistributionEMAIL": "",
					"claimantEmail": "vijayan.chinnamani@aig.com",
					"aigEmail": "aig@aig.com",
					"brokerEmail": "vijayan.chinnamani@aig.com",
					"sharedServiceEmail": "",
					"isNotificationClaim": "",
					"lossState": "",
					"reportedByFirstName": "test",
					"reportedByLastName": "dummy",
					"mobile": "989797",
					"businessPhone": "",
					"originKey": "",
					"dateReported": "",
					"lossDate": "",
					"insuredName": "test",
					"clientName": "AIG"
				}
			}
		}
	}
};
export const resetSubmitClaim = () => {
	submitClaim = JSON.parse(JSON.stringify(master_submitClaim));
}

// this will be set only once and is not available outside this class
const master_submitClaim = JSON.parse(JSON.stringify(submitClaim));

