import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';
import { FnolService } from '../services/fnol.service';
import { Configuration } from '../../../shared/configuration';

@Injectable({
  providedIn: 'root'
})
export class FnolHomeUSApiService {
  headerTransactionId: string = '';
  public claimTypeAsUpdated = new BehaviorSubject<object>({});
  public policyHolderDetails = new BehaviorSubject<any>({ policyNo: "", policyHolderName: "", policyHolderEmail: "", policyFromDate: "", policyToDate: "" });
  public policyToggle = new BehaviorSubject<object>({ policyToggle: '' });
  public transactionID = new BehaviorSubject<string>('');
  public policyDetails = new BehaviorSubject<object>({});
  public telephoneCodeList = new BehaviorSubject(null);
  public paymentTypeUpdated = new BehaviorSubject<object>({});
  public accountNumberUpdated = new BehaviorSubject<object>({});
  policyDetailData;
  phoneCodeList;

  constructor(
    private http: HttpClient,
    private config: Configuration,
    private fnolService: FnolService
  ) {
    this.transactionID.subscribe(res => {
      this.headerTransactionId = res;
    });
    this.policyDetails.subscribe(res => {
      this.policyDetailData = res;
    });
  }

  generateClaimNumberHeaders(): any {
    return {
      headers: new HttpHeaders({
        'email-id': this.config.userEmailID,
        'transactionID': this.headerTransactionId,
        'Authorization': 'Basic ' + this.config['authorizationKey_new']
      })
    };
  }

  getCountryList(): any {
    return this.http.get('assets/data/locale/en-singapore/country-list.json');
  }

  getTelephoneCodeList(): any {
    this.http.get('assets/data/locale/en-singapore/country-telephone-code.json')
      .subscribe(res => {
        this.phoneCodeList = res;
        this.telephoneCodeList.next(res);
      });
  }

  getPolicyDetailsHeaders(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'email-id': this.config.userEmailID,
        'Authorization': 'Basic ' + this.config['authorizationKey_new'],
        ...(this.headerTransactionId ? { 'transactionID': this.headerTransactionId } : {})
      }),
    };
  }

  getPolicyDetails(policyNumber = '', name: string, dol: string = null): Observable<object> {
    let params;
    if (dol) {
      params = new HttpParams()
        .set('policyNo', policyNumber)
        .set('countryCd', 'US')
        .set('cName', name)
        .set('dol', dol);
    } else {
      params = new HttpParams()
        .set('policyNo', policyNumber)
        .set('countryCd', 'US')
        .set('cName', name);
    }
    const searchPolicyURL = `${this.config.apiEndPoints.searchPolicyURL}?${params.toString()}`;
    return this.http.get(searchPolicyURL, this.getPolicyDetailsHeaders()).pipe(map((res: any) => {
      if (res?.transactionId) {
        if (!this.headerTransactionId) {
          const transacID = res?.transactionId;
          this.transactionID.next(transacID);
          this.fnolService.setTransactionId(transacID);
        }
      }
      if (res.response?.policyDetails) {
        this.policyDetailData = res;
        this.policyDetails.next(res);
      }
      return res;
    }), catchError((error: any) => {
      return observableThrowError(error || 'Server error');
    }));
  }

  generateClaimNumber(): Observable<object> {
    const unrGenerateClaimNumberURL = this.config.apiEndPoints.unrGenerateClaimNumberCommon + 'SG';
    const options = this.generateClaimNumberHeaders();
    return this.http.get(unrGenerateClaimNumberURL, options).pipe(map((res: any) => {
      if (res?.transactionId) {
        if (!this.headerTransactionId) {
          const transacID = res?.transactionId;
          this.transactionID.next(transacID);
          this.fnolService.setTransactionId(transacID);
        }
      }
      return res;
    }), catchError((error: any) => {
      return observableThrowError(error || 'Server error');
    }));
  }

  setPolicyHolderDetails(details: any, policyState: string) {
    const holderDetails = {
      policyNo: '',
      policyHolderName: details["insuredName"] || details['policyHolderName'] || details['policyHolderNameNo'],
      policyHolderEmail: details['policyHolderEmail'] || details['policyHolderEmailNo'] || '',
      policyFromDate: details["startDate"] || details['insuranceFrom'] || details['insuranceFromNo'] || '',
      policyToDate: details["plannedEndDate"] || details['insuranceTo'] || details['insuranceToNo'] || ''
    }
    if (holderDetails.policyFromDate) {
      holderDetails.policyFromDate = new Date(holderDetails.policyFromDate);
    }
    if (holderDetails.policyToDate) {
      holderDetails.policyToDate = new Date(holderDetails.policyToDate);
    }
    if (policyState === 'truePolicy') {
      holderDetails.policyNo = details["policyNo"] || details['policyNumber'];
    } else if (policyState === 'falsePolicy') {
      holderDetails.policyNo = 'Not Validated';
    } else {
      holderDetails.policyNo = 'Not Provided';
    }
    console.log('holderDetails', holderDetails);
    this.policyHolderDetails.next(holderDetails);
  }

  generateSetFnolHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'email-id': this.config.userEmailID,
        'transactionID': this.headerTransactionId || '',
        'Authorization': 'Basic ' + this.config['authorizationKey_new']
      }),
    };
  }

  makeHttpPostCall(url, data, options): any {
    return this.http.post(url, data, options).pipe(map((res: any) => {
      return res;
    }), catchError((error: any) => {
      return observableThrowError(error || 'Server error');
    }));
  }

  makeHttpPutCall(url, data, options): any {
    return this.http.put(url, data, options).pipe(map((res: any) => {
      return res;
    }), catchError((error: any) => {
      return observableThrowError(error || 'Server error');
    }));
  }

}
