import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'modal-confirm',
  templateUrl: './modal-confirm.component.html',
  
})
export class ModalConfirmComponent implements OnInit {
  public fromDashboard : any;
  public fromSignOut : any;
  @ViewChild('modalConfirm') modalConfirm;

  constructor(
    private router: Router,
    
  ) { }

  ngOnInit():void {
    
  }
  

  openModal(param?): void {
    this.fromDashboard = false;
    this.fromSignOut = false;
    if(param == 'Dashboard'){
      this.fromDashboard = true;
    } else{
      this.fromSignOut = true;
    }
    this.modalConfirm.open();
  }

  modalCloseOnContinue(param?){
    
    this.router.navigate([param]);
  }
}

