import { CountryService } from './country.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class DataResolver implements Resolve<any>{
    constructor(private service: CountryService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return this.service.loadTranslationsData();
    }
}
