
import {share} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';


@Injectable()
export class ConditionService {

  private _conditionSetting: Object = {};

  conditionSetting$: Observable<any>;
  private _observer: Observer<any>;
  constructor() {
    //this._conditionSetting.pageForm = false;
    //this._conditionSetting.pageUpload = false;
    this.conditionSetting$ = new Observable(observer =>
      this._observer = observer
    ).pipe(share());
  }

  setCondition(type: string, value) {
    this._conditionSetting[type] = value;
    if(this._observer)
      { this._observer.next(type); }
  }

  conditionSetting() { return this._conditionSetting; }
}
