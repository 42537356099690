export const RegistrationConstantJP = {

  "profile": {
    "locale": "ja_JP",
    "emailId": "",
    "lastNameKanji": "",
    "firstNameKanji": "",
    "lastNameKatakana": "",
    "firstNameKatakana": "",
    "phoneNumber": "",
    "mobileNumber": "",
    "zipCode": "",
    "prefecture": "",
    "city": "",
    "addressline1": "",
    "addressLine2": "",
    "countryCd": "JP"
  },
  "skipUserPolicy": "N",
  "userExistedInCDP": "N",
  "policy": {
    "emailId": "",
    "statusCd": "Active",
    "countryCd": "JP",
    "policyDetailsJson": ""
  }
}
