import { Injectable } from '@angular/core';
import { FNOL_STEPS, CONSCENT_DECLARATION, FNOL_COUNTRY } from '../config/fnol-constants';
import { FnolService } from './fnol.service';
import { Configuration } from '../../../shared/configuration';
import { URL_PARAMETERS } from '../config/urlParameters.constant';
import * as moment from 'moment';
import { FnolModelService } from '../services/fnol-model.service';
import { FnolModelUsService } from './fnol-model-us.service';
import { icorrSubmit } from '../shared/model/icorr-submit';
import { DataStoreService } from './data-store.service';
import { CommonUtils } from '../shared/utils/common-utils';

@Injectable({
    providedIn: 'root'
})
export class SubmitClaimModelService {

    uploadedFiles = [];
    readonly isCountryAuOrNz = CommonUtils.isCountryAuOrNz;

    constructor(private fnolService: FnolService,
        private config: Configuration,
        private fnolModelService: FnolModelService,
        private fnolModelUsService: FnolModelUsService,
        private dataStoreService: DataStoreService) { }

    generateSubmitClaimModelData(pdfGenerationData) {

        // Document Upload data starts
        // icorr data starts
        icorrSubmit.iCorrespondence.requestHeader.countryCode = URL_PARAMETERS.COUNTRY.toString();
        icorrSubmit.iCorrespondence.requestHeader.language = URL_PARAMETERS.LANGUAGE.toString();
        icorrSubmit.iCorrespondence.requestHeader.systemDate = moment(new Date()).format('YYYYMMDD');
        icorrSubmit.iCorrespondence.requestHeader.requestMessageID = this.fnolModelService.getClaimNumber();
        icorrSubmit.iCorrespondence.requestHeader.lineOfBusiness = this.fnolModelService.getDetail('lob');
        icorrSubmit.iCorrespondence.ApplicationContext.country = URL_PARAMETERS.COUNTRY.toString();
        icorrSubmit.iCorrespondence.ApplicationContext.language = URL_PARAMETERS.LANGUAGE.toString();
        icorrSubmit.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.claimID = this.fnolModelService.getClaimNumber();
        // icorrSubmit.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[0].attribute[1].value = URL_PARAMETERS.POLICY_NO.toString();
        icorrSubmit.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info = pdfGenerationData;
        const finalStep = {
            title: FNOL_STEPS.DECLARATION.title,
            attribute: []
        };
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
            finalStep.attribute.push(
                {
                    name: CONSCENT_DECLARATION.provision,
                    value: 'Yes'
                },
                {
                    name: CONSCENT_DECLARATION.conscent,
                    value: 'Yes'
                }
            );
        }
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode) {
            finalStep.attribute.push(
                {
                    name: CONSCENT_DECLARATION.declaration,
                    value: 'Yes'
                }
            );
        }
        if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
            finalStep.attribute.push(
                {
                    name: CONSCENT_DECLARATION.conscent,
                    value: 'Yes'
                }
            );
        }
        icorrSubmit.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info.push(finalStep);
        icorrSubmit.iCorrespondence.correspondenceItem.distributionGenerationData.country = 'US';
        icorrSubmit.iCorrespondence.correspondenceItem.distributionGenerationData.lob = 'AL';
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.claimantEmail = this.getDetail('email');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.brokerEmail = this.getDetail('brokerEmail');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.sharedServiceEmail = this.getDetail('sharedServiceEmail');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.insuredName = this.getDetail('policyHolderName');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.policyNumber = this.getDetail('policyNo');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByFirstName = this.getDetail('firstName');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByLastName = this.getDetail('lastName');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.mobile = this.getDetail('mobile');
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.businessPhone = '';
        icorrSubmit.iCorrespondence.correspondenceItem.correspondenceGenerationData.lossDate = this.getDetail('lossDateGMT');
        // icorr data ends

        // post fnoldata fetching from service
        const postFnolConstant = this.fnolModelService.generateFnolModelData();

        // construct request object for submit claim
       
            return Object.assign({},this.fnolModelService.getFnolRequestBody(),{Icorr:icorrSubmit})
            
        
    }

    getDetail(requestName: string): any {
        if (requestName === 'subFolder') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return 'FNOL';
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return 'USSPLCMA';
            }
        }
        const claimantData = this.dataStoreService.get(FNOL_STEPS.CLAIMANT_INFO.name);
        if (requestName === 'policyNo') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return this.fnolModelUsService.getDetail('policyNumber');
            } else {
                return claimantData.uiData.policyNumber;
            }
        }
        if (requestName === 'policyHolderName') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return this.fnolModelUsService.getDetail('policyHolderNameForMail');
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.NAM.countryCode) {
                return claimantData.uiData.policyName ? claimantData.uiData.policyName : '';
            }
        }
        if (requestName === 'email') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return claimantData.uiData.contactEmail;
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return claimantData.uiData.claimantEmail;
            }
        }
        if (requestName === 'brokerEmail') {
            return claimantData.uiData.brokerEmail ? claimantData.uiData.brokerEmail : "";
        }
        if (requestName === 'firstName') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return claimantData.uiData.firstName;
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return claimantData.uiData.claimantFirstName;
            }
        }
        if (requestName === 'lastName') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return claimantData.uiData.lastName;
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return claimantData.uiData.claimantLastName;
            }
        }
        if (requestName === 'mobile') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return claimantData.uiData.contactPhone;
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return claimantData.uiData.claimantPrimaryNumber['phoneNumber'];
            }
        }
        const incidentData = this.dataStoreService.get(FNOL_STEPS.INCIDENT_DETAILS.name);
        if (requestName === 'lossDate') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return moment(incidentData.uiData.tripIncidentDate).format('YYYY-MM-DD');
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return moment(incidentData.uiData.dateLoss).format('YYYY-MM-DD');
            }
        } 
        if (requestName === 'lossDateGMT') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz) {
                return CommonUtils.convertDateGMT(incidentData.uiData.tripIncidentDate, 'YYYYMMDD');
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                return CommonUtils.convertDateGMT(incidentData.uiData.dateLoss, 'YYYYMMDD');
            }
        }
        if (requestName === 'sharedServiceEmail') {
            if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
                const claimTypeCode = this.fnolModelUsService.getDetail('claimTypeCode');
                if (this.config.aigSharedeMailID[URL_PARAMETERS.COUNTRY.toLowerCase()][claimTypeCode]) {
                    return this.config.aigSharedeMailID[URL_PARAMETERS.COUNTRY.toLowerCase()][claimTypeCode];
                } else { return this.config.aigSharedeMailID[URL_PARAMETERS.COUNTRY.toLowerCase()]; }
            } else if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode) {
                return URL_PARAMETERS.TYPE ? this.config.aigSharedeMailID[URL_PARAMETERS.COUNTRY.toLowerCase()]['CAT'] : this.config.aigSharedeMailID[URL_PARAMETERS.COUNTRY.toLowerCase()]['STD'];
            } else {
                return this.config.aigSharedeMailID[URL_PARAMETERS.COUNTRY.toLowerCase()];
            }
        }
    }

    getUploadedFiles(): string[] {
        const uploadsData = this.dataStoreService.get(FNOL_STEPS.UPLOAD_DOCS.name);
        this.uploadedFiles = (uploadsData.uiData.customFileUpload && uploadsData.uiData.customFileUpload.uploads) ?
            uploadsData.uiData.customFileUpload.uploads : [];
        const docObjectArray = [];
        for (let a = 0; a < this.uploadedFiles.length; a++) {
            const docObject = { 'vendorDocID': this.uploadedFiles[a].docId ? this.uploadedFiles[a].docId : '' };
            docObjectArray.push(docObject);
        }
        return docObjectArray;
    }

    getEmailList(): string {
        const claimantData = this.dataStoreService.get(FNOL_STEPS.CLAIMANT_INFO.name);
        if (claimantData.uiData.brokerEmail) {
            return this.getDetail('email') + ',' + claimantData.uiData.brokerEmail;
        } else {
            return this.getDetail('email') + ',' + this.config.brokerEmailID;
        }
    }

    getBranch(): string {
        return this.fnolModelService.getClaimNumber() ? 'REF:' + this.fnolModelService.getClaimNumber() + 'O' : '';
    }

}
