<div  class="axis-content-wrapper" *ngIf="country === 'jp'">
  <div class="_text-right axis-container-fluid" >
    {{config.siteId}}
  </div>
</div>

<footer class="axis-footer">
  <div class="axis-content-wrapper" *ngIf="auth.countryCheck()">
  <ng-container >
    <div
      *ngIf="auth.countryCheck()"
      class="axis-container-fluid"
    >
      <div class="axis-grid _justify-content-center">
        <div class="axis-col-md-4">
          <!-- <h3 i18n="@@TermsUseFooter">About AIG</h3> -->
          <ul class="axis-footer-list"  style="font-family: aig_meiryo;">
            <li i18n="@@TermsUseFooter"><a
                href="https://www.aig.co.jp/sonpo/guideline"
                target="_blank"
              >ご利用条件</a></li>
            <li><a
                href="https://www.aig.co.jp/sonpo/company/direction/privacy-policy"
                target="_blank"
                i18n="privacyFooter"
              >プライバシーポリシー</a></li>
            <li><a
                href="https://www.aig.co.jp/sonpo/company/direction/solicitation"
                target="_blank"
                i18n="policyFooter"
              >勧誘方針</a></li>

          </ul>
        </div>
        <div class="axis-col-md-4">
          <!-- <h3>Legal</h3> -->
          <ul class="axis-footer-list"  style="font-family: aig_meiryo;">
            <li><a
                i18n="conflictFooter"
                href="https://www.aig.co.jp/sonpo/company/direction/conflict"
                target="_blank"
              >利益相反管理基本方針</a></li>
            <li><a
                i18n="forcesFooter"
                href="https://www.aig.co.jp/sonpo/company/direction/anti-social"
                target="_blank"
              >反社会勢力に対する基本方針</a></li>
          </ul>
        </div>

        <div class="axis-col-md-4">
          <!-- <h3>Support</h3> -->
          <ul class="axis-footer-list"  style="font-family: aig_meiryo;">
            <li><a
                i18n="environmentFooter"
                href="https://www.aig.co.jp/sonpo/contact/requirements"
                target="_blank"
              >推奨環境</a></li>
            <li><a
                i18n="postedFooter"
                href="https://www.aig.co.jp/sonpo/contact/outside-site"
                target="_blank"
              >本ウェブサイト以外で掲載の情報について</a></li>
          </ul>
        </div>
      </div>
      <p  style="font-family: aig_meiryo;"><a
          href="https://www.aig.co.jp/sonpo"
          target="_blank"
        >Japan</a> | <a
          href="https://www.aig.com/worldwide"
          target="_blank"
        >Worldwide</a></p>
      <p class="axis-footer-copyright">Copyright © 2022, American International Group, Inc. All rights reserved.</p>
    </div>
  </ng-container>

</div>
  <div
    class="axis-content-wrapper"
    *ngIf="fnolCheck"
  >
    <div
      *ngIf="country === 'sg' || country === 'singapore'"
      class="axis-container-fluid"
    >
      <div class="axis-grid _justify-content-center">
        <div class="axis-col-md-3">
          <h3>About AIG</h3>
          <ul class="axis-footer-list sgList">

            <li><a
                href="https://www.aig.sg/aboutus"
                target="_blank"
              >About Us</a></li>
            <li><a
                href="http://www.aig.com/careers"
                target="_blank"
              >Careers</a></li>
            <li><a
                href="https://www.aig.sg/aboutus/news"
                target="_blank"
              >News and Media</a></li>

          </ul>
        </div>
        <div class="axis-col-md-3">
          <h3>Legal</h3>
          <ul class="axis-footer-list sgList">
            <li><a
                href="https://www.aig.sg/legal-notice"
                target="_blank"
              >Legal Notice</a></li>
            <li><a
                href="https://www.aig.sg/privacy"
                target="_blank"
              >Privacy</a></li>
            <li><a
                href="https://www.aig.sg/terms-of-use"
                target="_blank"
              >Terms of Use</a></li>
              <li *ngIf="country === 'singapore'"><a
                href="https://secure.ethicspoint.com/domain/media/en/gui/61813/index.html"
                target="_blank"
              >Compliance Help Line</a></li>
          </ul>
        </div>
        <div class="axis-col-md-3">
          <h3>Support</h3>
          <ul class="axis-footer-list sgList">

            <li><a
                href="https://www.aig.sg/fraud-warning"
                target="_blank"
              >Fraud Warning</a></li>
              <li *ngIf="country === 'singapore'"><a
                href="https://www.aig.sg/safety-tips"
                target="_blank"
              >Safety Tips</a></li>
<li><a
  href="supported.html"
  target="_blank"
>Recommended Software</a></li>
          </ul>
        </div>
        <div class="axis-col-md-3">
          <h3>Follow Us</h3>
          <div class="axis-footer-list">
            <a
              href="http://www.facebook.com/AIGsg"
              target="_blank"
            >
              <img
                alt="facebook"
                data-size="medium"
                src="assets/images/logos/facebook.png"
              >
            </a>
            <a
              class="_ml-medium"
              href="https://www.linkedin.com/company/1760"
              target="_blank"
            >
              <img
                alt="linkedIn"
                data-size="medium"
                src="assets/images/logos/linkedin.png"
              >
            </a>
          </div>
        </div>
      </div>
      <div class="_mt-medium"><a
          href="http://www.aig.sg/"
          target="_blank"
        >Singapore</a> | <a
          href="https://aig.com/worldwide"
          target="_blank"
        >Worldwide</a></div>
      <p class="axis-footer-copyright _mt-xxlarge">Copyright © 2022 AIG Asia Pacific Insurance Pte. Ltd.</p>
    </div>

    <div
      *ngIf="country === 'au'"
      class="axis-container-fluid"
    >
      <div class="axis-grid _justify-content-center">
        <div class="axis-col-md-4">
          <h3>AIG Australia</h3>
          <ul class="axis-footer-list">

            <li><a
                href="https://www.aig.com.au/about-us"
                target="_blank"
              >About Us</a></li>
            <li><a
                href="https://www.aig.com.au/contact-us"
                target="_blank"
              >Contact Us</a></li>
            <li><a
                href="https://www.aig.com.au/contact/complaints-handling"
                target="_blank"
              >Complaints Handling</a></li>
            <li><a
                href="https://www.aig.com.au/about-us/customer-care"
                target="_blank"
              >Customer Care</a></li>

          </ul>
        </div>
        <div class="axis-col-md-4">
          <h3>Useful Links</h3>
          <ul class="axis-footer-list">
            <li><a
                href="https://www.aig.com.au/key-fact-sheets"
                target="_blank"
              >Key Fact Sheets</a></li>
            <li><a
                href="https://www.aig.com.au/individual/travel-alerts"
                target="_blank"
              >Travel Alerts</a></li>
            <li><a
                href="https://www.aig.com.au/claims"
                target="_blank"
              >Claims</a></li>


          </ul>
        </div>
        <div class="axis-col-md-4">
          <h3>Legal</h3>
          <ul class="axis-footer-list">

            <li><a
                href="https://www.aig.com.au/producer-compensation-disclosure"
                target="_blank"
              >Producer Compensation Disclosure</a></li>
            <li><a
                href="https://www.aig.com.au/about-us/governance/code-of-practice"
                target="_blank"
              >Code of Practice</a></li>
            <li><a
                href="https://www.aig.com.au/about-us/governance/financial-services-guide"
                target="_blank"
              >Financial Services Guide</a></li>
            <li><a
                href="https://www.aig.com.au/fraud-warning"
                target="_blank"
              >Fraud Warning</a></li>
            <li><a
                href="https://insurancecouncil.com.au/issues-in-focus/bi-test-cases"
                target="_blank"
              >Business Interruption Test Cases</a></li>

          </ul>
        </div>

      </div>
      <p><a
        href="supported.html"
        target="_blank"
      >Recommended environment</a></p>
      <p><a
          href="https://www.aig.com.au/business"
          target="_blank"
        >Australia</a> | <a
          href="https://www.aig.com/worldwide"
          target="_blank"
        >Worldwide</a> ></p>
      <p class="axis-footer-copyright"><a
          href="https://www.aig.com.au/about-us/governance/privacy"
          target="_blank"
        >Privacy Policy</a> | <a
          href="https://www.aig.com.au/terms-of-use"
          target="_blank"
        >Terms of Use</a></p>
      <p class="axis-footer-copyright">Copyright © 2020 AIG Australia Ltd</p>
      <p class="axis-footer-copyright">Product descriptions on this website are intended only as a guide to coverage
        terms and conditions, including benefits, and should not be relied upon to determine policy coverage. Policy
        coverage is suject to the specific terms and conditions of each policy wording.</p>
    </div>

    <div
      *ngIf="country === 'us'"
      class="axis-container-fluid"
    >
      <div class="axis-grid _justify-content-center">
        <div class="axis-col-md-3">
          <h3>About AIG</h3>
          <ul class="axis-footer-list">

            <li><a
                href="https://www.aig.com/individual"
                target="_blank"
              >Individuals</a></li>
            <li><a
                href="https://www.aig.com/business"
                target="_blank"
              >Business</a></li>
            <li><a
                href="https://www.aig.com/careers"
                target="_blank"
              >Careers</a></li>
            <li><a
                href="https://www.aig.com/about-us/newsroom"
                target="_blank"
              >Newsroom</a></li>
            <li><a
                href="https://www.aig.com/about-us"
                target="_blank"
              >Site Map</a></li>

          </ul>
        </div>
        <div class="axis-col-md-3">
          <h3>Legal</h3>
          <ul class="axis-footer-list">
            <li><a
                href="https://www.aig.com/about-our-ads"
                target="_blank"
              >About Our Ads</a></li>
            <li><a
                href="https://www.aig.com/terms-of-use"
                target="_blank"
              >Terms of Use</a></li>
            <li><a
                href="https://www.aig.com/privacy-policy"
                target="_blank"
              >Privacy</a></li>
            <li><a
                href="https://www.aig.com/legal-notice"
                target="_blank"
              >Legal Notice</a></li>
            <li><a
                href="https://www.aig.com/statement-about-accessibility"
                target="_blank"
              >Accessibility Statement</a></li>
            <li><a
                href="https://www.aig.com/business-resilience-disclosure"
                target="_blank"
              >Business Resilience</a></li>

          </ul>
        </div>
        <div class="axis-col-md-3">
          <h3>Support</h3>
          <ul class="axis-footer-list">

            <li><a
                href="https://www.aig.com/producer-notification"
                target="_blank"
              >Producer Notifications</a></li>

            <li><a
                href="https://www.aig.com/fraud-warning"
                target="_blank"
              >Fraud Warning</a></li>
            <li><a
                href="https://www.aigcorporate.com/_20_306214.html"
                target="_blank"
              >Employee Login</a></li>
<li><a
  href="supported.html"
  target="_blank"
>Recommended environment</a></li>
          </ul>
        </div>
        <div class="axis-col-md-3">
          <h3>Follow Us</h3>
          <div class="axis-footer-list">
            <a
              href="https://twitter.com/AIGInsurance"
              target="_blank"
            >
              <img
                alt="twitter"
                data-size="medium"
                src="assets/images/logos/twitter.png"
              >
            </a>
            <a
              class="_ml-medium"
              href="https://www.facebook.com/AIGInsurance"
              target="_blank"
            >
              <img
                alt="facebook"
                data-size="medium"
                src="assets/images/logos/facebook.png"
              >
            </a>
            <a
              class="_ml-medium"
              href="https://www.youtube.com/user/AIG"
              target="_blank"
            >
              <img
                alt="youtube"
                data-size="medium"
                src="assets/images/logos/youtube.png"
              >
            </a>
            <a
              class="_ml-medium"
              href="https://www.linkedin.com/company/aig"
              target="_blank"
            >
              <img
                alt="linkedIn"
                data-size="medium"
                src="assets/images/logos/linkedin.png"
              >
            </a>
          </div>
        </div>
      </div>

      <p><a
          href="https://www.aig.com/business"
          target="_blank"
        >United States</a> | <a
          href="https://aig.com/worldwide"
          target="_blank"
        >Worldwide</a></p>

      <p class="axis-footer-copyright">Copyright © 2020 American International Group, Inc. All rights reserved.</p>
      <p class="axis-footer-copyright">American International Group, Inc. (AIG) is a leading global insurance
        organization. AIG member companies provide a wide range of property casualty insurance, life insurance,
        retirement solutions, and other financial services to customers in more than 80 countries and jurisdictions.
        These diverse offerings include products and services that help businesses and individuals protect their assets,
        manage risks and provide for retirement security. AIG common stock is listed on the New York Stock Exchange.</p>
    </div>

    <div
      *ngIf="country === 'nz'"
      class="axis-container-fluid"
    >
      <div class="axis-grid">
        <div class="axis-col-md-4">
          <h3>AIG New Zealand</h3>
          <ul class="axis-footer-list">

            <li><a
                href="https://www.aig.co.nz/about-us"
                target="_blank"
              >About Us</a></li>
            <li><a
                href="https://www.aig.co.nz/contact"
                target="_blank"
              >Contact Us</a></li>
            <li><a
                href="https://www.aig.co.nz/claims"
                target="_blank"
              >Claims</a></li>
          </ul>
        </div>

        <div class="axis-col-md-4">
          <h3>Legal</h3>
          <ul class="axis-footer-list">
            <li><a
                href="https://www.aig.co.nz/fraud-warning"
                target="_blank"
              >Fraud Warning</a></li>
            <li><a
                href="https://www.aig.co.nz/about-us/producer-compensation"
                target="_blank"
              >Producer Compensation</a></li>
            <li><a
                href="https://www.aig.co.nz/contact/feedback-and-complaints-form"
                target="_blank"
              >Feedback and Complaints Form</a></li>

          </ul>
        </div>

      </div>
      <p><a
        href="supported.html"
        target="_blank"
      >Recommended environment</a></p>
      <p><a
          href="https://www.aig.co.nz/business"
          target="_blank"
        >New Zealand</a> | <a
          href="https://aig.com/worldwide"
          target="_blank"
        >Worldwide</a> ></p>
      <p class="axis-footer-copyright"><a
          href="https://www.aig.co.nz/privacy-policy"
          target="_blank"
        >Privacy Policy</a> | <a
          href="https://www.aig.co.nz/terms-of-use"
          target="_blank"
        >Terms of Use</a></p>
      <p class="axis-footer-copyright">Copyright © 2020 AIG Insurance New Zealand Limited (Company Number 3195589,
        FSP189804), a licensed insurer regulated by the Reserve Bank of New Zealand.</p>

    </div>
    <div
      *ngIf="country === 'th'"
      class="axis-container-fluid"
    >
      <div *ngIf="selectedLanguage === 'en'">
        <div class="axis-grid">
          <div class="axis-col-md-3">

            <ul class="axis-footer-list">

              <li><a
                  href="https://www.aig.co.th/en/about-us"
                  target="_blank"
                >About Us</a></li>
              <li><a
                  href="https://www.aig.co.th/en/contact-us"
                  target="_blank"
                >Contact Us</a></li>
              <li><a
                  href="https://www.aig.co.th/en/careers"
                  target="_blank"
                >Careers</a></li>


            </ul>
          </div>
          <div class="axis-col-md-3">
            <ul class="axis-footer-list">
              <li><a
                  href="https://www.aig.co.th/en/news"
                  target="_blank"
                >News</a></li>
              <li><a
                  href="https://www.aig.co.th/en/sitemap"
                  target="_blank"
                >Sitemap</a></li>
            </ul>
          </div>

        </div>
        <div class="axis-grid">
          <div class="axis-col-md-4">
            <span>
              <a
                href="http://www.aig.co.th/"
                target="_blank"
              >Thailand</a> | <a
                href="http://www.aig.com/"
                target="_blank"
              >Worldwide</a> >
            </span>
          </div>
          <div class="axis-col-md-4">
            <span><a
                href
                target="_blank"
              >ไทย</a> | <a
                href
                target="_blank"
              >English</a></span>
          </div>
        </div>
        <p><a
          href="supported.html"
          target="_blank"
        >Recommended environment</a></p>
        <p class="axis-footer-copyright"><a
            href="https://www.aig.co.th/en/terms-of-use"
            target="_blank"
          >Terms of Use</a> | <a
            href="https://www.aig.co.th/en/privacy-policy"
            target="_blank"
          >Privacy Policy</a> |<a
            href="https://www.aig.co.th/en/legal-notice"
            target="_blank"
          > Legal Notice</a></p>
        <p class="axis-footer-copyright">Copyright © 2021 American International Group, Inc. All Rights Reserved.</p>
      </div>
      <div
        *ngIf="selectedLanguage === 'th'"
        class="axis-container-fluid"
      >
        <div class="axis-grid">
          <div class="axis-col-md-3">

            <ul class="axis-footer-list">

              <li><a
                  href="https://www.aig.co.th/about-us"
                  target="_blank"
                >เกี่ยวกับเรา</a></li>
              <li><a
                  href="https://www.aig.co.th/contact-us"
                  target="_blank"
                >ติดต่อเรา</a></li>
              <li><a
                  href="https://www.aig.com/careers"
                  target="_blank"
                >ร่วมงานกับเรา</a></li>
            </ul>
          </div>

        </div>
        <div class="axis-grid">
          <div class="axis-col-md-4">
            <span>
              <a
                href
                target="_blank"
              >ประเทศไทย</a> | <a
                href="https://www.aig.com/"
                target="_blank"
              >เอไอจีทั่วโลก</a> >
            </span>
          </div>
        </div>
        <p class="axis-footer-copyright"><a
            href="https://www.aig.co.th/terms-of-use"
            target="_blank"
          >ข้อตกลงและเงื่อนไขการใช้บริการ</a> | <a
            href="https://www.aig.co.th/privacy-policy"
            target="_blank"
          >นโยบายการรักษาข้อมูลส่วนบุคคล</a> | <a
            href="https://www.aig.co.th/legal-notice"
            target="_blank"
          >เงื่อนไขและข้อกำหนดทางกฏหมาย</a></p>
        <p class="axis-footer-copyright">Copyright © 2021 American International Group, Inc. All Rights Reserved.</p>
      </div>
    </div>
  </div>
 
  <div  *ngIf="country === 'no'" class="axis-footer-container _ptb-xlarge">
    <div class="axis-grid _justify-content-center">
        <div class="axis-col-md-3">
          <h3 class="_text-bold" >{{aigNorway}}</h3>
          <ul class="axis-footer-list"  style="font-family: aig_meiryo;"  data-size="large">
            <li ><a
                href="https://www.aig.no/business/om-oss"
                target="_blank" 
              >{{aboutUs}}</a></li>
              <li ><a
                href="https://www.aig.no/business/kontakt-oss"
                target="_blank" 
              >{{contactus}}</a></li>
            <li><a
                href="https://www.aig.no/business/careers-at-aig"
                target="_blank" 
               >{{workatAig}}</a></li>
          </ul>
        </div>
        <div class="axis-col-md-3">
          <h3 class="_text-bold" >{{more}}</h3>
          <ul class="axis-footer-list"  style="font-family: aig_meiryo;"  data-size="large" >
            <li><a
                href="https://www.aig.no/business/nyheter"
                target="_blank"  
              >{{news}}</a></li>
            <li><a
                href="https://www.aig.no/business/klagehandtering"
                target="_blank" 
              >{{complaintHandling}}</a></li>
          </ul>
        </div>

        <div class="axis-col-md-3">
          <h3 class="_text-bold">{{support}}</h3>
          <ul class="axis-footer-list"  style="font-family: aig_meiryo;"  data-size="large">
              <li><a
               [attr.href]="'supported.html?language=' + nordicUtilService.getLanguage()"
                target="_blank"  
              >{{recommSoftware}}</a></li>
          </ul>
        </div>
          <div class="axis-col-md-3">
          <div class="axis-footer-list">
            <div class="axis-form-group colorWhite" *ngIf="auth.hideLanguageChange()">
              <label
              class="_sr-only"
              for="idLanguageDropdown"
              >Languages</label>
            <axis-dropdown
            data-kind="link"
            data-theme="dark"
              [dataProvider]="languageList"
              id="idLanguageDropdown"
              [isImage]="true"
              (ngModelChange)="modelChanged($event, index)"
              [isLabelVisible]="true"
              [ngModel]="languageList1"
              ></axis-dropdown>
          </div>

            <h3  class="_text-bold" >{{followUp}}</h3>
           
            <ul class="axis-list-inline">
              <li>
                <a
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2FAIGemea"
                  target="_blank"
                >
                  <img
                    alt="twitter"
                    data-size="medium"
                    src="assets/images/logos/twitter.png"
                  >
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/AIGInsurance"
                  target="_blank"
                >
                  <img
                    alt="facebook"
                    data-size="medium"
                    src="assets/images/logos/facebook.png"
                  >
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/AIG"
                  target="_blank"
                >
                  <img
                    alt="youtube"
                    data-size="medium"
                    src="assets/images/logos/youtube.png"
                  >
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/aig/"
                  target="_blank"
                >
                  <img
                    alt="linkedIn"
                    data-size="medium"
                    src="assets/images/logos/linkedin.png"
                  >
                </a>
              </li>
            </ul>

          </div>
        </div>
      </div>


      <p><a
        href=" https://www.aig.no/business"
        target="_blank"
      >{{norway}}</a> | <a
        href="https://www.aig.com/worldwide"
        target="_blank"
        class="_text-bold"
      >Worldwide ></a></p>
      <p class="_mt-xlarge"><a
        href="https://www.aig.no/sample-sikkerhet-og-personvern"
        target="_blank"
      >{{securit}}</a> | <a
        href="https://www.aig.no/business/juridisk-informasjon"
        target="_blank"
        class="_text-bold"
      >{{legal}}</a> </p>
   
    <p class="axis-footer-copyright">
      Copyright @ 2023 American International Group, Inc. All rights reserved.
    </p>
  </div>

</footer>
<!-- <axis-scroll2tb></axis-scroll2tb> -->