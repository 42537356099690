import { NgModule } from '@angular/core';

import {
    AuthGuardService,
    AuthService
} from './services';

@NgModule({
    providers: [
        AuthGuardService,
        AuthService
    ]
})
export class AuthModule { }
