import { APPLICATION_ID } from '../../config/fnol-constants';

export const setFnolModel = {
  'ns2:InvoiceDownloadRequest': {
    '#namespaces': {
      ns1: 'http://www.aig.com/gct/services/commonHeaderV1.0',
      ns2: 'http://www.aig.com/gct/services/InvoiceIntakeServiceV1.0',
      m1: 'http://www.ACORD.org/standards/PC_Surety/ACORD1/xml/',
      m2: 'http://www.aig.com/ACORD1/xml/',
      ns3: 'http://www.aig.com/gct/services/InvoiceIntakeServiceV1.0',
    },
    'ns2:requestHeader': {
      'ns1:id': 62,
      'ns1:requestApplicationID': APPLICATION_ID,
      'ns1:requestMessageID': '',
      'ns1:cmdType': '',
      'ns1:cmdMode': '',
      'ns1:echoBack': false,
    },
    'ns2:applicationContext': {
      'ns1:region': '',
      'ns1:country': '',
      'ns1:language': '',
      'ns1:lineOfBusiness': '',
      'ns1:subLineOfBusiness': '',
      'ns1:systemDate': '',
      'ns1:userId': '',
      'ns1:requestProviderVersion': '',
      'ns1:targetSystemName': 'OCFE',
      'ns1:providerSystemID': '',
      'ns1:requestType': 'CDP',
      'ns1:providerSystemPassword': '',
    },
    'ns3:ClaimDownloadRq': {
      'm1:ClaimsDownloadInfo': {
        'm1:Producer': {
          'm1:ProducerInfo': {
            'm1:ProducerRoleCd': 'CLAIMANT',
            'm1:ContractNumber': '',
          },
        },
        'm1:ClaimsOccurrence': {
          'm1:LossDt': '',
          'm1:LossTime': '',
          'm1:ClaimsReported': [
            {
              '@id': 'Date Reported to Insured',
              'm1:ReportedDt': '',
            },
            {
              '@id': 'Date Reported to AIG',
              'm1:ReportedDt': '',
            },
          ],
          'm1:ClaimTypeCd': '',
          'm1:LossCauseCd': '',
          'm1:IncidentDesc': '',
          'm1:DamageDesc': '',
          'm1:QuestionAnswer': [],
          'm2:ClaimNumber': '',
        },
        'm1:InsuredOrPrincipal': [
          {
            'm1:InsuredOrPrincipalInfo': {
              'm1:InsuredOrPrincipalRoleCd': 'Insured',
            },
            'm1:GeneralPartyInfo': {
              'm1:NameInfo': [
                {
                  'm1:CommlName': {
                    'm1:CommercialName': '',
                  },
                },
              ],
              'm1:Communications': {
                'm1:PhoneInfo': [],
                'm1:EmailInfo': [],
              },
            },
          },
        ],
        'm1:RemarkText': 'Accident and Health',
        'm1:ClaimsPayment': {
          'm1:TotalClaimLossPaidAmt': 0,
        },
        'm1:Policy': {
          'm1:PolicyNumber': '',
          'm1:PolicyStatusCd': '',
          'm1:PolicyVersion': '',
          'm1:ContractTerm': {
            'm1:EffectiveDt': '',
            'm1:ExpirationDt': '',
          },
          'm1:Coverage': {
            'm1:CoverageTypeCd': '',
          },
          'm1:ProductCode': '',
          'm2:IssuingOffice': '',
          'm2:AnnualStatementCompanyCode': '',
          'm2:DivisionCd': '',
          'm1:PolicyOfficeCd': '',
          'm1:CountryCd': '',
          'm1:CurCd': '',
        },
        'm1:ClaimsParty': [
          {
            'm1:ClaimsPartyInfo': {
              'm1:ClaimsPartyRoleCd': 'Claimant',
            },
            'm1:GeneralPartyInfo': {
              'm2:CommunicationModeCd': '',
              'm1:NameInfo': {
                'm1:PersonName': {
                  'm1:GivenName': '',
                  'm1:Surname': '',
                },
              },
              'm1:Addr': {
                'm1:Addr1': '',
                'm1:Addr2': '',
                'm1:City': '',
                'm1:StateProvCd': '',
                'm1:PostalCode': '',
                'm1:CountryCd': '',
                'm1:StateProv': '',
              },
              'm1:Communications': {
                'm1:EmailInfo': {
                  'm1:EmailAddr': '',
                },
                'm1:PhoneInfo': [
                  {
                    'm1:PhoneTypeCd': '',
                    'm1:PhoneNumber': '',
                    'm1:CountryPhoneCd': '',
                  },
                ],
              },
              'm1:MiscParty': {
                'm1:MiscPartyInfo': {
                  '@id': 1,
                  'm1:MiscPartySubRoleCd': '',
                },
              },
              'm2:IndOrOrgCd': '',
            },
            'm1:PersonInfo': {
              'm1:GenderCd': '',
              'm1:BirthDt': '',
            },
          },
        ],
        'm2:LossInfo': [],
      },
    },
  },
};
