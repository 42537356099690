export var submitClaim={
    "applicationContext": {
        "countryCode": "NO",
        "language": "en",
        "lineOfBusiness": "AH",
        "userId": "swathi.dumpala@aig.com",
        "claimNumber": "1345456789NO",
        "subLob": "789",
        "submissionDate": "2023-01-31",
        "submissionTime": "22:28:16"
    },
    "lossType": []
}