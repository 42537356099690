import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormatAddressService {
  constructor() {}


  getSplitAddress(str,type){
    let index=str.substring(0, 35).lastIndexOf(" ");
     if(type === "part1"){   
      return  index > -1 ? str.substring(0,index) : str.substring(0, 35)
     } else{
      return index > -1? str.substring(index +1):str.substring(35)
     }
  }
  
}
