import { Inject, Injectable } from "@angular/core";
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { CacheService } from "../../shared/services/cache.service";

@Injectable()
export class AuthService {

  showLanguage=true;
  constructor(
    private cacheService: CacheService,
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth
  ) { }

  isAuthenticationTokenAvailalbe(): boolean {
    let tokensCached: boolean =
      this.cacheService.get(
        this.cacheService.IDTOKEN,
        CacheService.COOKIE_STORAGE
      ) != "" &&
      this.cacheService.get(
        this.cacheService.ACCESSTOKEN,
        CacheService.COOKIE_STORAGE
      ) != "" &&
      this.cacheService.get(
        this.cacheService.REFRESHTOKEN,
        CacheService.COOKIE_STORAGE
      ) != "";

    return tokensCached;
  }

  isLicAgreementAccepted(): boolean {
    let accepted: boolean =
      this.cacheService.get(
        this.cacheService.ACCEPTED_LIC_AGREEMENT,
        CacheService.COOKIE_STORAGE
      ) != null &&
      this.cacheService.get(
        this.cacheService.ACCEPTED_LIC_AGREEMENT,
        CacheService.COOKIE_STORAGE
      ) == "Y";

    return accepted;
  }

  isAuthenticated() {
    return this.oktaAuth.isAuthenticated().then((isAuthenticated) => {
      console.log(isAuthenticated);
      return isAuthenticated;
    });
  }

  hideLanguageChange(){
    return this.showLanguage
  }
  setHideLanguage(){
   this.showLanguage=false
  }

  countryCheck() {
    const country = this.cacheService.get("country");
    if (country) {
      return country.toLocaleLowerCase() === "jp" ? true : false;
    } else {
      return false;
    }
  }
}
