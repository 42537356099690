<axis-modal #modalConfirm data-size="medium">
    <axis-modal-header>
        <h1>
            Before you go
        </h1>
    </axis-modal-header>
    <axis-modal-content>
        <p>
            Any changes made to your Personal Information, Notification Settings, and Security Settings will not be saved.
            <br>
            <span class="_text-bold">NOTE: If you changed your password, this has already been saved.</span>
        </p>
    </axis-modal-content>
    <axis-modal-footer>
        <div class="axis-btn-wrapper">
            <div class="axis-btn-wrapper-left">
            </div>
            <div class="axis-btn-wrapper-right">
                <button axis-button data-kind="link" (click)="modalConfirm.close()">Close</button>
                <button axis-button data-kind="primary" *ngIf="fromDashboard" (click)="modalCloseOnContinue('/dashboard');modalConfirm.close()">Continue</button>
                <button axis-button data-kind="primary" *ngIf="fromSignOut" (click)="modalCloseOnContinue('/login/logout');modalConfirm.close()" >Continue</button>
            </div>
        </div>
    </axis-modal-footer>
</axis-modal>