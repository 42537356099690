import { APPLICATION_ID } from '../config/fnol-constants';

export const commonSetFnolSgModel = {
    "applicationContext": {
        "countryCode": "SG",
        "language": "en",
        "lineOfBusiness": "ACHE",
        "userId": "email-address@company.com"
    },
    "claimsDetails": {
        "userId": "email-address@company.com",
        "dateofIncident": "",
        "claimNumber": "",
        "content": ""
    }
}

export const setFnolSgModel = {
    "ns2:InvoiceDownloadRequest": {
        "#namespaces": {
            "ns1": "http://www.aig.com/gct/services/commonHeaderV1.0",
            "ns2": "http://www.aig.com/gct/services/InvoiceIntakeServiceV1.0",
            "m1": "http://www.ACORD.org/standards/PC_Surety/ACORD1/xml/",
            "m2": "http://www.aig.com/ACORD1/xml/",
            "ns3": "http://www.aig.com/gct/services/InvoiceIntakeServiceV1.0"
        },
        "ns2:requestHeader": {
            "ns1:id": 62,
            "ns1:requestApplicationID": APPLICATION_ID,
            "ns1:requestMessageID": "",
            "ns1:cmdType": "",
            "ns1:cmdMode": "",
            "ns1:echoBack": false
        },
        "ns2:applicationContext": {
            "ns1:region": "APAC",
            "ns1:country": "SG",
            "ns1:language": "en",
            "ns1:lineOfBusiness": "7",
            "ns1:subLineOfBusiness": "999",
            "ns1:systemDate": "",
            "ns1:userId": "",
            "ns1:requestProviderVersion": "",
            "ns1:targetSystemName": "OCFE",
            "ns1:providerSystemID": "FNOL",
            "ns1:requestType": "CDP",
            "ns1:providerSystemPassword": ""
        },
        "ns3:ClaimDownloadRq": {
            "m1:ClaimsDownloadInfo": {
                "m1:Producer": {
                    "m1:ProducerInfo": {
                        "m1:ProducerRoleCd": "CLAIMANT",
                        "m1:ContractNumber": ""
                    }
                },
                "m1:ClaimsOccurrence": {
                    "m1:LossDt": "20211202T050000.000 GMT",
                    "m1:LossTime": "04:03:1",
                    "m1:ClaimsReported": [
                        {
                            "@id": "Date Reported to Insured",
                            "m1:ReportedDt": ""
                        },
                        {
                            "@id": "Date Reported to AIG",
                            "m1:ReportedDt": ""
                        }
                    ],
                    "m1:ClaimTypeCd": "",
                    "m1:LossCauseCd": "",
                    "m1:IncidentDesc": "",
                    "m1:DamageDesc": "",
                    "m1:QuestionAnswer": [],
                    "m2:ClaimNumber": ""
                },
                "m1:InsuredOrPrincipal": [
                    {
                        "m1:InsuredOrPrincipalInfo": {
                            "m1:InsuredOrPrincipalRoleCd": "Insured"
                        },
                        "m1:GeneralPartyInfo": {
                            "m1:NameInfo": [
                                {
                                    "m1:CommlName": {
                                        "m1:CommercialName": ""
                                    }
                                }
                            ],
                            "m1:Communications": {
                                "m1:PhoneInfo": [],
                                "m1:EmailInfo": []
                            }
                        }
                    }
                ],
                "m1:RemarkText": "Accident and Health",
                "m1:ClaimsPayment": {
                    "m1:TotalClaimLossPaidAmt": 0
                },
                "m1:Policy": {
                    "m1:PolicyNumber": "",
                    "m1:PolicyStatusCd": "",
                    "m1:PolicyVersion": "",
                    "m1:ContractTerm": {
                        "m1:EffectiveDt": "",
                        "m1:ExpirationDt": ""
                    },
                    "m1:Coverage": {
                        "m1:CoverageTypeCd": ""
                    },
                    "m1:ProductCode": "",
                    "m2:IssuingOffice": "",
                    "m2:AnnualStatementCompanyCode": "",
                    "m2:DivisionCd": "",
                    "m1:PolicyOfficeCd": "",
                    "m1:CountryCd": "SG",
                    "m1:CurCd": "SGD-Singapore Dollar",
                    "m1:PolicyHolderName": "",
                    "m1:PolicyNamePlan": "",
                    "m1:multiNationalPolicy": "",
                    "m1:certificateNumber": "",
                    "m1:externalReference": "",
                    "m1:firstName": "",
                    "m1:lastName": "",
                    "m1:policyOfficeCode": "",
                    "m1:policyHolderNo": "",
                    "m1:renewalCertificateNumber": "",
                    "m1:database": "",
                    "m1:effectiveDateSequenceNumber": "",
                    "m1:policyStatus": "",
                    "m1:startDate": ""
                },
                "m1:ClaimsParty": [
                    {
                        "m1:ClaimsPartyInfo": {
                            "m1:ClaimsPartyRoleCd": "Claimant"
                        },
                        "m1:GeneralPartyInfo": {
                            "m2:CommunicationModeCd": "",
                            "m1:NameInfo": {
                                "m1:PersonName": {
                                    "m1:GivenName": "",
                                    "m1:Surname": ""
                                }
                            },
                            "m1:Addr": {
                                "m1:Addr1": "",
                                "m1:Addr2": "",
                                "m1:City": "",
                                "m1:StateProvCd": "",
                                "m1:PostalCode": "",
                                "m1:CountryCd": "",
                                "m1:StateProv": ""
                            },
                            "m1:Communications": {
                                "m1:EmailInfo": {
                                    "m1:EmailAddr": ""
                                },
                                "m1:PhoneInfo": []
                            },
                            "m1:MiscParty": {
                                "m1:MiscPartyInfo": {
                                    "@id": 1,
                                    "m1:MiscPartySubRoleCd": ""
                                }
                            },
                            "m2:IndOrOrgCd": ""
                        },
                        "m1:PersonInfo": {
                            "m1:GenderCd": "",
                            "m1:BirthDt": ""
                        }
                    }
                ],
                "m2:LossInfo": [
                ]
            }
        }
    }
}

export const icorSgModel = {
    "Icorr": {
        "iCorrespondence": {
            "requestHeader": {
                "userID": "O1111147",
                "lineOfBusiness": "",
                "requestApplicationID": "FNOL",
                "requestMessageID": "",
                "language": "en",
                "countryCode": "SG",
                "systemDate": ""
            },
            "ApplicationContext": {
                "applicationID": APPLICATION_ID,
                "country": "SG",
                "language": "en"
            },
            "correspondenceItem": {
                "pdfGenerationData": {
                    "correspondenceItemID": "FNOL_AH",
                    "claimDetails": {
                        "claimID": "3164585253SG",
                        "cdpDetails": {
                            "info": [
                                {
                                    "title": "Policy Information",
                                    "attribute": []
                                },
                                {
                                    "title": "Privacy Policy and Terms of Use",
                                    "attribute": []
                                },
                                {
                                    "title": "Personal Information",
                                    "attribute": []
                                },
                                {
                                    "title": "Claim Details",
                                    "attribute": []
                                },
                                {
                                    "title": "Payment Details",
                                    "attribute": []
                                },
                                {
                                    "title": "Upload Supporting Documents",
                                    "attribute": []
                                },
                                {
                                    "title": "Review and Submit the Claim",
                                    "attribute": [
                                        {
                                            "name": "I have read and agree to the declarations and authorisation & by submitting this form, I have read and agree with the declarations and authorisation",
                                            "value": "Yes"
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                },
                "distributionGenerationData": {
                    "insuredID": "",
                    "clientName": "",
                    "notificationType": "",
                    "country": "",
                    "lob": "",
                    "lossState": "",
                    "lossAddressID": ""
                },
                "correspondenceGenerationData": {
                    "callerDistributionFax": "",
                    "callerDistributionEMAIL": "",
                    "claimantEmail": "",
                    "brokerEmail": "",
                    "sharedServiceEmail": "",
                    "isNotificationClaim": "",
                    "lossState": "",
                    "reportedByFirstName": "",
                    "reportedByLastName": "",
                    "mobile": "",
                    "businessPhone": "",
                    "originKey": "",
                    "dateReported": "",
                    "lossDate": "",
                    "insuredName": "",
                    "clientName": "AIG",
                    "policyNumber": "",
                    "batchName": ""
                }
            }
        }
    }
}

export const docUploadModel = {
    "DocUpload": {
        "claimNumber": "",
        "vendorID": "AIG",
        "vendorDocType": "FNOL",
        "cmDocType": "FNOL - Internet Report",
        "lossDate": "",
        "insuredName": "GOJEK",
        "policyNumber": "",
        "Origin": "O",
        "receivedFrom": "Claims Digital Portal",
        "subFolder": "FNOL",
        "batchName": "",
        "branchCase": "",
        "documentInfo": []
    }
}