export const uploadModel = {
    "uploadDocRequest": {
        "userId": "narendran.sengamalai@aig.com",
        "documentId": "",
        "country": "JP",
        "dateofIncident": "2022-02-03",
        "claimNumber": "",
        "policyNumber": "",
        "documentSize": 12,
        "documentMimeType": "txt",
        "content": "TUVESUNBTCBCSUxM",
        "documentName": "Medical.txt",
        "documentClassification": " Mandatory :Claim Type Injury / Illness /Personal Effects",
        "documentNotes": " Mandatory :Document Type : e.g. Receipt/Attending Physician's Statement",
        "documentVisibilityInd": ""
    }
}

export const updateDocModel = {
    "updateDocRequest": {
        "documentId": "",
        "claimNumber": "",
        "documentName": "Medical.txt",
        "documentClassification": " Mandatory :Claim Type Injury / Illness /Personal Effects",
        "documentNotes": " Mandatory :Document Type : e.g. Receipt/Attending Physician's Statement",
        "documentStatusCd":""
    }
}