interface FnolSteps {
  CLAIMANT_INFO: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}

interface FnolStepsObj {
  name: string;
  slNo: number;
  title: string;
  progressStepTitle: string;
}

export const FNOL_STEPS_SINGAPORE: FnolSteps = {
  CLAIMANT_INFO: {
    name: "fnol-claimant-info",
    slNo: 1,
    title: `Personal Information`,
    progressStepTitle: `Personal Information`,
  },
  CLAIM_TYPE_DETAILS: {
    name: "fnol-claimtype-details",
    slNo: 2,
    title: `Claim Details`,
    progressStepTitle: `Claim Details`,
  },
  PAYMENT_DETAILS: {
    name: "fnol-payment-details",
    slNo: 3,
    title: `Payment Details`,
    progressStepTitle: `Payment`,
  },
  UPLOAD_DOCS: {
    name: "fnol-upload-docs",
    slNo: 4,
    title: ` Documents`,
    progressStepTitle: `Documents`,
  },
  DECLARATION: {
    name: "fnol-declaration",
    slNo: 5,
    title: `Review and Submit the Claim`,
    progressStepTitle: `Review & Submit`,
  },
};

export const ContainerCountMapSG = {
  addTreatmentMain: 7,
  addFractureMain: 7,
  addDislocationMain: 7,
  addLeaveMain: 7,
  addDoctorMain: 7,
  addWitnessMain: 7,
  addIncomeMain: 7,
};

export const DATE_FORMAT = "dd/MM/yyyy";
export const CLAIM_TYPE_DATE_FIELDS = [
  "dateAccident",
  "firstVisit",
  "dateOfAdmission",
  "dateOfDischarge",
  "dateOfStart",
  "dateOfDisability",
  "dateOfDeath",
  "dateSymptom",
  "dateVisit",
  "dateOfAdmissionIllness",
  "dateOfDischargeIllness",
  "dateOfDiagnosed",
];

export const SG_DATA_CONTAINERS = [
  "addTreatmentMain",
  "addFractureMain",
  "addDislocationMain",
  "addLeaveMain",
  "addDoctorMain",
  "addWitnessMain",
  "addIncomeMain",
];

export const HEADINGMANAGERSG = {
  claimTypeAs: [
    "claimTypeAs",
    "others",
    "firstName",
    "lastName",
    "insuredDOB",
    "insuredNric",
    "insuredMobileNumber",
    "insuredEmail",
    "nric",
    "nonInsuredMobileNumber",
    "email",
    "dob",
    "currentAge",
    "country",
    "myAddressLookup",
    "levelUnitNumber",
    "postalCode",
    "insuredCountry",
  ],
  nameBroker: ["nameBroker", "emailBroker", "brokerMobileNumber"],
  accidentOccurred: [
    "accidentOccurred",
    "injuriesSustained",
    "dateAccident",
    "timeIncident",
    "accidentLocationLookup",
    "firstVisit",
    "diagnosis",
    "others",
    "trafficAccident",
  ],
  chooseClaimType: ["chooseClaimType"],
  medicalExpense: ["medicalExpense", "citizen"],
  admittedHospital: [
    "admittedHospital",
    "addTreatmentMain",
    "admittedIcu",
    "admittedToIcu",
  ],
  sustainFracture: [
    "sustainFracture",
    "addFractureMain",
    "sustainDislocation",
    "addDislocationMain",
  ],
  addLeaveMain: ["addLeaveMain", "employed", "dateOfExpected"],
  dateOfDisability: ["dateOfDisability", "typeDisability"],
  dateOfDeath: [
    "dateOfDeath",
    "localTimePersonal",
    "causeDeath",
    "causeOthers",
    "autopsy",
    "reason",
  ],
  doctorInfo: ["doctorInfo", "addDoctorMain"],
  witnessInfo: ["witnessInfo", "addWitnessMain"],
  describeIllness: [
    "describeIllness",
    "dateSymptom",
    "dateVisit",
    "diagnosisIllness",
    "othersIllness",
    "claimingIllness",
  ],
  chooseClaimTypeIllness: ["chooseClaimTypeIllness"],
  dailyIncome: ["addIncomeMain", "admittedIcuIllness", "admittedToIcuIllness"],
  dateOfDiagnosed: ["dateOfDiagnosed", "disabilityIllness"],
  paymentChannel: [
    "paymentChannel",
    "payeeNameBank",
    "bankName",
    "accountNumber",
    "confirmAccountNumber",
    "payeeName",
  ],
  customFileUpload: ["customFileUpload"],
};

export const CUSTOMERRORMESSAGE = {
  dischargeDateSame: $localize`"Date of Admission" and "Date of Discharge" cannot be same`,
  dischargeDateLesser: $localize`"Date of Discharge" is expected after "Date of admission"`,
  dischargeTimeLesser: $localize`"Time of Discharge" is expected after "Time of admission"`,
  admissionDateGreater: $localize`"Date of Admission" is expected before "Date of discharge"`,
  timeDischarge: $localize`"Time of Discharge" cannot be greater than current date and time`,
  timeAdmissionGreater: $localize`"Time of Admission" cannot be greater than current date and time`,
  medicalLeaveDatesSame: $localize`"Medical Leave Start Date" and "Medical Leave End Date" cannot be same`,
  medicalLeaveStartDateGreater: $localize`"Medical Leave Start Date" is expected before "Medical Leave End Date"`,
  medicalLeaveEndDateLesser: $localize`"Medical Leave End Date" is expected after "Medical Leave Start Date"`,
  timeIncident: $localize`"Accident Time" cannot be greater than current date and time`,
  timeDeath: $localize`"Time of Death" cannot be greater than current date and time`,
  futureDateGeneral: $localize`Date cannot be greater than current date`,
  futureDate: $localize` cannot be greater than current date`,
  futureDateDob: $localize`Claimant Date of Birth cannot be greater than current date`,
  futureDateAccident: $localize`Accident Date cannot be greater than current date`,
  futureDateFirstVisit: $localize`Date of First Doctor's Visit cannot be greater than current date`,
  futureDateStart: $localize`Medical Leave Start Date cannot be greater than current date`,
  firstVisitDateGreater: $localize`"Date of First Doctor's Visit" is expected after "Accident Date"`,
  admissionDateGreaterAccDate: $localize`"Date of Admission" is expected after "Accident Date"`,
  admissionTimeGreaterAccTime: $localize`"Time of Admission" is expected after "Accident Time"`,
  localTimeGreaterAccTime: $localize`"Time of Death" is expected after "Accident Time"`,
  disabilityDateGreater: $localize`"Date of Disability Diagnosis" is expected after "Accident Date"`,
  deathDateGreater: $localize`"Date of Death" is expected after "Accident Date"`,
  startDateGreater: $localize`"Medical Leave Start Date" is expected after "Accident Date"`,
  expectedDateGreater: $localize`"When does the patient expect to return to work" is expected after "Medical Leave End Date"`,
  visitDateGreater: $localize`"Date of First Doctor's Visit" is expected after "When did symptoms begin?"`,
  addmissionDateGreaterSympDate: $localize`"Date of Admission" is expected after "When did symptoms begin?"`,
  disabilityDateGreaterSympDate: $localize`"Date of Disability Diagnosis" is expected after "When did symptoms begin?"`,
  icuAdmittedDays: $localize`Number of days in ICU should be less than or equal to Total hospital admitted days`,
  readmissionDateLesser: $localize`Re-admission date is expected after the previous "Date of Discharge"`,
  readmissionTimeLesser: $localize`Re-admission time is expected after the previous "Time of Discharge"`,
  reStartDateLesser: $localize`Re-start date is expected after the previous "Medical Leave End Date"`,
};

export class UtilsConstSGLabelsRetrieve {
  public static getClaimBenefitTypes(benefitType): Array<any> {
    let claimBenefitType = [];
    if (benefitType === "Personal Accident") {
      claimBenefitType = [
        {
          id: "1",
          name: "Copy of Death Certificate",
        },
        {
          id: "2",
          name: "Medical Bill And Receipts",
        },
        {
          id: "3",
          name: "Medical Leave Certificate",
        },
        {
          id: "4",
          name: "Medical Report/Doctor's Memo/Inpatient Discharge Summary",
        },
        {
          id: "5",
          name: "Police Report If This Is A Road Traffic Accident",
        },
        {
          id: "6",
          name: "Postmortem And Toxicology Report",
        },
        {
          id: "7",
          name: "Referral Letter For Physiotherapy Treatment",
        },
        {
          id: "8",
          name: "Medical Reports",
        },
      ];
    } else if (benefitType === "Illness / Critical Illness") {
      claimBenefitType = [
        {
          id: "1",
          name: "All Available Functional Test Results",
        },
        {
          id: "2",
          name: "All Available Test/Laboratory Results",
        },
        {
          id: "3",
          name: "Detailed Medical Report",
        },
        {
          id: "4",
          name: "Hospitalisation Bills",
        },
        {
          id: "5",
          name: "Medical Report/Inpatient discharge summary/Attending physician's statement by treating doctor",
        },
      ];
    }
    return claimBenefitType;
  }
}

export const FRACTURELOCATION = [
  {
    typeId: "1",
    locations: [
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "2",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "3",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "4",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "5",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "6",
    locations: [
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "7",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compression",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
  {
    typeId: "8",
    locations: [
      {
        id: "1",
        name: "Complete",
      },
      {
        id: "2",
        name: "Compound",
      },
      {
        id: "3",
        name: "All other fractures",
      },
    ],
  },
];
