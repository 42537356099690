export const GetUserData={
    "ApplicationContext": {
        "applicationID": "EXTPOR-001",
        "country": "JP",
        "language": "EN"
    },
    "GetUserDataReqType": {
        "UserId": ""
    }
}

export const updateUserData={
  "profile": {
    "locale": "ja_JP",
    "emailId": "",
    "lastNameKanji": "",
    "firstNameKanji": "",
    "lastNameKatakana": "",
    "firstNameKatakana": "",
    "phoneNumber": "",
    "mobileNumber": "",
    "zipCode": "",
    "prefecture": "",
    "city": "",
    "addressline1": "",
    "addressLine2": "",
    "countryCd": "JP"
      }
}