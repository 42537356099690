import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadCustomComponent } from '../features/fnol/custom/custom-file-upload.component';
import { AxisSharedModule } from '../_shared/axis-shared.module';
import { AxisSharedAugModule } from '../_shared/axis-shared-aug.module';
import { Utils } from 'axis-ui-generator';

@NgModule({
  declarations: [FileUploadCustomComponent],
  imports: [
    CommonModule,
    AxisSharedModule,
    AxisSharedAugModule,
  ],
  exports:[FileUploadCustomComponent]
})
export class SharedComponentModuleModule { 
  constructor() {
    Utils.customComponents['FileUploadCustomComponent'] = FileUploadCustomComponent;
  }
}
