export const INJURYFIELDS = ['injuryCode', 'injuryCause', 'injuryStatus', 'injuryDescription', 'addInjuredMain', 'addMedicalMain', 'treatment'];

export const ILLNESSFIELDS = ['surgeryCause', 'symptoms', 'occurrenceDate', 'consultationDate', 'addInstitutionMain', 'treatmentIllness'];

export const DATEBLOCK = ['hospitalAdmission','hospitalDischarge','dateOfSurgery','lastOutpatient',
'outPatientTreatmentDate','fixingDate','fixtureRemoved','purchaseYearInjury','occurrenceDate','consultationDate',
'illnessHospitalAdmission','illnessHospitalDischarge','illnessDateOfSurgery','startRadiation',
'endRadiation','accidentDateTime','purchaseYear','notificationDate','theftPurchaseYear','tripIncidentTime'];

export const INSUREDINFO = ['lastNameKanji', 'firstNameKanji', 'lastNameKatakana', 'firstNameKatakana'];

export const FIELDTORESET = ['symbol', 'number', 'accountNumberKatakana'];

export const FIELDRESET = ['financialInstitution', 'typeFinancial', 'branchCode', 'branchName', 'accountType', 'accountNumber', 'accountHolder'];

export const DATATYPES = ['addInjuredMain', 'addMedicalMain', 'addHospitalizationMain', 'addSurgeryMain', 'addFixturesMain', 'addDamageInjuryMain', 'addInsuranceMain', 'addInstitutionMain', 'addInpatientMain', 'addSurgeryIllnessMain', 'addRadiationMain', 'addDamageMain', 'addTheftMain'];

export const PERSONALEFFECTFIELDS = ['accidentDateTime', 'tripIncidentTime', 'damage', 'damageLocation', 'accidentSituation', 'accidentDescription', 'schoolManagement'];

export const DATEFIELDS = ['accidentDate', 'dob', 'hospitalAdmission', 'hospitalDischarge', 'dateOfSurgery', 'lastOutpatient', 'outPatientFrom', 'outPatientTo', 'fixingDate', 
                            'fixtureRemoved', 'purchaseYearInjury', 'occurrenceDate', 'consultationDate', 'illnessHospitalAdmission', 'illnessHospitalDischarge', 'illnessDateOfSurgery', 
                            'startRadiation', 'endRadiation', 'accidentDateTime', 'purchaseYear', 'theftPurchaseYear', 'notificationDate'];

export const DATEINNERFIELDS = ['Purchase Time', 'Date of Hospital Admission', 'Date of Hospital Discharge', 'Date of Surgery', 'Radiation - Start Date', 
                                  'Radiation - End Date', 'Outpatient Treatment From', 'Outpatient Treatment To', 'Fixing Date', 'Date Fixture Removed'];

export const TIMEFIELDS = ['accidentTime', 'tripIncidentTime'];

export const CURRENCYFIELDS = ['lastOutpatient', 'outPocket', 'expensesIncurred'];

export const CURRENCYINNERFIELDS = ['Purchase Price', 'Repair cost'];

export const interceptorConstants = ['uploadDocJP','getUserDataJP','submitClaimJP', 'deletePolicy', 'fetchClaimDetail', 'deleteClaimDetail', 'addClaimDetail', 'claimSearchJP', 'updateUserDataJP'];
export const DISABLECLAIM = [ 'accidentDate', 'accidentTime', 'lossLocation', 'policyNumberDetails', 'selectPolicy', 'lastNameKanji', 'firstNameKanji', 'lastNameKatakana', 'firstNameKatakana', 'dob', 'relationshipInsured', 'insuredOthers', 'insuredBreak', 'lastNameKanjiClaim', 'firstNameKanjiClaim', 'lastNameKatakanaClaim', 'firstNameKatakanaClaim','postalCode','prefecture','city','condominium','phone','mobile','email'];
export const DISABLECLAIM2 = [ 'claimBenefits', 'treatment', 'treatmentIllness'];

export const accountPolicy = [ {
  "value": "An account in the name of a contractor or subscriber other than a premium transfer account",
  "label": "An account in the name of a contractor or subscriber other than a premium transfer account",
  "checked": false,
  "inline": false
},
{
  "value": "Insurance premium transfer account",
  "label": "Insurance premium transfer account",
  "checked": false,
  "inline": false
}];
export const accountPolicyJP = [ 
{
  "value": "保険料振替口座以外の契約者または加入者名義の口座",
  "label": "保険料振替口座以外の契約者または加入者名義の口座",
  "checked": false,
  "inline": false
},
{
  "value": "保険料振替口座",
  "label": "保険料振替口座 <span data-color='danger' class='_ml-small'>※保険料口座振替ではないお客さまは、「保険料振替口座」は選択いただけません。</span>",
  "checked": false,
  "inline": false
}];


export const claimTypeIcons = [
	{
    type: 'bandage',
    iconType: 'bandage',
    content: $localize`:@@InjuryIcon:Injury`,
    value: 'Injury',
  },
  {
    type: 'medical',
    iconType: 'medical_cross',
    content: $localize`:@@IllnessIcon:Illness`,
    value: 'Illness',
  },
  {
     type: 'handbag',
     iconType: 'handbag',
     content: $localize`:@@PersonalEffectIcon:Personal Effect`,
     value: 'Personal Effects',
  }
];


export const Prefacture = [
  {
    'code': '01',
    'jp - name': '北海道',
    'en - name': 'Hokkaido'
  },
  {
    'code': '02',
    'jp - name': '青森県',
    'en - name': 'Aomori'
  },
  {
    'code': '03',
    'jp - name': '岩手県',
    'en - name': 'Iwate'
  },
  {
    'code': '04',
    'jp - name': '宮城県',
    'en - name': 'Miyagi'
  },
  {
    'code': '10',
    'jp - name': '秋田県',
    'en - name': 'Akita'
  },
  {
    'code': '11',
    'jp - name': '山形県',
    'en - name': 'Yamagata'
  },
  {
    'code': '12',
    'jp - name': '福島県',
    'en - name': 'Fukushima'
  },
  {
    'code': '20',
    'jp - name': '茨城県',
    'en - name': 'Ibaraki'
  },
  {
    'code': '21',
    'jp - name': '栃木県',
    'en - name': 'Tochigi'
  },
  {
    'code': '22',
    'jp - name': '群馬県',
    'en - name': 'Gunma'
  },
  {
    'code': '23',
    'jp - name': '埼玉県',
    'en - name': 'Saitama'
  },
  {
    'code': '24',
    'jp - name': '千葉県',
    'en - name': 'Chiba'
  },
  {
    'code': '25',
    'jp - name': '東京都',
    'en - name': 'Tokyo'
  },
  {
    'code': '26',
    'jp - name': '神奈川県',
    'en - name': 'Kanagawa'
  },
  {
    'code': '30',
    'jp - name': '新潟県',
    'en - name': 'Niigata'
  },
  {
    'code': '31',
    'jp - name': '富山県',
    'en - name': 'Toyama'
  },
  {
    'code': '32',
    'jp - name': '石川県',
    'en - name': 'Ishikawa'
  },
  {
    'code': '33',
    'jp - name': '福井県',
    'en - name': 'Fukui'
  },
  {
    'code': '34',
    'jp - name': '山梨県',
    'en - name': 'Yamanashi'
  },
  {
    'code': '40',
    'jp - name': '長野県',
    'en - name': 'Nagano'
  },
  {
    'code': '41',
    'jp - name': '岐阜県',
    'en - name': 'Gifu'
  },
  {
    'code': '42',
    'jp - name': '静岡県',
    'en - name': 'Shizuoka'
  },
  {
    'code': '43',
    'jp - name': '愛知県',
    'en - name': 'Aichi'
  },
  {
    'code': '44',
    'jp - name': '三重県',
    'en - name': 'Mie'
  },
  {
    'code': '50',
    'jp - name': '滋賀県',
    'en - name': 'Shiga'
  },
  {
    'code': '57',
    'jp - name': '京都府',
    'en - name': 'Kyoto'
  },
  {
    'code': '58',
    'jp - name': '大阪府',
    'en - name': 'Osaka'
  },
  {
    'code': '59',
    'jp - name': '兵庫県',
    'en - name': 'Hyogo'
  },
  {
    'code': '60',
    'jp - name': '奈良県',
    'en - name': 'Nara'
  },
  {
    'code': '61',
    'jp - name': '和歌山県',
    'en - name': 'Wakayama'
  },
  {
    'code': '62',
    'jp - name': '鳥取県',
    'en - name': 'Tottori'
  },
  {
    'code': '63',
    'jp - name': '島根県',
    'en - name': 'Shimane'
  },
  {
    'code': '64',
    'jp - name': '岡山県',
    'en - name': 'Okayama'
  },
  {
    'code': '70',
    'jp - name': '広島県',
    'en - name': 'Hiroshima'
  },
  {
    'code': '71',
    'jp - name': '山口県',
    'en - name': 'Yamaguchi'
  },
  {
    'code': '72',
    'jp - name': '徳島県',
    'en - name': 'Tokushima'
  },
  {
    'code': '73',
    'jp - name': '香川県',
    'en - name': 'Kagawa'
  },
  {
    'code': '74',
    'jp - name': '愛媛県',
    'en - name': 'Ehime'
  },
  {
    'code': '80',
    'jp - name': '高知県',
    'en - name': 'Kochi'
  },
  {
    'code': '81',
    'jp - name': '福岡県',
    'en - name': 'Fukuoka'
  },
  {
    'code': '82',
    'jp - name': '佐賀県',
    'en - name': 'Saga'
  },
  {
    'code': '83',
    'jp - name': '長崎県',
    'en - name': 'Nagasaki'
  },
  {
    'code': '84',
    'jp - name': '熊本県',
    'en - name': 'Kumamoto'
  },
  {
    'code': '90',
    'jp - name': '大分県',
    'en - name': 'Oita'
  },
  {
    'code': '91',
    'jp - name': '宮崎県',
    'en - name': 'Miyazaki'
  },
  {
    'code': '92',
    'jp - name': '鹿児島県',
    'en - name': 'Kagoshima'
  },
  {
    'code': '93',
    'jp - name': '沖縄県',
    'en - name': 'Okinawa'
  },
  {
    'code': '94',
    'jp - name': '離島',
    'en - name': 'Remote island'
  },
  {
    'code': '99',
    'jp - name': 'その他',
    'en - name': 'Others'
  }
];

export const LossLocation = [
  {
    'code': '01',
    'jp - name': '北海道',
    'en - name': 'Hokkaido'
  },
  {
    'code': '02',
    'jp - name': '青森県',
    'en - name': 'Aomori'
  },
  {
    'code': '03',
    'jp - name': '岩手県',
    'en - name': 'Iwate'
  },
  {
    'code': '04',
    'jp - name': '宮城県',
    'en - name': 'Miyagi'
  },
  {
    'code': '10',
    'jp - name': '秋田県',
    'en - name': 'Akita'
  },
  {
    'code': '11',
    'jp - name': '山形県',
    'en - name': 'Yamagata'
  },
  {
    'code': '12',
    'jp - name': '福島県',
    'en - name': 'Fukushima'
  },
  {
    'code': '20',
    'jp - name': '茨城県',
    'en - name': 'Ibaraki'
  },
  {
    'code': '21',
    'jp - name': '栃木県',
    'en - name': 'Tochigi'
  },
  {
    'code': '22',
    'jp - name': '群馬県',
    'en - name': 'Gunma'
  },
  {
    'code': '23',
    'jp - name': '埼玉県',
    'en - name': 'Saitama'
  },
  {
    'code': '24',
    'jp - name': '千葉県',
    'en - name': 'Chiba'
  },
  {
    'code': '25',
    'jp - name': '東京都',
    'en - name': 'Tokyo'
  },
  {
    'code': '26',
    'jp - name': '神奈川県',
    'en - name': 'Kanagawa'
  },
  {
    'code': '30',
    'jp - name': '新潟県',
    'en - name': 'Niigata'
  },
  {
    'code': '31',
    'jp - name': '富山県',
    'en - name': 'Toyama'
  },
  {
    'code': '32',
    'jp - name': '石川県',
    'en - name': 'Ishikawa'
  },
  {
    'code': '33',
    'jp - name': '福井県',
    'en - name': 'Fukui'
  },
  {
    'code': '34',
    'jp - name': '山梨県',
    'en - name': 'Yamanashi'
  },
  {
    'code': '40',
    'jp - name': '長野県',
    'en - name': 'Nagano'
  },
  {
    'code': '41',
    'jp - name': '岐阜県',
    'en - name': 'Gifu'
  },
  {
    'code': '42',
    'jp - name': '静岡県',
    'en - name': 'Shizuoka'
  },
  {
    'code': '43',
    'jp - name': '愛知県',
    'en - name': 'Aichi'
  },
  {
    'code': '44',
    'jp - name': '三重県',
    'en - name': 'Mie'
  },
  {
    'code': '50',
    'jp - name': '滋賀県',
    'en - name': 'Shiga'
  },
  {
    'code': '57',
    'jp - name': '京都府',
    'en - name': 'Kyoto'
  },
  {
    'code': '58',
    'jp - name': '大阪府',
    'en - name': 'Osaka'
  },
  {
    'code': '59',
    'jp - name': '兵庫県',
    'en - name': 'Hyogo'
  },
  {
    'code': '60',
    'jp - name': '奈良県',
    'en - name': 'Nara'
  },
  {
    'code': '61',
    'jp - name': '和歌山県',
    'en - name': 'Wakayama'
  },
  {
    'code': '62',
    'jp - name': '鳥取県',
    'en - name': 'Tottori'
  },
  {
    'code': '63',
    'jp - name': '島根県',
    'en - name': 'Shimane'
  },
  {
    'code': '64',
    'jp - name': '岡山県',
    'en - name': 'Okayama'
  },
  {
    'code': '70',
    'jp - name': '広島県',
    'en - name': 'Hiroshima'
  },
  {
    'code': '71',
    'jp - name': '山口県',
    'en - name': 'Yamaguchi'
  },
  {
    'code': '72',
    'jp - name': '徳島県',
    'en - name': 'Tokushima'
  },
  {
    'code': '73',
    'jp - name': '香川県',
    'en - name': 'Kagawa'
  },
  {
    'code': '74',
    'jp - name': '愛媛県',
    'en - name': 'Ehime'
  },
  {
    'code': '80',
    'jp - name': '高知県',
    'en - name': 'Kochi'
  },
  {
    'code': '81',
    'jp - name': '福岡県',
    'en - name': 'Fukuoka'
  },
  {
    'code': '82',
    'jp - name': '佐賀県',
    'en - name': 'Saga'
  },
  {
    'code': '83',
    'jp - name': '長崎県',
    'en - name': 'Nagasaki'
  },
  {
    'code': '84',
    'jp - name': '熊本県',
    'en - name': 'Kumamoto'
  },
  {
    'code': '90',
    'jp - name': '大分県',
    'en - name': 'Oita'
  },
  {
    'code': '91',
    'jp - name': '宮崎県',
    'en - name': 'Miyazaki'
  },
  {
    'code': '92',
    'jp - name': '鹿児島県',
    'en - name': 'Kagoshima'
  },
  {
    'code': '93',
    'jp - name': '沖縄県',
    'en - name': 'Okinawa'
  },
  {
    'code': '94',
    'jp - name': '離島',
    'en - name': 'Remote island'
  },
  {
    'code': '99',
    'jp - name': 'その他',
    'en - name': 'Others'
  },
  {
    'code': '1A',
    'jp - name': 'ニューヨーク',
    'en - name': 'New York'
  },
  {
    'code': '1B',
    'jp - name': 'サンフランシスコ',
    'en - name': 'San Francisco'
  },
  {
    'code': '1C',
    'jp - name': 'ハワイ',
    'en - name': 'Hawaii'
  },
  {
    'code': '1D',
    'jp - name': 'グアム',
    'en - name': 'Guam'
  },
  {
    'code': '1E',
    'jp - name': 'サイパン',
    'en - name': 'Sipan'
  },
  {
    'code': '1F',
    'jp - name': 'ロスアンゼルス',
    'en - name': 'Los Angeles'
  },
  {
    'code': '1Z',
    'jp - name': 'アメリカその他',
    'en - name': 'U.S.America Others'
  },
  {
    'code': '2A',
    'jp - name': 'カナダ',
    'en - name': 'Canada'
  },
  {
    'code': '3A',
    'jp - name': 'コスタリカ',
    'en - name': 'Costa Rica'
  },
  {
    'code': '3B',
    'jp - name': 'ガテマラ',
    'en - name': 'Guatemala'
  },
  {
    'code': '3C',
    'jp - name': 'メキシコ',
    'en - name': 'Mexico'
  },
  {
    'code': '3D',
    'jp - name': 'パナマ',
    'en - name': 'Panama'
  },
  {
    'code': '3E',
    'jp - name': 'カリブ諸島',
    'en - name': 'Caribbean Islands'
  },
  {
    'code': '3F',
    'jp - name': 'ブエルトリコ',
    'en - name': 'Puerto Rico'
  },
  {
    'code': '3X',
    'jp - name': 'キューバ',
    'en - name': 'Cuba'
  },
  {
    'code': '3Z',
    'jp - name': '中米その他',
    'en - name': 'Central America Others'
  },
  {
    'code': '4A',
    'jp - name': 'アルゼンチン',
    'en - name': 'Argentina'
  },
  {
    'code': '4B',
    'jp - name': 'ブラジル',
    'en - name': 'Brazail'
  },
  {
    'code': '4C',
    'jp - name': 'チリ',
    'en - name': 'Chile'
  },
  {
    'code': '4D',
    'jp - name': 'エクアドル',
    'en - name': 'Ecuador'
  },
  {
    'code': '4E',
    'jp - name': 'ペルー',
    'en - name': 'Peru'
  },
  {
    'code': '4F',
    'jp - name': 'ウルグアイ',
    'en - name': 'Uruguay'
  },
  {
    'code': '4Z',
    'jp - name': '南アメリカその他	',
    'en - name': 'South America Others'
  },
  {
    'code': '5A',
    'jp - name': 'ミャンマー',
    'en - name': 'Myanmar'
  },
  {
    'code': '5B',
    'jp - name': '中国	',
    'en - name': 'China'
  },
  {
    'code': '5C',
    'jp - name': 'インド',
    'en - name': 'India'
  },
  {
    'code': '5D',
    'jp - name': 'インドネシア',
    'en - name': 'Indonesia'
  },
  {
    'code': '5E',
    'jp - name': '上海',
    'en - name': 'Shanghai'
  },
  {
    'code': '5F',
    'jp - name': 'イスラエル',
    'en - name': 'Israel'
  },
  {
    'code': '5G',
    'jp - name': '韓国',
    'en - name': 'Korea	'
  },
  {
    'code': '5H',
    'jp - name': 'マレーシア',
    'en - name': 'Malaysia'
  },
  {
    'code': '5I',
    'jp - name': 'ベトナム',
    'en - name': 'Vietnam'
  },
  {
    'code': '5J',
    'jp - name': 'モンゴル',
    'en - name': 'Mongilia'
  },
  {
    'code': '5K',
    'jp - name': '香港	',
    'en - name': 'Hong Kong'
  },
  {
    'code': '5L',
    'jp - name': 'カンボジア',
    'en - name': 'Cambodia'
  },
  {
    'code': '5M',
    'jp - name': 'モルディブ',
    'en - name': 'Maldives'
  },
  {
    'code': '5N',
    'jp - name': 'シリア',
    'en - name': 'Syria'
  },
  {
    'code': '5O',
    'jp - name': 'ネパール',
    'en - name': 'Nepal'
  },
  {
    'code': '5P',
    'jp - name': 'パキスタン',
    'en - name': 'Pakistan'
  },
  {
    'code': '5Q',
    'jp - name': 'フィリピン',
    'en - name': 'Philippines'
  },
  {
    'code': '5R',
    'jp - name': 'シンガポール',
    'en - name': 'Singapore'
  },
  {
    'code': '5S',
    'jp - name': 'スリランカ',
    'en - name': 'Sri Lanka'
  },
  {
    'code': '5T',
    'jp - name': '台湾	',
    'en - name': 'Taiwan'
  },
  {
    'code': '5U',
    'jp - name': 'タイ',
    'en - name': 'Thailand'
  },
  {
    'code': '5V',
    'jp - name': 'アフガニスタン',
    'en - name': 'Afghanistan'
  },
  {
    'code': '5W',
    'jp - name': 'リビア',
    'en - name': 'Libya'
  },
  {
    'code': '5X',
    'jp - name': 'イラン',
    'en - name': 'Iran'
  },
  {
    'code': '5Y',
    'jp - name': 'イラク',
    'en - name': 'Iraq	'
  },
  {
    'code': '5Z',
    'jp - name': 'ｱｼﾞｱその他_TCP除',
    'en - name': 'Asia Others_TCP excluded'
  },
  {
    'code': '6A',
    'jp - name': 'オーストラリア',
    'en - name': 'Australia'
  },
  {
    'code': '6B',
    'jp - name': 'ニュージーランド',
    'en - name': 'New Zealand'
  },
  {
    'code': '6C',
    'jp - name': 'パプアニューギニア',
    'en - name': 'Papua New Guinea'
  },
  {
    'code': '6D',
    'jp - name': 'ニューカレドニア',
    'en - name': 'New Caledonia'
  },
  {
    'code': '6Z',
    'jp - name': 'オセアニアその他	',
    'en - name': 'Oceania'
  },
  {
    'code': '7A',
    'jp - name': 'オーストリア',
    'en - name': 'Austria'
  },
  {
    'code': '7B',
    'jp - name': 'ベルギー',
    'en - name': 'Belgium'
  },
  {
    'code': '7C',
    'jp - name': 'チェコ	',
    'en - name': 'Czech Republic'
  },
  {
    'code': '7D',
    'jp - name': 'デンマーク',
    'en - name': 'Denmark'
  },
  {
    'code': '7E',
    'jp - name': 'イギリス',
    'en - name': 'United Kingdom'
  },
  {
    'code': '7F',
    'jp - name': 'フィンランド',
    'en - name': '	Finland'
  },
  {
    'code': '7G',
    'jp - name': 'フランス',
    'en - name': 'France'
  },
  {
    'code': '7H',
    'jp - name': 'ドイツ',
    'en - name': 'Germany'
  },
  {
    'code': '7I',
    'jp - name': 'トルコ',
    'en - name': 'Turkey'
  },
  {
    'code': '7O',
    'jp - name': 'ギリシャ',
    'en - name': 'Greece'
  },
  {
    'code': '7P',
    'jp - name': 'イタリア',
    'en - name': 'Italy'
  },
  {
    'code': '7Q',
    'jp - name': 'オランダ',
    'en - name': 'Netherlands'
  },
  {
    'code': '7R',
    'jp - name': 'ノルウェー',
    'en - name': 'Norway'
  },
  {
    'code': '7S',
    'jp - name': 'ポーランド',
    'en - name': 'Poland'
  },
  {
    'code': '7T',
    'jp - name': 'ポルトガル',
    'en - name': 'Portugal'
  },
  {
    'code': '7U',
    'jp - name': 'ロシア',
    'en - name': 'Russia'
  },
  {
    'code': '7V',
    'jp - name': 'スペイン',
    'en - name': 'Spain'
  },
  {
    'code': '7W',
    'jp - name': 'スウェーデン',
    'en - name': 'Sweden'
  },
  {
    'code': '7X',
    'jp - name': 'スイス',
    'en - name': 'Switzerland'
  },
  {
    'code': '7Y',
    'jp - name': 'ロシア以外旧ソ連邦',
    'en - name': '	Former Soviet Union except Russia'
  },
  {
    'code': '7Z',
    'jp - name': 'ﾖｰﾛｯﾊﾟその他_TCP除',
    'en - name': 'Europe and others_TCP excluded'
  },
  {
    'code': '8A',
    'jp - name': '南アフリカ',
    'en - name': 'South Africa'
  },
  {
    'code': '8B',
    'jp - name': 'エジプト',
    'en - name': 'Egypt'
  },
  {
    'code': '8C',
    'jp - name': 'ケニヤ',
    'en - name': 'Kenya'
  },
  {
    'code': '8D',
    'jp - name': 'モロッコ',
    'en - name': 'Morocco'
  },
  {
    'code': '8E',
    'jp - name': 'ナイジェリア',
    'en - name': 'Nigeria'
  },
  {
    'code': '8V',
    'jp - name': 'コンゴ民主共和国',
    'en - name': 'Congo'
  },
  {
    'code': '8W',
    'jp - name': 'リベリア',
    'en - name': 'Liberia'
  },
  {
    'code': '8X',
    'jp - name': 'スーダン',
    'en - name': 'Sudan'
  },
  {
    'code': '8Z',
    'jp - name': 'アフリカその他',
    'en - name': 'Other Africa'
  }
]



