import { URL_PARAMETERS } from './urlParameters.constant';
import { Country } from '../shared/model/country';
import { ErrorMessages } from 'axis-ui-generator';

interface FnolSteps {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsUS {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsCAT {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsJP {
  CLAIMANT_INFO: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}

interface FnolStepsObj {
  name: string;
  slNo: number;
  title: string;
  progressStepTitle: string;
}
export const NAME_DATE_LOSS = {
  AU: 'tripIncidentDate',
  US: 'dateLoss',
  SG: 'tripIncidentDate',
  NZ: 'tripIncidentDate',
};
export const FNOL_STEPS: FnolSteps = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Claimant Information`, progressStepTitle: `Claimant Information` },
  INCIDENT_DETAILS: { name: 'fnol-incident-details', slNo: 2, title: `Incident Details`, progressStepTitle: `Incident Details` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 3, title: `Claim Type Details`, progressStepTitle: `Claim Type Details` },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Payment Details`, progressStepTitle: `Payment Details` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 5, title: `Upload Supporting Documents`, progressStepTitle: `Upload Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 6, title: `Review and Submit the Claim`, progressStepTitle: `Declaration` }
};
export const FNOL_STEPS_CAT: FnolStepsCAT = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Claimant Information`, progressStepTitle: `Claimant Information` },
  INCIDENT_DETAILS: { name: 'fnol-incident-details', slNo: 2, title: `Incident Details`, progressStepTitle: `Incident Details` },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 3, title: `Payment Details`, progressStepTitle: `Payment Details` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 4, title: `Upload Supporting Documents`, progressStepTitle: `Upload Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Review and Submit the Claim`, progressStepTitle: `Declaration` }
};

export const FNOL_STEPS_US: FnolStepsUS = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Policy & Claimant`, progressStepTitle: `Policy & Claimant` },
  INCIDENT_DETAILS: { name: 'fnol-incident-details', slNo: 2, title: `Incident Details`, progressStepTitle: `Incident Details` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 3, title: `Claim Type`, progressStepTitle: `Claim Type` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 4, title: ` Documents`, progressStepTitle: `Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Review and Submit the Claim`, progressStepTitle: `Declaration` }
};

interface FnolCountry {
  SINGAPORE: Country;
  SINGAPORENEW: Country;
  THAILAND: Country;
  VIETNAM: Country;
  AUSTRALIA: Country;
  NEWZEALAND: Country;
  NAM: Country
  JAPAN: Country;
}

export const profileCheck = {
  "zipCode": "postalCode",
  "lastNameKanji": "lastNameKanjiClaim",
  "firstNameKanji": "firstNameKanjiClaim",
  "lastNameKatakana": "lastNameKatakanaClaim",
  "firstNameKatakana": "firstNameKatakanaClaim",
  "prefecture": "prefecture",
  "addressline1": "condominium",
  "city": "city",
  "phoneNumber": "phone",
  "mobileNumber": "mobile",
  "emailId": "email"
}

export const addContainerMain = ['addHospitalizationMain',
  'addSurgeryMain',
  'addFixturesMain',
  'addDamageInjuryMain',
  'addInpatientMain',
  'addSurgeryIllnessMain',
  'addRadiationMain',
  'addDamageMain',
  'addTheftMain'];

export const FNOL_COUNTRY: FnolCountry = {
  SINGAPORE: { countryCode: 'sg', callingCode: '+65', currencyCode: 'SGD', currencyDescription: 'Singapore Dollar', language: 'en' },
  SINGAPORENEW: { countryCode: 'singapore', callingCode: '+65', currencyCode: 'SGD', currencyDescription: 'Singapore Dollar', language: 'en' },
  THAILAND: { countryCode: 'th', callingCode: '+66', currencyCode: 'THB', currencyDescription: 'Thai baht', language: 'th' },
  VIETNAM: { countryCode: 'vn', callingCode: '+84', currencyCode: 'VND', currencyDescription: 'Vietnamese dong', language: 'vn' },
  AUSTRALIA: { countryCode: 'au', callingCode: '+43', currencyCode: 'AUD', currencyDescription: 'Australian Dollar', language: 'en' },
  NEWZEALAND: { countryCode: 'nz', callingCode: '+64', currencyCode: 'NZD', currencyDescription: 'New Zealand Dollar', language: 'en' },
  NAM: { countryCode: 'us', callingCode: '+1', currencyCode: 'USD', currencyDescription: 'United States Dollar', language: 'en' },
  JAPAN: { countryCode: 'jp', callingCode: '+81', currencyCode: 'JPY', currencyDescription: 'Japanese yen', language: 'jp', lob: '8' }
};

export const DOUBLEBYTECONVERSIONFIELDS = {
  lastNameKatakana: true,
  firstNameKatakana: true,
  lastNameKatakanaClaim: true,
  firstNameKatakanaClaim: true,
  financialInstitution: true,
  branchName: true,
  accountHolder: true,
  accountNumberKatakana: true,
  lastKatakana: true,
  firstKatakana: true
};

export const ZENHANONLY = {
  "lastNameKanji": true,
  "firstNameKanji": true,
  "lastNameKanjiClaim": true,
  "firstNameKanjiClaim": true,
  "financialInstitution": true,
  "branchName": true,
  "accountHolder": true
};

export const KATAKANAONLY = {
  lastNameKatakana: true,
  firstNameKatakana: true,
  lastNameKatakanaClaim: true,
  firstNameKatakanaClaim: true,
};

export const ADDSPACEINPDF_FIELDS = ['country', 'visitDoctor', 'damageIncident', 'dod', 'dateDisability', 'insuranceCovering', 'damageIncident'];
export const EXCLUDEINPDF_FIELDS = ['firstName', 'lastNameDoctor', 'lastName', 'lastNameBeneficiary', 'tod',
  'others', 'others1', 'others2'];
export const APPLICATION_ID = 'EXTPOR-001';
export const DATE_FORMAT = 'dd/MM/yyyy';

export const FNOL_JSON = {
  FNOL_FEEDBACK: { name: 'fnol-feedback' }
};

export const ADDRESS_FIELDS = ['Address1', 'Address2', 'State', 'City', 'PostalCode', 'Country'];

export const DISPLAY_MESSAGES = {
  API_FAILURE: 'Document upload timed-out or failed. Please try again.',
  API_FAILURE_DEL: 'Failed to delete the document.',
  FEEDBACK_FAILURE: 'Failed to submit your feedback. Please try again.',
  ZEROBYTE_ERROR: 'Upload files must be greater than 0 bytes.',
  FILE_TYPE: 'The file format you submitted is not among the formats supported.',
  FILE_TYPE_NO: 'Filformatet du sendte inn er ikke blant formatene som støttes.',
  FILE_SIZE: $localize`Sorry that file size is too large. Please upload a file under 25 MB.`,
  FILE_TYPE_NORDICS:'Please upload the file in the specified file format with a file size of 25MB or less.',
  FILE_TYPE_NORDICS_HLF:'The file format you submitted is not among the formats supported.'
};

export const MARINE_FILE_ALLOWED_FORMAT = [
  'AFP', 'BMP', 'DIB', 'DOC', 'DOCX', 'GIF', 'JPEG', 'JPG', 'MPP', 'MSG',
  'PDF', 'PPT', 'PPTX', 'PTX', 'RTF', 'TIF', 'TIFF', 'TXT', 'XLS', 'XLSB',
  'XLSX', 'CSV', 'PNG', 'WAV', 'DOCM', 'XLSM', 'PPTM', 'VSD', 'VDX', 'WMA',
  'VSDX', 'MP3', 'EML', 'MPEG', 'MP4', 'AVI', 'MOV', 'WMV', '3GP'
];

export const FNOL_STEPS_JP: FnolStepsJP = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Policy & Claim Incident`, progressStepTitle: `Policy & Claim Incident` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Claim Type`, progressStepTitle: `Claim Type` },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 3, title: `Payment`, progressStepTitle: `Payment` },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 4, title: `Documents`, progressStepTitle: `Documents` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Declaration`, progressStepTitle: `Declaration` }
};

export const FNOL_STEPS_JP_FOLLOW_UP_CLAIM = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Policy & Claim Incident`, progressStepTitle: `Policy & Claim Incident` },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Claim Type`, progressStepTitle: `Claim Type` },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Declaration`, progressStepTitle: `Declaration` }
};

export const JP_DATA_CONTAINERS = ['addMedicalMain', 'addInstitutionMain', 'addInjuredMain', 'addHospitalizationMain', 'addInpatientMain', 'addDamageInjuryMain', 'addSurgeryMain', 'addSurgeryIllnessMain', 'addFixturesMain', 'addInsuranceMain', 'addRadiationMain', 'addTheftMain', 'addDamageMain'];

export const CUSTOMERRORMESSAGE = {
  dob: $localize`Claimant Date of birth cannot be greater than current date`,
  incidentDate: $localize`Date of Incident cannot be greater than current date`,
  doctorVisitDate: $localize`"When did you visit the doctor?" must not be greater than current date`,
  hospitalizationDate: $localize`"Date of hospitalization" cannot be greater than current date`,
  dod: $localize`"Date and Time of Death" must not be greater than current date and time`,
  disabilityDate: $localize`"Date disability was diagnosed" cannot be greater than current date`,
  policyGreaterDate: $localize`"Period of Insurance from" cannot be greater than current date`,
  timeGreater: $localize`Time cannot be greater than the current time`,
  incidentTime: $localize`'Time of incident' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`,
  commencementTime: `'Passenger pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`,
  commencementTimeVnTh: $localize`'Passenger/Parcel/Food pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`,
  completionTimeBefore: $localize`'Trip Completion Time' is expected after 'Job Acceptance Time'`,
  jobAcceptanceTimeBefore: $localize`'Job Acceptance Time' is expected before 'Trip Completion Time'`,
  timeAdmissionGreater: $localize`"Time of Admission" cannot be greater than current date and time`,
  dischargeDateSame: $localize`"Date of Admission" and "Date of Discharge" cannot be same`,
  admissionDateGreater: $localize`"Date of Admission" is expected before "Date of discharge"`,
  dischargeDateLesser: $localize`"Date of Discharge" is expected after "Date of admission"`,
  medicalLeaveStartDateGreater: $localize`"Start of outpatient medical leave" is expected before "End of outpatient medical leave"`,
  medicalLeaveEndDateLesser: $localize`"End of outpatient medical leave" is expected after "Start of outpatient medical leave"`,
  medicalLeaveDatesSame: $localize`"Start of outpatient medical leave" and "End of outpatient medical leave" cannot be same`,
  timeDischarge: $localize`Time of Discharge cannot be greater than current date and time`,
  fromDate: $localize`Date cannot be greater than "Period of Insurance To"`,
  toDate: $localize`Date cannot be less than "Period of Insurance From"`,
  ageLesser: $localize`:@@age18:Insured can only be selected if current Insured age is equal or greater than 18 years old`,
  confirmMail: $localize`:@@confirmEmail:Email and Confirm mail should match.`,
  policyNumber: `A minimum character of 3 to a maximum 10 is expected. Please update your entry based on your policy record.`,
  serviceErrorMsg: $localize`Internal Server Error.Please try again`,
  policyInvalid: $localize`:@@policyInvalid:ご入力の内容が確認できませんでした。このお手続きをスキップするか、お手数ですがご確認の上もう一度ご入力ください。このお手続きをスキップして次へ進む`,
  doiGreater: $localize`:@@doiGreater:Date of Hospital Visit must be greater or equal to Date of Accident`,
  consultationGreater: $localize`:@@consultationGreater:Date of Hospital Visit must be greater or equal to Date of Accident`,
  illnessHospitalGreater: $localize`:@@illnessHospitalGreater:Date of Hospital Visit must be greater or equal to Admission Date`,
  purchaseDateGreater: $localize`:@@purchaseDateGreater:Purchase Date must occur on or before Date of Loss/Incident`,
  notificationDateGreater: $localize`:@@notificationDateGreater:Notification Date can not occur before Date of Loss/Incident`,
  accountSymbolInvalid: $localize`:@@accountSymbolInvalid:Account Symbol is expected to be exactly 5 characters including the first 1 and the last 0. `,
  illnessSurgeryGreater: $localize`:@@illnessSurgeryGreater:Day of Surgery must be greater or equal to Date of Initial Consultation`,
  illnessRadiationGreater: $localize`:@@illnessRadiationGreater:Radiation Start Date must be greater or equal to Date of Initial Consultation`,
  dateOfSurgeryGreater: $localize`:@@dateOfSurgeryGreater: Date Of Surgery cannot occur before Date of Incident/Loss`,
  dateOfSurgeryAdmissionGreater: $localize`:@@dateOfSurgeryAdmissionGreater: Date Of Surgery must occur after Admission or Treatment Date`,
  outPatientGreater: $localize`:@@outPatientGreater: Out Patient From cannot occur before Date of Incident/Loss`,
  fixingGreater: $localize`:@@fixingGreater: Fixing Date cannot occur before Date of Incident/Loss`,
  outPatientSame: $localize`"Outpatient Treatment From" and "Outpatient Treatment To" cannot be same`,
  outPatientEndDateLesser: $localize`:@@toOutpatient:Outpatient Treatment To must occur after Outpatient Treatment From`,
  outPatientStartDateGreater: $localize`:@@fromOutpatient:Outpatient Treatment From is expected before Outpatient Treatment To`,
  fixtureDateSame: $localize`"Fixing Date" and "Date Fixture Removed" cannot be same`,
  fixingDateGreater: $localize`Fixing Date is expected before Date Fixture Removed`,
  fixtureRemoveDateLesser: $localize`Date Fixture Removed must occur after Fixing Date`,
  radiationDateSame: $localize`"Radiation Start Date" and "Radiation End Date" cannot be same`,
  radiationStartDateGreater: $localize`Radiation Start Date is expected before Radiation End Date`,
  radiationEndDateLesser: $localize`Radiation End Date must occur after Radiation Start Date`,
};
export const Constants = {
  today: 'Today',
  clear: 'Clear'
}

export const IMG_ALLOWED_FORMAT = ['TIF', 'JPG', 'PDF', 'TIFF', 'JPEG'];
export const DATE_FORMAT_JPN = 'yyyy-MM-dd';

export const ContainerCountMap =
{
  'addInjuredMain': 3,
  'addMedicalMain': 3,
  'addHospitalizationMain': 3,
  'addSurgeryMain': 3,
  'addFixturesMain': 3,
  'addInsuranceMain': 3,
  'addInstitutionMain': 3,
  'addInpatientMain': 3,
  'addSurgeryIllnessMain': 3,
  'addRadiationMain': 3,
  'addDamageMain': 20,
  'addTheftMain': 20,
  'addDamageInjuryMain': 20
};

export const CONSCENT_DECLARATION = {
  conscentAll: 'I have read and agree to the declarations and authorisation:',
  declarationAll: 'By submitting this form, I have read and agree with the declarations and authorisation:',
  conscent: $localize`:@@consentAIG:I consent to AIG collecting, using and disclosing personal information as set out in this notice. If I have provided or will provide information to AIG about any other individuals, I confirm that I am authorised to disclose his or her personal information to AIG and also to give this consent on both my and their behalf.`,
  declaration: 'I have read and agree to the declarations and authorisation. By submitting this form, I have read and agree with the declarations and authorisation:',
  provision: `I have read 'Claim Preparation Costs' provision`
};

export const HEADINGMANAGERUS = {
  submitting: ['submitting',
    'policyNumber',
    'policyCheck',
    'policyNameInsured',
    'insuranceStartInsured',
    'insuranceExpiryInsured'
  ],
  brokerName: ['brokerName',
    'brokerBusinessName',
    'brokerPrimary',
    'brokerPrimaryExtn',
    'brokerSecondary',
    'brokerEmail'],
  claimantFirstName: ['identify',
    'claimantFirstName',
    'claimantLastName',
    'claimantCompanyName',
    'claimantAddress1',
    'claimantAddress2',
    'claimantCity',
    'claimantState',
    'claimantPostalCode',
    'claimantCountry',
    'claimantPrimaryNumber',
    'claimantPrimaryNumberExtn',
    'claimantSecondaryNumber',
    'claimantEmail'],
  certificate: ['certificate',
    'dateLoss',
    'estimateLoss',
    'estimateCargo',
    'tpClaim',
    'location',
    'loss'
  ],
  lossLocationAddress1: [
    'lossLocationAddress1',
    'lossLocationAddress2',
    'lossLocationCity',
    'lossLocationState',
    'lossLocationPostalCode',
    'lossLocationCountry'
  ],
  claimBenefits: ['claimBenefits'],
  cargoLoss: ['cargoLoss',
    'cargoIdentify',
    'cargoFirstName',
    'cargoLastName',
    'cargoCompanyName',
    'cargoPrimaryNumber',
    'cargoPrimaryNumberExtn',
    'cargoSecondaryNumber',
    'cargoEmail'],
  cargoAddress1: [
    'cargoEntryAddress1',
    'cargoEntryAddress2',
    'cargoEntryCity',
    'cargoEntryState',
    'cargoEntryPostalCode',
    'cargoEntryCountry',
    'cargoAddress1',
    'cargoAddress2',
    'cargoCity',
    'cargoState',
    'cargoPostalCode',
    'cargoCountry',
    'cargoPhoneNumber',
    'cargoPhoneNumberExtn',
    'arrivalDate',
    'cargoAdditional'],
  conveyance: ['conveyance',
    'carrier',
    'vessel',
    'voyage',
    'descriptionCargo',
    'lading',
    'landingDate',
    'origin',
    'destination'],
  howManyVPD: ['howManyVPD'],
  vehicle: ['vehicle',
    'year',
    'make',
    'model',
    'vin',
    'unit'],
  vehicle2: ['vehicle2',
    'year2',
    'make2',
    'model2',
    'vin2',
    'unit2'],
  cargoInlandLoss: [
    'cargoInlandLoss',
    'inlandIdentify',
    'inlandFirstName',
    'inlandLastName',
    'inlandCompanyName',
    'inlandPrimaryNumber',
    'inlandPrimaryNumberExtn',
    'inlandSecondaryNumber',
    'inlandEmail'],
  vehicle2CRD: [
    'vehicle2CRD',
    'year2CRD',
    'make2CRD',
    'model2CRD',
    'unit2CRD'
  ],
  sameLocation: ['sameLocation'],
  inlandAddress1: [
    'inlandEntryAddress1',
    'inlandEntryAddress2',
    'inlandEntryCity',
    'inlandEntryState',
    'inlandEntryPostalCode',
    'inlandEntryCountry',
    'inlandAddress1',
    'inlandAddress2',
    'inlandCity',
    'inlandState',
    'inlandPostalCode',
    'inlandCountry',
    'inlandPhoneNumber',
    'inlandPhoneNumberExtn',
    'inlandArrivalDate',
    'inlandAdditional'],
  inlandAddress1MTC: [
    'inlandEntryAddress1MTC',
    'inlandEntryAddress2MTC',
    'inlandEntryCityMTC',
    'inlandEntryStateMTC',
    'inlandEntryPostalCodeMTC',
    'inlandEntryCountryMTC',
    'inlandAddress1MTC',
    'inlandAddress2MTC',
    'inlandCityMTC',
    'inlandStateMTC',
    'inlandPostalCodeMTC',
    'inlandCountryMTC',
    'inlandPhoneNumberMTC',
    'inlandPhoneNumberMTCExtn',
    'inlandArrivalDateMTC',
    'inlandAdditionalMTC'],
  mtcAvailable: ['mtcAvailable'],
  carrierInland: ['carrierInland',
    'ladingInland',
    'landingDateInland',
    'cargoProduct',
    'descriptionCargoInland',
    'originInland',
    'destinationInland']
};

export const HEADINGMANAGER = {
  firstName: ['firstName', 'lastName', 'contactPhone', 'contactEmail'],
  subsidiaries: ['subsidiaries', 'otherParties', 'parentCompany'],
  brokerName: ['brokerName', 'brokerBusinessName', 'brokerNumber', 'brokerEmail'],
  policyHolder: ['policyHolder', 'firstNameAccountant', 'lastNameAccountant', 'phoneAccountant', 'emailAccountant'],
  assist: ['assist', 'arising', 'detailsFinancial'],
  description: ['description'],
  premises: ['premises', 'caseDate'], // 'policy', 'closedWhole', 'insured', 'reClosed', 'exactDate'],
  policy: ['policy', 'confirmedCase'],
  closedWhole: ['closedWhole', 'durationDate'],
  insured: ['insured', 'partiallyClosed'],
  exactDate: ['exactDate'],
  insuredNotice: ['insuredNotice', 'confirmation'],
  restrictions: ['restrictions', 'writtenDoc'],
  infoAig: ['infoAig']
};

export const HEADINGMANAGERJP = {
  accidentDate: ['accidentDate',
    'accidentTime',
    'lossLocation'],
  lastNameKanji: ['lastNameKanji',
    'firstNameKanji',
    'lastNameKatakana',
    'firstNameKatakana',
    'dob'],
  relationshipInsured: ['relationshipInsured',
    'insuredOthers',
    'lastNameKanjiClaim',
    'firstNameKanjiClaim',
    'lastNameKatakanaClaim',
    'firstNameKatakanaClaim'],
  postalCode: ['postalCode',
    'prefecture',
    'city',
    'condominium',
    'country',
    'phone',
    'mobile',
    'email'],

  claimBenefits: ['claimBenefits'],

  injuryCode: ['injuryCode',
    'injuryCause',
    'injuryStatus',
    'injuryDescription'],

  addInjuredMain: ['addInjuredMain'],

  addMedicalMain: ['addMedicalMain'],

  // treatment: [
  //   'treatment'],
  selectStatus: ['selectStatus'],
  addHospitalizationMain: ['addHospitalizationMain'],

  surgery: ['surgery'],
  addSurgeryMain: ['addSurgeryMain'],

  outpatient: ['outpatient'],
  outPatientTreatmentDate: ['outPatientTreatmentDate',
    'lastOutpatient',
    'hospitalVisits'],

  fixtures: ['fixtures'],
  addFixturesMain: ['addFixturesMain'],

  coverage: ['coverage'],
  outPocket: ['outPocket'],
  transportation: [
    'transportation',

    'distanceTraveled',
    'tollRoad'],
  expensesIncurred: ['expensesIncurred'],
  contracts: ['contracts'],
  addInsuranceMain: ['addInsuranceMain'],
  damagePE: ['damagePE'],

  damageLocationInjury: [
    "damageLocationInjury",
    "schoolManagementInjury"],

  addDamageInjuryMain: ['addDamageInjuryMain'],


  surgeryCause: ["surgeryCause",
    "symptoms",
    "occurrenceDate",
    "consultationDate"],

  addInstitutionMain: ['addInstitutionMain'],



  // deliveryAddress:["deliveryAddress",
  // "nameDestination",
  // "treatmentIllness"],

  illnessSelectStatus: [
    'illnessSelectStatus'],

  addInpatientMain: ['addInpatientMain'],
  diagnosed: ['diagnosed',
    'insuranceBenefits'],
  surgeryIllness: ['surgeryIllness'],

  addSurgeryIllnessMain: ['addSurgeryIllnessMain'],

  radiotherapy: ['radiotherapy'],

  addRadiationMain: ["addRadiationMain"],

  accidentDateTime: ["accidentDateTime",
    "tripIncidentTime",
    "damage",
    "damageLocation",
    "accidentSituation",
    "accidentDescription",
    "schoolManagement"],

  addDamageMain: ["addDamageMain"],

  addTheftMain: ['addTheftMain'],
  reported: ["reported",
    "receiptNumber",
    "notificationDate",
    "reportingParty"],


  account: ['account'],
  institutions: ['institutions'],
  financialInstitution: ['financialInstitution',
    'typeFinancial',
    'branchCode',
    'branchName',
    'accountType',
    'accountNumber',
    'accountHolder'],
  symbol: ['symbol',
    'number',
    'accountNumberKatakana']
};

export const UPLOADTEXT = {
  vn: {
    uploadText: 'Kéo và Thả tài liệu hoặc <u>Tìm</u> tài liệu',
    uploadButtonText: 'Tìm tài liệu'
  },
  th: {
    uploadText: 'ลากและวางหรือ<u>ค้นหา</u>ไฟล์',
    uploadButtonText: 'ค้นหาไฟล์'
  },
  sg: {
    uploadText:
      `<p>Drag and drop or <u>Browse</u> here to select the upload File.</p>
       <p data-color="gray" class="_text-small">Maximum file size: 25 MB</p>`
  },
  uploadText:
    `<p>Drag and drop or <u>Browse</u> here to select the upload File.</p>
  <p data-color="gray" class="_text-small">Maximum supported file size: 25 MB</p>`,
  uploadTextJP: `Drag and drop, or Browse here to select the upload file.
  <p data-color="gray" class="_text-small _nmb">Supported file types: JPG, JPEG, TIF, TIFF, PDF and HEIC.</p>
  <p data-color="gray" class="_text-small">Maximum supported file size: 25 Mb</p>`,
  uploadTextJPLocalize: `ここにファイルをドラッグするか端末内からアップロードするファイルを選択してください
  <p data-color="gray" class="_text-small _nmb">対応するファイル形式：JPG, JPEG, TIF, TIFF, PDF, HEIC</p>
  <p data-color="gray" class="_text-small">ファイルサイズの上限：25 MB</p>`
};

export const PRIVACY_POLICY = {
  byContinue_US: 'By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use: ',
  byContinue: 'By ticking this box and making use of this form and website, I acknowledge: ',
  byContinue1: '(i) I have read the Privacy Policy and the Terms of Use;',
  byContinue2: '(ii) I assent and agree with the provisions found in the Privacy Policy and the Terms of Use; and',
  byContinue3_AU: '(iii) that AIG Australia may revise the Privacy Policy and Terms of Use by updating the same on this website and that, as and when I make use of this website, I am bound by any such revisions.',
  byContinue3_NZ: '(iii) that AIG New Zealand may revise the Privacy Policy and Terms of Use by updating the same on this website and that, as and when I make use of this website, I am bound by any such revisions.',
  byContinue4: '(iii) that AIG may revise the Privacy Policy and Terms of Use by updating the same on this website and that, as and when I make use of this website, I am bound by any such revisions.',
  byContinue_JP: $localize`:@@consentAIG:I consent to AIG collecting, using and disclosing personal information as set out in this notice. If I have provided or will provide information to AIG about any other individuals, I confirm that I am authorised to disclose his or her personal information to AIG and also to give this consent on both my and their behalf.`
};

export const DISPLAY_NAME_CHANGE_LIST_AU = ['firstName', 'lastName', 'contactPhone', 'contactEmail', 'brokerName', 'brokerBusinessName', 'brokerNumber', 'brokerEmail'];

export const DISPLAY_NAME_CHANGE_LIST_NAM = ['claimantFirstName', 'claimantLastName', 'claimantCompanyName', 'claimantAddress1', 'claimantAddress2', 'claimantCity', 'claimantState', 'claimantPostalCode', 'claimantCountry',
  'claimantPrimaryNumber', 'claimantPrimaryNumberExtn', 'claimantSecondaryNumber', 'claimantEmail', 'contactPhone', 'contactEmail', 'brokerName', 'brokerBusinessName', 'brokerPrimary', 'brokerPrimaryExtn', 'brokerSecondary', 'brokerEmail'];

export const NEW_DISPLAY_NAME_MAPPING_AU = {
  firstName: 'Policy Holder First Name',
  lastName: 'Policy Holder Last Name',
  contactPhone: 'Policy Holder Phone',
  contactEmail: 'Policy Holder Email',
  brokerName: 'Broker Name',
  brokerBusinessName: 'Broker Business Name',
  brokerNumber: 'Broker Phone',
  brokerEmail: 'Broker Email'
};

export const NEW_DISPLAY_NAME_MAPPING_NAM = {
  claimantFirstName: 'Claimant First Name',
  claimantLastName: 'Claimant Last Name',
  claimantCompanyName: 'Claimant Company Name',
  claimantAddress1: 'Claimant Address Line 1',
  claimantAddress2: 'Claimant Address Line 2',
  claimantCity: 'Claimant Town/City',
  claimantState: 'Claimant State/Province',
  claimantPostalCode: 'Claimant Zip/Postal Code',
  claimantCountry: 'Claimant Country',
  claimantPrimaryNumber: 'Claimant Primary Contact Number',
  claimantPrimaryNumberExtn: 'Claimant Primary Contact Number - Extension',
  claimantSecondaryNumber: 'Claimant Secondary Contact Number',
  claimantEmail: 'Claimant Email',
  brokerName: 'Broker Name',
  brokerBusinessName: 'Broker Business Name',
  brokerPrimary: 'Broker Primary Contact Number',
  brokerPrimaryExtn: 'Broker Primary Contact Number - Extension',
  brokerSecondary: 'Broker Secondary Contact Number',
  brokerEmail: 'Broker Email'
};

export class UtilsConstLabelsLocalization {

  public static getFnolSteps(): void {
    // this.stepTitleList[0].progressStepTitle = 'Policy/Claimant Information';
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode) {
      FNOL_STEPS_JP['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP['CLAIM_TYPE_DETAILS']['title'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP['CLAIM_TYPE_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP['PAYMENT_DETAILS']['title'] = $localize`:@@stpesLabel-1137120387899046074:Payment`;
      FNOL_STEPS_JP['PAYMENT_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-1137120387899046074:Payment`;
      FNOL_STEPS_JP['UPLOAD_DOCS']['progressStepTitle'] = $localize`:@@stpesLabel-6433493604936913919:Documents`;
      FNOL_STEPS_JP['UPLOAD_DOCS']['title'] = $localize`:@@stpesLabel-6433493604936913919:Documents`;
      FNOL_STEPS_JP['DECLARATION']['progressStepTitle'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;
      FNOL_STEPS_JP['DECLARATION']['title'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;

      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-5796007691650886453:Policy & Claim Incident`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIM_TYPE_DETAILS']['title'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['CLAIM_TYPE_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['DECLARATION']['progressStepTitle'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;
      FNOL_STEPS_JP_FOLLOW_UP_CLAIM['DECLARATION']['title'] = $localize`:@@stpesLabel-4312716739160141249:Declaration`;

    }
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.AUSTRALIA.countryCode ||
      URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NEWZEALAND.countryCode) {
      FNOL_STEPS['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-57960076916508864544:Policy/Claimant Information`;
      FNOL_STEPS['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-57960076916508864555:Policy and Claimant Information`;
    } else {
      FNOL_STEPS['CLAIMANT_INFO']['progressStepTitle'] = $localize`:@@stpesLabel-5796007691650886453:Claimant Information`;
      FNOL_STEPS['CLAIMANT_INFO']['title'] = $localize`:@@stpesLabel-5796007691650886453:Claimant Information`;
    }
    FNOL_STEPS['INCIDENT_DETAILS']['title'] = $localize`:@@stpesLabel-1309456754889132509:Incident Details`;
    FNOL_STEPS['INCIDENT_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-1309456754889132509:Incident Details`;
    FNOL_STEPS['CLAIM_TYPE_DETAILS']['title'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type Details`;
    FNOL_STEPS['CLAIM_TYPE_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-8488384375363948807:Claim Type Details`;
    FNOL_STEPS['PAYMENT_DETAILS']['title'] = $localize`:@@stpesLabel-1137120387899046074:Payment Details`;
    FNOL_STEPS['PAYMENT_DETAILS']['progressStepTitle'] = $localize`:@@stpesLabel-1137120387899046074:Payment Details`;
    FNOL_STEPS['UPLOAD_DOCS']['progressStepTitle'] = $localize`:@@stpesLabel-6433493604936913917:Upload Documents`;
    FNOL_STEPS['UPLOAD_DOCS']['title'] = $localize`:@@stpesLabel-6433493604936913919:Upload Supporting Documents`;
    FNOL_STEPS['DECLARATION']['progressStepTitle'] = $localize`:@@stpesLabel-4312716739160141243:Declaration`;
    FNOL_STEPS['DECLARATION']['title'] = $localize`:@@stpesLabel-4312716739160141249:Review and Submit the Claim`;
  }

  public static getCustomText(): void {
    PRIVACY_POLICY.byContinue_JP = $localize`:@@consentAIG:I consent to AIG collecting, using and disclosing personal information as set out in this notice. If I have provided or will provide information to AIG about any other individuals, I confirm that I am authorised to disclose his or her personal information to AIG and also to give this consent on both my and their behalf.`;
  }

  public static getDisplayMessages(): void {
    DISPLAY_MESSAGES.API_FAILURE_DEL=$localize`:@@deleteFail:Failed to delete the document.`
    DISPLAY_MESSAGES.API_FAILURE = $localize`:@@docFail:Document upload timed-out or failed. Please try again.`;
    DISPLAY_MESSAGES.FEEDBACK_FAILURE = $localize`:@@submitFeedback:Failed to submit your feedback. Please try again.`;
    DISPLAY_MESSAGES.ZEROBYTE_ERROR = $localize`:@@docBytes:Upload files must be greater than 0 bytes.`;
    DISPLAY_MESSAGES.FILE_TYPE = $localize`:@@docSize:Please upload the file in the specified file format with a file size of 10MB or less.`;
    DISPLAY_MESSAGES.FILE_TYPE_NORDICS = $localize`:@@docSize:Please upload the file in the specified file format with a file size of 25MB or less.`;
  }

  public static getClaimBenefitType(country: string, type?): Array<any> {
    let claimBenefitType = [];
    if (country === FNOL_COUNTRY.SINGAPORE.countryCode) {
      claimBenefitType = [{
        id: '1',
        name: $localize`:@@Medicalexpense:Medical Expense`
      },
      {
        id: '2',
        name: $localize`:@@AccidentalDeath:Accidental Death`
      },
      {
        id: '3',
        name: $localize`:@@permanentDisability:Permanent Disability`
      }];
    } else if (country === FNOL_COUNTRY.VIETNAM.countryCode) {
      claimBenefitType = [{
        id: '1',
        name: $localize`:@@Medicalexpense:Medical Expense`
      },
      {
        id: '2',
        name: $localize`:@@AccidentalDeath:Accidental Death`
      }, {
        id: '3',
        name: $localize`:@@permanentDisability:Permanent Disability`
      }, {
        id: '4',
        name: $localize`:@@DamageLoss:Damage or Loss of Property`
      }];
    } else if (country === FNOL_COUNTRY.THAILAND.countryCode) {
      claimBenefitType = [{
        id: '1',
        name: $localize`:@@Medicalexpense:Medical Expense`
      },
      {
        id: '2',
        name: $localize`:@@AccidentalDeath:Accidental Death / Dismemberment  / Loss of sight / Permanent Total Disability`
      }, {
        id: '3',
        name: $localize`:@@DamageLoss:Damage or Loss of Property`
      }];
    } else if (country === FNOL_COUNTRY.JAPAN.countryCode) {
      if (type === 'Personal Effects') {
        claimBenefitType = [
          {
            id: '9',
            code: 'DMG,DMG013,IR4P07',
            name: $localize`:@@PE1:Photo of damaged goods`
          },
          {
            id: '10',
            code: 'DMG,DMG011,IR4P08',
            name: $localize`:@@PE2:Repair quote`
          },
          {
            id: '11',
            code: 'DMG,DMG101,IDX040',
            name: $localize`:@@PE3:Nonrepairable certificate`
          },
          {
            id: '12',
            code: 'DMG,DMG101,IR4069',
            name: $localize`:@@PE4:Receipt or warranty when purchased damaged goods`
          },
          {
            id: '13',
            code: 'POL,POL011,4S1181',
            name: $localize`:@@PE5:Certificate of enrollment and certificate of accident (claimant needs to submit specific document 在籍確認兼事故証明書.pdf)`
          },
          {
            id: '14',
            code: 'COR,COR004,IR4118',
            name: $localize`:@@PE6:Theft report`
          },
          {
            id: '15',
            code: 'POL,POL012,IDX010',
            name: $localize`:@@PE7:Driver's License (without description of Honseki(permanent address))`
          },
          {
            id: '16',
            code: 'POL,POL012,IDX022',
            name: $localize`:@@PE8:Passport Number`
          },
          {
            id: '17',
            code: 'POL,POL011,IDX043',
            name: $localize`:@@PE9:National Pension Handbook`
          }
        ]
      } else {
        claimBenefitType = [{
          id: '1',
          code: 'MED,MED004,IDX004',
          name: $localize`:@@Illness1:Receipt`
        },
        {
          id: '2',
          code: 'MED,MED002,IDX002',
          name: $localize`:@@Illness2:Attending Physician's Statement`
        },
        {
          id: '3',
          code: 'MED,MED004,IR4043',
          name: $localize`:@@Illness3:Certificate of Discharge`
        },
        {
          id: '4',
          code: 'MED,MED001,IDX047',
          name: $localize`:@@Illness4:Medical Certificate`
        },
        {
          id: '23',
          code: 'MED,MED001,4SM-651',
          name: $localize`:@@Illness9:入通院申告書（病気）`
        },
        {
          id: '24',
          code: 'MED,MED001,4SP-631',
          name: $localize`:@@Illness10:入通院申告書（ケガ）`
        },
        {
          id: '5',
          code: 'MED,MED004,IDX034',
          name: $localize`:@@Illness5:Receipt for transportation`
        },
        {
          id: '6',
          code: 'POL,POL012,IDX010',
          name: $localize`:@@Illness6:Driver's License（without description of Honseki(permanent address)）`
        },
        {
          id: '7',
          code: 'POL,POL012,IDX022',
          name: $localize`:@@Illness7:Passport Number`
        },
        {
          id: '8',
          code: 'POL,POL011,IDX043',
          name: $localize`:@@Illness8:National Pension Handbook`
        },
        {
          id: '18',
          code: 'DMG,DMG013,IR4P07',
          name: $localize`:@@PE1:Photo of damaged goods`
        },
        {
          id: '19',
          code: 'DMG,DMG011,IR4P08',
          name: $localize`:@@PE2:Repair quote`
        },
        {
          id: '20',
          code: 'DMG,DMG101,IDX040',
          name: $localize`:@@PE3:Nonrepairable certificate`
        },
        {
          id: '21',
          code: 'DMG,DMG101,IR4069',
          name: $localize`:@@PE4:Receipt or warranty when purchased damaged goods`
        },
        {
          id: '22',
          code: 'POL,POL011,4S1181',
          name: $localize`:@@PE5:Certificate of enrollment and certificate of accident (claimant needs to submit specific document 在籍確認兼事故証明書.pdf)`
        },

        ]
      }

    }
    return claimBenefitType;
  }
  public static getFILE_SIZE(): void {
    DISPLAY_MESSAGES.FILE_SIZE = $localize`:@@customError-fileSize:Sorry that file size is too large. Please upload a file under 25 MB`;
  }

  public static getCustomErrorMessages(): void {
    CUSTOMERRORMESSAGE.dob = $localize`:@@customError-dob:Claimant Date of birth cannot be greater than current date`;
    CUSTOMERRORMESSAGE.incidentDate = $localize`:@@customError-incidentDate:Date of Incident cannot be greater than current date`;
    CUSTOMERRORMESSAGE.doctorVisitDate = $localize`:@@customError-doctorVisit:"When did you visit the doctor?" must not be greater than current date`;
    CUSTOMERRORMESSAGE.hospitalizationDate = $localize`:@@customError-hospitalizationDate:Date of hospitalization cannot be greater than current date`;
    CUSTOMERRORMESSAGE.dod = $localize`:@@customError-dod:Date and Time of Death must not be greater than current date and time`;
    CUSTOMERRORMESSAGE.disabilityDate = $localize`:@@customError-disabilityDate:"Date disability was diagnosed" cannot be greater than current date`;
    CUSTOMERRORMESSAGE.timeGreater = $localize`:@@customError-timeGreater:Time cannot be greater than the current time`;
    CUSTOMERRORMESSAGE.incidentTime = $localize`:@@customError-incidentTime:'Time of incident' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`;
    CUSTOMERRORMESSAGE.commencementTime = `'Passenger pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`;
    CUSTOMERRORMESSAGE.commencementTimeVnTh = $localize`:@@customError-commencementTimeVnTh:'Passenger/Parcel/Food pick-up Commencement Time' is expected between 'Job Acceptance Time' to 'Trip Completion Time'`;
    // tslint:disable-next-line: align:@@customError
    CUSTOMERRORMESSAGE.completionTimeBefore = $localize`:@@customError-completionTimeBefore:'Trip Completion Time' is expected after 'Job Acceptance Time'`;
    CUSTOMERRORMESSAGE.jobAcceptanceTimeBefore = $localize`:@@customError-jobAcceptanceTimeBefore:Job Acceptance Time is expected before Trip Completion Time`;
    CUSTOMERRORMESSAGE.timeAdmissionGreater = $localize`:@@customError-timeAdmissionGreater:"Time of Admission" cannot be greater than current date and time`;
    CUSTOMERRORMESSAGE.dischargeDateSame = $localize`:@@customError-dischargeDateSame:"Date of Admission" and "Date of Discharge" cannot be same`;
    CUSTOMERRORMESSAGE.admissionDateGreater = $localize`:@@customError-admissionDateGreater:"Date of Admission" is expected before "Date of discharge"`;
    CUSTOMERRORMESSAGE.dischargeDateLesser = $localize`:@@customError-dischargeDateLesser:"Date of Discharge" is expected after "Date of admission"`;
    CUSTOMERRORMESSAGE.medicalLeaveStartDateGreater = $localize`:@@customError-medicalLeaveStartDateGreater:"Start of outpatient medical leave" is expected before "End of outpatient medical leave"`;
    CUSTOMERRORMESSAGE.medicalLeaveEndDateLesser = $localize`:@@customError-medicalLeaveEndDateLesser:"End of outpatient medical leave" is expected after "Start of outpatient medical leave"`;
    CUSTOMERRORMESSAGE.medicalLeaveDatesSame = $localize`:@@customError-medicalLeaveDatesSame:"Start of outpatient medical leave" and "End of outpatient medical leave" cannot be same`;
    CUSTOMERRORMESSAGE.timeDischarge = $localize`:@@customError-timeDischarge:Time of Discharge cannot be greater than current date and time`;
    CUSTOMERRORMESSAGE.toDate = $localize`:@@customError-toDate:Date cannot be less than Period of Insurance From`;
    CUSTOMERRORMESSAGE.doiGreater = $localize`:@@doiGreater:Date of Hospital Visit must be greater or equal to Date of Accident`;
    CUSTOMERRORMESSAGE.consultationGreater = $localize`:@@consultationGreater:Date of Hospital Visit must be greater or equal to Date of Accident`;
    CUSTOMERRORMESSAGE.illnessHospitalGreater = $localize`:@@illnessHospitalGreater:Date of Hospital Visit must be greater or equal to Admission Date`;
    CUSTOMERRORMESSAGE.purchaseDateGreater = $localize`:@@purchaseDateGreater:Purchase Date must occur on or before Date of Loss/Incident`;
    CUSTOMERRORMESSAGE.notificationDateGreater = $localize`:@@notificationDateGreater:Notification Date can not occur before Date of Loss/Incident`;
    CUSTOMERRORMESSAGE.accountSymbolInvalid = $localize`:@@accountSymbolInvalid:Account Symbol is expected to be exactly 5 characters including the first 1 and the last 0.`;
    CUSTOMERRORMESSAGE.illnessSurgeryGreater = $localize`:@@illnessSurgeryGreater:Day of Surgery must be greater or equal to Date of Initial Consultation`;
    CUSTOMERRORMESSAGE.illnessRadiationGreater = $localize`:@@illnessRadiationGreater:Radiation Start Date must be greater or equal to Date of Initial Consultation`;
    CUSTOMERRORMESSAGE.dateOfSurgeryGreater = $localize`:@@dateOfSurgeryGreater: Date Of Surgery cannot occur before Date of Incident/Loss`;
    CUSTOMERRORMESSAGE.dateOfSurgeryAdmissionGreater = $localize`:@@dateOfSurgeryAdmissionGreater: Date Of Surgery must occur after Admission or Treatment Date`,
      CUSTOMERRORMESSAGE.outPatientGreater = $localize`:@@outPatientGreater: Out Patient From cannot occur before Date of Incident/Loss`;
    CUSTOMERRORMESSAGE.fixingGreater = $localize`:@@fixingGreater: Fixing Date cannot occur before Date of Incident/Loss`;
    CUSTOMERRORMESSAGE.outPatientEndDateLesser = $localize`:@@toOutpatient:Outpatient Treatment To must occur after Outpatient Treatment From`;
    CUSTOMERRORMESSAGE.outPatientStartDateGreater = $localize`:@@fromOutpatient:Outpatient Treatment From is expected before Outpatient Treatment To`;
    CUSTOMERRORMESSAGE.outPatientSame = $localize`:@@sameOutpatient:"Outpatient Treatment From" and "Outpatient Treatment To" cannot be same`;
    CUSTOMERRORMESSAGE.policyInvalid = $localize`:@@policyInvalid:ご入力いただいた番号ではご契約が確認できませんでした。もう一度お試しいただくか、このお手続きをスキップする場合は、上記の「いいえ」を選択してください。`;
    CUSTOMERRORMESSAGE.radiationDateSame = $localize`:@@radiationSame:"Radiation Start Date" and "Radiation End Date" cannot be same`;
    CUSTOMERRORMESSAGE.admissionDateGreater = $localize`:@@admissionDate:Date of Admission is expected before Date of discharge`;
    CUSTOMERRORMESSAGE.dischargeDateLesser = $localize`:@@dischargeDate:Discharge Date must occur after Admission Date`;
    CUSTOMERRORMESSAGE.outPatientEndDateLesser = $localize`:@@toOutpatient:Outpatient Treatment To must occur after Outpatient Treatment From`;
    CUSTOMERRORMESSAGE.outPatientStartDateGreater = $localize`:@@fromOutpatient:Outpatient Treatment From is expected before Outpatient Treatment To`;
    CUSTOMERRORMESSAGE.outPatientSame = $localize`:@@sameOutpatient:"Outpatient Treatment From" and "Outpatient Treatment To" cannot be same`;
    CUSTOMERRORMESSAGE.fixtureDateSame = $localize`:@@fixtureSame:"Fixing Date" and "Date Fixture Removed" cannot be same`;
    CUSTOMERRORMESSAGE.fixingDateGreater = $localize`:@@dateFixing:Fixing Date is expected before Date Fixture Removed`;
    CUSTOMERRORMESSAGE.fixtureRemoveDateLesser = $localize`:@@removeFixing:Date Fixture Removed must occur after Fixing Date`;
    CUSTOMERRORMESSAGE.radiationStartDateGreater = $localize`:@@startRadiation:Radiation Start Date is expected before Radiation End Date`;
    CUSTOMERRORMESSAGE.radiationEndDateLesser = $localize`:@@endRadiation:Radiation End Date must occur after Radiation Start Date`;
    CUSTOMERRORMESSAGE.ageLesser = $localize`:@@age18:Insured can only be selected if current Insured age is equal or greater than 18 years old`;
    CUSTOMERRORMESSAGE.confirmMail = $localize`:@@confirmEmail:Email and Confirm mail should match.`;
    CUSTOMERRORMESSAGE.policyNumber = $localize`:@@minChar:A minimum character of 3 to a maximum 10 is expected. Please update your entry based on your policy record.`;
  }
}

export const ErrorMessagesShow=[
{type:'1',value:'認証失敗'},
{type:'2',value:'トークンが当社の記録と一致しません。もう一度お試しください。'},
{type:'3',value:'認証失敗'}
]

 
