
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';



@Injectable()
export class HttpService {

	constructor(private http: HttpClient) {
	}

	makeHttpGetCall(url, options?) {
		return this.http.get(url, options).pipe(map((res: any) => {
			return res;
		}),catchError((error: any) => {
			return observableThrowError(error || 'Server error')
		}),)
	}

	makeHttpPostCall(url, data, options) {
		return this.http.post(url, data, options).pipe(map((res: any) => {
			return res;
		}),catchError((error: any) => {
			return observableThrowError(error || 'Server error')
		}),)
	}

	getSimpleHeaders() {
		let httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			})
		};
		return httpOptions;
	}

	getFormDataHeaders() {
		let httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'text/plain'
			})
		};
		return httpOptions;
	}

	public put(key: string, value: string, storageType?: string) {
		if (storageType && storageType.toLowerCase() === "session") {
			sessionStorage.setItem(key, value);
		} else if (storageType && storageType.toLowerCase() === "local") {
			localStorage.setItem(key, value);
		} else {
			localStorage.setItem(key, value);
		}
	}

	public get(key: string) {
		return localStorage.getItem(key) ? localStorage.getItem(key) : sessionStorage.getItem(key); 
	}
}