import { Component, OnInit } from '@angular/core';
import { FnolService } from '../../services/fnol.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html'
})
export class MaintenancePageComponent implements OnInit {
  isJp: boolean;
  time: any;

  constructor(private fnolService: FnolService,
    private route: ActivatedRoute,
    private router: Router) {

    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          history.back();
        }
      })


  }


  ngOnInit(): void {
    const res = this.route.snapshot.paramMap.get('country');
    if (res == "jp") {
      this.isJp = true
    } else {
      this.isJp = false
    }

    // this.time = JSON.parse(localStorage.getItem('response'))
    this.time = this.fnolService.getAppStatus()

    this.goTop();
  }
  /* methods */
  goTop() {  // reset the page when changing route
    window.scrollTo(0, 0);
  }
}
