import { Injectable } from "@angular/core";

@Injectable()
export class CacheService {
  // storage type supported
  public static LOCAL_STORAGE: string = "LOCAL_STORAGE";
  public static SESSION_STORAGE: string = "SESSION_STORAGE";

  public static COOKIE_STORAGE: string = "COOKIE_STORAGE";

  // keys used for storing in cache
  public IDTOKEN: string = "idtoken";
  public ACCESSTOKEN: string = "accesstoken";

  public REFRESHTOKEN: string = "refreshtoken";
  public USERID: string ;
  public USERFULLNAME: string = "userfullname";
  public ACCEPTED_LIC_AGREEMENT: string = "licagreement";
  claimNumberGenerated: string;
  claimNumberGeneratedFollowUp: string;
  alreadyGeneratedClaimNumber: any;
  constructor() {}

  public put(key: string, value: string, storageType?: string) {
    this.remove(key, storageType);

    if (storageType && storageType.toLowerCase() === "session") {
      sessionStorage.setItem(key, value);
    } else if (storageType && storageType.toLowerCase() === "local") {
      localStorage.setItem(key, value);
    } else if (storageType && storageType === CacheService.COOKIE_STORAGE) {
      this.setCookie(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  }

  public get(key: string, storageType?: string): string {
    let returnVal = "";
    if (storageType && storageType.toLowerCase() === "session") {
      returnVal = sessionStorage.getItem(key)
        ? sessionStorage.getItem(key)
        : "";
    } else if (storageType && storageType.toLowerCase() === "local") {
      returnVal = localStorage.getItem(key)
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    } else if (storageType && storageType === CacheService.COOKIE_STORAGE) {
      returnVal = this.getCookie(key);
    } else {
      returnVal = localStorage.getItem(key)
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    }

    return returnVal;
  }

  public remove(key: string, storageType?: string) {
    if (storageType && storageType.toLowerCase() === "session") {
      sessionStorage.removeItem(key);
    } else if (storageType && storageType.toLowerCase() === "local") {
      localStorage.removeItem(key);
    } else if (storageType && storageType === CacheService.COOKIE_STORAGE) {
      this.deleteCookie(key);
    } else {
      localStorage.removeItem(key);
    }
  }

  public clearAll() {
    sessionStorage.clear();
    localStorage.clear();
    this.deleteAllCookies();
  }

  getGeneratedClaimNumber() {
    return this.alreadyGeneratedClaimNumber;
  }

  private setCookie(name: string, value: string) {
    document.cookie = name + "=" + value;
  }

  private getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(";");
    let caLen: number = ca.length;

    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, "");
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return "";
  }

  private deleteCookie(cookieName: string) {
    var cookies = document.cookie.split(";");
    console.log("before deleting", document.cookie);
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name === cookieName) {
        console.log("in deleting", cookieName);
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        break;
      }
    }
    console.log("after deleting", document.cookie);
  }

  setClaimNumber(claimNumber) {
    this.claimNumberGenerated = claimNumber;
    console.log(claimNumber, "setClaimNumber")
  }

  
  getClaimNumber() {
    return this.claimNumberGenerated;
  }

  setFollowUpClaimNumber(claimNumber) {
    this.claimNumberGeneratedFollowUp = claimNumber;
    console.log(claimNumber, "setFollowUpClaimNumber")
  }

  
  getFollowUpClaimNumber() {
    return this.claimNumberGeneratedFollowUp;
  }

  setUserid(res){
    console.log("userid: ", res);
    this.USERID = res;
  }
  getUserId() {
    return this.USERID;
  }

  private deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }


  accessToken(){
  return JSON.parse(localStorage.getItem('okta-token-storage'))
  }
}
