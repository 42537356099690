import { Injectable } from "@angular/core";
import * as moment from "moment";
import { FNOL_STEPS_SINGAPORE } from "../config/fnol-sg-constants";
import { URL_PARAMETERS } from "../config/urlParameters.constant";
import { Configuration } from "../../../../app/shared/configuration";
import { DataStoreService } from "../services/data-store.service";
import { FnolModelService } from "../services/fnol-model.service";
import {
  commonSetFnolSgModel,
  setFnolSgModel,
  icorSgModel,
  docUploadModel,
} from "./fnol-sg-model";
import { CommonUtils } from "../shared/utils/common-utils";
import { NEW_SINGAPORE_POLICY_INFO } from "../config/policy-info-constants";
import { FNOL_COUNTRY } from "../config/fnol-constants";
import { FormatAddressService } from "../services/format-address.service";

@Injectable({
  providedIn: "root",
})
export class FnolSgModelService {
  policyDetails;
  phoneCodeList;
  insuredDetails = null;
  constructor(
    private config: Configuration,
    private _dataStoreService: DataStoreService,
    private fnolModelService: FnolModelService,
    private formatAddress: FormatAddressService
  ) {}

  setPolicyHolderDetails(details: any) {
    this.insuredDetails = {
      policyNo: details["policyNumber"],
      firstName: details["firstName"] || details["policyFirstName"],
      lastName: details["lastName"] || details["policyLastName"],
      dob: details["dateOfBirth"] || details["policyDateOfBirth"],
      nric: details["nricFin"] || details["policyNricFin"],
    };
  }

  getDetail(
    requestName: string,
    type: string = "",
    contactNoObj: any = {}
  ): any {
    const claimTypeData = this._dataStoreService.get(
      FNOL_STEPS_SINGAPORE.CLAIM_TYPE_DETAILS.name
    );
    const claimantData = this._dataStoreService.get(
      FNOL_STEPS_SINGAPORE.CLAIMANT_INFO.name
    );
    const paymentData = this._dataStoreService.get(
      FNOL_STEPS_SINGAPORE.PAYMENT_DETAILS.name
    );
    const uploadData = this._dataStoreService.get(
      FNOL_STEPS_SINGAPORE.UPLOAD_DOCS.name
    );
    if (requestName === "sysDateGmt") {
      const systemDate = new Date().toISOString();
      return systemDate
        .replace(/\-/g, "")
        .replace(/\:/g, "")
        .replace("Z", " GMT");
    }
    if (requestName === "dateOfAccident") {
      if (
        claimTypeData.uiData.claimBenefits === "Personal Accident" &&
        claimTypeData.uiData.dateAccident
      ) {
        const accidentDate = claimTypeData.uiData.dateAccident;
        if (type === "icor") {
          const d = new Date(accidentDate);
          return moment(d).format("DD/MM/YYYY");
        } else if (type === "doc") {
          const d = new Date(accidentDate);
          return moment(d).format("YYYY-MM-DD");
        }
        return CommonUtils.convertDateGMT(
          claimTypeData.uiData.dateAccident,
          "YYYYMMDD"
        );
      } else if (
        claimTypeData.uiData.claimBenefits === "Illness / Critical Illness" &&
        claimTypeData.uiData.dateSymptom
      ) {
        const dateSymptom = claimTypeData.uiData.dateSymptom;
        if (type === "icor") {
          const d = new Date(dateSymptom);
          return moment(d).format("DD/MM/YYYY");
        } else if (type === "doc") {
          const d = new Date(dateSymptom);
          return moment(d).format("YYYY-MM-DD");
        }
        const x = CommonUtils.convertDateGMT(
          claimTypeData.uiData.dateSymptom,
          "YYYYMMDD"
        );
        return x;
      } else {
        return "";
      }
    } else if (requestName === "lossTime") {
      if (claimTypeData.uiData.claimBenefits === "Personal Accident") {
        const timeIncident = moment(claimTypeData.uiData.timeIncident).format(
          "hh:mm A"
        );
        const incidentTimeArray = timeIncident.split(" ");
        if (incidentTimeArray[1] === "AM") {
          const timeVal = incidentTimeArray[0];
          if (type === "icor") {
            return `${timeVal} AM`;
          }
          return `${timeVal}:0`;
        } else {
          const timeVal = incidentTimeArray[0];
          if (type === "icor") {
            return `${timeVal} PM`;
          }
          return `${timeVal}:1`;
        }
      } else {
        return "";
      }
    } else if (requestName === "claimType") {
      return claimTypeData.uiData.claimBenefits;
    } else if (requestName === "incidentDesc") {
      if (claimTypeData.uiData.claimBenefits === "Personal Accident") {
        return claimTypeData.uiData.accidentOccurred;
      } else if (
        claimTypeData.uiData.claimBenefits === "Illness / Critical Illness"
      ) {
        return claimTypeData.uiData.describeIllness;
      }
      return "";
    } else if (requestName === "DamageDesc") {
      if (
        claimTypeData.uiData.claimBenefits === "Personal Accident" &&
        claimTypeData.uiData.dateAccident
      ) {
        return claimTypeData.uiData.injuriesSustained;
      }
      return "";
    } else if (requestName === "nric") {
      if (
        claimantData.uiData.policyExist === "true" &&
        claimantData.uiData.claimTypeAs.name === "Self"
      ) {
        return claimantData.uiData.insuredNric;
      } else {
        return claimantData.uiData.nric;
      }
      return "";
    } else if (requestName === "isUSCitizen") {
      if (claimTypeData.uiData.claimBenefits === "Personal Accident") {
        return claimTypeData.uiData.citizen || "";
      }
      return "";
    } else if (requestName === "dateOfBirth") {
      if (
        claimantData.uiData.policyExist === "true" &&
        claimantData.uiData.claimTypeAs.name === "Self"
      ) {
        return moment(new Date(this.insuredDetails.dob)).format("DD/MM/YYYY");
      } else {
        return moment(claimantData.uiData.dob).format("DD/MM/YYYY");
      }
    } else if (requestName === "getAge") {
      if (
        claimantData.uiData.policyExist === "true" &&
        claimantData.uiData.claimTypeAs.name === "Self"
      ) {
        const bracketedAgeStr = claimantData.uiData.insuredDOB.split(" ")[1];
        return bracketedAgeStr.slice(1);
      } else {
        return CommonUtils.calculateAge(
          new Date(claimantData.uiData.dob).getTime()
        );
      }
    } else if (requestName === "claimAs") {
      return claimantData.uiData.claimTypeAs.name;
    } else if (requestName === "claimAsCode") {
      if (claimantData.uiData.claimTypeAs.name === "Self") {
        return "001";
      } else if (claimantData.uiData.claimTypeAs.name === "Spouse") {
        return "002";
      } else if (claimantData.uiData.claimTypeAs.name === "Child") {
        return "003";
      } else if (claimantData.uiData.claimTypeAs.name === "Parent") {
        return "004";
      } else if (claimantData.uiData.claimTypeAs.name === "Employee") {
        return "010";
      } else if (claimantData.uiData.claimTypeAs.name === "AIG Agent") {
        return "013";
      } else if (claimantData.uiData.claimTypeAs.name === "Others") {
        return "023";
      }
      return "";
    } else if (requestName === "claimAsOthers") {
      return claimantData.uiData.others;
    } else if (requestName === "policyNo") {
        if (this.policyDetails?.response?.policyDetails?.externalReference) {
          return this.policyDetails.response.policyDetails.externalReference;
        } else if (claimantData.uiData['enteredPolicyNumber']) {
          return 'NOMATCHPOLICY';
        } else {
          return 'NOPOLICY';
        }
    } else if (requestName === "policyStatus") {
      return this.policyDetails?.response?.policyDetails?.policyStatus || "";
    } else if (requestName === "policyEffectiveDate") {
      if (this.policyDetails?.response?.policyDetails?.startDate) {
        return this.getGMTformatDate(
          this.policyDetails.response.policyDetails.startDate
        );
      } else {
        return "";
      }
    } else if (requestName === "policyExpiryDate") {
      if (this.policyDetails?.response?.policyDetails?.plannedEndDate) {
        return this.getGMTformatDate(
          this.policyDetails.response.policyDetails.plannedEndDate
        );
      } else {
        return "";
      }
    } else if (requestName === "policyNumber") {
      return claimantData.uiData.enteredPolicyNumber;
    } else if (requestName === "policyHolderFirstName") {
      if (claimantData.uiData.claimTypeAs.name === "Self") {
        return claimantData.uiData.firstName;
      } else {
        return claimantData.uiData.policyHolderFirstName;
      }
    } else if (requestName === "policyHolderLastName") {
      if (claimantData.uiData.claimTypeAs.name === "Self") {
        return claimantData.uiData.lastName;
      } else {
        return claimantData.uiData.policyHolderLastName;
      }
    } else if (requestName === "policyHolderDob") {
      if (
        claimantData.uiData.claimTypeAs.name === "Self" &&
        claimantData.uiData.insuredDOB
      ) {
        const insuredDOB = claimantData.uiData.insuredDOB.split(" ")[0];
        if (type === "icor") {
          return insuredDOB;
        }
        return CommonUtils.convertDateGMT(insuredDOB, "YYYYMMDD");
      } else {
        const insuredDOB = claimantData.uiData.policyHolderDob;
        if (type === "icor") {
          const d = new Date(insuredDOB);
          return moment(d).format("DD/MM/YYYY");
        }
        return CommonUtils.convertDateGMT(insuredDOB, "YYYYMMDD");
      }
    } else if (requestName === "policyHolderNric") {
      if (
        claimantData.uiData.claimTypeAs.name === "Self" &&
        claimantData.uiData.insuredNric
      ) {
        return claimantData.uiData.insuredNric;
      } else {
        return claimantData.uiData.policyHolderNric;
      }
    } else if (requestName === "multiNationalPolicy") {
      return (
        this.policyDetails?.response?.policyDetails?.multiNationalPolicy || ""
      );
    } else if (requestName === "policyHolderNo") {
      return this.policyDetails?.response?.policyDetails?.policyHolderNo || "";
    } else if (requestName === "renewalCertificateNumber") {
      return (
        this.policyDetails?.response?.policyDetails?.renewalCertificateNumber ||
        ""
      );
    } else if (requestName === "certificateNumber") {
      return (
        this.policyDetails?.response?.policyDetails?.certificateNumber || ""
      );
    } else if (requestName === "policyOfficeCode") {
      return (
        this.policyDetails?.response?.policyDetails?.policyOfficeCode || ""
      );
    } else if (requestName === "effectiveDateSequenceNumber") {
      return (
        this.policyDetails?.response?.policyDetails
          ?.effectiveDateSequenceNumber || ""
      );
    } else if (requestName === "policyDatabase") {
      return this.policyDetails?.response?.policyDetails?.database || "";
    } else if (requestName === "claimantFirstName") {
      return claimantData.uiData.firstName || "";
    } else if (requestName === "insuredName") {
      return claimantData.uiData.policyExist === "true"
        ? `${claimantData.uiData.firstName} ${claimantData.uiData.lastName}`
        : "";
    } else if (requestName === "claimantLastName") {
      return claimantData.uiData.lastName || "";
    } else if (requestName === "address1") {
      // do stuff
      return claimantData.uiData.myAddressLookup;
    } else if (requestName === "stateCode") {
      return "NA";
    } else if (requestName === "address2") {
      return claimantData.uiData.levelUnitNumber || "";
    } else if (requestName === "postalCode") {
      return claimantData.uiData?.postalCode || "";
    } else if (requestName === "countryCode") {
      if (
        claimantData.uiData.policyExist === "true" &&
        claimantData.uiData.claimTypeAs.name === "Self"
      ) {
        return claimantData.uiData?.insuredCountry?.code || "SG";
      }
      return claimantData.uiData?.country?.code || "SG";
    } else if (requestName === "countryName") {
      if (
        claimantData.uiData.policyExist === "true" &&
        claimantData.uiData.claimTypeAs.name === "Self"
      ) {
        return claimantData.uiData?.insuredCountry?.name || "Singapore";
      }
      return claimantData.uiData?.country?.name || "Singapore";
    } else if (requestName === "claimantMail") {
      return claimantData.uiData?.email || claimantData.uiData?.insuredEmail;
    } else if (requestName === "claimantPhoneNo") {
      const phoneNo =
        claimantData.uiData?.nonInsuredMobileNumber ||
        claimantData.uiData?.insuredMobileNumber;
      return phoneNo;
    } else if (requestName === "claimantPhoneInfo") {
      const phoneNo =
        claimantData.uiData?.nonInsuredMobileNumber ||
        claimantData.uiData?.insuredMobileNumber;
      const ext = phoneNo.split("-")[0];
      return [
        {
          "m1:PhoneTypeCd": "Mobile Phone",
          "m1:PhoneNumber": phoneNo.split("-")[1],
          "m1:PhoneExtn": "",
          "m1:CountryPhoneCd":
            this.phoneCodeList?.countryTelephoneCode?.find(
              (obj) => obj.telephoneCode === ext
            ).countryCode || "",
        },
      ];
    } else if (requestName === "claimantDob") {
      if (
        claimantData.uiData.policyExist === "true" &&
        claimantData.uiData.claimTypeAs.name === "Self"
      ) {
        const insuredDOB = claimantData.uiData.insuredDOB.split(" ")[0];
        const insuredDOBparts = insuredDOB.split("/");
        const formattedDate = this.getFormattedDate(insuredDOB);
        if (type === "icor") {
          const dateIcor =
            insuredDOBparts[2] +
            "/" +
            insuredDOBparts[1] +
            "/" +
            insuredDOBparts[0];
          return dateIcor;
        }
        const dateNonIcor = formattedDate + "T05:00:00.000Z";
        return CommonUtils.convertDateGMT(dateNonIcor, "YYYYMMDD");
      } else {
        if (type === "icor") {
          const d = new Date(claimantData.uiData.dob);
          return moment(d).format("DD/MM/YYYY");
        }
        return CommonUtils.convertDateGMT(claimantData.uiData.dob, "YYYYMMDD");
      }
    } else if (requestName === "brokerName") {
      return claimantData.uiData.nameBroker || "";
    } else if (requestName === "brokerEmail") {
      return claimantData.uiData.emailBroker || "";
    } else if (requestName === "brokerNumber") {
      const y = claimantData.uiData?.brokerMobileNumber.split("-");
      const phoneNo = y[1] ? claimantData.uiData?.brokerMobileNumber : "";
      return phoneNo;
    } else if (requestName === "brokerNumberInfo") {
      const phoneNo = claimantData.uiData?.brokerMobileNumber;
      const ext = phoneNo.split("-")[0];
      return [
        {
          "m1:PhoneTypeCd": "Mobile Phone",
          "m1:PhoneNumber": phoneNo.split("-")[1],
          "m1:PhoneExtn": "",
          "m1:CountryPhoneCd":
            this.phoneCodeList?.countryTelephoneCode?.find(
              (obj) => obj.telephoneCode === ext
            ).countryCode || "",
        },
      ];
    } else if (requestName === "phoneInfo") {
      return [
        {
          "m1:PhoneTypeCd": "Mobile Phone",
          "m1:PhoneNumber": contactNoObj?.phoneNumber,
          "m1:PhoneExtn": "",
          "m1:CountryPhoneCd":
            this.phoneCodeList?.countryTelephoneCode?.find(
              (obj) => obj.telephoneCode === contactNoObj?.phoneCode.label?contactNoObj?.phoneCode.label:contactNoObj?.phoneCode
            ).countryCode || "",
        },
      ];
    } else if (requestName === "accidentOccurred") {
      return claimTypeData.uiData.accidentOccurred;
    } else if (requestName === "injuriesSustained") {
      return claimTypeData.uiData.injuriesSustained;
    } else if (requestName === "accidentLocation") {
      const formatAddress = claimTypeData.uiData?.accidentAddObj?.formatted_address;
      if (formatAddress && typeof formatAddress === 'string') {
        return formatAddress;
      }
      if (formatAddress && typeof formatAddress === 'object' && formatAddress.hasOwnProperty('name')) {
        return formatAddress.name;
      }
      const accidentLookup = claimTypeData.uiData?.accidentLocationLookup;
      if (accidentLookup && typeof accidentLookup === 'string') {
        return accidentLookup;
      }
      if (accidentLookup && typeof accidentLookup === 'object' && accidentLookup.hasOwnProperty('name')) {
        return accidentLookup.name;
      }
      return '';
    } else if (requestName === "firstVisit") {
      if (type === "icor") {
        return moment(claimTypeData.uiData.firstVisit).format("DD/MM/YYYY");
      }
      return CommonUtils.convertDateGMT(
        claimTypeData.uiData.firstVisit,
        "YYYYMMDD"
      );
    } else if (requestName === "diagnosis") {
      return claimTypeData.uiData.diagnosis?.name;
    } else if (requestName === "otherDiagnosis") {
      return claimTypeData.uiData.others;
    } else if (requestName === "isTrafficAccident") {
      return claimTypeData.uiData.trafficAccident;
    } else if (requestName === "claimBenefitsType") {
      const claimBenefitsType = [];
      if (claimTypeData.uiData.medicalExpenseClaim === "medicalExpenseClaim") {
        claimBenefitsType.push(
          "Accident Medical Reimbursement/ Daily Hospital Income/ Fracture or Dislocation"
        );
      }
      if (claimTypeData.uiData.leaveBenefit === "leaveBenefit") {
        claimBenefitsType.push("Medical Leave Benefit");
      }
      if (claimTypeData.uiData.permanentDisability === "permanentDisability") {
        claimBenefitsType.push("Permanent Disablement");
      }
      if (claimTypeData.uiData.accidentalDeath === "accidentalDeath") {
        claimBenefitsType.push("Accidental Death");
      }
      return claimBenefitsType.join(", ");
    } else if (requestName === "medicalExpenseClaim") {
      return claimTypeData.uiData.medicalExpenseClaim;
    } else if (requestName === "westernMedicine") {
      return (
        parseFloat(claimTypeData.uiData.medicalExpense["westernMedicine"]) || ""
      );
    } else if (requestName === "dentalTreatment") {
      return (
        parseFloat(claimTypeData.uiData.medicalExpense["dentalTreatment"]) || ""
      );
    } else if (requestName === "chineseMedicine") {
      return (
        parseFloat(claimTypeData.uiData.medicalExpense["chriopractor"]) || ""
      );
    } else if (requestName === "mobilityaid") {
      return parseFloat(claimTypeData.uiData.medicalExpense["mobileAdd"]) || "";
    } else if (requestName === "totalExpense") {
      return parseFloat(claimTypeData.uiData.medicalExpense["sum"]) || "";
    } else if (requestName === "admittedHospital") {
      return claimTypeData.uiData.admittedHospital;
    }
    else if (requestName === "treatmentList" && type !== "icor") {
      const treatmentDetails = [];
      claimTypeData.uiData.addTreatmentMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          let fieldValue = elem[el];
          if (el === "Date of Admission" || el === "Date of Discharge") {
            fieldValue = fieldValue.split('-').join('/');
          } else if (el === "Time of Admission" || el === "Time of Discharge") {
            fieldValue = moment(elem[el]).format("hh:mm A");
          }
          el = el === 'Hospital Name' ? 'Name of Hospital' : el;
            treatmentDetails.push({
                "m1:QuestionText": `${el}${i + 1}`,
                "m1:YesNoCd": fieldValue,
            });
        });
      });
      treatmentDetails.map((x,y) => {
        if (x["m1:QuestionText"].includes("Time of Admission") || x["m1:QuestionText"].includes("Time of Discharge")) {
          let dateVal = treatmentDetails[y-1];
            if (dateVal["m1:QuestionText"].includes("Date of Admission") || dateVal["m1:QuestionText"].includes("Date of Discharge")) {
              dateVal["m1:YesNoCd"] =this.getGMTDateConverter(dateVal["m1:YesNoCd"].concat(" " + x["m1:YesNoCd"]));
              treatmentDetails.splice(y,1);
            }
        }
      });
      return treatmentDetails;
    } 
    else if (requestName === "treatmentList" && type === "icor") {
      const treatmentDetails = [];
      claimTypeData.uiData.addTreatmentMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          let fieldValue = elem[el];
          if (el === "Time of Admission" || el === "Time of Discharge") {
            const timeVal = moment(elem[el]).format("hh:mm A");
            const timeValArray = timeVal.split(" ");
            fieldValue = timeValArray[1] === "AM" ? `${timeValArray[0]} AM` : `${timeValArray[0]} PM`;
          } else if (el === "Date of Admission" || el === "Date of Discharge") {
            fieldValue = this.dateFormatter(fieldValue);
          }
          treatmentDetails.push({
              name:
                claimTypeData.uiData.addTreatmentMainList.length === 1
                  ? `${el}:`
                  : `${el} ${i + 1}:`,
              value: fieldValue,
          });
        });
      });
      return treatmentDetails;
    }
    
    else if (requestName === "admittedIcu") {
      return claimTypeData.uiData.admittedIcu;
    } else if (requestName === "admittedToIcuDays") {
      return claimTypeData.uiData.admittedToIcu;
    } else if (requestName === "sustainFracture") {
      return claimTypeData.uiData.sustainFracture;
    } else if (requestName === "addFractureMainList") {
      const fractureDetails = [];
      claimTypeData.uiData.addFractureMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          if (type === "icor") {
            fractureDetails.push({
              name:
                claimTypeData.uiData.addFractureMainList.length === 1
                  ? `${el}:`
                  : `${el} ${i + 1}:`,
              value: elem[el].name,
            });
          } else {
            let fractureTypeRDF;
            let fractureLocationRDF;
            if (el === "Type of Fracture") {
              if (elem[el].name === "Complete") {
                fractureTypeRDF = "002";
              } else if (elem[el].name === "Compound") {
                fractureTypeRDF = "001";
              } else if (elem[el].name === "Compression") {
                fractureTypeRDF = "004";
              } else {
                fractureTypeRDF = "003";
              }
            } else if (el === "Site of Fracture") {
              if (
                elem[el].name === "Hip or Pelvis (excluding thigh or coccyx)"
              ) {
                fractureLocationRDF = "1";
              } else if (elem[el].name === "Thigh or Heel") {
                fractureLocationRDF = "2";
              } else if (
                elem[el].name ===
                "Lower leg, skull, clavicle, ankle, elbows, upper or lower arm (including wrist but excluding Colles-type fractures)"
              ) {
                fractureLocationRDF = "3";
              } else if (
                elem[el].name === "Colles type fracture of the lower arm"
              ) {
                fractureLocationRDF = "4";
              } else if (
                elem[el].name ===
                "Shoulder blade, knee cap, sternum, hand (excluding fingers and wrist), foot (excluding toes or heel)"
              ) {
                fractureLocationRDF = "5";
              } else if (
                elem[el].name ===
                "Spinal Column (vertebrae but excluding coccyx)"
              ) {
                fractureLocationRDF = "6";
              } else if (elem[el].name === "Lower Jaw") {
                fractureLocationRDF = "7";
              } else if (
                elem[el].name ===
                "Ribs or ribs, cheekbone, coccyx, upper jaw, nose, toe or toes, finger or fingers"
              ) {
                fractureLocationRDF = "8";
              }
            }
            fractureDetails.push({
              "m1:QuestionText": `${el}${i + 1}`,
              "m1:YesNoCd":
                el === "Type of Fracture"
                  ? fractureTypeRDF
                  : el === "Site of Fracture"
                  ? fractureLocationRDF
                  : elem[el].name,
            });
          }
        });
      });
      return fractureDetails;
    } else if (requestName === "sustainDislocation") {
      return claimTypeData.uiData.sustainDislocation;
    } else if (requestName === "addDislocationMainList") {
      const dislocationDetails = [];
      claimTypeData.uiData.addDislocationMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          if (type === "icor") {
            dislocationDetails.push({
              name:
                claimTypeData.uiData.addDislocationMainList.length === 1
                  ? `${el}:`
                  : `${el} ${i + 1}:`,
              value: elem[el].name,
            });
          } else {
            let siteOfDislocationRDF;
            if (el === "Site of Dislocation") {
              if (elem[el].name === "Spine or back (excluding slipped disc)") {
                siteOfDislocationRDF = "1";
              } else if (elem[el].name === "Hip") {
                siteOfDislocationRDF = "2";
              } else if (elem[el].name === "Knee") {
                siteOfDislocationRDF = "3";
              } else if (elem[el].name === "Wrist or elbow") {
                siteOfDislocationRDF = "4";
              } else if (
                elem[el].name === "Ankle, shoulder blade or collarbone"
              ) {
                siteOfDislocationRDF = "5";
              } else if (elem[el].name === "Fingers, toes, or jaw") {
                siteOfDislocationRDF = "6";
              }
            }
            dislocationDetails.push({
              "m1:QuestionText": `${el}${i + 1}`,
              "m1:YesNoCd":
                el === "Site of Dislocation"
                  ? siteOfDislocationRDF
                  : elem[el].name,
            });
          }
        });
      });
      return dislocationDetails;
    } else if (requestName === "leaveBenefit") {
      return claimTypeData.uiData.leaveBenefit;
    } else if (requestName === "addLeaveMainList") {
      const medicalLeaveDetails = [];
      claimTypeData.uiData.addLeaveMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          let fieldValue = elem[el];
          if (
            el === "Medical Leave Start Date" ||
            el === "Medical Leave End Date"
          ) {
            if (type !== "icor") {
              fieldValue = CommonUtils.convertDateGMT(fieldValue, "YYYYMMDD");
            } else if (type === "icor") {
              fieldValue = this.dateFormatter(fieldValue);
            }
          }
          if (type === "icor") {
            medicalLeaveDetails.push({
              name:
                claimTypeData.uiData.addLeaveMainList.length === 1
                  ? `${el}:`
                  : `${el} ${i + 1}:`,
              value: fieldValue,
            });
          } else {
            el = el === 'Medical Leave Start Date' ? 'Start of medical leave' : 'End of medical leave';
            medicalLeaveDetails.push({
              "m1:QuestionText": `${el}${i + 1}`,
              "m1:YesNoCd": fieldValue,
            });
          }
        });
      });
      return medicalLeaveDetails;
    } else if (requestName === "dateOfExpected") {
      if (type === "icor") {
        const d = new Date(claimTypeData.uiData.dateOfExpected);
        return moment(d).format("DD/MM/YYYY");
      }
      return CommonUtils.convertDateGMT(
        claimTypeData.uiData.dateOfExpected,
        "YYYYMMDD"
      );
    } else if (requestName === "employed") {
      return claimTypeData.uiData.employed;
    } else if (requestName === "permanentDisability") {
      return claimTypeData.uiData.permanentDisability;
    } else if (requestName === "accidentalDeath") {
      return claimTypeData.uiData.accidentalDeath;
    } else if (requestName === "dateOfDisability") {
      if (type === "icor") {
        const d = new Date(claimTypeData.uiData.dateOfDisability);
        return moment(d).format("DD/MM/YYYY");
      }
      return CommonUtils.convertDateGMT(
        claimTypeData.uiData.dateOfDisability,
        "YYYYMMDD"
      );
    } else if (requestName === "typeDisability") {
      return claimTypeData.uiData.typeDisability.name;
    } else if (requestName === "dateOfDeath") {
      if (type === "icor") {
        const d = new Date(claimTypeData.uiData.dateOfDeath);
        return moment(d).format("DD/MM/YYYY");
      }
      return CommonUtils.convertDateGMT(
        claimTypeData.uiData.dateOfDeath,
        "YYYYMMDD"
      );
    } else if (requestName === "localTimePersonal") {
      const deathTime = moment(claimTypeData.uiData.localTimePersonal).format(
        "hh:mm A"
      );
      const deathTimeArray = deathTime.split(" ");
      if (deathTimeArray[1] === "AM") {
        const timeVal = deathTimeArray[0];
        if (type === "icor") {
          return `${timeVal} AM`;
        }
        return `${timeVal}:0`;
      } else {
        const timeVal = deathTimeArray[0];
        if (type === "icor") {
          return `${timeVal} PM`;
        }
        return `${timeVal}:1`;
      }
    } else if (requestName === "causeDeath") {
      return claimTypeData.uiData.causeDeath.name;
    } else if (requestName === "causeOthers") {
      return claimTypeData.uiData.causeOthers;
    } else if (requestName === "autopsy") {
      return claimTypeData.uiData.autopsy;
    } else if (requestName === "reason") {
      return claimTypeData.uiData.reason;
    } else if (requestName === "doctorInfo") {
      return claimTypeData.uiData.doctorInfo;
    } else if (requestName === "addDoctorMainList") {
      const doctorMainListDetails = [];
      claimTypeData.uiData.addDoctorMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          const fieldValue =
            el === "Contact Number"
              ? elem[el].phoneNumber !== ""
                ? `${(elem[el].phoneCode?.label?elem[el].phoneCode.label:elem[el].phoneCode)}-${elem[el].phoneNumber}`
                : ""
              : elem[el];

          if (type === "icor") {
            if ((el === "Contact Number" || el === "Email") && fieldValue) {
              doctorMainListDetails.push({
                name:
                  claimTypeData.uiData.addDoctorMainList.length === 1
                    ? `${el}:`
                    : `${el} ${i + 1}:`,
                value: fieldValue,
              });
            } else if (el !== "Contact Number" && el !== "Email") {
              doctorMainListDetails.push({
                name:
                  claimTypeData.uiData.addDoctorMainList.length === 1
                    ? `${el}:`
                    : `${el} ${i + 1}:`,
                value: fieldValue,
              });
            }
          } else {
            doctorMainListDetails.push({
              "m1:QuestionText": `${el}${i + 1}`,
              "m1:YesNoCd": fieldValue,
            });
          }
        });
      });
      return doctorMainListDetails;
    } else if (requestName === "witnessInfo") {
      return claimTypeData.uiData.witnessInfo;
    } else if (requestName === "addWitnessMainList") {
      const witnessMainListDetails = [];
      claimTypeData.uiData.addWitnessMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          const fieldValue =
            el === "Contact Number"
              ? elem[el].phoneNumber !== ""
                ? `${(elem[el].phoneCode?.label?elem[el].phoneCode.label:elem[el].phoneCode)}-${elem[el].phoneNumber}`
                : ""
              : elem[el];

          if (type === "icor") {
            if ((el === "Contact Number" || el === "Email") && fieldValue) {
              witnessMainListDetails.push({
                name:
                  claimTypeData.uiData.addWitnessMainList.length === 1
                    ? `${el}:`
                    : `${el} ${i + 1}:`,
                value: fieldValue,
              });
            } else if (el !== "Contact Number" && el !== "Email") {
              witnessMainListDetails.push({
                name:
                  claimTypeData.uiData.addWitnessMainList.length === 1
                    ? `${el}:`
                    : `${el} ${i + 1}:`,
                value: fieldValue,
              });
            }
          } else {
            witnessMainListDetails.push({
              "m1:QuestionText": `${el}${i + 1}`,
              "m1:YesNoCd": fieldValue,
            });
          }
        });
      });
      return witnessMainListDetails;
    } else if (requestName === "describeIllness") {
      return claimTypeData.uiData.describeIllness;
    } else if (requestName === "dateSymptom") {
      if (type === "icor") {
        const d = new Date(claimTypeData.uiData.dateSymptom);
        return moment(d).format("DD/MM/YYYY");
      }
      return CommonUtils.convertDateGMT(
        claimTypeData.uiData.dateSymptom,
        "YYYYMMDD"
      );
    } else if (requestName === "dateVisit") {
      if (type === "icor") {
        return moment(claimTypeData.uiData.dateVisit).format("DD/MM/YYYY");
      }
      return CommonUtils.convertDateGMT(
        claimTypeData.uiData.dateVisit,
        "YYYYMMDD"
      );
    } else if (requestName === "diagnosisIllness") {
      return claimTypeData.uiData.diagnosisIllness.name;
    } else if (requestName === "othersIllness") {
      return claimTypeData.uiData.othersIllness;
    } else if (requestName === "claimingIllness") {
      return claimTypeData.uiData.claimingIllness;
    } else if (requestName === "chooseClaimTypeIllness") {
      const illnessClaimBenefitsType = [];
      if (claimTypeData.uiData.dailyIncome === "dailyIncome") {
        illnessClaimBenefitsType.push(
          "Daily Hospital Income/ Critical Illness"
        );
      }
      if (claimTypeData.uiData.lossActivities === "lossActivities") {
        illnessClaimBenefitsType.push("Loss of Activities of Daily Living");
      }
      return illnessClaimBenefitsType.join(", ");
    } else if (requestName === "dailyIncome") {
      return claimTypeData.uiData.dailyIncome;
    } else if (requestName === "lossActivities") {
      return claimTypeData.uiData.lossActivities;
    } else if (requestName === "dateOfDiagnosed") {
      if (type === "icor") {
        const d = new Date(claimTypeData.uiData.dateOfDiagnosed);
        return moment(d).format("DD/MM/YYYY");
      }
      return CommonUtils.convertDateGMT(
        claimTypeData.uiData.dateOfDiagnosed,
        "YYYYMMDD"
      );
    } else if (requestName === "disabilityIllness") {
      const disabilityIllnessType = [];
      if (claimTypeData.uiData.Washing === "Washing") {
        disabilityIllnessType.push("Washing");
      }
      if (claimTypeData.uiData.Dressing === "Dressing") {
        disabilityIllnessType.push("Dressing");
      }
      if (claimTypeData.uiData.Feeding === "Feeding") {
        disabilityIllnessType.push("Feeding");
      }
      if (claimTypeData.uiData.Toileting === "Toileting") {
        disabilityIllnessType.push("Toileting");
      }
      if (claimTypeData.uiData.Mobility === "Mobility") {
        disabilityIllnessType.push("Mobility");
      }
      if (claimTypeData.uiData.Transferring === "Transferring") {
        disabilityIllnessType.push("Transferring");
      }
      return disabilityIllnessType.join(", ");
    } else if (requestName === "admittedHospitalIllness") {
      return claimTypeData.uiData.admittedHospitalIllness;
    } 
    else if (requestName === "addIncomeMainList" && type !== "icor") {
      const addIncomeDetails = [];
      claimTypeData.uiData.addIncomeMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          let fieldValue = elem[el];
          if (el === "Date of Admission" || el === "Date of Discharge") {
            fieldValue = fieldValue.split('-').join('/');
          } else if (el === "Time of Admission" || el === "Time of Discharge") {
            fieldValue = moment(elem[el]).format("hh:mm A");
          }
          el = el === 'Hospital Name' ? 'Name of Hospital' : el;
          addIncomeDetails.push({
            "m1:QuestionText": `${el}${i + 1}`,
            "m1:YesNoCd": fieldValue,
          });
        });
      });
      addIncomeDetails.map((x,y) => {
        if (x["m1:QuestionText"].includes("Time of Admission") || x["m1:QuestionText"].includes("Time of Discharge")) {
          let dateVal = addIncomeDetails[y-1];
            if (dateVal["m1:QuestionText"].includes("Date of Admission") || dateVal["m1:QuestionText"].includes("Date of Discharge")) {
              dateVal["m1:YesNoCd"] =this.getGMTDateConverter(dateVal["m1:YesNoCd"].concat(" " + x["m1:YesNoCd"]));
              addIncomeDetails.splice(y,1);
            }
        }
      });    
      return addIncomeDetails;
    } 
    else if (requestName === "addIncomeMainList" && type === "icor") {
      const addIncomeDetails = [];
      claimTypeData.uiData.addIncomeMainList.forEach((elem, i) => {
        Object.keys(elem).forEach((el) => {
          let fieldValue = elem[el];
          if (el === "Time of Admission" || el === "Time of Discharge") {
            const timeVal = moment(elem[el]).format("hh:mm A");
            const timeValArray = timeVal.split(" ");
            fieldValue = timeValArray[1] === "AM" ? `${timeValArray[0]} AM` : `${timeValArray[0]} PM`;
          } else if (el === "Date of Admission" || el === "Date of Discharge") {
              fieldValue = this.dateFormatter(fieldValue);
          }
          addIncomeDetails.push({
              name:
                claimTypeData.uiData.addIncomeMainList.length === 1
                  ? `${el}:`
                  : `${el} ${i + 1}:`,
              value: fieldValue,
          });
        });
      });
      return addIncomeDetails;
    } 
    else if (requestName === "admittedToIcuIllness") {
      return claimTypeData.uiData.admittedToIcuIllness;
    } else if (requestName === "admittedIcuIllness") {
      return claimTypeData.uiData.admittedIcuIllness;
    } else if (requestName === "paymentChannel") {
      if (paymentData?.uiData.paymentChannel === "electronicBankTransfer") {
        return "Electronic Bank Transfer";
      } else if (paymentData?.uiData.paymentChannel === "cheque") {
        return "Cheque";
      }
    } else if (requestName === "payeeNameBank") {
      return paymentData?.uiData.payeeNameBank || "";
    } else if (requestName === "bankNameCode") {
      return paymentData?.uiData.bankName?.name || "";
    } else if (requestName === "onlyBankName") {
      return paymentData?.uiData.bankName?.name?.split("/")[0] || "";
    } else if (requestName === "onlyBankCode") {
      return paymentData?.uiData.bankName?.name?.split("/")[1] || "";
    } else if (requestName === "accountNumber") {
      return (
        paymentData?.uiData?.accountNumber ||
        paymentData?.uiData?.accountNumbe?.accountNumber ||
        ""
      );
    } else if (requestName === "payeeName") {
      return paymentData?.uiData.payeeName || "";
    } else if (requestName === "medicineTypeList") {
      const medicineList = [];
      if (this.getDetail("westernMedicine")) {
        medicineList.push("Western Medicine");
      }
      if (this.getDetail("chineseMedicine")) {
        medicineList.push("Traditional Chinese Medicine/Chiropractor");
      }
      if (this.getDetail("mobilityaid")) {
        medicineList.push("Mobility Aid");
      }
      if (this.getDetail("dentalTreatment")) {
        medicineList.push("Dental Treatment");
      }
      return medicineList.join(", ");
    } else if (requestName === "uploadedDocs") {
      return uploadData?.uiData?.customFileUpload?.uploads || [];
    } else if (requestName === "witnessDetails") {
      return claimTypeData.uiData.addWitnessMainList;
    } else if (requestName === "doctorDetails") {
      return claimTypeData.uiData.addDoctorMainList;
    } else if (requestName === "diagnosisRDF") {
      if (claimTypeData.uiData.diagnosis?.name === "Abrasion") {
        return "T14 - Injury of unspecified body region";
      } else if (
        claimTypeData.uiData.diagnosis?.name ===
        "Avulsion/Laceration not Requiring Sutures"
      ) {
        return "T01 - Open wounds involving multiple body regions";
      } else if (
        claimTypeData.uiData.diagnosis?.name ===
        "Avulsion/Laceration Requiring Sutures"
      ) {
        return "T01 - Open wounds involving multiple body regions";
      } else if (claimTypeData.uiData.diagnosis?.name === "Burns") {
        return "T31 - Burns classified according to extent of body surface involved";
      } else if (
        claimTypeData.uiData.diagnosis?.name === "Concussion/Head Injury"
      ) {
        return "S06 - Intracranial injury";
      } else if (claimTypeData.uiData.diagnosis?.name === "Contusion/Bruise") {
        return "T14 - Injury of unspecified body region";
      } else if (claimTypeData.uiData.diagnosis?.name === "Food Poisoning") {
        return "T47 - Poisoning by agents primarily affecting the gastrointestinal system";
      } else if (
        claimTypeData.uiData.diagnosis?.name === "Fracture/Dislocation"
      ) {
        return "T02 - Fractures involving multiple body regions";
      } else if (claimTypeData.uiData.diagnosis?.name === "Haemorrhage") {
        return "R58 - Haemorrhage, not elsewhere classified";
      } else if (claimTypeData.uiData.diagnosis?.name === "Sprain") {
        return "T03 - Dislocations, sprains and strains involving multiple body regions";
      } else if (claimTypeData.uiData.diagnosis?.name === "Strain/Tear") {
        return "T03 - Dislocations, sprains and strains involving multiple body regions";
      } else if (claimTypeData.uiData.diagnosis?.name === "Whiplash") {
        return "S13 - Dislocation, sprain and strain of joints and ligaments at neck level";
      } else if (claimTypeData.uiData.diagnosis?.name === "Others") {
        return "Others";
      }
    } else if (requestName === "diagnosisIllnessRDF") {
      if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Allergic Reaction/Rashes"
      ) {
        return "R21 - Rash and other nonspecific skin eruption";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Arthritis/Chronic Joint or Muscle Disorder"
      ) {
        return "M13 - Other arthritis";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Asthma/Chronic Airway Disorder"
      ) {
        return "J45 - Asthma";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name === "Cancer/Tumor"
      ) {
        return "C76 - Malignant neoplasm of other and ill-defined sites";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name === "Dengue/Malaria/Similar"
      ) {
        return "A92 - Other mosquito-borne viral fevers";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Ear/Eye/Gum/Mouth Infection"
      ) {
        return "B33 - Other viral diseases, not elsewhere classified";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Flu/Cold/Cough/URTI/Sore Throat"
      ) {
        return "B34 - Viral infection of unspecified site";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name === "Headache/Migraine"
      ) {
        return "R51 - Headache";
      } else if (claimTypeData.uiData.diagnosisIllness?.name === "Diabetes") {
        return "E14 - Unspecified diabetes mellitus";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Nausea/Vomiting/Stomach Pain"
      ) {
        return "K92 - Other diseases of digestive system";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Blood Pressure/Hypertension"
      ) {
        return "I10 - Essential (primary) hypertension";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Cardiovascular/Heart Condition"
      ) {
        return "I50 - Heart failure";
      } else if (claimTypeData.uiData.diagnosisIllness?.name === "Covid") {
        return "J09 - Influenza due to certain identified influenza virus";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name ===
        "Gastroenteritis/Diarrhea"
      ) {
        return "A09 - Other gastroenteritis and colitis of infectious and unspecified origin";
      } else if (
        claimTypeData.uiData.diagnosisIllness?.name === "Stroke/CVA/TIA"
      ) {
        return "I67 - Other cerebrovascular diseases";
      } else if (claimTypeData.uiData.diagnosisIllness?.name === "Others") {
        return "Others";
      }
    }
  }

  generateFnolModelData() {
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns2:requestHeader"][
      "ns1:requestMessageID"
    ] = this.fnolModelService.getClaimNumber();

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns2:applicationContext"][
      "ns1:userId"
    ] = this.config.userEmailID;
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns2:applicationContext"][
      "ns1:providerSystemPassword"
    ] = this.fnolModelService.getClaimNumber();
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns2:applicationContext"][
      "ns1:systemDate"
    ] = moment(new Date()).format("YYYYMMDD");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns2:applicationContext"][
      "ns1:providerSystemID"
    ] = this.fnolModelService.getDetail("providerSysId");

    //ClaimsOccurrence
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:LossDt"] = this.getDetail("dateOfAccident");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:LossTime"] = this.getDetail("lossTime");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:ClaimsReported"][0]["m1:ReportedDt"] =
      this.getDetail("sysDateGmt");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:ClaimsReported"][1]["m1:ReportedDt"] =
      this.getDetail("sysDateGmt");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:ClaimTypeCd"] = this.getDetail("claimType");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:IncidentDesc"] =
      this.getDetail("incidentDesc");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:DamageDesc"] = this.getDetail("DamageDesc");

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"] = [];

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push(
      {
        "m1:QuestionText": "Identification Number",
        "m1:YesNoCd": this.getDetail("nric"),
      },
      {
        "m1:QuestionText": "Is the Insured a US citizen?",
        "m1:YesNoCd": this.getDetail("isUSCitizen"),
      },
      {
        "m1:QuestionText": "Current Age",
        "m1:YesNoCd": this.getDetail("getAge"),
      },
      {
        "m1:QuestionText": "Relationship to Insured",
        "m1:YesNoCd": this.getDetail("claimAsCode"),
      }
    );

    if (this.getDetail("claimAsCode") === "023") {
      setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
        "m1:ClaimsDownloadInfo"
      ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push({
        "m1:QuestionText": "Claimed as others",
        "m1:YesNoCd": this.getDetail("claimAsOthers"),
      });
    }

    // setFnolSgModel['ns2:InvoiceDownloadRequest']['ns3:ClaimDownloadRq']['m1:ClaimsDownloadInfo']['m1:ClaimsOccurrence']['m1:QuestionAnswer'].push(
    //   {
    //     'm1:QuestionText': 'Is this due to a road traffic accident?',
    //     'm1:YesNoCd': this.getDetail('isTrafficAccident') === 'Yes'
    //   }
    // )

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push({
      "m1:QuestionText": "Select desired payment channel",
      "m1:YesNoCd": this.getDetail("paymentChannel"),
    });

    if (this.getDetail("paymentChannel") === "Electronic Bank Transfer") {
      setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
        "m1:ClaimsDownloadInfo"
      ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push(
        {
          "m1:QuestionText": "Payee Name",
          "m1:YesNoCd": this.getDetail("payeeNameBank"),
        },
        {
          "m1:QuestionText": "Bank Name",
          "m1:YesNoCd": this.getDetail("onlyBankName"),
        },
        {
          "m1:QuestionText": "Bank Code",
          "m1:YesNoCd": this.getDetail("onlyBankCode"),
        },
        {
          "m1:QuestionText": "Bank Account No",
          "m1:YesNoCd": this.getDetail("accountNumber"),
        }
      );
    } else if (this.getDetail("paymentChannel") === "Cheque") {
      setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
        "m1:ClaimsDownloadInfo"
      ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push({
        "m1:QuestionText": "Payee Name",
        "m1:YesNoCd": this.getDetail("payeeName"),
      });
    }

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push({
      "m1:QuestionText": "What was the diagnosis?",
      "m1:YesNoCd":
        this.getDetail("claimType") === "Personal Accident"
          ? this.getDetail("diagnosisRDF")
          : this.getDetail("diagnosisIllnessRDF"),
    });

    // add others for diagnosis
    const isDiagnosisOthers =
      this.getDetail("claimType") === "Personal Accident"
        ? this.getDetail("diagnosisRDF")
        : this.getDetail("diagnosisIllnessRDF");

    if (isDiagnosisOthers === "Others") {
      setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
        "m1:ClaimsDownloadInfo"
      ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push({
        "m1:QuestionText": "Diagnosis Other",
        "m1:YesNoCd":
          this.getDetail("claimType") === "Personal Accident"
            ? this.getDetail("otherDiagnosis")
            : this.getDetail("othersIllness"),
      });
    }

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsOccurrence"]["m2:ClaimNumber"] =
      this.fnolModelService.getClaimNumber();

    if (this.getDetail("claimType") === "Personal Accident") {
      setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
        "m1:ClaimsDownloadInfo"
      ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push(
        {
          "m1:QuestionText": "Describe how the accident occurred",
          "m1:YesNoCd": this.getDetail("accidentOccurred"),
        },
        {
          "m1:QuestionText": "Describe injuries sustained",
          "m1:YesNoCd": this.getDetail("injuriesSustained"),
        },
        {
          "m1:QuestionText": "Date of Accident",
          "m1:YesNoCd": this.getDetail("dateOfAccident"),
        },
        {
          "m1:QuestionText": "Time of Accident",
          "m1:YesNoCd": this.getDetail("lossTime"),
        },
        {
          "m1:QuestionText": "Accident Location",
          "m1:YesNoCd": this.getDetail("accidentLocation"),
        },
        {
          "m1:QuestionText": "Date of first doctor visit",
          "m1:YesNoCd": this.getDetail("firstVisit"),
        },
        {
          "m1:QuestionText": "Is this due to a road traffic accident?",
          "m1:YesNoCd": this.getDetail("isTrafficAccident") === "Yes",
        }
      );
    } else if (this.getDetail("claimType") === "Illness / Critical Illness") {
      setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
        "m1:ClaimsDownloadInfo"
      ]["m1:ClaimsOccurrence"]["m1:QuestionAnswer"].push(
        {
          "m1:QuestionText": "Description of the illness",
          "m1:YesNoCd": this.getDetail("describeIllness"),
        },
        {
          "m1:QuestionText": "When did the symptom first occur?",
          "m1:YesNoCd": this.getDetail("dateSymptom"),
        },
        {
          "m1:QuestionText": "Date of first doctor visit",
          "m1:YesNoCd": this.getDetail("dateVisit"),
        },
        {
          "m1:QuestionText": "Is Critical Illness being claimed? ",
          "m1:YesNoCd": this.getDetail("claimingIllness"),
        }
      );
    }

    // policy details
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:PolicyNumber"] = this.getDetail("policyNo");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:PolicyStatusCd"] = this.getDetail("policyStatus");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:ContractTerm"]["m1:EffectiveDt"] = this.getDetail(
      "policyEffectiveDate"
    );
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:ContractTerm"]["m1:ExpirationDt"] =
      this.getDetail("policyExpiryDate");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:PolicyHolderName"] = this.getDetail(
      "policyHolderFirstName"
    );
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:multiNationalPolicy"] = this.getDetail(
      "multiNationalPolicy"
    );
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:certificateNumber"] =
      this.getDetail("certificateNumber");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:externalReference"] = this.getDetail("policyNo");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:firstName"] = this.getDetail("policyHolderFirstName");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:lastName"] = this.getDetail("policyHolderLastName");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:PolicyOfficeCd"] = this.getDetail("policyOfficeCode");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:policyOfficeCode"] = this.getDetail("policyOfficeCode");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:policyHolderNo"] = this.getDetail("policyHolderNo");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:renewalCertificateNumber"] = this.getDetail(
      "renewalCertificateNumber"
    );
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:database"] = this.getDetail("policyDatabase");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:effectiveDateSequenceNumber"] = this.getDetail(
      "effectiveDateSequenceNumber"
    );
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:policyStatus"] = this.getDetail("policyStatus");
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:Policy"]["m1:startDate"] = this.getDetail("policyEffectiveDate");

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsParty"] = [];
    //claimant claims party
    let claimTypeIndex = 1;
    const claimantClaimsParty = {
      "m1:ClaimsPartyInfo": {
        "m1:ClaimsPartyRoleCd": "Claimant",
      },
      "m1:GeneralPartyInfo": {
        "m2:CommunicationModeCd": "",
        "m1:NameInfo": {
          "m1:PersonName": {
            "m1:GivenName": this.getDetail("claimantFirstName"),
            "m1:Surname": this.getDetail("claimantLastName"),
          },
        },
        "m1:Addr": {
          "m1:Addr1": this.getDetail("address1").length > 35?this.formatAddress.getSplitAddress(this.getDetail("address1"),"part1"):this.getDetail("address1"),
          "m1:Addr2": this.getDetail("address1").length > 35?this.formatAddress.getSplitAddress(this.getDetail("address1"),"part2") + ","+ this.getDetail("address2"): this.getDetail("address2"),
          "m1:City": "NA",
          "m1:StateProvCd": this.getDetail("stateCode"),
          "m1:PostalCode": this.getDetail("postalCode"),
          "m1:CountryCd": this.getDetail("countryCode"),
          "m1:StateProv": this.getDetail("stateCode"),
        },
        "m1:Communications": {
          "m1:EmailInfo": {
            "m1:EmailAddr": this.getDetail("claimantMail"),
          },
          "m1:PhoneInfo": this.getDetail("claimantPhoneInfo"),
        },
        "m1:MiscParty": {
          "m1:MiscPartyInfo": {
            "@id": claimTypeIndex,
            "m1:MiscPartySubRoleCd": "",
          },
        },
        "m2:IndOrOrgCd": "",
      },
      "m1:PersonInfo": {
        "m1:GenderCd": "",
        "m1:BirthDt": this.getDetail("claimantDob"),
      },
    };
    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m1:ClaimsParty"][0] = claimantClaimsParty;

    //broker details
    if (this.getDetail("brokerName")) {
      claimTypeIndex++;
      const brokerData = {
        "m1:ClaimsPartyInfo": {
          "m1:ClaimsPartyRoleCd": "Broker",
        },
        "m1:GeneralPartyInfo": {
          "m2:CommunicationModeCd": "",
          "m1:NameInfo": {
            "m1:PersonName": {
              "m1:GivenName": this.getDetail("brokerName"),
              "m1:Surname": "",
            },
          },
          "m1:Addr": {
            "m1:Addr1": "",
            "m1:Addr2": "",
            "m1:City": "",
            "m1:StateProvCd": "",
            "m1:PostalCode": "",
            "m1:CountryCd": "",
            "m1:StateProv": "",
          },
          "m1:Communications": {
            "m1:EmailInfo": {
              "m1:EmailAddr": this.getDetail("brokerEmail"),
            },
            "m1:PhoneInfo": this.getDetail("brokerNumberInfo"),
          },
          "m1:MiscParty": {
            "m1:MiscPartyInfo": {
              "@id": claimTypeIndex,
              "m1:MiscPartySubRoleCd": "Broker",
            },
          },
          "m2:IndOrOrgCd": "",
        },
        "m1:PersonInfo": {
          "m1:GenderCd": "",
          "m1:BirthDt": "",
        },
      };
      setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
        "m1:ClaimsDownloadInfo"
      ]["m1:ClaimsParty"].push(brokerData);
    }

    // witness claims party
    if (this.getDetail("witnessInfo") === "Yes") {
      const witnessDetails = this.getDetail("witnessDetails");

      witnessDetails.forEach((party) => {
        claimTypeIndex++;
        const witnessDetailsData: any = {
          "m1:ClaimsPartyInfo": {
            "m1:ClaimsPartyRoleCd": "WIT",
          },
          "m1:GeneralPartyInfo": {
            "m2:CommunicationModeCd": "",
            "m1:NameInfo": {
              "m1:PersonName": {
                "m1:GivenName": party["Witness Name"],
                "m1:Surname": "",
              },
            },
            "m1:Addr": {
              "m1:Addr1": "",
              "m1:Addr2": "",
              "m1:City": "",
              "m1:StateProvCd": "",
              "m1:PostalCode": "",
              "m1:CountryCd": "",
              "m1:StateProv": "",
            },
            "m1:Communications": {
              "m1:EmailInfo": {
                "m1:EmailAddr": party["Email"],
              },
              "m1:PhoneInfo": this.getDetail(
                "phoneInfo",
                "",
                party["Contact Number"]
              ),
            },
            "m1:MiscParty": {
              "m1:MiscPartyInfo": {
                "@id": claimTypeIndex,
                "m1:MiscPartySubRoleCd": "Witness",
              },
            },
            "m2:IndOrOrgCd": "",
          },
          "m1:PersonInfo": {
            "m1:GenderCd": "",
            "m1:BirthDt": "",
          },
        };
        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m1:ClaimsParty"].push(witnessDetailsData);
      });
    }

    //doctor's claims party
    if (this.getDetail("doctorInfo") === "Yes") {
      const doctorDetails = this.getDetail("doctorDetails");

      doctorDetails.forEach((party) => {
        claimTypeIndex++;
        const doctorDetailsData: any = {
          "m1:ClaimsPartyInfo": {
            "m1:ClaimsPartyRoleCd": "Doctor",
          },
          "m1:GeneralPartyInfo": {
            "m2:CommunicationModeCd": "",
            "m1:NameInfo": {
              "m1:PersonName": {
                "m1:GivenName": party["Doctor's Full Name"],
                "m1:Surname": "",
              },
            },
            "m1:Addr": {
              "m1:Addr1": "",
              "m1:Addr2": "",
              "m1:City": "",
              "m1:StateProvCd": "",
              "m1:PostalCode": "",
              "m1:CountryCd": "",
              "m1:StateProv": "",
            },
            "m1:Communications": {
              "m1:EmailInfo": {
                "m1:EmailAddr": party["Email"],
              },
              "m1:PhoneInfo": this.getDetail(
                "phoneInfo",
                "",
                party["Contact Number"]
              ),
            },
            "m1:MiscParty": {
              "m1:MiscPartyInfo": {
                "@id": claimTypeIndex,
                "m1:MiscPartySubRoleCd": "Doctor",
              },
            },
            "m2:IndOrOrgCd": "",
          },
          "m1:PersonInfo": {
            "m1:GenderCd": "",
            "m1:BirthDt": "",
          },
        };
        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m1:ClaimsParty"].push(doctorDetailsData);
      });
    }

    setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
      "m1:ClaimsDownloadInfo"
    ]["m2:LossInfo"] = [];
    if (this.getDetail("claimType") === "Personal Accident") {
      let featureIndex = 0;
      if (this.getDetail("medicalExpenseClaim") === "medicalExpenseClaim") {
        if (
          this.getDetail("totalExpense") !== "" &&
          this.getDetail("taotalExpense") !== 0
        ) {
          featureIndex++;
          const accidentMedicalData = this.formatFeatureTypeCd(featureIndex);
          setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
            "m1:ClaimsDownloadInfo"
          ]["m2:LossInfo"].push(...accidentMedicalData);
          featureIndex += accidentMedicalData.length - 1;
        }

        // Daily Hospital Income
        if (this.getDetail("admittedHospital") === "Yes") {
          featureIndex++;
          const dailyHospitalIncome = {
            "m2:FeatureTypeCd": "Hospital Cash & Hospital Income Claims",
            "m2:FeatureNumber": {
              "@id": true,
              $: featureIndex,
            },
            "m1:QuestionAnswer": [
              ...this.getDetail("treatmentList"),
              {
                "m1:QuestionText": "Was the Insured admitted to the ICU?",
                "m1:YesNoCd": this.getDetail("admittedIcu") === "Yes",
              },
            ],
          };
          if (this.getDetail("admittedIcu") === "Yes") {
            dailyHospitalIncome["m1:QuestionAnswer"].push({
              "m1:QuestionText":
                "For the duration of the hospital stay, how many were spent in ICU?",
              "m1:YesNoCd": this.getDetail("admittedToIcuDays"),
            });
          }
          setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
            "m1:ClaimsDownloadInfo"
          ]["m2:LossInfo"].push(dailyHospitalIncome);
        }

        // Fracture & Dislocation
        featureIndex++;
        const fractureNDislocation = this.buildFractureNDislocation(featureIndex);
        if (fractureNDislocation) {
          setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
            "m1:ClaimsDownloadInfo"
          ]["m2:LossInfo"].push(...fractureNDislocation);
        }
      }

      //Leave benefit
      if (this.getDetail("leaveBenefit") === "leaveBenefit") {
        featureIndex++;
        const leaveBenefitData = {
          "m2:FeatureTypeCd": "Permanent Disability/WI",
          "m2:FeatureNumber": {
            "@id": true,
            $: featureIndex,
          },
          "m1:QuestionAnswer": [
            ...this.getDetail("addLeaveMainList"),
            {
              "m1:QuestionText":
                "Was the Insured employed at the time of the accident?",
              "m1:YesNoCd": this.getDetail("employed") === "Yes",
            },
            {
              "m1:QuestionText": "Expected date of return to work",
              "m1:YesNoCd": this.getDetail("dateOfExpected"),
            },
          ],
        };
        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m2:LossInfo"].push(leaveBenefitData);
      }

      // permanent Disability
      if (this.getDetail("permanentDisability") === "permanentDisability") {
        featureIndex++;
        const permanentDisablementData = {
          "m2:FeatureTypeCd": "Permanent Disability/PD",
          "m2:FeatureNumber": {
            "@id": true,
            $: featureIndex,
          },
          "m1:QuestionAnswer": [
            {
              "m1:QuestionText": "Date disability was diagnosed",
              "m1:YesNoCd": this.getDetail("dateOfDisability"),
            },
            {
              "m1:QuestionText": "Type of Permanent Disability",
              "m1:YesNoCd": this.getDetail("typeDisability"),
            },
          ],
        };
        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m2:LossInfo"].push(permanentDisablementData);
      }

      // accident death
      if (this.getDetail("accidentalDeath") === "accidentalDeath") {
        featureIndex++;
        const accidentalDeathData = {
          "m2:FeatureTypeCd": "Accidental Death",
          "m2:FeatureNumber": {
            "@id": true,
            $: featureIndex,
          },
          "m1:QuestionAnswer": [
            {
              "m1:QuestionText": "Date of Death",
              "m1:YesNoCd": this.getDetail("dateOfDeath"),
            },
            {
              "m1:QuestionText": "Local Time",
              "m1:YesNoCd": this.getDetail("localTimePersonal"),
            },
            {
              "m1:QuestionText": "Select Cause of Death",
              "m1:YesNoCd": this.getDetail("causeDeath"),
            },
          ],
        };
        if (this.getDetail("causeDeath") === "Others") {
          accidentalDeathData["m1:QuestionAnswer"].push({
            "m1:QuestionText": "Other Reason",
            "m1:YesNoCd": this.getDetail("causeOthers"),
          });
        }
        accidentalDeathData["m1:QuestionAnswer"].push({
          "m1:QuestionText": "Was an autopsy performed?",
          "m1:YesNoCd": this.getDetail("autopsy") === "Yes",
        });
        if (this.getDetail("autopsy") === "No") {
          accidentalDeathData["m1:QuestionAnswer"].push({
            "m1:QuestionText": "If No, then please provide a reason",
            "m1:YesNoCd": this.getDetail("reason"),
          });
        }
        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m2:LossInfo"].push(accidentalDeathData);
      }
    } else if (this.getDetail("claimType") === "Illness / Critical Illness") {
      // Critical Illness Daily Hospital Income
      let featureIndex = 0;
      if (
        this.getDetail("dailyIncome") === "dailyIncome" &&
        this.getDetail("claimingIllness") === "Yes"
      ) {
        featureIndex++;
        const dailyIncomeDetailsData1 = {
          "m2:FeatureTypeCd": "Critical Illness",
          "m2:FeatureNumber": {
            "@id": true,
            $: featureIndex,
          },
          "m1:QuestionAnswer": [
            {
              "m1:QuestionText": "When did the symptom first occur?",
              "m1:YesNoCd": this.getDetail("dateSymptom"),
            },
            {
              "m1:QuestionText": "Date of first doctor visit",
              "m1:YesNoCd": this.getDetail("dateVisit"),
            },
          ],
        };

        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m2:LossInfo"].push(dailyIncomeDetailsData1);
      }

      if (
        this.getDetail("dailyIncome") === "dailyIncome" &&
        this.getDetail("claimingIllness") === "No"
      ) {
        featureIndex++;
        const dailyIncomeDetailsData2 = {
          "m2:FeatureTypeCd": "Hospital Cash & Hospital Income Claims",
          "m2:FeatureNumber": {
            "@id": true,
            $: featureIndex,
          },
          "m1:QuestionAnswer": [
            ...this.getDetail("addIncomeMainList"),
            {
              "m1:QuestionText": "Was the Insured admitted to the ICU?",
              "m1:YesNoCd": this.getDetail("admittedIcuIllness") === "Yes",
            },
          ],
        };
        if (this.getDetail("admittedIcuIllness") === "Yes") {
          dailyIncomeDetailsData2["m1:QuestionAnswer"].push({
            "m1:QuestionText":
              "For the duration of the hospital stay, how many were spent in ICU?",
            "m1:YesNoCd": this.getDetail("admittedToIcuIllness"),
          });
        }

        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m2:LossInfo"].push(dailyIncomeDetailsData2);
      }

      // loss of activity
      if (this.getDetail("lossActivities") === "lossActivities") {
        featureIndex++;
        const lossOfActivityDetailsData = {
          "m2:FeatureTypeCd": "Permanent Disability/LO",
          "m2:FeatureNumber": {
            "@id": true,
            $: featureIndex,
          },
          "m1:QuestionAnswer": [
            {
              "m1:QuestionText": "Date disability was diagnosed",
              "m1:YesNoCd": this.getDetail("dateOfDiagnosed"),
            },
            {
              "m1:QuestionText": "Type of Disability",
              "m1:YesNoCd": this.getDetail("disabilityIllness"),
            },
          ],
        };

        setFnolSgModel["ns2:InvoiceDownloadRequest"]["ns3:ClaimDownloadRq"][
          "m1:ClaimsDownloadInfo"
        ]["m2:LossInfo"].push(lossOfActivityDetailsData);
      }
    }
    return setFnolSgModel;
  }

  generateIcorModelData() {
    icorSgModel.Icorr.iCorrespondence.requestHeader.language =
      URL_PARAMETERS.LANGUAGE.toString();
    icorSgModel.Icorr.iCorrespondence.requestHeader.systemDate = moment(
      new Date()
    ).format("YYYYMMDD");
    icorSgModel.Icorr.iCorrespondence.requestHeader.requestMessageID =
      this.fnolModelService.getClaimNumber();
    icorSgModel.Icorr.iCorrespondence.requestHeader.lineOfBusiness =
      URL_PARAMETERS.LOB.toString();

    icorSgModel.Icorr.iCorrespondence.ApplicationContext.language =
      URL_PARAMETERS.LANGUAGE.toString();

    icorSgModel.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.claimID =
      this.fnolModelService.getClaimNumber();
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[0].attribute =
      this.getPolicyInfo();
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[1].attribute =
      this.getPrivacyPolicySectionInfo();
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[2].attribute =
      this.getClaimantInfo();
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[3].attribute =
      this.getDetail("claimType") === "Personal Accident"
        ? this.getPersonalAccidentDetails()
        : this.criticalIllnessDetails();
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[4].attribute =
      this.paymentDetails();
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[5].attribute =
      this.uploadedDocumentDetails();

    icorSgModel.Icorr.iCorrespondence.correspondenceItem.distributionGenerationData.country =
      "SG";
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.distributionGenerationData.lob =
      "ACHE";

    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.claimantEmail =
      this.getDetail("claimantMail");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.brokerEmail =
      this.getDetail("brokerEmail");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.sharedServiceEmail =
      this.config.aigSharedeMailID
        ? this.config.aigSharedeMailID["sg"]
        : this.config.aigSharedeMailID;
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.insuredName =
      this.getDetail("insuredName");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByFirstName =
      this.getDetail("claimantFirstName");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByLastName =
      this.getDetail("claimantLastName");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.mobile =
      this.getDetail("claimantPhoneNo");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.lossDate =
      this.getDetail("dateOfAccident");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.policyNumber = 
      this.getDetail("policyNo");
    icorSgModel.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.batchName ='';
    return icorSgModel;
  }

  generateDocUploadData() {
    docUploadModel.DocUpload.claimNumber =
      this.fnolModelService.getClaimNumber();
    docUploadModel.DocUpload.lossDate = this.getDetail("dateOfAccident");
    docUploadModel.DocUpload.policyNumber = this.getDetail("policyNo");
    docUploadModel.DocUpload.batchName = '';
    docUploadModel.DocUpload.branchCase =
      "REF:" + this.fnolModelService.getClaimNumber() + "O";
    docUploadModel.DocUpload.documentInfo = this.getDetail("uploadedDocs");
    return docUploadModel;
  }

  getPolicyInfo() {
    const claimInfoSectPolicy = [];
    if (this.getDetail("policyNumber")) {
      claimInfoSectPolicy.push({
          name: "Policy Number:",
          value: this.getDetail("policyNumber"),
      });
    }
    claimInfoSectPolicy.push(
      {
        name: "NRIC or FIN:",
        value: this.getDetail("policyHolderNric"),
      },
      {
        name: "Policyholder First/Given Name:",
        value: this.getDetail("policyHolderFirstName"),
      },
      {
        name: "Policyholder Last/Surname:",
        value: this.getDetail("policyHolderLastName"),
      },
      {
        name: "Date of Birth:",
        value: this.getDetail("policyHolderDob", "icor"),
      },
      
    );
    return claimInfoSectPolicy;
  }

  getPrivacyPolicySectionInfo() {
    return [
      {
        name: "Privacy Policy:",
        value: "Yes",
      },
      {
        name: "Terms of Use:",
        value: "Yes",
      },
      {
        name: "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
        value: "Yes",
      },
    ];
  }

  getClaimantInfo() {
    const claimInfoSect = [];
    claimInfoSect.push({
      name: "What is your relationship to the policyholder:",
      value: this.getDetail("claimAs"),
    });
    if (this.getDetail("claimAs") === "Others") {
      claimInfoSect.push({
        name: "Others:",
        value: this.getDetail("claimAsOthers"),
      });
    }
    claimInfoSect.push(
      {
        name: "First/Given Name:",
        value: this.getDetail("claimantFirstName"),
      },
      {
        name: "Last/Surname:",
        value: this.getDetail("claimantLastName"),
      },
      {
        name: "NRIC or FIN:",
        value: this.getDetail("nric"),
      },
      {
        name: "Contact Number:",
        value: this.getDetail("claimantPhoneNo"),
      },
      {
        name: "Email:",
        value: this.getDetail("claimantMail"),
      },
      {
        name: "Date of Birth:",
        value: this.getDetail("dateOfBirth"),
      },
      {
        name: "Current Age:",
        value: this.getDetail("getAge"),
      },
      {
        name: "Country:",
        value: this.getDetail("countryName"),
      },
      {
        name: "Street Address:",
        value: this.getDetail("address1"),
      }
    );
    if (this.getDetail("address2")) {
      claimInfoSect.push({
        name: "Level/Unit Number:",
        value: this.getDetail("address2"),
      });
    }
    claimInfoSect.push({
      name: "Postal Code:",
      value: this.getDetail("postalCode"),
    });

    if (
      this.getDetail("brokerName") ||
      this.getDetail("brokerEmail") ||
      this.getDetail("brokerNumber")
    ) {
      claimInfoSect.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Agent Information (Optional)",
          value: "",
        }
      );
      if (this.getDetail("brokerName")) {
        claimInfoSect.push({
          name: "Name:",
          value: this.getDetail("brokerName"),
        });
      }
      if (this.getDetail("brokerEmail")) {
        claimInfoSect.push({
          name: "Email:",
          value: this.getDetail("brokerEmail"),
        });
      }
      if (this.getDetail("brokerNumber")) {
        claimInfoSect.push({
          name: "Contact Number:",
          value: this.getDetail("brokerNumber"),
        });
      }
    }

    return claimInfoSect;
  }

  getMedicineTreatmentCopy(type) {
    const claimTypeData = this._dataStoreService.get(
      FNOL_STEPS_SINGAPORE.CLAIM_TYPE_DETAILS.name
    );
    if (type === "Western medicine") {
      return claimTypeData.uiData.medicalExpense.westernMedicine > 0
        ? claimTypeData.uiData.medicalExpense.westernMedicine
        : "";
    } else if (type === "Traditional Chinese Medicine/Chriopractor") {
      return claimTypeData.uiData.medicalExpense.chriopractor > 0
        ? claimTypeData.uiData.medicalExpense.chriopractor
        : "";
    } else if (type === "Mobility Aid") {
      return claimTypeData.uiData.medicalExpense.mobileAdd > 0
        ? claimTypeData.uiData.medicalExpense.mobileAdd
        : "";
    } else if (type === "Dental Treatment") {
      return claimTypeData.uiData.medicalExpense.dentalTreatment > 0
        ? claimTypeData.uiData.medicalExpense.dentalTreatment
        : "";
    } else if (type === "Total Claim Amount") {
      return claimTypeData.uiData.medicalExpense.sum > 0
        ? claimTypeData.uiData.medicalExpense.sum.toString().indexOf(".") == -1
          ? claimTypeData.uiData.medicalExpense.sum + ".00"
          : claimTypeData.uiData.medicalExpense.sum.toFixed(2)
        : "";
    }
  }

  getPersonalAccidentDetails() {
    const personalAccidentData = [];
    personalAccidentData.push(
      {
        name: "",
        value: "",
      },
      {
        name: "Personal Accident Details",
        value: "",
      },
      {
        name: "",
        value: "",
      },
      {
        name: "In a few words, how did the accident happen:",
        value: this.getDetail("incidentDesc"),
      },
      {
        name: "What injuries resulted from the accident:",
        value: this.getDetail("DamageDesc"),
      },
      {
        name: "Accident Date:",
        value: this.getDetail("dateOfAccident", "icor"),
      },
      {
        name: "Accident Time:",
        value: this.getDetail("lossTime", "icor"),
      },
      {
        name: "Accident location:",
        value: this.getDetail("accidentLocation"),
      },
      {
        name: "Date of First Doctor's Visit:",
        value: this.getDetail("firstVisit", "icor"),
      },
      {
        name: "Diagnosis:",
        value: this.getDetail("diagnosis"),
      }
    );
    if (this.getDetail("diagnosis") === "Others") {
      personalAccidentData.push({
        name: "Others:",
        value: this.getDetail("otherDiagnosis"),
      });
    }
    personalAccidentData.push(
      {
        name: "Was this a traffic accident:",
        value: this.getDetail("isTrafficAccident"),
      },
      {
        name: "",
        value: "",
      },
      {
        name: "Personal Accident Benefits",
        value: "",
      },
      {
        name: "Which benefits are you claiming? Select all that apply:",
        value: this.getDetail("claimBenefitsType", "icor"),
      }
    );
    if (this.getDetail("medicalExpenseClaim") === "medicalExpenseClaim") {
      personalAccidentData.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Accident Medical Reimbursement",
          value: "",
        }
      );

      if (this.getMedicineTreatmentCopy("Total Claim Amount") > 0) {
        personalAccidentData.push({
          name: "Cost of Treatment(SGD)",
          value: "",
        });
      }
      if (this.getMedicineTreatmentCopy("Western medicine")) {
        personalAccidentData.push({
          name: "Western medicine:",
          value: this.decimalConverter(this.getMedicineTreatmentCopy("Western medicine"), 'SG')
        });
      }

      if (
        this.getMedicineTreatmentCopy(
          "Traditional Chinese Medicine/Chriopractor"
        )
      ) {
        personalAccidentData.push({
          name: "Traditional Chinese Medicine/Chriopractor:",
          value: this.decimalConverter(this.getMedicineTreatmentCopy(
            "Traditional Chinese Medicine/Chriopractor"
          ), 'SG')
        });
      }
      if (this.getMedicineTreatmentCopy("Mobility Aid")) {
        personalAccidentData.push({
          name: "Mobility Aid:",
          value: this.decimalConverter(this.getMedicineTreatmentCopy("Mobility Aid"), 'SG')
        });
      }
      if (this.getMedicineTreatmentCopy("Dental Treatment")) {
        personalAccidentData.push({
          name: "Dental Treatment:",
          value: this.decimalConverter(this.getMedicineTreatmentCopy("Dental Treatment"), 'SG')
        });
      }
      if (this.getMedicineTreatmentCopy("Total Claim Amount") > 0) {
        personalAccidentData.push({
          name: "Total Claim Amount:",
          value: this.decimalConverter(this.getMedicineTreatmentCopy("Total Claim Amount"), 'SG')
        });
      }
      personalAccidentData.push(
        {
          name: "Is the Patient a US citizen:",
          value: this.getDetail("isUSCitizen"),
        },
        {
          name: "",
          value: "",
        },
        {
          name: "Daily Hospital Income",
          value: "",
        },
        {
          name: "Was the patient hospitalised:",
          value: this.getDetail("admittedHospital"),
        }
      );

      if (this.getDetail("admittedHospital") === "Yes") {
        personalAccidentData.push(...this.getDetail("treatmentList", "icor"), {
          name: "Was the patient admitted to the ICU (Intensive Care Unit)?:",
          value: this.getDetail("admittedIcu"),
        });
        if (this.getDetail("admittedIcu") === "Yes") {
          personalAccidentData.push({
            name: "How many days did the patient spend in the ICU:",
            value: this.getDetail("admittedToIcuDays"),
          });
        }
      }

      personalAccidentData.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Fracture or Dislocation",
          value: "",
        },
        {
          name: "Did the patient break or fracture a bone:",
          value: this.getDetail("sustainFracture"),
        }
      );

      if (this.getDetail("sustainFracture") === "Yes") {
        personalAccidentData.push(
          ...this.getDetail("addFractureMainList", "icor")
        );
      }

      personalAccidentData.push(
        {
          name: "Did the patient dislocate a bone or joint:",
          value: this.getDetail("sustainDislocation"),
        }
      );
      if (this.getDetail("sustainDislocation") === "Yes") {
        personalAccidentData.push(
          ...this.getDetail("addDislocationMainList", "icor")
        );
      }
    }

    if (this.getDetail("leaveBenefit") === "leaveBenefit") {
      personalAccidentData.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Medical Leave Benefit",
          value: "",
        },
        ...this.getDetail("addLeaveMainList", "icor"),
        {
          name: "Was the patient employed at the time of the accident:",
          value: this.getDetail("employed"),
        },
        {
          name: "When does the patient expect to return to work:",
          value: this.getDetail("dateOfExpected", "icor"),
        }
      );
    }

    if (this.getDetail("permanentDisability") === "permanentDisability") {
      personalAccidentData.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Permanent Disablement",
          value: "",
        },
        {
          name: "Date of Disability Diagnosis:",
          value: this.getDetail("dateOfDisability", "icor"),
        },
        {
          name: "What was the patient's diagnosis:",
          value: this.getDetail("typeDisability"),
        }
      );
    }

    if (this.getDetail("accidentalDeath") === "accidentalDeath") {
      personalAccidentData.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Accidental Death of Policyholder",
          value: "",
        },
        {
          name: "Date of Death:",
          value: this.getDetail("dateOfDeath", "icor"),
        },
        {
          name: "Time of Death:",
          value: this.getDetail("localTimePersonal", "icor"),
        },
        {
          name: "What was the cause of death:",
          value: this.getDetail("causeDeath"),
        }
      );
      if (this.getDetail("causeDeath") === "Others") {
        personalAccidentData.push({
          name: "Others:",
          value: this.getDetail("causeOthers"),
        });
      }
      personalAccidentData.push({
        name: "Was an autopsy performed:",
        value: this.getDetail("autopsy"),
      });
      if (this.getDetail("autopsy") === "No") {
        personalAccidentData.push({
          name: "What was the reason for not performing an autopsy:",
          value: this.getDetail("reason"),
        });
      }
    }

    personalAccidentData.push(
      {
        name: "",
        value: "",
      },
      {
        name: "Hospital and Doctor Information",
        value: "",
      },
      {
        name: "Do you have the hospital and doctor contact information:",
        value: this.getDetail("doctorInfo"),
      }
    );

    if (this.getDetail("doctorInfo") === "Yes") {
      personalAccidentData.push(...this.getDetail("addDoctorMainList", "icor"));
    }
    personalAccidentData.push(
      {
        name: "",
        value: "",
      },
      {
        name: "Witness Information",
        value: "",
      },
      {
        name: "Did anyone witness the accident:",
        value: this.getDetail("witnessInfo"),
      }
    );

    if (this.getDetail("witnessInfo") === "Yes") {
      personalAccidentData.push(
        ...this.getDetail("addWitnessMainList", "icor")
      );
    }

    return personalAccidentData;
  }

  criticalIllnessDetails() {
    const criticalIllnessData = [];

    criticalIllnessData.push(
      {
        name: "",
        value: "",
      },
      {
        name: "Illness Details",
        value: "",
      },
      {
        name: "",
        value: "",
      },
      {
        name: "In a few words, what was the illness or condition?:",
        value: this.getDetail("describeIllness"),
      },
      {
        name: "When did symptoms begin?:",
        value: this.getDetail("dateSymptom", "icor"),
      },
      {
        name: "Date of First Doctor's Visit:",
        value: this.getDetail("dateVisit", "icor"),
      },
      {
        name: "Diagnosis:",
        value: this.getDetail("diagnosisIllness"),
      }
    );
    if (this.getDetail("diagnosisIllness") === "Others") {
      criticalIllnessData.push({
        name: "Others",
        value: this.getDetail("othersIllness"),
      });
    }
    criticalIllnessData.push(
      {
        name: "Is this a Critical Illness claim?",
        value: this.getDetail("claimingIllness"),
      },
      {
        name: "",
        value: "",
      },
      {
        name: "Illness Benefits",
        value: "",
      },
      {
        name: "Which benefits are you claiming? Select all that apply:",
        value: this.getDetail("chooseClaimTypeIllness"),
      }
    );

    if (this.getDetail("dailyIncome") === "dailyIncome") {
      criticalIllnessData.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Daily Hospital Income",
          value: "",
        }
      );
      // if (this.getDetail('admittedHospitalIllness') === 'Yes') {
      criticalIllnessData.push(...this.getDetail("addIncomeMainList", "icor"), {
        name: "Was the patient admitted to the ICU (Intensive Care Unit)?:",
        value: this.getDetail("admittedIcuIllness"),
      });
      if (this.getDetail("admittedIcuIllness") === "Yes") {
        criticalIllnessData.push({
          name: "How many days did the patient spend in the ICU?:",
          value: this.getDetail("admittedToIcuIllness"),
        });
      }
      // }
    }

    if (this.getDetail("lossActivities") === "lossActivities") {
      criticalIllnessData.push(
        {
          name: "",
          value: "",
        },
        {
          name: "Loss of Activities of Daily Living",
          value: "",
        },
        {
          name: "Date of Disability Diagnosis",
          value: this.getDetail("dateOfDiagnosed", "icor"),
        },
        {
          name: "Which daily activities can the patient no longer perform due to the disability? Select all the apply:",
          value: this.getDetail("disabilityIllness"),
        }
      );
    }
    return criticalIllnessData;
  }

  paymentDetails() {
    const x = [];
    x.push(
      {
        name: "How should we send your claim payment?:",
        value: this.getDetail("paymentChannel"),
      },
      {
        name: "Full Name (Per Bank Account):",
        value:
          this.getDetail("paymentChannel") === "Electronic Bank Transfer"
            ? this.getDetail("payeeNameBank")
            : this.getDetail("payeeName"),
      }
    );
    if (this.getDetail("paymentChannel") === "Electronic Bank Transfer") {
      x.push(
        {
          name: "Bank Name/Bank Code:",
          value: this.getDetail("bankNameCode"),
        },
        {
          name: "Account Number:",
          value: this.getDetail("accountNumber"),
        }
      );
    }
    return x;
  }

  uploadedDocumentDetails() {
    const uploadedDocs = this.getDetail("uploadedDocs");

    const docObjectArray = uploadedDocs.map((elem, i) => {
      return {
        name: uploadedDocs.length === 1 ? `Upload:` : `Upload ${i + 1}:`,
        value: elem?.name,
      };
    });

    return docObjectArray || [];
  }

  getFnolRequestBody(
    type: string = "",
    policyDetailsData: any = {},
    phoneCodeListData: any = []
  ) {
    this.policyDetails = policyDetailsData;
    this.phoneCodeList = phoneCodeListData;

    const requestObj = [];
    commonSetFnolSgModel["applicationContext"]["userId"] =
      this.config.userEmailID;
    commonSetFnolSgModel["claimsDetails"]["userId"] = this.config.userEmailID;
    commonSetFnolSgModel["claimsDetails"]["claimNumber"] =
      this.fnolModelService.getClaimNumber();
    commonSetFnolSgModel["claimsDetails"]["dateofIncident"] =
      this.getDetail("dateOfAccident");

    requestObj.push(commonSetFnolSgModel);

    if (
      (type === "setFnol" || type === "finalSubmit") &&
      URL_PARAMETERS.COUNTRY.toLowerCase() ===
        FNOL_COUNTRY.SINGAPORENEW.countryCode
    ) {
      requestObj.push(this.generateFnolModelData());
    }

    if (
      type === "finalSubmit" &&
      URL_PARAMETERS.COUNTRY.toLowerCase() ===
        FNOL_COUNTRY.SINGAPORENEW.countryCode
    ) {
      requestObj.push(this.generateIcorModelData());
    }

    if (type === "finalSubmit") {
      // requestObj.push(this.generateDocUploadData())
    }

    return Object.assign({}, ...requestObj);
  }

  getFormattedDate(rawDate) {
    const insuredDOBparts = rawDate.split("/");
    return (
      insuredDOBparts[2] + "-" + insuredDOBparts[1] + "-" + insuredDOBparts[0]
    );
  }
  getGMTDateConverter(rawDate){
    var date = new Date(rawDate); 
    let month = String(date.getUTCMonth()+1);
    let days =String(date.getUTCDate());
    let hours =String(date.getUTCHours());
    let mins =String(date.getUTCMinutes());
    let secs =String(date.getUTCSeconds());
      if (month.length === 1){ month = "0" + month;}
      if(days.length===1){ days ="0" + days;}
      if(hours.length===1){ hours ="0" + hours;}  
      if(mins.length===1){ mins ="0" + mins;}
       if(secs.length===1){ secs ="0" + secs;} 

    let resultDate = date.getUTCFullYear()+''+month+''+days+'T'+hours+''+mins+''+secs+'.000 GMT'; 
    return resultDate;
  }

  dateFormatter(rawDate) {
    const hospitalDate = rawDate.split("-");
    return hospitalDate[2] + "/" + hospitalDate[1] + "/" + hospitalDate[0];
  }

  getGMTformatDate(rawDate) {
    return rawDate.replaceAll("-", "") + "T050000.000 GMT";
  }

  decimalConverter(num: string, locale: string): string {
    let convert2Num: number = parseFloat(num);
    let decimalNum = convert2Num.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    if (decimalNum.substring(decimalNum.length-3)[0] === ',') {
      let decimalNumber = decimalNum.replace(decimalNum.substring(decimalNum.length-3)[0], '.');
      return decimalNumber;
    } else {
      return decimalNum;
    }
  }

  buildFractureNDislocation(index: number) {
    const allFeatures = [];
    const fracture = this.getDetail("sustainFracture") === "Yes";

    if (fracture) {
      const f = {
        "m2:FeatureTypeCd": "Broken Bones, Burns & Dislocations/F",
        "m2:FeatureNumber": {
          "@id": true,
          $: index,
        },
        "m1:QuestionAnswer": [
          {
            "m1:QuestionText": "Did the Insured sustain a Fracture?",
            "m1:YesNoCd": fracture,
          },
        ],
      };
      f["m1:QuestionAnswer"].push(...this.getDetail("addFractureMainList"));
      allFeatures.push(f);
      index++;
    }

    const dislocation = this.getDetail("sustainDislocation") === "Yes";
    if (dislocation) {
      const d = {
        "m2:FeatureTypeCd": "Broken Bones, Burns & Dislocations/D",
        "m2:FeatureNumber": {
          "@id": true,
          $: index,
        },
        "m1:QuestionAnswer": [
          {
            "m1:QuestionText": "Did the Insured sustain a Dislocation?",
            "m1:YesNoCd": dislocation,
          },
        ],
      };
      d["m1:QuestionAnswer"].push(...this.getDetail("addDislocationMainList"));
      allFeatures.push(d);
    }

    return allFeatures;
  }

  formatFeatureTypeCd(index: number) {
    const allFeatures = [];

    const admittedHospital = this.getDetail("admittedHospital") === "Yes";
    let finalFeature = {};
    const featureType = {};
    const featureArr = [
      "westernMedicine",
      "chineseMedicine",
      "mobilityaid",
      "dentalTreatment",
    ];

    featureArr.forEach((f) => {
      featureType[f] = this.getDetail(f);
    });

    if (featureType["westernMedicine"] && featureType["dentalTreatment"]) {
      finalFeature = this.buildFinalFeature(
        "/WD",
        index,
        admittedHospital
      );
      const tempTotal = featureType["westernMedicine"] + featureType["dentalTreatment"];
      const strTotal = tempTotal.toFixed(2);
      const finalTotal = parseFloat(strTotal);
      finalFeature["m1:QuestionAnswer"].push(
        {
          "m1:QuestionText": "Date of first doctor visit",
          "m1:YesNoCd": this.getDetail("firstVisit"),
        }, 
        {
        "m1:QuestionText": "Western Medicine",
        "m1:YesNoCd": featureType["westernMedicine"],
        },
        {
        "m1:QuestionText": "Dental Treatment",
        "m1:YesNoCd": featureType["dentalTreatment"],
        },
        {
        "m1:QuestionText": "Total Claim Amount",
        "m1:YesNoCd": finalTotal,
        },
        {
          "m1:QuestionText": "Is the Insured a US citizen?",
          "m1:YesNoCd": this.getDetail("isUSCitizen"),
        },
        {
          "m1:QuestionText": "Was the Insured admitted to a hospital?",
          "m1:YesNoCd": this.getDetail("admittedHospital") === "Yes",
        });
      allFeatures.push(finalFeature);
    } else if (
      featureType["westernMedicine"] &&
      !featureType["dentalTreatment"]
    ) {
      finalFeature = this.buildFinalFeature(
        "/WM",
        index,
        admittedHospital
      );
      finalFeature["m1:QuestionAnswer"].push(
      {
        "m1:QuestionText": "Date of first doctor visit",
        "m1:YesNoCd": this.getDetail("firstVisit"),
      },
      {
        "m1:QuestionText": "Western Medicine",
        "m1:YesNoCd": featureType["westernMedicine"],
      },
      {
        "m1:QuestionText": "Total Claim Amount",
        "m1:YesNoCd": featureType["westernMedicine"],
      },
      {
        "m1:QuestionText": "Is the Insured a US citizen?",
        "m1:YesNoCd": this.getDetail("isUSCitizen"),
      },
      {
        "m1:QuestionText": "Was the Insured admitted to a hospital?",
        "m1:YesNoCd": this.getDetail("admittedHospital") === "Yes",
      });
      allFeatures.push(finalFeature);
    } else if (
      !featureType["westernMedicine"] &&
      featureType["dentalTreatment"]
    ) {
      finalFeature = this.buildFinalFeature(
        "/DT",
        index,
        admittedHospital
      );
      finalFeature["m1:QuestionAnswer"].push(
      {
        "m1:QuestionText": "Date of first doctor visit",
        "m1:YesNoCd": this.getDetail("firstVisit"),
      },
      {
        "m1:QuestionText": "Dental Treatment",
        "m1:YesNoCd": featureType["dentalTreatment"],
      },
      {
        "m1:QuestionText": "Total Claim Amount",
        "m1:YesNoCd": featureType["dentalTreatment"],
      },
      {
        "m1:QuestionText": "Is the Insured a US citizen?",
        "m1:YesNoCd": this.getDetail("isUSCitizen"),
      },
      {
        "m1:QuestionText": "Was the Insured admitted to a hospital?",
        "m1:YesNoCd": this.getDetail("admittedHospital") === "Yes",
      });
      allFeatures.push(finalFeature);
    }

    finalFeature = {};
    if (featureType["chineseMedicine"]) {
      finalFeature = this.buildFinalFeature(
        "/TCM",
        allFeatures.length + index,
        admittedHospital
      );
      finalFeature["m1:QuestionAnswer"].push(
      {
        "m1:QuestionText": "Date of first doctor visit",
        "m1:YesNoCd": this.getDetail("firstVisit"),
      },
      {
        "m1:QuestionText": "Traditional Chinese Medicine/Chriopractor",
        "m1:YesNoCd": featureType["chineseMedicine"],
      },
      {
        "m1:QuestionText": "Total Claim Amount",
        "m1:YesNoCd": featureType["chineseMedicine"],
      },
      {
        "m1:QuestionText": "Is the Insured a US citizen?",
        "m1:YesNoCd": this.getDetail("isUSCitizen"),
      },
      {
        "m1:QuestionText": "Was the Insured admitted to a hospital?",
        "m1:YesNoCd": this.getDetail("admittedHospital") === "Yes",
      });
      allFeatures.push(finalFeature);
    }

    finalFeature = {};
    if (featureType["mobilityaid"]) {
      finalFeature = this.buildFinalFeature(
        "/MA",
        allFeatures.length + index,
        admittedHospital
      );
      finalFeature["m1:QuestionAnswer"].push(
      {
        "m1:QuestionText": "Date of first doctor visit",
        "m1:YesNoCd": this.getDetail("firstVisit"),
      },
      {
        "m1:QuestionText": "Mobility Aid",
        "m1:YesNoCd": featureType["mobilityaid"],
      },
      {
        "m1:QuestionText": "Total Claim Amount",
        "m1:YesNoCd": featureType["mobilityaid"],
      },
      {
        "m1:QuestionText": "Is the Insured a US citizen?",
        "m1:YesNoCd": this.getDetail("isUSCitizen"),
      },
      {
        "m1:QuestionText": "Was the Insured admitted to a hospital?",
        "m1:YesNoCd": this.getDetail("admittedHospital") === "Yes",
      });
      allFeatures.push(finalFeature);
    }
    return allFeatures;
  }

  private buildFinalFeature(
    featureTypeCd: string,
    featureIndex: number,
    hospital: boolean
  ) {
    let title = "";
    if (hospital) {
      title = "Medical Expense Claims - Hospital & Surgical Products";
    } else {
      title = "Medical Expense Claims - Non Hospital & Surgical Products";
    }
    return {
      "m2:FeatureTypeCd": title + featureTypeCd,
      "m2:FeatureNumber": {
        "@id": true,
        $: featureIndex,
      },
      "m1:QuestionAnswer": [],
    };
  }

}
