<axis-modal #modalContact data-size="medium">
    <axis-modal-header>
        <h1>
            How can we help?
        </h1>
    </axis-modal-header>
    <axis-modal-content>
        <p>
            For assistance please contact the IntelliRisk Help Center.
            <br>For United States and Canada
            <span class="_text-bold"> 1 800 767 2524</span>
            <br>Available
            <span class="_text-bold"> Monday - Friday, 3am - 8pm EST</span>
            <br> or e-mail at
            <a class="_text-bold" href="mailto:intellirisk@aig.com">intellirisk@aig.com</a>

        </p>
    </axis-modal-content>
    <axis-modal-footer> 
        <div class="axis-btn-wrapper">
            <div class="axis-btn-wrapper-left">
            </div>
            <div class="axis-btn-wrapper-right">
                <button axis-button data-kind="link" (click)="modalContact.close()">Close</button>
            </div>
        </div>
    </axis-modal-footer>
</axis-modal>