import { tap } from "rxjs/operators";
import { Inject, Injectable, NgModule } from "@angular/core";
import { Observable } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { CacheService } from "../services/cache.service";
import { Configuration } from "../configuration";
import { Router } from "@angular/router";
import { TimerService } from "../../shared/services/timer.service";
import { OKTA_AUTH } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { URL_PARAMETERS } from "src/app/features/fnol/config/urlParameters.constant";
import { apiEndPoints } from "../services/utils/apiEndPointConstants";
import { FnolService } from "src/app/features/fnol/services/fnol.service";
import { Okta } from "../services/okta.service";
import { FnolModelService } from "src/app/features/fnol/services/fnol-model.service";
@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  callsToExclude: string[] = [
    "config.json", "config-qa.json", "/assets/",
    "smpwservices.fcc",
    "cognito/generateToken",
    "rdflookup/getenumeration",
    "registration/verifypolicy",
    "registration/submitregistration",
    "registration/getsecurityquestion",
    "registration/getuserstatus",
    "registration/validatestagingtoken",
    "registration/verifyuserid",
    "registration/sendvalidationemail",
    "registration/validatesecurityquestion",
    "registration/validateandcreateuser",
  ];

  constructor(
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth,
    private config: Configuration,
    private cacheService: CacheService,
    private router: Router,
    private okta: Okta,
    private timerService: TimerService,
    private fnolService: FnolService,
    private fnolModelService: FnolModelService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
   

    // req.headers.keys().forEach((e) => {
    //   authorizedHeaders = authorizedHeaders.set(e, req.headers.get(e));
    // });

    let dupReq;
    let avoidHeader: boolean = false;

    for (let i = 0; i < this.callsToExclude.length; i++) {
      if (avoidHeader) {
        break;
      } else {
        avoidHeader = req.url.indexOf(this.callsToExclude[i]) > -1;
      }
    }

    if (avoidHeader) {
      dupReq = req;
    } else {
      let authorizedHeaders: HttpHeaders = this.createAuthorizationHeaders(req);
      dupReq = req.clone({ headers: authorizedHeaders });
    }

    return next.handle(dupReq).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (
              (event && event.status == 403) ||
              (event && event.status == 401)
            ) {
             // this.okta.logout();
            } else {
              // store tokens
              if (
                event.headers.get("idtoken") &&
                event.headers.get("accesstoken") &&
                event.headers.get("refreshtoken")
              ) {
                this.cacheService.put(
                  this.cacheService.IDTOKEN,
                  event.headers.get("idtoken"),
                  CacheService.COOKIE_STORAGE
                );
                this.cacheService.put(
                  this.cacheService.ACCESSTOKEN,
                  event.headers.get("accesstoken"),
                  CacheService.COOKIE_STORAGE
                );
                this.cacheService.put(
                  this.cacheService.REFRESHTOKEN,
                  event.headers.get("refreshtoken"),
                  CacheService.COOKIE_STORAGE
                );
              }
              this.timerService.resetTime();
            }
          }
        },
        (error) => {
          console.log(error);
          if (
            (error && error.status == 403) ||
            (event && error.status == 401)
          ) {
           // this.okta.logout();
          }
        }
      )
    );
  }

  createAuthorizationHeaders(req) {
   const url = req.url;
   console.log('createAuthorizationHeaders: URL:', url, req.method);
    let headers;
    let country;
    const path = window.location.pathname.split("/");
    const queryParams = new URLSearchParams(window.location.search);
    if (path.length > 2) {
     country = path[2].toLocaleUpperCase();
    }
    let claimHeaderDoc = {
      "Cache-Control": "no-cache, no-store",
      Pragma: "no-cache",
      claimNumber:url.indexOf('claims')>-1?this.fnolModelService.getClaimNumber():"",
      transactionId: this.fnolService.getTransactionId()||"",
      Expires: "-1",
      // 'id-token': this.cacheService.get(this.cacheService.IDTOKEN, CacheService.COOKIE_STORAGE) ? this.cacheService.get(this.cacheService.IDTOKEN, CacheService.COOKIE_STORAGE) : '',
      Authorization:
       ((
          country === "JP" )
          && !( url.indexOf('non-auth') > -1 )
          ? "Bearer " + this.oktaAuth.getAccessToken()
          : "Basic " + this.config.authorizationKey_new)||"",
      //'refresh-token': this.cacheService.get(this.cacheService.REFRESHTOKEN, CacheService.COOKIE_STORAGE) ? this.cacheService.get(this.cacheService.REFRESHTOKEN, CacheService.COOKIE_STORAGE) : '',
      "C-Client-ID": this.config.cClientId ? this.config.cClientId : "",
      "O-Client-ID": this.config.cClientId ? this.config.cClientId : "",
      "Strict-Transport-Security": ["31536000"],
      "Access-Control-Allow-Origin": this.config.hostName ||"",
      "email-id":( (
      country === "JP" )
      && !( url.indexOf('non-auth') > -1  )
        ?  this.cacheService.getUserId()
        : this.config.userEmailID)||"",
    }
    let claimHeader = {
      ...claimHeaderDoc,
      'Content-Type': 'application/json'
    }
    if (country === 'JP' && url.indexOf('docInfo') < 0 && url.indexOf('auth/docs') > -1) {
      headers = new HttpHeaders(claimHeaderDoc);
    } else if (country !== 'JP' && url.indexOf('non-auth/docs') > -1 && url.indexOf('non-auth/docs/docInfo') === -1) {
      headers = new HttpHeaders(claimHeaderDoc);
    } else if (country !== 'JP' && url.indexOf('non-auth/docs/docInfo') > -1) {
      headers = new HttpHeaders(claimHeader);
    } else {
      headers = new HttpHeaders(claimHeader);
    }
    return headers;
  }
}
