import { Injectable } from '@angular/core';

@Injectable()
export class TimerService {

	// session timeout in minutes 
	public static CDP_SESSION_TIME: number = 89.50;

	// time to show popup in minutes 
	public static CDP_POPUP_TIME: string = "05:00";

	cdpInterval = null;
	timeRemaining: number = TimerService.CDP_SESSION_TIME * 60;

	constructor() {
	}

	public createOrResetTimer() {
		this.cdpInterval = setInterval(function () {
			//this.updateTime();
			this.timeRemaining = this.timeRemaining - 1;
			if (this.timeRemaining <= 0) {
				this.destroyTimer();
			}
		}, 1000);
	}

	public updateTime() {
		this.timeRemaining = this.timeRemaining - 1;
	}

	public resetTime() {
		this.timeRemaining = TimerService.CDP_SESSION_TIME * 60;
	}

	public getRemainingTime(format: string): string {
		let returnVal = '';
		if (format.toLowerCase() == 'mm:ss') {
			let minStr = '' + Math.floor(this.timeRemaining / 60);
			let secStr = '' + (this.timeRemaining - Math.floor(this.timeRemaining / 60) * 60)

			minStr = minStr.length == 1 ? '0' + minStr : minStr
			secStr = secStr.length == 1 ? '0' + secStr : secStr

			returnVal = minStr + ':' + secStr;
		} else {
			returnVal = '' + this.timeRemaining;
		}

		return returnVal;
	}

	public destroyTimer() {
		clearInterval(this.cdpInterval);
	}
}