import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'modal-contact',
  templateUrl: './modal-contact.component.html',
})
export class ModalContactComponent implements OnInit {
  @ViewChild('modalContact') modalContact;

  constructor(
    private router: Router
  ) { }

  ngOnInit():void {
    
  }  

  openModal(): void {
    this.modalContact.open();
  }
}

