import { Country } from "../../shared/model/country";

interface FnolSteps {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsTP {
  CLAIMANT_INFO: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsCAT {
  CLAIMANT_INFO: FnolStepsObj;
  INCIDENT_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}
interface FnolStepsJP {
  CLAIMANT_INFO: FnolStepsObj;
  CLAIM_TYPE_DETAILS: FnolStepsObj;
  PAYMENT_DETAILS: FnolStepsObj;
  UPLOAD_DOCS: FnolStepsObj;
  DECLARATION: FnolStepsObj;
}

interface FnolStepsObj {
  name: string;
  slNo: number;
  title: string;
  progressStepTitle: string;
}
interface FnolCountry{
  en_no_tp : Country;
  no_no_tp : Country;
  en_no_ah : Country;
  no_no_ah : Country;
  en_no_hlf : Country;
  no_no_hlf : Country;
}
export const NAME_DATE_LOSS = {
  AU: 'tripIncidentDate',
  US: 'dateLoss',
  SG: 'tripIncidentDate',
  NZ: 'tripIncidentDate',
};
export const FNOL_STEPS_NO_TP = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Personal Information`, progressStepTitle: `Personal Information`,containerName:"chooseInsurance",analyticsTitle:"Personal Information" },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Claim  Details`, progressStepTitle: `Claim  Details`,containerName:"tripDetails",analyticsTitle:"Claim  Details" },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 3, title: `Upload Supporting Documents`, progressStepTitle: `Upload Documents`,containerName:"uploadDocumentDetails",analyticsTitle:"Upload Documents" },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Payment Type`, progressStepTitle: `Payment Type`,containerName:"paymentDetails",analyticsTitle:"Payment Type" },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Review and Submit the Claim`, progressStepTitle: `Review & Submit`,analyticsTitle:"Review & Submit" }
};

export const FNOL_STEPS_NO_TPNO = {
    CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Personopplysninger`, progressStepTitle: `Personopplysninger`,containerName:"chooseInsurance",analyticsTitle:"Personal Information" },
    CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Skadedetaljer`, progressStepTitle: `Skadedetaljer`,containerName:"tripDetails",analyticsTitle:"Claim  Details" },
    UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 3, title: `Vennligst last opp relevant dokumentasjon for ditt krav`, progressStepTitle: `Last opp dokumenter`,containerName:"uploadDocumentDetails",analyticsTitle:"Upload Documents" },
    PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Bankdetaljer`, progressStepTitle: `Bankdetaljer`,containerName:"paymentDetails",analyticsTitle:"Payment Type" },
    DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Bekreft at informasjonen er korrekt og send inn kravet`, progressStepTitle: `Bekreft og send inn`,analyticsTitle:"Review & Submit" }
  };

  export const FNOL_STEPS_NO_HLF = {
    CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Personal Information`, progressStepTitle: `Personal Information`,containerName:"insuranceDetails",analyticsTitle: "Personal Information" },
    CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Claim  Details`, progressStepTitle: `Claim  Details`,containerName:"claimDetails", analyticsTitle: "Claim Details" },
    UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 3, title: `Upload Supporting Documents`, progressStepTitle: `Upload Documents`,containerName:"uploadDocumentDetails", analyticsTitle: "Upload Documents" },
    PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Payment Type`, progressStepTitle: `Payment Type`,containerName:"paymentDetails",analyticsTitle:"Payment Type" },
    DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Review and Submit the Claim`, progressStepTitle: `Review & Submit`,analyticsTitle:"Review & Submit" }
  };

  export const FNOL_STEPS_NO_HLFNO = {
    CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Personopplysninger`, progressStepTitle: `Personopplysninger`,containerName:"insuranceDetails",analyticsTitle: "Personal Information" },
    CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Skadedetaljer`, progressStepTitle: `Skadedetaljer`,containerName:"claimDetails", analyticsTitle: "Claim Details" },
    UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 3, title: `Vennligst last opp relevant dokumentasjon for ditt krav`, progressStepTitle: `Last opp dokumenter`,containerName:"uploadDocumentDetails", analyticsTitle: "Upload Documents" },
    PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Bankdetaljer`, progressStepTitle: `Bankdetaljer`,containerName:"paymentDetails",analyticsTitle:"Payment Type" },
    DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Bekreft at informasjonen er korrekt og send inn kravet`, progressStepTitle: `Bekreft og send inn`,analyticsTitle:"Review & Submit" }
  };

export const FNOL_STEPS_NO_AH = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Personal Information`, progressStepTitle: `Personal Information`,containerName:"chooseInsurance",analyticsTitle: "Personal Information" },
 CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Claim  Details`, progressStepTitle: `Claim Details`,containerName:"personalAccidentDetails", analyticsTitle: "Claim Details" },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 3, title: `Upload Supporting Documents`, progressStepTitle: `Upload Documents`,containerName:"uploadDocumentDetails", analyticsTitle: "Upload Documents"  },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Payment Type`, progressStepTitle: `Payment Type`,containerName:"paymentDetails",analyticsTitle:"Payment Type" },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Review and Submit the Claim`, progressStepTitle: `Review & Submit`,analyticsTitle:"Review & Submit" },
};
export const FNOL_STEPS_NO_AHNO = {
  CLAIMANT_INFO: { name: 'fnol-claimant-info', slNo: 1, title: `Personopplysninger`, progressStepTitle: `Personopplysninger`,containerName:"chooseInsurance",analyticsTitle: "Personal Information" },
  CLAIM_TYPE_DETAILS: { name: 'fnol-claimtype-details', slNo: 2, title: `Skadedetaljer`, progressStepTitle: `Skadedetaljer`,containerName:"personalAccidentDetails",analyticsTitle: "Claim Details" },
  UPLOAD_DOCS: { name: 'fnol-upload-docs', slNo: 3, title: `Vennligst last opp relevant dokumentasjon for ditt krav`, progressStepTitle: `Last opp dokumenter`,containerName:"uploadDocumentDetails",analyticsTitle: "Upload Documents" },
  PAYMENT_DETAILS: { name: 'fnol-payment-details', slNo: 4, title: `Bankdetaljer`, progressStepTitle: `Bankdetaljer`,containerName:"paymentDetails",analyticsTitle:"Payment Type" },
  DECLARATION: { name: 'fnol-declaration', slNo: 5, title: `Bekreft at informasjonen er korrekt og send inn kravet`, progressStepTitle: `Bekreft og send inn`,analyticsTitle:"Review & Submit" }
}

export const Country_Lob_Steps_Mapping = {
  en_no_tp: { steps: FNOL_STEPS_NO_TP, path: "assets/data/locale/en-no-tp/", login: "N", landingPage: true, landingImgPath: "assets/images/AH_landing_image.png" },
  no_no_tp: { steps: FNOL_STEPS_NO_TPNO, path: "assets/data/locale/no-no-tp/", login: "N", landingPage: true, landingImgPath: "assets/images/AH_landing_image.png" },
  en_no_hlf: { steps: FNOL_STEPS_NO_HLF, path: "assets/data/locale/en-no-hlf/", login: "N", landingPage: true, landingImgPath: "assets/images/AH_landing_image.png" },
  no_no_hlf: { steps: FNOL_STEPS_NO_HLFNO, path: "assets/data/locale/no-no-hlf/", login: "N", landingPage: true, landingImgPath: "assets/images/AH_landing_image.png" },
  en_no_ah: { steps: FNOL_STEPS_NO_AH, path: "assets/data/locale/en-no-ah/", login: "N", landingPage: true, landingImgPath: "assets/images/AH_landing_image.png" },
  no_no_ah: { steps: FNOL_STEPS_NO_AHNO, path: "assets/data/locale/no-no-ah/", login: "N", landingPage: true, landingImgPath: "assets/images/AH_landing_image.png" }
}
export const NORDICS_FNOL_COUNTRY: FnolCountry = {
  en_no_tp: { countryCode: 'no', callingCode: '+47', currencyCode: 'SGD', currencyDescription: 'Singapore Dollar', language: 'en' },
  no_no_tp: { countryCode: 'no', callingCode: '+47', currencyCode: 'SGD', currencyDescription: 'Singapore Dollar', language: 'en' },
  en_no_ah: { countryCode: 'no', callingCode: '+47', currencyCode: 'NOK', currencyDescription: 'Norwegian Krone', language: 'en' },
  no_no_ah: { countryCode: 'no', callingCode: '+47', currencyCode: 'NOK', currencyDescription: 'Norwegian Krone', language: 'en' },
  en_no_hlf: { countryCode: 'no', callingCode: '+47', currencyCode: 'NOK', currencyDescription: 'Norwegian Krone', language: 'en' },
  no_no_hlf: { countryCode: 'no', callingCode: '+47', currencyCode: 'NOK', currencyDescription: 'Norwegian Krone', language: 'en' },

};
export const UPLOADTEXT = {
  en: {
    uploadText: `<p>Drag & drop files here</p>
                    <p> or </p>
        <button axis-button data-kind="link"><span class="axis-btn-icon-text">Browse Files</span></button>`
  },
  no: {
    uploadText: `<p>Dra og slipp filer her</p>
                    <p> eller </p>
        <button axis-button data-kind="link"><span class="axis-btn-icon-text">Last opp</span></button>`
  }
}

export const PHONENUMBERDETAILS={
  no:{code :"+47",src:"assets/images/flags/no.svg"}
}
export const ButtonContinueData = {
  en_no_tp: {
    step1: { containerToSelect: "insuranceDetails", btnLabel: "Continue to Personal Details", nextStepName: FNOL_STEPS_NO_AH.CLAIMANT_INFO.name, type: "static", prevStepContainer: "" },
    insuranceDetails: { containerToSelect: "personalDetails", btnLabel: "Continue to Trip Details", nextStepName: FNOL_STEPS_NO_AH.CLAIMANT_INFO.name, type: "static", prevStepContainer: "step1" },
    personalDetails: { containerToSelect: "tripDetails", btnLabel: "Continue to Incident Details", nextStepName: FNOL_STEPS_NO_AH.CLAIM_TYPE_DETAILS.name, type: "static", prevStepContainer: "insuranceDetails" },
    // tripDetails:{containerToSelect:"baggaeDamageDetails",btnLabel:"Continue to Incident Details",nextStepName:FNOL_STEPS_NO_AH.CLAIM_TYPE_DETAILS.name,type:"static"},
    //Need to add typese;ectedDetails uidata key in step2 for the containers and need to add displayIf
    //Based on loss selected from landing we will prepare below two object by using the landing selected data
    //loss type>1 will push instead of cliam amount btnLabel will add next loss type container name and label to supplements
    //at the end loss type after supplement obj last obj will be amount button label obj and nextstepname would be payment
    // baggaeDelayLossDetails:{containerToSelect:"baggaeDelayLossDetails",btnLabel:"Continue to Supplemental Questions Details",nextStepName:FNOL_STEPS_NO_AH.CLAIM_TYPE_DETAILS.name,type:"dynamic"},
    // baggaeDelaySupplementalDetails:{containerToSelect:"paymentdeatils",btnLabel:"Continue to Claim Amount Details",nextStepName:FNOL_STEPS_NO_AH.PAYMENT_DETAILS.name},
  }
}

export const CUSTOMERRORMESSAGE = {
  scheArrivalTime: $localize`Invalid Input`,
  actualArrivalTime: $localize`Invalid Input`,
  incidentDate: $localize`Date of Incident cannot be greater than current date`,
  incidentTime: $localize`Invalid Input`,
  startDate: $localize`Start Date cannot  be greater than the return Date`,
  BagLostTime: $localize`Invalid Input`,
  BagLostDate: $localize`Baggage lost Date cannot be less than the bagged delayed Date`,

  scheArrivalTimeNor: $localize`Ugyldig inndata`,
  actualArrivalTimeNor: $localize`Ugyldig inndata`,
  incidentDateNor: $localize`Dato for Hendelsen kan ikke være etter dagens dato`,
  incidentTimeNor: $localize`Ugyldig inndata`,
  startDateNor: $localize`Startdato kan ikke være etter sluttdato`,
  BagLostTimeNor: $localize`Ugyldig inndata`,
  BagLostDateNor: $localize`Bagasje tapt dato kan ikke før bagasjeforsinkelsen dato`,

};


export const lossTypeDocumentsList = {
  en_no_tp:{
  damage: [{ name: "Travel Documents", id: 1 },
  { name: "Damage Report (Confirmation from Airline)", id: 2 },
  { name: "Repair Report for Damaged Items", id: 3 },
  { name: "Receipts", id: 4 },
  { name: "Other documents to support your claim", id: 5 },
  ],
  cancel: [{ name: "Travel Documents", id: 1 },
  { name: "Confirmation of cancellation/refund", id: 6 },
  { name: "Doctor's certificate", id: 7 },
  { name: "The refunded amount of taxes and fees for unused flight tickets", id: 8 },
  { name: "Other documents to support your claim", id: 5 }
  ],
  rental: [
  { name: "Car rental contract/agreement", id: 9 },
  { name: "Evidence of incurred deductible", id: 10 },
  { name: "Damage Report", id: 11 },
  { name: "Other documents to support your claim", id: 5 },
  ],
  airline: [{ name: "Travel Documents", id: 1 },
  { name: "Property Irregularity Report (PIR)", id: 12 },
  { name: "Receipts for purchase of necessary clothes and toiletries", id: 13 },
  { name: "Other documents to support your claim", id: 5 },
  ],
  delay: [{ name: "Travel Documents", id: 1 },
  { name: "Documentation from the airline/tour operator confirming the cause and duration of the delay", id: 14 },
  { name: "Receipts for additional costs incurred", id: 15 },
  { name: "Other documents to support your claim", id: 5 },
  ],
  travel: [{ name: "Travel Documents", id: 1 },
  { name: "Medical Documents", id: 16 },
  { name: "Receipts for Medical Expenses", id: 17 },
  { name: "Other documents to support your claim", id: 5 },
  ],
  theft: [{ name: "Travel Documents", id: 1 },
  { name: "Police Notification/Report", id: 18 },
  { name: "Receipts for missing items", id: 19 },
  { name: "Other documents to support your claim", id: 5 },
  ]
},
no_no_tp:{
  damage: [{ name: "Reisedokumenter",  cmDocType: "Travel Documents", id: 1 },
  { name: "Skaderapport (bekreftelse fra flyselskap)", cmDocType: "Damage Report (Confirmation from Airline)",id: 2 },
  { name: "Reparasjonsrapport for skadede gjenstander",cmDocType: "Repair Report for Damaged Items", id: 3 },
  { name: "Kvitteringer", cmDocType: "Receipts",id: 4 },
  { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType: "Other documents to support your claim",id: 5 },
  ],
  cancel: [{ name: "Reisedokumenter", cmDocType: "Travel Documents", id: 1 },
  { name: "Bekreftelse på kansellering/refusjon",cmDocType: "Confirmation of cancellation/refund", id: 6 },
  { name: "Legeattest", cmDocType: "Doctor's certificate",id: 7 },
  { name: "Refundert beløp for skatter og avgifter på ubenyttede flybilletter",cmDocType: "The refunded amount of taxes and fees for unused flight tickets", id: 8 },
  { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType: "Other documents to support your claim", id: 5 },
  ],
  rental: [{ name: "Leiebilkontrakt/avtale",  cmDocType: "Car rental contract/agreement", id: 9 },
  { name: "Faktura for betalt egenandel",cmDocType: "Evidence of incurred deductible",  id: 10 },
  { name: "Skaderapport", cmDocType: "Damage Report",id: 11 },
  { name: "Annen dokumentasjon tilhørende din skadesak",cmDocType: "Other documents to support your claim", id: 5 },
  ],
  airline: [{ name: "Reisedokumenter",cmDocType: "Travel Documents", id: 1 },
  { name: "Property Irregularity Report (PIR)", cmDocType: "Property Irregularity Report (PIR)", id: 12 },
  { name: "Kvitteringer for kjøp av nødvendige klær og toalettsaker", cmDocType: "Receipts for purchase of necessary clothes and toiletries",  id: 13 },
  { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType: "Other documents to support your claim", id: 5 },
  ],
  delay: [{ name: "Reisedokumenter", cmDocType: "Travel Documents", id: 1 },
  { name: "Dokumentasjon fra flyselskap/turarrangør som bekrefter forsinkelsens årsak og varighet",cmDocType: "Documentation from the airline/tour operator confirming the cause and duration of the delay",  id: 14 },
  { name: "Kvitteringer for påløpte merkostnader", cmDocType: "Receipts for additional costs incurred", id: 15 },
  { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType: "Other documents to support your claim", id: 5 },
  ],
  travel: [{ name: "Reisedokumenter", cmDocType: "Travel Documents", id: 1 },
  { name: "Medisinske dokumenter", cmDocType: "Medical Documents", id: 16 },
  { name: "Kvitteringer for medisinske utgifter", cmDocType: "Receipts for Medical Expenses", id: 17},
  { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType: "Other documents to support your claim", id: 5 },
  ],
  theft: [{ name: "Reisedokumenter", cmDocType: "Travel Documents", id: 1 },
  { name: "Politanmeldelse/rapport",cmDocType: "Police Notification/Report", id: 18 },
  { name: "Kvitteringer for tapte eiendeler",cmDocType: "Receipts for missing items", id: 19 },
  { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType: "Other documents to support your claim", id: 5 },
  ]
},
  en_no_hlf:{
    lost: [{ name: "Copy of the receipt for the replacement hearing aid",  id: 1 },
    { name: "Copy of documentation on hearing aid allocation from NAV/Folketrygden or the decision letter from NAV/Folketrygden", id: 2 },
    { name: "Other documents to support your claim", id: 3 }
    ],
    damaged: [{ name: "Copy of the receipt for the replacement hearing aid", id: 1 },
    { name: "Copy of documentation on hearing aid allocation from NAV/Folketrygden or the decision letter from NAV/Folketrygden", id: 6 },
    { name: "Damage Report if the hearing aid cannot be repaired", id: 7 },
    { name: "Other documents to support your claim", id: 3 }
    ],
    stolen: [{ name: "Copy of the receipt for the replacement hearing aid", id: 9 },
    { name: "Copy of documentation on hearing aid allocation from NAV/Folketrygden or the decision letter from NAV/Folketrygden", id: 10 },
    { name: "Police report in case of theft", id: 11 },
    { name: "Other documents to support your claim", id: 3 }
    ]
  },
  no_no_hlf:{
    lost: [{ name: "Kopi av faktura/kvittering som viser kjøp av nytt høreapparat",cmDocType: "Copy of the receipt for the replacement hearing aid",  id: 1 },
    { name: "Kopi av dokumentasjon på tildeling av høreapparat fra NAV/Folketrygden eller vedtaksbrevet fra NAV/Folketrygden", cmDocType: "Copy of documentation on hearing aid allocation from NAV/Folketrygden or the decision letter from NAV/Folketrygden", id: 2 },
    { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType: "Other documents to support your claim", id: 3 }
    ],
    damaged: [{ name: "Kopi av faktura/kvittering som viser kjøp av nytt høreapparat", cmDocType: "Copy of the receipt for the replacement hearing aid", id: 1 },
    { name: "Kopi av dokumentasjon på tildeling av høreapparat fra NAV/Folketrygden eller vedtaksbrevet fra NAV/Folketrygden", cmDocType:"Copy of documentation on hearing aid allocation from NAV/Folketrygden or the decision letter from NAV/Folketrygden", id: 6 },
    { name: "Bekreftelse fra leverandør dersom høreapparatet ikke kan repareres", cmDocType:"Damage Report if the hearing aid cannot be repaired", id: 7 },
    { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType:"Other documents to support your claim", id: 3 }
    ],
    stolen: [{ name: "Kopi av faktura/kvittering som viser kjøp av nytt høreapparat", cmDocType:"Copy of the receipt for the replacement hearing aid", id: 9 },
    { name: "Kopi av dokumentasjon på tildeling av høreapparat fra NAV/Folketrygden eller vedtaksbrevet fra NAV/Folketrygden", cmDocType:"Copy of documentation on hearing aid allocation from NAV/Folketrygden or the decision letter from NAV/Folketrygden", id: 10 },
    { name: "Politianmeldelse ved tyveri", cmDocType:"Police report in case of theft", id: 11 },
    { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType:"Other documents to support your claim", id: 3 }
    ]
  },
  en_no_ah:{
    accidentalDeath: [{ name: "Emergency room report or other medical information", id: 1 },
    { name: "Other documents to support your claim", id: 7 }
    ],
    personalAccident: [{ name: "Medical records/discharge summary", id: 2 },
    { name: "Receipts for medical expenses", id: 3 },
    { name: "Other documents to support your claim", id: 5 }
    ],
    criticalIllness: [{ name: "Medical records/discharge summary", id: 6 }
  ]
  },
  no_no_ah:{
    accidentalDeath: [{ name: "Epikrise eller annen medisinsk dokumentasjon på dødsfallet", cmDocType:"Emergency room report or other medical information",  id: 1 },
    { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType:"Other documents to support your claim", id: 7 }
    ],
    personalAccident: [{ name: "Medisinsk dokumentasjon i form av epikrise, journaler eller lignende", cmDocType:"Medical records/discharge summary", id: 2 },
    { name: "Kvitteringer for medisinsk behandling", cmDocType:"Receipts for medical expenses", id: 3 },
    { name: "Annen dokumentasjon tilhørende din skadesak", cmDocType:"Other documents to support your claim", id: 5 }
    ],
    criticalIllness: [{ name: "Medisinsk dokumentasjon i form av epikrise, journaler eller lignende", cmDocType:"Medical records/discharge summary", id: 6 }
  ]
  }
}

export const PHONELIST=[
  {
      "countryCode": "AD",
      "label": "+376",
      "src": "assets/images/flags/ad.svg"
  },
  {
      "countryCode": "AE",
      "label": "+971",
      "src": "assets/images/flags/ae.svg"
  },
  {
      "countryCode": "AF",
      "label": "+93",
      "src": "assets/images/flags/af.svg"
  },
  {
      "countryCode": "AG",
      "label": "+1268",
      "src": "assets/images/flags/ag.svg"
  },
  {
      "countryCode": "AI",
      "label": "+1264",
      "src": "assets/images/flags/ai.svg"
  },
  {
      "countryCode": "AL",
      "label": "+355",
      "src": "assets/images/flags/al.svg"
  },
  {
      "countryCode": "AM",
      "label": "+374",
      "src": "assets/images/flags/am.svg"
  },
  {
      "countryCode": "AO",
      "label": "+244",
      "src": "assets/images/flags/ao.svg"
  },
  {
      "countryCode": "AQ",
      "label": "+672",
      "src": "assets/images/flags/aq.svg"
  },
  {
      "countryCode": "AR",
      "label": "+54",
      "src": "assets/images/flags/ar.svg"
  },
  {
      "countryCode": "AS",
      "label": "+1684",
      "src": "assets/images/flags/as.svg"
  },
  {
      "countryCode": "AT",
      "label": "+43",
      "src": "assets/images/flags/at.svg"
  },
  {
      "countryCode": "AU",
      "label": "+61",
      "src": "assets/images/flags/au.svg"
  },
  {
      "countryCode": "AW",
      "label": "+297",
      "src": "assets/images/flags/aw.svg"
  },
  {
      "countryCode": "AX",
      "label": "+358",
      "src": "assets/images/flags/ax.svg"
  },
  {
      "countryCode": "AZ",
      "label": "+994",
      "src": "assets/images/flags/az.svg"
  },
  {
      "countryCode": "BA",
      "label": "+387",
      "src": "assets/images/flags/ba.svg"
  },
  {
      "countryCode": "BB",
      "label": "+1246",
      "src": "assets/images/flags/bb.svg"
  },
  {
      "countryCode": "BD",
      "label": "+880",
      "src": "assets/images/flags/bd.svg"
  },
  {
      "countryCode": "BE",
      "label": "+32",
      "src": "assets/images/flags/be.svg"
  },
  {
      "countryCode": "BF",
      "label": "+226",
      "src": "assets/images/flags/bf.svg"
  },
  {
      "countryCode": "BG",
      "label": "+359",
      "src": "assets/images/flags/bg.svg"
  },
  {
      "countryCode": "BH",
      "label": "+973",
      "src": "assets/images/flags/bh.svg"
  },
  {
      "countryCode": "BI",
      "label": "+257",
      "src": "assets/images/flags/bi.svg"
  },
  {
      "countryCode": "BJ",
      "label": "+229",
      "src": "assets/images/flags/bj.svg"
  },
  {
      "countryCode": "BL",
      "label": "+590",
      "src": "assets/images/flags/bl.svg"
  },
  {
      "countryCode": "BM",
      "label": "+1441",
      "src": "assets/images/flags/bm.svg"
  },
  {
      "countryCode": "BN",
      "label": "+673",
      "src": "assets/images/flags/bn.svg"
  },
  {
      "countryCode": "BO",
      "label": "+591",
      "src": "assets/images/flags/bo.svg"
  },
  {
      "countryCode": "BR",
      "label": "+55",
      "src": "assets/images/flags/br.svg"
  },
  {
      "countryCode": "BS",
      "label": "+1242",
      "src": "assets/images/flags/bs.svg"
  },
  {
      "countryCode": "BT",
      "label": "+975",
      "src": "assets/images/flags/bt.svg"
  },
  // {
  //     "countryCode": "BV",
  //     "label": "+47",
  //     "src": "assets/images/flags/bv.svg"
  // },
  {
      "countryCode": "BW",
      "label": "+267",
      "src": "assets/images/flags/bw.svg"
  },
  {
      "countryCode": "BY",
      "label": "+375",
      "src": "assets/images/flags/by.svg"
  },
  {
      "countryCode": "BZ",
      "label": "+501",
      "src": "assets/images/flags/bz.svg"
  },
  {
      "countryCode": "CA",
      "label": "+1",
      "src": "assets/images/flags/ca.svg"
  },
  {
      "countryCode": "CC",
      "label": "+61",
      "src": "assets/images/flags/cc.svg"
  },
  {
      "countryCode": "CD",
      "label": "+243",
      "src": "assets/images/flags/cd.svg"
  },
  {
      "countryCode": "CF",
      "label": "+236",
      "src": "assets/images/flags/cf.svg"
  },
  {
      "countryCode": "CG",
      "label": "+242",
      "src": "assets/images/flags/cg.svg"
  },
  {
      "countryCode": "CH",
      "label": "+41",
      "src": "assets/images/flags/ch.svg"
  },
  {
      "countryCode": "CI",
      "label": "+225",
      "src": "assets/images/flags/ci.svg"
  },
  {
      "countryCode": "CK",
      "label": "+682",
      "src": "assets/images/flags/ck.svg"
  },
  {
      "countryCode": "CL",
      "label": "+56",
      "src": "assets/images/flags/cl.svg"
  },
  {
      "countryCode": "CM",
      "label": "+237",
      "src": "assets/images/flags/cm.svg"
  },
  {
      "countryCode": "CN",
      "label": "+86",
      "src": "assets/images/flags/cn.svg"
  },
  {
      "countryCode": "CO",
      "label": "+57",
      "src": "assets/images/flags/co.svg"
  },
  {
      "countryCode": "CR",
      "label": "+506",
      "src": "assets/images/flags/cr.svg"
  },
  {
      "countryCode": "CU",
      "label": "+53",
      "src": "assets/images/flags/cu.svg"
  },
  {
      "countryCode": "CV",
      "label": "+238",
      "src": "assets/images/flags/cv.svg"
  },
  {
      "countryCode": "CX",
      "label": "+61",
      "src": "assets/images/flags/cx.svg"
  },
  {
      "countryCode": "CY",
      "label": "+357",
      "src": "assets/images/flags/cy.svg"
  },
  {
      "countryCode": "CZ",
      "label": "+420",
      "src": "assets/images/flags/cz.svg"
  },
  {
      "countryCode": "DE",
      "label": "+49",
      "src": "assets/images/flags/de.svg"
  },
  {
      "countryCode": "DJ",
      "label": "+253",
      "src": "assets/images/flags/dj.svg"
  },
  {
      "countryCode": "DK",
      "label": "+45",
      "src": "assets/images/flags/dk.svg"
  },
  {
      "countryCode": "DM",
      "label": "+1767",
      "src": "assets/images/flags/dm.svg"
  },
  {
      "countryCode": "DO",
      "label": "+1809",
      "src": "assets/images/flags/do.svg"
  },
  {
      "countryCode": "DZ",
      "label": "+213",
      "src": "assets/images/flags/dz.svg"
  },
  {
      "countryCode": "EC",
      "label": "+593",
      "src": "assets/images/flags/ec.svg"
  },
  {
      "countryCode": "EE",
      "label": "+372",
      "src": "assets/images/flags/ee.svg"
  },
  {
      "countryCode": "EG",
      "label": "+20",
      "src": "assets/images/flags/eg.svg"
  },
  {
      "countryCode": "EH",
      "label": "+212",
      "src": "assets/images/flags/eh.svg"
  },
  {
      "countryCode": "ER",
      "label": "+291",
      "src": "assets/images/flags/er.svg"
  },
  {
      "countryCode": "ES",
      "label": "+34",
      "src": "assets/images/flags/es.svg"
  },
  {
      "countryCode": "ET",
      "label": "+251",
      "src": "assets/images/flags/et.svg"
  },
  {
      "countryCode": "FI",
      "label": "+358",
      "src": "assets/images/flags/fi.svg"
  },
  {
      "countryCode": "FJ",
      "label": "+679",
      "src": "assets/images/flags/fj.svg"
  },
  {
      "countryCode": "FK",
      "label": "+500",
      "src": "assets/images/flags/fk.svg"
  },
  {
      "countryCode": "FM",
      "label": "+691",
      "src": "assets/images/flags/fm.svg"
  },
  {
      "countryCode": "FO",
      "label": "+298",
      "src": "assets/images/flags/fo.svg"
  },
  {
      "countryCode": "FR",
      "label": "+33",
      "src": "assets/images/flags/fr.svg"
  },
  {
      "countryCode": "GA",
      "label": "+241",
      "src": "assets/images/flags/ga.svg"
  },
  {
      "countryCode": "GB",
      "label": "+44",
      "src": "assets/images/flags/gb.svg"
  },
  {
      "countryCode": "GD",
      "label": "+1473",
      "src": "assets/images/flags/gd.svg"
  },
  {
      "countryCode": "GE",
      "label": "+995",
      "src": "assets/images/flags/ge.svg"
  },
  {
      "countryCode": "GF",
      "label": "+594",
      "src": "assets/images/flags/gf.svg"
  },
  {
      "countryCode": "GH",
      "label": "+233",
      "src": "assets/images/flags/gh.svg"
  },
  {
      "countryCode": "GI",
      "label": "+350",
      "src": "assets/images/flags/gi.svg"
  },
  {
      "countryCode": "GL",
      "label": "+299",
      "src": "assets/images/flags/gl.svg"
  },
  {
      "countryCode": "GM",
      "label": "+220",
      "src": "assets/images/flags/gm.svg"
  },
  {
      "countryCode": "GN",
      "label": "+224",
      "src": "assets/images/flags/gn.svg"
  },
  {
      "countryCode": "GP",
      "label": "+590",
      "src": "assets/images/flags/gp.svg"
  },
  {
      "countryCode": "GQ",
      "label": "+240",
      "src": "assets/images/flags/gq.svg"
  },
  {
      "countryCode": "GR",
      "label": "+30",
      "src": "assets/images/flags/gr.svg"
  },
  {
      "countryCode": "GT",
      "label": "+502",
      "src": "assets/images/flags/gt.svg"
  },
  {
      "countryCode": "GU",
      "label": "+1671",
      "src": "assets/images/flags/gt.svg"
  },
  {
      "countryCode": "GW",
      "label": "+245",
      "src": "assets/images/flags/gw.svg"
  },
  {
      "countryCode": "GY",
      "label": "+592",
      "src": "assets/images/flags/gy.svg"
  },
  {
      "countryCode": "HK",
      "label": "+852",
      "src": "assets/images/flags/hk.svg"
  },
  {
      "countryCode": "HN",
      "label": "+504",
      "src": "assets/images/flags/hn.svg"
  },
  {
      "countryCode": "HR",
      "label": "+385",
      "src": "assets/images/flags/hr.svg"
  },
  {
      "countryCode": "HT",
      "label": "+509",
      "src": "assets/images/flags/ht.svg"
  },
  {
      "countryCode": "HU",
      "label": "+36",
      "src": "assets/images/flags/hu.svg"
  },
  {
      "countryCode": "ID",
      "label": "+62",
      "src": "assets/images/flags/id.svg"
  },
  {
      "countryCode": "IE",
      "label": "+353",
      "src": "assets/images/flags/ie.svg"
  },
  {
      "countryCode": "IL",
      "label": "+972",
      "src": "assets/images/flags/il.svg"
  },
  {
      "countryCode": "IM",
      "label": "+44",
      "src": "assets/images/flags/im.svg"
  },
  {
      "countryCode": "IN",
      "label": "+91",
      "src": "assets/images/flags/in.svg"
  },
  {
      "countryCode": "IQ",
      "label": "+964",
      "src": "assets/images/flags/iq.svg"
  },
  {
      "countryCode": "IR",
      "label": "+98",
      "src": "assets/images/flags/ir.svg"
  },
  {
      "countryCode": "IS",
      "label": "+354",
      "src": "assets/images/flags/is.svg"
  },
  {
      "countryCode": "IT",
      "label": "+39",
      "src": "assets/images/flags/it.svg"
  },
  {
      "countryCode": "JM",
      "label": "+1876",
      "src": "assets/images/flags/jm.svg"
  },
  {
      "countryCode": "JO",
      "label": "+962",
      "src": "assets/images/flags/jo.svg"
  },
  {
      "countryCode": "JP",
      "label": "+81",
      "src": "assets/images/flags/jp.svg"
  },
  {
      "countryCode": "KE",
      "label": "+254",
      "src": "assets/images/flags/ke.svg"
  },
  {
      "countryCode": "KG",
      "label": "+996",
      "src": "assets/images/flags/kg.svg"
  },
  {
      "countryCode": "KH",
      "label": "+855",
      "src": "assets/images/flags/kh.svg"
  },
  {
      "countryCode": "KI",
      "label": "+686",
      "src": "assets/images/flags/ki.svg"
  },
  {
      "countryCode": "KM",
      "label": "+269",
      "src": "assets/images/flags/km.svg"
  },
  {
      "countryCode": "KN",
      "label": "+1869",
      "src": "assets/images/flags/kn.svg"
  },
  {
      "countryCode": "KP",
      "label": "+850",
      "src": "assets/images/flags/kp.svg"
  },
  {
      "countryCode": "KR",
      "label": "+82",
      "src": "assets/images/flags/kr.svg"
  },
  {
      "countryCode": "KW",
      "label": "+965",
      "src": "assets/images/flags/kw.svg"
  },
  {
      "countryCode": "KY",
      "label": "+1345",
      "src": "assets/images/flags/ky.svg"
  },
  {
      "countryCode": "KZ",
      "label": "+7",
      "src": "assets/images/flags/kz.svg"
  },
  {
      "countryCode": "LA",
      "label": "+856",
      "src": "assets/images/flags/la.svg"
  },
  {
      "countryCode": "LB",
      "label": "+961",
      "src": "assets/images/flags/lb.svg"
  },
  {
      "countryCode": "LC",
      "label": "+1758",
      "src": "assets/images/flags/lc.svg"
  },
  {
      "countryCode": "LI",
      "label": "+423",
      "src": "assets/images/flags/li.svg"
  },
  {
      "countryCode": "LK",
      "label": "+94",
      "src": "assets/images/flags/lk.svg"
  },
  {
      "countryCode": "LR",
      "label": "+231",
      "src": "assets/images/flags/lr.svg"
  },
  {
      "countryCode": "LS",
      "label": "+266",
      "src": "assets/images/flags/ls.svg"
  },
  {
      "countryCode": "LT",
      "label": "+370",
      "src": "assets/images/flags/lt.svg"
  },
  {
      "countryCode": "LU",
      "label": "+352",
      "src": "assets/images/flags/lu.svg"
  },
  {
      "countryCode": "LV",
      "label": "+371",
      "src": "assets/images/flags/lv.svg"
  },
  {
      "countryCode": "LY",
      "label": "+218",
      "src": "assets/images/flags/ly.svg"
  },
  {
      "countryCode": "MA",
      "label": "+212",
      "src": "assets/images/flags/ma.svg"
  },
  {
      "countryCode": "MC",
      "label": "+377",
      "src": "assets/images/flags/mc.svg"
  },
  {
      "countryCode": "MD",
      "label": "+373",
      "src": "assets/images/flags/md.svg"
  },
  {
      "countryCode": "ME",
      "label": "+382",
      "src": "assets/images/flags/me.svg"
  },
  {
      "countryCode": "MF",
      "label": "+1599",
      "src": "assets/images/flags/mf.svg"
  },
  {
      "countryCode": "MG",
      "label": "+261",
      "src": "assets/images/flags/mg.svg"
  },
  {
      "countryCode": "MH",
      "label": "+692",
      "src": "assets/images/flags/mh.svg"
  },
  {
      "countryCode": "MK",
      "label": "+389",
      "src": "assets/images/flags/mk.svg"
  },
  {
      "countryCode": "ML",
      "label": "+223",
      "src": "assets/images/flags/ml.svg"
  },
  {
      "countryCode": "MM",
      "label": "+95",
      "src": "assets/images/flags/mm.svg"
  },
  {
      "countryCode": "MN",
      "label": "+976",
      "src": "assets/images/flags/mn.svg"
  },
  {
      "countryCode": "MO",
      "label": "+853",
      "src": "assets/images/flags/mo.svg"
  },
  {
      "countryCode": "MP",
      "label": "+1670",
      "src": "assets/images/flags/mp.svg"
  },
  {
      "countryCode": "MQ",
      "label": "+596",
      "src": "assets/images/flags/mq.svg"
  },
  {
      "countryCode": "MR",
      "label": "+222",
      "src": "assets/images/flags/mr.svg"
  },
  {
      "countryCode": "MS",
      "label": "+1664",
      "src": "assets/images/flags/ms.svg"
  },
  {
      "countryCode": "MT",
      "label": "+356",
      "src": "assets/images/flags/mt.svg"
  },
  {
      "countryCode": "MU",
      "label": "+230",
      "src": "assets/images/flags/mu.svg"
  },
  {
      "countryCode": "MV",
      "label": "+960",
      "src": "assets/images/flags/mv.svg"
  },
  {
      "countryCode": "MW",
      "label": "+265",
      "src": "assets/images/flags/mw.svg"
  },
  {
      "countryCode": "MX",
      "label": "+52",
      "src": "assets/images/flags/mx.svg"
  },
  {
      "countryCode": "MY",
      "label": "+60",
      "src": "assets/images/flags/my.svg"
  },
  {
      "countryCode": "MZ",
      "label": "+258",
      "src": "assets/images/flags/mz.svg"
  },
  {
      "countryCode": "NA",
      "label": "+264",
      "src": "assets/images/flags/na.svg"
  },
  {
      "countryCode": "NC",
      "label": "+687",
      "src": "assets/images/flags/nc.svg"
  },
  {
      "countryCode": "NE",
      "label": "+227",
      "src": "assets/images/flags/ne.svg"
  },
  {
      "countryCode": "NF",
      "label": "+672",
      "src": "assets/images/flags/nf.svg"
  },
  {
      "countryCode": "NG",
      "label": "+234",
      "src": "assets/images/flags/ng.svg"
  },
  {
      "countryCode": "NI",
      "label": "+505",
      "src": "assets/images/flags/ni.svg"
  },
  {
      "countryCode": "NL",
      "label": "+31",
      "src": "assets/images/flags/nl.svg"
  },
  {
      "countryCode": "NO",
      "label": "+47",
      "src": "assets/images/flags/no.svg"
  },
  {
      "countryCode": "NP",
      "label": "+977",
      "src": "assets/images/flags/np.svg"
  },
  {
      "countryCode": "NR",
      "label": "+674",
      "src": "assets/images/flags/nr.svg"
  },
  {
      "countryCode": "NU",
      "label": "+683",
      "src": "assets/images/flags/nu.svg"
  },
  {
      "countryCode": "NZ",
      "label": "+64",
      "src": "assets/images/flags/nz.svg"
  },
  {
      "countryCode": "OM",
      "label": "+968",
      "src": "assets/images/flags/om.svg"
  },
  {
      "countryCode": "PA",
      "label": "+507",
      "src": "assets/images/flags/pa.svg"
  },
  {
      "countryCode": "PE",
      "label": "+51",
      "src": "assets/images/flags/pe.svg"
  },
  {
      "countryCode": "PF",
      "label": "+689",
      "src": "assets/images/flags/pf.svg"
  },
  {
      "countryCode": "PG",
      "label": "+675",
      "src": "assets/images/flags/pg.svg"
  },
  {
      "countryCode": "PH",
      "label": "+63",
      "src": "assets/images/flags/ph.svg"
  },
  {
      "countryCode": "PK",
      "label": "+92",
      "src": "assets/images/flags/pk.svg"
  },
  {
      "countryCode": "PL",
      "label": "+48",
      "src": "assets/images/flags/pl.svg"
  },
  {
      "countryCode": "PM",
      "label": "+508",
      "src": "assets/images/flags/pm.svg"
  },
  {
      "countryCode": "PN",
      "label": "+870",
      "src": "assets/images/flags/pn.svg"
  },
  {
      "countryCode": "PR",
      "label": "+1",
      "src": "assets/images/flags/pr.svg"
  },
  {
      "countryCode": "PS",
      "label": "+970",
      "src": "assets/images/flags/ps.svg"
  },
  {
      "countryCode": "PT",
      "label": "+351",
      "src": "assets/images/flags/pt.svg"
  },
  {
      "countryCode": "PW",
      "label": "+680",
      "src": "assets/images/flags/pw.svg"
  },
  {
      "countryCode": "PY",
      "label": "+595",
      "src": "assets/images/flags/py.svg"
  },
  {
      "countryCode": "QA",
      "label": "+974",
      "src": "assets/images/flags/qa.svg"
  },
  {
      "countryCode": "RO",
      "label": "+40",
      "src": "assets/images/flags/ro.svg"
  },
  {
      "countryCode": "RS",
      "label": "+381",
      "src": "assets/images/flags/rs.svg"
  },
  {
      "countryCode": "RU",
      "label": "+7",
      "src": "assets/images/flags/ru.svg"
  },
  {
      "countryCode": "RW",
      "label": "+250",
      "src": "assets/images/flags/rw.svg"
  },
  {
      "countryCode": "SA",
      "label": "+966",
      "src": "assets/images/flags/sa.svg"
  },
  {
      "countryCode": "SB",
      "label": "+677",
      "src": "assets/images/flags/sb.svg"
  },
  {
      "countryCode": "SC",
      "label": "+248",
      "src": "assets/images/flags/sc.svg"
  },
  {
      "countryCode": "SD",
      "label": "+249",
      "src": "assets/images/flags/sd.svg"
  },
  {
      "countryCode": "SE",
      "label": "+46",
      "src": "assets/images/flags/se.svg"
  },
  {
      "countryCode": "SG",
      "label": "+65",
      "src": "assets/images/flags/sg.svg"
  },
  {
      "countryCode": "SH",
      "label": "+290",
      "src": "assets/images/flags/sh.svg"
  },
  {
      "countryCode": "SI",
      "label": "+386",
      "src": "assets/images/flags/si.svg"
  },
  // {
  //     "countryCode": "SJ",
  //     "label": "+47",
  //     "src": "assets/images/flags/sj.svg"
  // },
  {
      "countryCode": "SK",
      "label": "+421",
      "src": "assets/images/flags/sk.svg"
  },
  {
      "countryCode": "SL",
      "label": "+232",
      "src": "assets/images/flags/sl.svg"
  },
  {
      "countryCode": "SM",
      "label": "+378",
      "src": "assets/images/flags/sm.svg"
  },
  {
      "countryCode": "SN",
      "label": "+221",
      "src": "assets/images/flags/sn.svg"
  },
  {
      "countryCode": "SO",
      "label": "+252",
      "src": "assets/images/flags/so.svg"
  },
  {
      "countryCode": "SR",
      "label": "+597",
      "src": "assets/images/flags/sr.svg"
  },
  {
      "countryCode": "ST",
      "label": "+239",
      "src": "assets/images/flags/st.svg"
  },
  {
      "countryCode": "SV",
      "label": "+503",
      "src": "assets/images/flags/sv.svg"
  },
  {
      "countryCode": "SY",
      "label": "+963",
      "src": "assets/images/flags/sy.svg"
  },
  {
      "countryCode": "SZ",
      "label": "+268",
      "src": "assets/images/flags/sz.svg"
  },
  {
      "countryCode": "TC",
      "label": "+1649",
      "src": "assets/images/flags/tc.svg"
  },
  {
      "countryCode": "TD",
      "label": "+235",
      "src": "assets/images/flags/td.svg"
  },
  {
      "countryCode": "TG",
      "label": "+228",
      "src": "assets/images/flags/tg.svg"
  },
  {
      "countryCode": "TH",
      "label": "+66",
      "src": "assets/images/flags/th.svg"
  },
  {
      "countryCode": "TJ",
      "label": "+992",
      "src": "assets/images/flags/tj.svg"
  },
  {
      "countryCode": "TK",
      "label": "+690",
      "src": "assets/images/flags/tk.svg"
  },
  {
      "countryCode": "TL",
      "label": "+670",
      "src": "assets/images/flags/tl.svg"
  },
  {
      "countryCode": "TM",
      "label": "+993",
      "src": "assets/images/flags/tm.svg"
  },
  {
      "countryCode": "TN",
      "label": "+216",
      "src": "assets/images/flags/tn.svg"
  },
  {
      "countryCode": "TO",
      "label": "+676",
      "src": "assets/images/flags/to.svg"
  },
  {
      "countryCode": "TR",
      "label": "+90",
      "src": "assets/images/flags/tr.svg"
  },
  {
      "countryCode": "TT",
      "label": "+1868",
      "src": "assets/images/flags/tt.svg"
  },
  {
      "countryCode": "TV",
      "label": "+688",
      "src": "assets/images/flags/tv.svg"
  },
  {
      "countryCode": "TW",
      "label": "+886",
      "src": "assets/images/flags/tw.svg"
  },
  {
      "countryCode": "TZ",
      "label": "+255",
      "src": "assets/images/flags/tz.svg"
  },
  {
      "countryCode": "UA",
      "label": "+380",
      "src": "assets/images/flags/ua.svg"
  },
  {
      "countryCode": "UG",
      "label": "+256",
      "src": "assets/images/flags/ug.svg"
  },
  {
      "countryCode": "US",
      "label": "+1",
      "src": "assets/images/flags/us.svg"
  },
  {
      "countryCode": "UY",
      "label": "+598",
      "src": "assets/images/flags/uy.svg"
  },
  {
      "countryCode": "UZ",
      "label": "+998",
      "src": "assets/images/flags/uz.svg"
  },
  {
      "countryCode": "VA",
      "label": "+39",
      "src": "assets/images/flags/va.svg"
  },
  {
      "countryCode": "VC",
      "label": "+1784",
      "src": "assets/images/flags/vc.svg"
  },
  {
      "countryCode": "VE",
      "label": "+58",
      "src": "assets/images/flags/ve.svg"
  },
  {
      "countryCode": "VG",
      "label": "+1284",
      "src": "assets/images/flags/vg.svg"
  },
  {
      "countryCode": "VI",
      "label": "+1340",
      "src": "assets/images/flags/vi.svg"
  },
  {
      "countryCode": "VN",
      "label": "+84",
      "src": "assets/images/flags/vn.svg"
  },
  {
      "countryCode": "VU",
      "label": "+678",
      "src": "assets/images/flags/vu.svg"
  },
  {
      "countryCode": "WF",
      "label": "+681",
      "src": "assets/images/flags/wf.svg"
  },
  {
      "countryCode": "WS",
      "label": "+685",
      "src": "assets/images/flags/ws.svg"
  },
  {
      "countryCode": "YE",
      "label": "+967",
      "src": "assets/images/flags/ye.svg"
  },
  {
      "countryCode": "YT",
      "label": "+262",
      "src": "assets/images/flags/yt.svg"
  },
  {
      "countryCode": "ZA",
      "label": "+27",
      "src": "assets/images/flags/za.svg"
  },
  {
      "countryCode": "ZM",
      "label": "+260",
      "src": "assets/images/flags/zm.svg"
  },
  {
      "countryCode": "ZW",
      "label": "+263",
      "src": "assets/images/flags/zw.svg"
  }
]

export const CURRENCY=[
  {
    "label": "DKK",
    "value": "Denmark Krone"
  },
  {
    "label": "EUR",
    "value": "Euro"
  },
  {
    "label": "NOK",
    "value": "Norway Krone"
  },
  {
    "label": "SEK",
    "value": "Sweden Krona"
  },
  {
    "label": "USD",
    "value": "United States Dollar"
  },
  {
    "label": "AED",
    "value": "Uae Dirham"
  },
    {
      "label": "AFN",
      "value": "Afghanistan Afghani"
    },
    {
      "label": "ALL",
      "value": "Albanian Lek"
    },
    {
      "label": "AMD",
      "value": "Armenian Dram"
    },
    {
      "label": "ANG",
      "value": "Netherlands Antillian Guilder"
    },
    {
      "label": "AOA",
      "value": "Angola Kwanza"
    },
    {
      "label": "ARS",
      "value": "Argentina - Peso"
    },
    {
      "label": "AUD",
      "value": "Australia Dollar"
    },
    {
      "label": "AWG",
      "value": "Aruba Guilder"
    },
    {
      "label": "AZN",
      "value": "Azerbaijan New Manat"
    },
    {
      "label": "BAM",
      "value": "Bosnia & Herzegovina Convertible Mark"
    },
    {
      "label": "BBD",
      "value": "Barbados Dollar"
    },
    {
      "label": "BDT",
      "value": "Bangladesh Taka"
    },
    {
      "label": "BGN",
      "value": "Bulgarian Lev"
    },
    {
      "label": "BHD",
      "value": "Bahrain Dinar"
    },
    {
      "label": "BIF",
      "value": "Burundi Franc"
    },
    {
      "label": "BMD",
      "value": "Bermuda Dollar"
    },
    {
      "label": "BND",
      "value": "Brunei Dollar"
    },
    {
      "label": "BOB",
      "value": "Bolivia - Boliviano"
    },
    {
      "label": "BRL",
      "value": "Brazilian Real"
    },
    {
      "label": "BSD",
      "value": "Bahamas Dollar"
    },
    {
      "label": "BWP",
      "value": "Botswana Pula"
    },
    {
      "label": "BYN",
      "value": "Belarussian Ruble"
    },
    {
      "label": "BZD",
      "value": "Belize Dollar"
    },
    {
      "label": "CAD",
      "value": "Canada Dollar"
    },
    {
      "label": "CDF",
      "value": "Congolese Franc"
    },
    {
      "label": "CHF",
      "value": "Switzerland Franc"
    },
    {
      "label": "CLP",
      "value": "Chile New Peso"
    },
    {
      "label": "CNY",
      "value": "China Ren Yuan"
    },
    {
      "label": "COP",
      "value": "Colombia Peso"
    },
    {
      "label": "CRC",
      "value": "Costa Rica Colon"
    },
    {
      "label": "CUP",
      "value": "Cuban Peso"
    },
    {
      "label": "CVE",
      "value": "Cape Verde Escudo "
    },
    {
      "label": "CZK",
      "value": "Czeck Rep. Koruna"
    },
    {
      "label": "DJF",
      "value": "Djibouti Franc"
    },
    {
      "label": "DOP",
      "value": "Dominican Rep Peso"
    },
    {
      "label": "DZD",
      "value": "Algeria Dinar"
    },
    {
      "label": "EGP",
      "value": "Egypt Pound"
    },
    {
      "label": "ERN",
      "value": "Nakfa"
    },
    {
      "label": "ETB",
      "value": "Ethiopia Birr"
    },
    {
      "label": "FJD",
      "value": "Fiji Dollars"
    },
    {
      "label": "FKP",
      "value": "Falkland Islands Pound "
    },
    {
      "label": "GBP",
      "value": "Great Britain Pound"
    },
    {
      "label": "GEL",
      "value": "Georgia Lari"
    },
    {
      "label": "GHS",
      "value": "Ghana Cedi"
    },
    {
      "label": "GIP",
      "value": "Gibraltar Pound"
    },
    {
      "label": "GMD",
      "value": "Gambia Dalasi"
    },
    {
      "label": "GNF",
      "value": "Guinea Franc"
    },
    {
      "label": "GTQ",
      "value": "Guatemala Quetzal"
    },
    {
      "label": "GYD",
      "value": "Guyana Dollar"
    },
    {
      "label": "HKD",
      "value": "Hong Kong Dollar"
    },
    {
      "label": "HNL",
      "value": "Honduras Lempira"
    },
    {
      "label": "HRK",
      "value": "Croatia Kuna"
    },
    
    {
      "label": "HTG",
      "value": "Haiti Gourde"
    },
    {
      "label": "HUF",
      "value": "Hungary forint"
    },
    {
      "label": "IDR",
      "value": "Indonesia Rupiah"
    },
    {
      "label": "ILS",
      "value": "Israel Shekel"
    },
    {
      "label": "INR",
      "value": "India Rupee"
    },
    {
      "label": "IQD",
      "value": "Iraq Dinar"
    },
    {
      "label": "IRR",
      "value": "Iran Rial"
    },
    {
      "label": "ISK",
      "value": "Iceland Krona-New"
    },
    {
      "label": "JMD",
      "value": "Jamaica Dollar"
    },
    {
      "label": "JOD",
      "value": "Jordan Dinar"
    },
    {
      "label": "JPY",
      "value": "Japan Yen"
    },
    {
      "label": "KES",
      "value": "Kenya Shilling"
    },
    {
      "label": "KGS",
      "value": "Som"
    },
    {
      "label": "KHR",
      "value": "Cambodia Riel"
    },
    {
      "label": "KMF",
      "value": "Comoro Franc"
    },
    {
      "label": "KPW",
      "value": "North Korean Won "
    },
    {
      "label": "KRW",
      "value": "Korea (South) Won"
    },
    {
      "label": "KWD",
      "value": "Kuwait Dinar"
    },
    {
      "label": "KYD",
      "value": "Cayman Isles. Dollar"
    },
    {
      "label": "KZT",
      "value": "Kazakhstan Tenge"
    },
    {
      "label": "LAK",
      "value": "Kip "
    },
    {
      "label": "LBP",
      "value": "Lebanon Pound"
    },
    {
      "label": "LKR",
      "value": "Sri Lanka Rupee"
    },
    {
      "label": "LRD",
      "value": "Liberian Dollar "
    },
    {
      "label": "LSL",
      "value": "Lesotho Loti"
    },
    {
      "label": "LTL",
      "value": "Lithuania Litas"
    },
    {
      "label": "LVL",
      "value": "Latvia Lats"
    },
    {
      "label": "LYD",
      "value": "Libya Dinar"
    },
    {
      "label": "MAD",
      "value": "Morocco Dirham"
    },
    {
      "label": "MDL",
      "value": "Moldovan Leu"
    },
    {
      "label": "MGA",
      "value": "Madagascar Ariary"
    },
     
    {
      "label": "MKD",
      "value": "Denar"
    },
    {
      "label": "MMK",
      "value": "Burma Kyat"
    },
    {
      "label": "MNT",
      "value": "Mongolian Tugrik"
    },
    {
      "label": "MOP",
      "value": "Macao Pataca"
    },
    {
      "label": "MRO",
      "value": "Mauritania Ouguiya"
    },
    {
      "label": "MUR",
      "value": "Mauritius Rupee"
    },
    {
      "label": "MVR",
      "value": "Maldivian Rufiyaa"
    },
    {
      "label": "MWK",
      "value": "Malawi Kwacha"
    },
    {
      "label": "MXN",
      "value": "New Mexican Peso"
    },
    {
      "label": "MYR",
      "value": "Malaysia Ringgit"
    },
    {
      "label": "MZN",
      "value": "Mozambique New Metical"
    },
    {
      "label": "NAD",
      "value": "Namibia Dollar"
    },
    {
      "label": "NGN",
      "value": "Nigeria Naira"
    },
    {
      "label": "NIO",
      "value": "Nicaraguan Cordoba"
    },
    {
      "label": "NPR",
      "value": "Nepal Rupee"
    },
    {
      "label": "NZD",
      "value": "New Zealand Dollar"
    },
    {
      "label": "OMR",
      "value": "Oman Rial"
    },
    {
      "label": "PAB",
      "value": "Panama Balboa"
    },
    {
      "label": "PEN",
      "value": "Peru New Sol"
    },
    {
      "label": "PGK",
      "value": "Papua New G'Nea Kina"
    },
    {
      "label": "PHP",
      "value": "Philippines Peso"
    },
    {
      "label": "PKR",
      "value": "Pakistan Rupee"
    },
    {
      "label": "PLN",
      "value": "Poland New Zloty"
    },
    {
      "label": "PYG",
      "value": "Paraguay Guarani"
    },
    {
      "label": "QAR",
      "value": "Qatar Riyal"
    },
    {
      "label": "RON",
      "value": "Romania Leu New"
    },
    {
      "label": "RSD",
      "value": "Serbia Dinar"
    },
    {
      "label": "RUB",
      "value": "Russian Ruble"
    },
    {
      "label": "RWF",
      "value": "Rwanda Franc"
    },
    {
      "label": "SAR",
      "value": "Saudi Arabia Riyal"
    },
    {
      "label": "SBD",
      "value": "Solomon Islands"
    },
    {
      "label": "SCR",
      "value": "Seychelles Rupee"
    },
    {
      "label": "SDG",
      "value": "Sudanese Pound "
    },
    {
      "label": "SGD",
      "value": "Singapore Dollar"
    },
    {
      "label": "SHP",
      "value": "Saint Helena Pound "
    },
    {
      "label": "SLL",
      "value": "Sierra Leone Leone"
    },
    {
      "label": "SOS",
      "value": "Somalia Shilling"
    },
    {
      "label": "SRD",
      "value": "Surinam Dollar"
    },
    {
      "label": "STD",
      "value": "Sao Tome Dobra"
    },
    {
      "label": "SVC",
      "value": "El Salvador Colon"
    },
    {
      "label": "SYP",
      "value": "Syria Pound"
    },
    {
      "label": "SZL",
      "value": "Swaziland Emalangeni"
    },
    {
      "label": "THB",
      "value": "Thailand Baht"
    },
    {
      "label": "TJS",
      "value": "Somoni"
    },
    {
      "label": "TMT",
      "value": "Turkmenistan New Manat"
    },
    {
      "label": "TND",
      "value": "Tunisia Dinar"
    },
    {
      "label": "TOP",
      "value": "Tongan Pa'anga "
    },
    {
      "label": "TRY",
      "value": "Turkish Lire New"
    },
    {
      "label": "TTD",
      "value": "Trinidad-Tobago Dollar"
    },
    {
      "label": "TWD",
      "value": "Taiwan N.T. Dollar"
    },
    {
      "label": "TZS",
      "value": "Tanzania Shilling"
    },
    {
      "label": "UAH",
      "value": "Ukraine Hryvna"
    },
    {
      "label": "UGX",
      "value": "Uganda Shilling"
    },
    {
      "label": "UYU",
      "value": "Uruguay - New Peso"
    },
    {
      "label": "UZS",
      "value": "Uzbekistan Sum"
    },
    {
      "label": "VEF",
      "value": "Venezuela Bolivar Fuerte"
    },
    {
      "label": "VND",
      "value": "Vietnam Dong"
    },
    {
      "label": "VUV",
      "value": "Vanuatu Vatu"
    },
    {
      "label": "WST",
      "value": "Western Samoa Tala"
    },
    {
      "label": "XAF",
      "value": "Cfa Franc Beac / Senegal Franc"
    },
    {
      "label": "XCD",
      "value": "East Caribbean Dollar"
    },
    {
      "label": "XKX",
      "value": "Kosovo"
    },
    {
      "label": "XOF",
      "value": "CFA Franc BCEAO"
    },
    {
      "label": "XPF",
      "value": "CFP Franc"
    },
    {
      "label": "YER",
      "value": "Yemen Rial (Rep of )"
    },
    {
      "label": "ZAR",
      "value": "South Africa Rand"
    },
    {
      "label": "ZMW",
      "value": "Zambia Kwacha"
    },
    {
      "label": "ZWL",
      "value": "Zimbabwe Dollar"
    }

    ]



