<div class="axis-content-wrapper">
  <div class="axis-container-fluid">
    <div class="axis-grid _justify-content-center initial-empty-container">
      <div class="axis-col-md-8">
        <img
          alt=""
          class="axis-img-responsive _mlr-auto"
          src="assets/images/messages/no-data-available.svg"
        />
        <ng-container *ngIf="isJp=== true">
          <h2 data-color="primary" class="axis-h2 _text-xxlarge _text-bold">現在、サービスを停止しております</h2>
          <p>ご迷惑をお掛けして申し訳ございません。</p>
        </ng-container>

        <ng-container *ngIf="isJp !== true">
          <h2 data-color="primary" class="axis-h2 _text-xxlarge _text-bold" i18n="@@service1">Service Unavailable</h2>
          <p  i18n="@@service2">The service is currently unavailable. We apologize for the inconvenience. Please try again later.</p>
        </ng-container>
       <!-- <button
          axis-button
          data-kind="primary"
          data-size="large"
        >Close</button> -->
      </div>
    </div>
  </div>
</div>
