import { FnolService } from 'src/app/features/fnol/services/fnol.service';
import { CacheService } from 'src/app/shared/services/cache.service';
import { Component, OnInit } from '@angular/core';
import { DataStoreService } from '../../services/data-store.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ["./thank-you.component.scss"],
})
export class ThankYouComponent implements OnInit {
  public email;
  public thanks: string = 'の登録までもう少しです';
  public instructions: string = `にアカウント（ID）を有効にするメールを送信しました。
  受信したメールを開き、「アカウントの有効化」のボタンをクリックしてください。パスワードの設定画面が開きますので、ルールに従いパスワードを設定してください。`;
  public language: string = 'jp';

  constructor(private dataStoreService: DataStoreService, private cacheService: CacheService, private fnolService: FnolService) { }

  ngOnInit(): void {
    this.language = this.cacheService.get('language');
    this.email = this.dataStoreService.get("registration0").uiData['insuredMail'];
    if (this.language !== 'jp') {
      this.thanks = `${this.email}の登録までもう少しです`;
      this.instructions = `${this.email}にアカウント（ID）を有効にするメールを送信しました。
      受信したメールを開き、「アカウントの有効化」のボタンをクリックしてください。パスワードの設定画面が開きますので、ルールに従いパスワードを設定してください。`;
    }
    this.fnolService.updateAnalyticsData("Success Confirmation", "user successfully registered");
  }
}
