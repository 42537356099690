import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { loadTranslations } from '@angular/localize';
import { commonSetFnolSgModel, setFnolSgModel } from '../fnol-sg/fnol-sg-model';
import { FnolModelService } from './fnol-model.service';
import { SubmitClaimModelService } from './submit-claim-model.service';
import { Configuration } from 'src/app/shared/configuration';
import { CacheService } from 'src/app/shared/services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countrySource = new BehaviorSubject<string>('default-country');
  currentCountry = this.countrySource.asObservable();

  constructor(private httpService: HttpService,private cacheService:CacheService) { }

  setCountry(country: string): void {
    this.countrySource.next(country);
  }

  loadTranslationsData(): Promise<any> {
    const urlParams = new URLSearchParams(window.location.search);
    const locale = window.location.pathname.indexOf('nordics') > -1?  window.location.pathname.split("/")[4] : urlParams.get('language')|| this.cacheService.get('language') || 'en';
    if (locale !== 'en') {
      return new Promise((resolve, reject) => {
        this.httpService.makeHttpGetCall('/assets/i18n/' + locale + '.json').subscribe((res: any) => {
          loadTranslations(res.translations);
          $localize.locale = res.locale;
          resolve(true);
        });
      });
    }
  }


}


