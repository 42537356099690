import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { dataArray } from "../constants/fnol-norway-travel.constant";

@Injectable({
    providedIn: 'root'
  })
  export class FnolNordicsApiService {
    public lossDetails = new BehaviorSubject<any>({ name: "", value: "", containerName: "",index:""});
    public selectedLossTypeDocuments= new BehaviorSubject<any>([]);
    public placeIdsList = new BehaviorSubject<any>([]);
    public lossTypePrefillDate;
    public selectedAhType;
    public selectedHLFType;
    public lossPrefillDate: any;
    public  uiData;
    public selectedLossData;

    setLossDetails(data){
        this.lossDetails.next(data);
    }
    getLossDetails(){
      return this.lossDetails;
    }
    setLosstypeDocuments(data){
      this.selectedLossTypeDocuments.next(data);
    }
    setAddressesPlaceIDs(data){
      this.placeIdsList.next(data);
    }
    setLossTypePrefillDate(data){
      this.lossTypePrefillDate = data;
    }
    getLossTypePrefillDate(){
      return this.lossTypePrefillDate;
    }
    setSelectedAhType(data){
      this.selectedAhType=data;
    }
    getSelectedAhType(){
      return this.selectedAhType;
    }
    setSelectedHLFType(data){
      this.selectedHLFType=data;
    }
    getSelectedHLFType(){
      return this.selectedHLFType;
    }
    setLossData(data): void {
      this.selectedLossData=data;
    }
    getLossData(): Array<any> {
      return this.selectedLossData;
    }
    getLossTypeDate(uiData) {
    this.uiData = uiData
    this.lossDetails.subscribe(res => {

      res.forEach((element, index) => {
        if (index === 0 && element.name !== 'airline' && element.name !== 'travel') {

          this.lossPrefillDate = this.uiData[dataArray[element.name][0]];
        }
        if (index === 0 && element.name === 'airline') {

          if (this.uiData['typeDelayLoss'].id === '1') {
            this.lossPrefillDate = this.uiData[dataArray[element.name][0]];

          } else if (this.uiData['typeDelayLoss'].id === '2') {
            this.lossPrefillDate = this.uiData[dataArray[element.name][2]];

          } else {
            this.lossPrefillDate = this.uiData[dataArray[element.name][4]];

          }
        } else if (index === 0 && element.name === 'travel') {
          if (this.uiData['claimingExpenses'] === 'Travel Accident') {
            this.lossPrefillDate = this.uiData[dataArray[element.name][0]];

          }
          else if (this.uiData['claimingExpenses'] === 'Other') {
            this.lossPrefillDate = this.uiData['otherDate'];

          }
           else {
            this.lossPrefillDate = this.uiData[dataArray[element.name][2]];

          }
        } 
        else if (index === 0 && element.name === 'cancel') {
          if (this.uiData['cancelType'] === 'Own Accident or Illness') {
            this.lossPrefillDate = this.uiData[dataArray[element.name][0]];

          }
          else if (this.uiData['cancelType'] === 'Other Cause') {
            this.lossPrefillDate = this.uiData[dataArray[element.name][2]];

          }
           else {
            this.lossPrefillDate = this.uiData[dataArray[element.name][1]];

          }
        }
        else {
          this.lossPrefillDate = this.uiData[dataArray[element.name][0]];

        }

      });
    })
    return this.lossPrefillDate;
  } 
  }
