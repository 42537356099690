import { Injectable } from "@angular/core";
import { submitClaim } from "../shared/model/submit-claim";
import * as moment from "moment";
import { DataStoreService } from "../services/data-store.service";
import { FnolModelService } from "../services/fnol-model.service";
import { APPLICATION_ID, FNOL_STEPS_JP } from "../config/fnol-constants";
import { CommonUtils } from "../shared/utils/common-utils";
import { CacheService } from "./../../../shared/services/cache.service";
import { FnolService } from "../services/fnol.service";
import {
  accountPolicy,
  accountPolicyJP,
  Prefacture,
} from "../config/fnol-jp-constants";
import { Utils } from "axis-ui-generator";
import { AppConfig } from "src/app/shared/config";
import { URL_PARAMETERS } from "../config/urlParameters.constant";
import { commonSetFnolSgModel } from "../fnol-sg/fnol-sg-model";
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root",
})
export class FnolJapanModelService {
  claimNumberGenerated: string;
  isLanguageJp = this.CacheService.get("language") === "jp" ? true : false;
  policyDetails: any;
  phoneCodeList: any;
  constructor(
    private datastoreservice: DataStoreService,
    private fnolModelService: FnolModelService,
    private CacheService: CacheService,
    private fnolService: FnolService,
    private router: Router
  ) { }

  public getSubmitClaimModelData(pdfGenerationData): any {
    let allStepData = [];
    Object.keys(FNOL_STEPS_JP).map((val) => {
      if (val !== "DECLARATION") {
        allStepData.push(this.datastoreservice.get(FNOL_STEPS_JP[val].name));
      }
    });
    const parameters = new URLSearchParams(window.location.search);
    this.removeEmptyArrays(submitClaim);
    submitClaim.applicationContext.userId = this.CacheService.getUserId();
    submitClaim.claimsDetails.userFilledData = {
      claimant: {...this.datastoreservice.get(FNOL_STEPS_JP.CLAIMANT_INFO.name).uiData},
      claimType: {...this.datastoreservice.get(FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name).uiData},
      setp2UiStructure: this.datastoreservice.get(FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name).uiStructure
    }
    /* icorr related fields */
    submitClaim["claimsDetails"]["submission"].pwd =
      this.CacheService.get("PWD") === "Y" ? { name: 'Yes', code: '1' } : { name: 'No', code: '2' };
    submitClaim["claimsDetails"]["followUpClaimNo"] = parameters.get('followup') ? this.CacheService.getClaimNumber() : null;
    submitClaim["Icorr"][
      "iCorrespondence"
    ].correspondenceItem.correspondenceGenerationData.claimantEmail =
      allStepData[0].uiData.email;
    submitClaim["Icorr"][
      "iCorrespondence"
    ].correspondenceItem.correspondenceGenerationData.brokerEmail =
      "qa_cdp_globaldelivery@aig.com";
    submitClaim["Icorr"][
      "iCorrespondence"
    ].correspondenceItem.correspondenceGenerationData.reportedByFirstName =
      allStepData[0].uiData.firstNameKanjiClaim;
    submitClaim["Icorr"][
      "iCorrespondence"
    ].correspondenceItem.correspondenceGenerationData.reportedByLastName =
      allStepData[0].uiData.lastNameKanjiClaim;
    submitClaim["Icorr"][
      "iCorrespondence"
    ].correspondenceItem.correspondenceGenerationData.aigEmail = AppConfig
      .aigSharedeMailID["jp"]
        ? AppConfig.aigSharedeMailID["jp"]
        : AppConfig.aigSharedeMailID;
    submitClaim["Icorr"]["iCorrespondence"]["requestHeader"]["userID"] =
      allStepData[0].uiData.email;
    submitClaim["Icorr"]["iCorrespondence"]["requestHeader"][
      "requestMessageID"
    ] = parameters.get('followup') ? this.CacheService.getFollowUpClaimNumber() : this.CacheService.getClaimNumber();
    submitClaim["Icorr"]["iCorrespondence"]["requestHeader"]["systemDate"] =
      moment(new Date()).format("YYYYMMDD");
    submitClaim["Icorr"]["iCorrespondence"]["correspondenceItem"][
      "pdfGenerationData"
    ]["claimDetails"]["policydetailsJapan"]["info"] = this.fnolService.getIsValidated() ? this.getValidatedGridData(
      allStepData[0].uiData.gridData) : this.getGridData(allStepData[0].uiData.gridData);
    submitClaim["Icorr"]["iCorrespondence"]["correspondenceItem"][
      "pdfGenerationData"
    ]["claimDetails"]["claimID"] = parameters.get('followup') ? this.CacheService.getFollowUpClaimNumber() : this.CacheService.getClaimNumber();
    submitClaim["Icorr"]["iCorrespondence"]["correspondenceItem"][
      "correspondenceGenerationData"
    ]["lossDate"] = CommonUtils.convertDateGMT(
      allStepData[0].uiData.accidentDate,
      "YYYYMMDD"
    );
    submitClaim["Icorr"]["iCorrespondence"]["correspondenceItem"][
      "pdfGenerationData"
    ]["claimDetails"]["cdpDetails"]["info"] = pdfGenerationData;

    /* generic data fills */
    submitClaim["claimsDetails"]["submission"]["submissionDate"] = moment(
      new Date()
    ).format("YYYY-MM-DD");
    submitClaim["claimsDetails"]["submission"]["submissionTime"] = moment(
      new Date().getTime()
    ).format("HH:mm:ss");

    /* policy and claimant info details step */
    submitClaim["claimsDetails"]["incidentClaimInformation"]["dateofIncident"] =
      moment(allStepData[0].uiData.accidentDate).format("YYYY-MM-DD");
    submitClaim["claimsDetails"]["incidentClaimInformation"]["timeofIncident"] =
      moment(allStepData[0].uiData.accidentTime).format("HH:mm:ss");
    submitClaim["claimsDetails"]["incidentClaimInformation"]["lossLocation"] = {
      name: allStepData[0].uiData.lossLocation.name,
      code: allStepData[0].uiData.lossLocation.code,
    };

    submitClaim["claimsDetails"]["policyCoverage"] = this.fnolService.getIsValidated() ? this.gridRequestMapping(
      allStepData[0].uiData.gridData) : [];

    submitClaim["claimsDetails"]["policySearch"] = (!this.fnolService.getIsValidated()) ? this.getGridPolicyData(
      allStepData[0].uiData.gridData
      , moment(allStepData[0].uiData.accidentDate).format("YYYY-MM-DD")) : [];


    submitClaim["claimsDetails"]["insuredInformation"]["insuredLastNameKanji"] =
      allStepData[0].uiData.lastNameKanji;
    submitClaim["claimsDetails"]["insuredInformation"][
      "insuredFirstNameKanji"
    ] = allStepData[0].uiData.firstNameKanji;
    submitClaim["claimsDetails"]["insuredInformation"][
      "insuredLastNameKatakana"
    ] = allStepData[0].uiData.lastNameKatakana;
    submitClaim["claimsDetails"]["insuredInformation"][
      "InsuredFirstNameKatakana"
    ] = allStepData[0].uiData.firstNameKatakana;
    submitClaim["claimsDetails"]["insuredInformation"]["gender"] = {
      name: "Male",
      code: "1",
    };
    // submitClaim["claimsDetails"]["insuredInformation"]["gender"] = {
    //   name: allStepData[0].uiData.gender,
    //   code:
    //     allStepData[0].uiData.gender === "Male" ||
    //     allStepData[0].uiData.gender === "男性"
    //       ? "1"
    //       : "2",
    // };
    submitClaim["claimsDetails"]["insuredInformation"]["insuredDOB"] = moment(
      allStepData[0].uiData.dob
    ).format("YYYY-MM-DD");

    submitClaim["claimsDetails"]["claimantInformation"]["lastNameKanji"] =
      allStepData[0].uiData.lastNameKanjiClaim;
    submitClaim["claimsDetails"]["claimantInformation"]["firstNameKanji"] =
      allStepData[0].uiData.firstNameKanjiClaim;
    submitClaim["claimsDetails"]["claimantInformation"]["lastNameKatakana"] =
      allStepData[0].uiData.lastNameKatakanaClaim;
    submitClaim["claimsDetails"]["claimantInformation"]["firstNameKatakana"] =
      allStepData[0].uiData.firstNameKatakanaClaim;
    submitClaim["claimsDetails"]["claimantInformation"]["claimNumber"] =
      parameters.get('followup') ? this.CacheService.getFollowUpClaimNumber() : this.CacheService.getClaimNumber();
    submitClaim["claimsDetails"]["claimantInformation"][
      "relationshipClaimantInsured"
    ] = {
      name: allStepData[0].uiData.relationshipInsured.name,
      code: allStepData[0].uiData.relationshipInsured.id,
    };
    submitClaim["claimsDetails"]["claimantInformation"]["relationshipOthers"] =
      allStepData[0].uiData.insuredOthers
        ? allStepData[0].uiData.insuredOthers
        : "";
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["postalCode"] = {
      name: allStepData[0].uiData.postalCode
      , code: allStepData[0].uiData.postalCode.indexOf("-") > -1 ? allStepData[0].uiData.postalCode.split("-")[0] : typeof allStepData[0].uiData.postalCode=== "string" ? allStepData[0].uiData.postalCode.substring(0, 3) : allStepData[0].uiData.postalCode.substring(0, 3)
    }
    // allStepData[0].uiData.postalCode
    //   .substr(0, 8)
    //   .split("-")[0];
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["zipCode"] = {
      name: allStepData[0].uiData.postalCode
      , code: allStepData[0].uiData.postalCode.indexOf("-") > -1 ? allStepData[0].uiData.postalCode.split("-")[1] : typeof allStepData[0].uiData.postalCode === "string" ? allStepData[0].uiData.postalCode.substring(3) : allStepData[0].uiData.postalCode.substring(3)
    }
    // allStepData[0].uiData.postalCode
    //   .substr(0, 8)
    //   .split("-")[1];
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["prefecture"] = this.prefectureMapping(allStepData);
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["cityOrWardOrTownOrVillage"] = allStepData[0].uiData.city;
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["condominiumOrBuildingOrFloorOrRoomNumber"] =
      allStepData[0].uiData.condominium;
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["phone"] = allStepData[0].uiData.phone;
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["mobile"] = allStepData[0].uiData.mobile;
    submitClaim["claimsDetails"]["claimantInformation"][
      "claimantAddressDetails"
    ]["email"] = allStepData[0].uiData.email;

    /* claim benefit type details step */
    if (allStepData[1].uiData.claimBenefits === "Personal Effects") {
      submitClaim["claimsDetails"]["claimBenefitType"][
        "personalEffectsDetails"
      ]["incidentPersonalEffectInfo"]["dateofIncident"] = moment(
        allStepData[1].uiData.accidentDateTime
      ).format("YYYY-MM-DD");
      submitClaim["claimsDetails"]["claimBenefitType"][
        "personalEffectsDetails"
      ]["incidentPersonalEffectInfo"]["timeofIncident"] = moment(
        allStepData[1].uiData.tripIncidentTime
      ).format("HH:mm:ss");
      submitClaim["claimsDetails"]["claimBenefitType"][
        "personalEffectsDetails"
      ]["incidentPersonalEffectInfo"]["lossLocation"] = {
        name: allStepData[1].uiData.damage.name,
        code: allStepData[1].uiData.damage.code,
      };
      submitClaim["claimsDetails"]["claimBenefitType"][
        "personalEffectsDetails"
      ]["incidentPersonalEffectInfo"]["wheretheDamageOccurred"] = {
        name: allStepData[1].uiData.damageLocation.name,
        code: allStepData[1].uiData.damageLocation.id,
      };
      submitClaim["claimsDetails"]["claimBenefitType"][
        "personalEffectsDetails"
      ]["incidentPersonalEffectInfo"]["accidentSituation"] = {
        name: allStepData[1].uiData.accidentSituation.value,
        code: allStepData[1].uiData.accidentSituation.code
        // allStepData[1].uiData.accidentSituation.id === "Stolen Items"
        //   ? "Theft"
        //   : "Damaged",
      };
      submitClaim["claimsDetails"]["claimBenefitType"][
        "personalEffectsDetails"
      ]["incidentPersonalEffectInfo"]["accidentDetails"] =
        allStepData[1].uiData.accidentDescription;
      submitClaim["claimsDetails"]["claimBenefitType"][
        "personalEffectsDetails"
      ]["isSchoolManagement"] =
        this.schoolManagementRule() !== ""
          ? {
            name: allStepData[1].uiData.schoolManagement,
            code:
              allStepData[1].uiData.schoolManagement === "Yes" ||
                allStepData[1].uiData.schoolManagement === "はい"
                ? "1"
                : "2",
          }
          : undefined;

      if (allStepData[1].uiData.accidentSituation.id === "Stolen Items") {
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["reportPoliceStation"] = allStepData[1].uiData.reported;
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["receiptNumber"] = allStepData[1].uiData.receiptNumber;
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["notificationDate"] = allStepData[1].uiData.notificationDate
            ? moment(allStepData[1].uiData.notificationDate).format("YYYY-MM-DD")
            : "";
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["reportingParty"] = allStepData[1].uiData.reportingParty;
        if (allStepData[1].uiData.addTheftMainList.length) {
          const addTheftList = allStepData[1].uiData.addTheftMainList;
          addTheftList.forEach((theftItem) => {
            const theftObj = {
              name: this.isLanguageJp ? theftItem["名称"] : theftItem["Name"],
              purchaseTime: this.isLanguageJp
                ? moment(theftItem["購入時期"]).format("YYYY-MM-DD")
                : moment(theftItem["Purchase Time"]).format("YYYY-MM-DD"),
              purchasePrice: this.isLanguageJp
                ? theftItem["購入金額"]
                : theftItem["Purchase Price"],
            };
            submitClaim["claimsDetails"]["claimBenefitType"][
              "personalEffectsDetails"
            ]["propertyDamageDetails"]["stolenItems"].push(theftObj);
          });
        }
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["propertyDamageDetails"]["damageItems"] = [];
      } else if (
        allStepData[1].uiData.accidentSituation.id !== "Stolen Items"
      ) {
        if (allStepData[1].uiData.addDamageMainList.length) {
          const addDamageList = allStepData[1].uiData.addDamageMainList;
          addDamageList.forEach((damageItem) => {
            const damageObj = {
              name: this.isLanguageJp ? damageItem["名称"] : damageItem["Name"],
              purchaseTime: this.isLanguageJp
                ? moment(damageItem["購入時期"]).format("YYYY-MM-DD")
                : moment(damageItem["Purchase Time"]).format("YYYY-MM-DD"),
              purchasePrice: this.isLanguageJp
                ? damageItem["購入金額"]
                : damageItem["Purchase Price"],
              repairability: {
                name: this.isLanguageJp
                  ? damageItem["修理の可否"].name
                  : damageItem["Repairability"].name,
                code: this.isLanguageJp
                  ? damageItem["修理の可否"].id
                  : damageItem["Repairability"].id === "Can be repaired"
                    ? "1"
                    : "2",
              },
              repairCost: this.isLanguageJp
                ? damageItem["修理費"]
                : damageItem["Repair cost"],
            };
            submitClaim["claimsDetails"]["claimBenefitType"][
              "personalEffectsDetails"
            ]["propertyDamageDetails"]["damageItems"].push(damageObj);
          });
        }
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["propertyDamageDetails"]["stolenItems"] = [];
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["reportPoliceStation"] = "";
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["receiptNumber"] = "";
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["notificationDate"] = "";
        submitClaim["claimsDetails"]["claimBenefitType"][
          "personalEffectsDetails"
        ]["reportingParty"] = "";
      }
    } else if (allStepData[1].uiData.claimBenefits === "Illness") {
      submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
        "nameofIllnessCausingHospitalization"
      ] = allStepData[1].uiData.surgeryCause;
      submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
        "aboutTheSymptoms"
      ] = allStepData[1].uiData.symptoms;
      submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
        "dateofOccurrence"
      ] = moment(allStepData[1].uiData.occurrenceDate).format("YYYY-MM-DD");
      submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
        "initialConsultationDate"
      ] = moment(allStepData[1].uiData.consultationDate).format("YYYY-MM-DD");
      const addInstList = allStepData[1].uiData.addInstitutionMainList;
      addInstList.forEach((institute) => {
        const instObj = {
          nameOfMedicalInstition: this.isLanguageJp
            ? institute["医療機関名"]
            : institute["Name of medical institution"],
          phoneNumber: this.isLanguageJp
            ? institute["電話番号"]
            : institute["Phone Number"],
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
          "medicalInstituion"
        ].push(instObj);
      });
      submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
        "isTreatmentInfoAvailable"
      ] = {
        name: allStepData[1].uiData.treatmentIllness,
        code:
          allStepData[1].uiData.treatmentIllness === "Yes" ||
            allStepData[1].uiData.treatmentIllness === "有り"
            ? "1"
            : "2",
      };
      if (
        allStepData[1].uiData.treatmentIllness === "Yes" ||
        allStepData[1].uiData.treatmentIllness === "有り"
      ) {
        submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
          "illnessTreatmentDetails"
        ]["illnessSelectStatus"] = {
          name: allStepData[1].uiData.illnessSelectStatus,
          code:
            allStepData[1].uiData.illnessSelectStatus === "Yes" ||
              allStepData[1].uiData.illnessSelectStatus === "有り"
              ? "1"
              : "2",
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
          "illnessTreatmentDetails"
        ]["illnessTreatment"]["hositalizationStatus"] = {
          name: allStepData[1].uiData.illnessSelectStatus,
          code: allStepData[1].uiData.illnessSelectStatus === "Yes" ||
            allStepData[1].uiData.illnessSelectStatus === "有り" ? "1" : "2",
        };
        if (
          allStepData[1].uiData.illnessSelectStatus === "Yes" ||
          allStepData[1].uiData.illnessSelectStatus === "有り"
        ) {
          const addHospitalizationList =
            allStepData[1].uiData.addInpatientMainList;
          addHospitalizationList.forEach((hospital) => {
            const hospitalObj = {
              admissionDate: this.isLanguageJp
                ? moment(hospital["入院日"]).format("YYYY-MM-DD")
                : moment(hospital["Date of Hospital Admission"]).format(
                  "YYYY-MM-DD"
                ),
              dischargeDate: this.isLanguageJp
                ? moment(hospital["退院日"]).format("YYYY-MM-DD")
                : moment(hospital["Date of Hospital Discharge"]).format(
                  "YYYY-MM-DD"
                ),
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
              "illnessTreatmentDetails"
            ]["illnessTreatment"]["hositalizationStatusDates"].push(
              hospitalObj
            );
          });
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
            "illnessTreatmentDetails"
          ]["illnessTreatment"]["hositalizationStatusDates"] = [];
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
          "illnessTreatmentDetails"
        ]["diagnosedRequiredForMorethan60day"] = {
          name: allStepData[1].uiData.diagnosed,
          code:
            allStepData[1].uiData.diagnosed === "Yes" ||
              allStepData[1].uiData.diagnosed === "はい"
              ? "1"
              : "2",
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
          "illnessTreatmentDetails"
        ]["hostipalizedWithSameIllnessAndClaimInsBenfts"] = {
          name: allStepData[1].uiData.insuranceBenefits,
          code:
            allStepData[1].uiData.insuranceBenefits === "Yes" ||
              allStepData[1].uiData.insuranceBenefits === "有り"
              ? "1"
              : "2",
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
          "illnessTreatmentDetails"
        ]["didSurgeryOccur"] = {
          name: allStepData[1].uiData.surgeryIllness,
          code:
            allStepData[1].uiData.surgeryIllness === "Yes" ||
              allStepData[1].uiData.surgeryIllness === "有り"
              ? "1"
              : "2",
        };
        if (
          allStepData[1].uiData.surgeryIllness === "Yes" ||
          allStepData[1].uiData.surgeryIllness === "有り"
        ) {
          const addSurgeryList =
            allStepData[1].uiData.addSurgeryIllnessMainList;
          addSurgeryList.forEach((surgery) => {
            const surgeryObj = {
              nameOfOperation: this.isLanguageJp
                ? surgery["手術名"]
                : surgery["Name of Operation"],
              dayOfSurgery: this.isLanguageJp
                ? moment(surgery["手術日"]).format("YYYY-MM-DD")
                : moment(surgery["Date of Surgery"]).format("YYYY-MM-DD"),
              operatingCode: this.isLanguageJp
                ? surgery["手術コード"]
                : surgery["Operation Code"],
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
              "illnessTreatmentDetails"
            ]["surgeryDetails"].push(surgeryObj);
          });
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
            "illnessTreatmentDetails"
          ]["surgeryDetails"] = [];
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
          "illnessTreatmentDetails"
        ]["wasRadiationTherapyUsed"] = {
          name: allStepData[1].uiData.radiotherapy,
          code:
            allStepData[1].uiData.radiotherapy === "Yes" ||
              allStepData[1].uiData.radiotherapy === "有り"
              ? "1"
              : "2",
        };
        if (
          allStepData[1].uiData.radiotherapy === "Yes" ||
          allStepData[1].uiData.radiotherapy === "有り"
        ) {
          const addRadiationList = allStepData[1].uiData.addRadiationMainList;
          addRadiationList.forEach((radiation) => {
            const radiObj = {
              irradiatedArea: this.isLanguageJp
                ? radiation["照射された部位"]
                : radiation["Irradiated area"],
              radiationStartDate: this.isLanguageJp
                ? moment(radiation["放射線開始日"]).format("YYYY-MM-DD")
                : moment(radiation["Radiation - Start Date"]).format(
                  "YYYY-MM-DD"
                ),
              radiationEndDate: this.isLanguageJp
                ? moment(radiation["放射線終了日"]).format("YYYY-MM-DD")
                : moment(radiation["Radiation - End Date"]).format(
                  "YYYY-MM-DD"
                ),
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
              "illnessTreatmentDetails"
            ]["radiationTherapy"].push(radiObj);
          });
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["illnessDetails"][
            "illnessTreatmentDetails"
          ]["radiationTherapy"] = [];
        }
      }
    } else if (allStepData[1].uiData.claimBenefits === "Injury") {
      submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
        "causeOfInjuryCode"
      ] = {
        name: allStepData[1].uiData.injuryCode.name,
        code: allStepData[1].uiData.injuryCode.id,
      };
      submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
        "causeOfInjuryDesc"
      ] = allStepData[1].uiData.injuryCause;
      submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
        "situationOfInjury"
      ] = {
        name: allStepData[1].uiData.injuryStatus.name,
        code: allStepData[1].uiData.injuryStatus.id,
      };
      submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
        "descSituationOfInjury"
      ] = allStepData[1].uiData.injuryDescription;
      const addInjuredList = allStepData[1].uiData.addInjuredMainList;
      addInjuredList.forEach((injured) => {
        const injuredObj = {
          nameOfInjury: this.isLanguageJp
            ? injured["ケガの名前"]
            : injured["Name of Injury"],
          injuredPart: {
            name: this.isLanguageJp
              ? injured["部位"].name
              : injured["Injured Part"].name,
            code: this.isLanguageJp
              ? injured["部位"].id
              : injured["Injured Part"].id,
          },
          injuredpartDesc: this.isLanguageJp
            ? injured["その他の方は具体的にお書きください "]
            : injured["Description of Injury Part"],
          injuryStatus: {
            name: this.isLanguageJp
              ? injured["状態"].name
              : injured["Injury Status"].name,
            code: this.isLanguageJp
              ? injured["状態"].id
              : injured["Injury Status"].id,
          },
          injuryStatusDesc: this.isLanguageJp
            ? injured["その他の方は具体的にお書きください"]
            : injured["Description of Injury Status"],
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuredPartandStatus"
        ].push(injuredObj);
      });
      const addMedicalList = allStepData[1].uiData.addMedicalMainList;
      addMedicalList.forEach((medical) => {
        const medicalObj = {
          nameOfMedicalInstition: this.isLanguageJp
            ? medical["医療機関名"]
            : medical["Name of medical institution"],
          phoneNumber: this.isLanguageJp
            ? medical["電話番号"]
            : medical["Phone Number"],
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "medicalInstituion"
        ].push(medicalObj);
      });
      submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
        "isTreatmentInfoAvailable"
      ] = {
        name: allStepData[1].uiData.treatment,
        code:
          allStepData[1].uiData.treatment === "Yes" ||
            allStepData[1].uiData.treatment === "有り"
            ? "1"
            : "2",
      };
      if (
        allStepData[1].uiData.treatment === "Yes" ||
        allStepData[1].uiData.treatment === "有り"
      ) {
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["injuryTreatment"]["hositalizationStatus"] = {
          name: allStepData[1].uiData.selectStatus,
          code:
            allStepData[1].uiData.selectStatus === "Yes" ||
              allStepData[1].uiData.selectStatus === "有り"
              ? "1"
              : "2",
        };
        if (
          allStepData[1].uiData.selectStatus === "Yes" ||
          allStepData[1].uiData.selectStatus === "有り"
        ) {
          const addHospitalList =
            allStepData[1].uiData.addHospitalizationMainList;
          addHospitalList.forEach((hospital) => {
            const hospitalObj = {
              admissionDate: this.isLanguageJp
                ? moment(hospital["入院日"]).format("YYYY-MM-DD")
                : moment(hospital["Date of Hospital Admission"]).format(
                  "YYYY-MM-DD"
                ),
              dischargeDate: this.isLanguageJp
                ? moment(hospital["退院日"]).format("YYYY-MM-DD")
                : moment(hospital["Date of Hospital Discharge"]).format(
                  "YYYY-MM-DD"
                ),
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
              "injuryTreatmentDetails"
            ]["injuryTreatment"]["hospitalAdmissionAndDischargeDates"].push(
              hospitalObj
            );
          });
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["injuryTreatment"]["hospitalAdmissionAndDischargeDates"] = [];
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["didSurgeryOccur"] = {
          name: allStepData[1].uiData.surgery,
          code:
            allStepData[1].uiData.surgery === "Yes" ||
              allStepData[1].uiData.surgery === "有り"
              ? "1"
              : "2",
        };
        if (
          allStepData[1].uiData.surgery === "Yes" ||
          allStepData[1].uiData.surgery === "有り"
        ) {
          const addSurgeryList = allStepData[1].uiData.addSurgeryMainList;
          addSurgeryList.forEach((surgery) => {
            const surgeryObj = {
              nameOfOperation: this.isLanguageJp
                ? surgery["手術名"]
                : surgery["Name of Operation"],
              dayOfSurgery: this.isLanguageJp
                ? moment(surgery["手術日"]).format("YYYY-MM-DD")
                : moment(surgery["Date of Surgery"]).format("YYYY-MM-DD"),
              operatingCode: this.isLanguageJp
                ? surgery["手術コード"]
                : surgery["Operation Code"],
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
              "injuryTreatmentDetails"
            ]["surgeryDetails"].push(surgeryObj);
          });
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["surgeryDetails"] = [];
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["didOutpaientTreatmentOccurr"] = {
          name: allStepData[1].uiData.outpatient,
          code:
            allStepData[1].uiData.outpatient === "Yes" ||
              allStepData[1].uiData.outpatient === "有り"
              ? "1"
              : "2",
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["opttotalNumberofDays"] = allStepData[1].uiData.hospitalVisits;
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["optLastDayofTreatment"] = moment(
          allStepData[1].uiData.lastOutpatient
        ).format("YYYY-MM-DD");
        if (
          allStepData[1].uiData.outpatient === "Yes" ||
          allStepData[1].uiData.outpatient === "有り"
        ) {
          allStepData[1].uiData.outPatientTreatmentDate.map((val) => {
            submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
              "injuryTreatmentDetails"
            ]["outPatientTreatment"].push(moment(val).format("YYYY-MM-DD"));
          })
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["outPatientTreatment"] = [];
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["wereFixturesUsed"] = {
          name: allStepData[1].uiData.fixtures,
          code:
            allStepData[1].uiData.fixtures === "Yes" ||
              allStepData[1].uiData.fixtures === "有り"
              ? "1"
              : "2",
        };
        if (
          allStepData[1].uiData.fixtures === "Yes" ||
          allStepData[1].uiData.fixtures === "有り"
        ) {
          const addFixturesList = allStepData[1].uiData.addFixturesMainList;
          addFixturesList.forEach((fixture) => {
            const fixtureObj = {
              regionWithAnchorage: this.isLanguageJp
                ? fixture[
                  "医師の指示により常時固定具を装着した部位を選択してください"
                ].name
                : fixture["Region with anchorage"].name,
              textWithFastener: this.isLanguageJp
                ? fixture["その他の方は具体的にお書きください"]
                : fixture["Text with Fastener"],
              mountedFixture: this.isLanguageJp
                ? fixture["装着した固定具の種類を選択してください"].name
                : fixture["Mounted Fixture"].name,
              mountedFixtureText: "",
              fixingDate: this.isLanguageJp
                ? moment(fixture["装着日"]).format("YYYY-MM-DD")
                : moment(fixture["Fixing Date"]).format("YYYY-MM-DD"),
              dateOnWhichTheFixtureWasRemoved: this.isLanguageJp
                ? moment(fixture["外した日"]).format("YYYY-MM-DD")
                : moment(fixture["Date Fixture Removed"]).format("YYYY-MM-DD"),
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
              "injuryTreatmentDetails"
            ]["fixtures"].push(fixtureObj);
          });
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["fixtures"] = [];
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["isAttOfSplClComAccMediExpens"] = {
          name: allStepData[1].uiData.expenses,
          code:
            allStepData[1].uiData.expenses === "Yes" ||
              allStepData[1].uiData.expenses === "有り"
              ? "1"
              : "2",
        };
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["medicalExpenses"]["coverageofMedicalExpenses"] =
          this.medicalExpenseRule() !== ""
            ? {
              name: allStepData[1].uiData.coverage,
              code:
                allStepData[1].uiData.coverage === "Yes" ||
                  allStepData[1].uiData.coverage === "有り"
                  ? "1"
                  : "2"
            }
            : undefined;
        if (
          allStepData[1].uiData.coverage === "Yes" ||
          allStepData[1].uiData.coverage === "有り"
        ) {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["medicalExpenses"]["outofPocketMedicalExpenses"] =
            this.medicalExpenseRule() !== ""
              ? allStepData[1].uiData.outPocket
              : undefined;
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["medicalExpenses"]["outofPocketMedicalExpenses"] = "";
        }
        if (
          (allStepData[1].uiData.selectStatus === "Yes" ||
            allStepData[1].uiData.selectStatus === "有り") &&
          this.medicalExpenseRule() !== ""
        ) {
          const transportation = allStepData[1].uiData.transportation;
          const transportArray = [];
          transportation.forEach((transport) => {
            transportArray.push(transport.value);
          });
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["medicalExpenses"]["meansofTransportation"] =
            this.medicalExpenseRule() !== ""
              ? { name: transportArray.toString(), code: this.meanOfTransportBits(transportArray) }
              : undefined;
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["medicalExpenses"]["meansofTransportation"] = {};
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["medicalExpenses"]["transportationExpensesIncurred"] =
          this.medicalExpenseRule() !== ""
            ? allStepData[1].uiData.expensesIncurred
            : undefined;
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["medicalExpenses"]["distanceTraveled"] =
          this.medicalExpenseRule() !== ""
            ? allStepData[1].uiData.distanceTraveled
            : undefined;
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["medicalExpenses"]["roadTollTotal"] =
          this.medicalExpenseRule() !== ""
            ? allStepData[1].uiData.tollRoad
            : undefined;
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["isOtherIsurance"] = {
          name: allStepData[1].uiData.contracts,
          code:
            allStepData[1].uiData.contracts === "Yes" ||
              allStepData[1].uiData.contracts === "有り"
              ? "1"
              : "2",
        };
        if (
          allStepData[1].uiData.contracts === "Yes" ||
          allStepData[1].uiData.contracts === "有り"
        ) {
          const addInsuranceList = allStepData[1].uiData.addInsuranceMainList;
          addInsuranceList.forEach((insurance) => {
            const insureObj = {
              nameOfInsuranceCompany: this.isLanguageJp
                ? insurance["保険会社名"]
                : insurance["Name of Insurance Company"],
              insuredNumber: this.isLanguageJp
                ? insurance["証券番号"]
                : insurance["Insured Number"],
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
              "injuryTreatmentDetails"
            ]["otherInsurance"].push(insureObj);
          });
        } else {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["otherInsurance"] = [];
        }
        submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
          "injuryTreatmentDetails"
        ]["isthereDamagetoPersonalEffects"] = {
          name: allStepData[1].uiData.damagePE,
          code:
            allStepData[1].uiData.damagePE === "Yes" ||
              allStepData[1].uiData.damagePE === "有り"
              ? "1"
              : "2",
        };
        if (
          allStepData[1].uiData.damagePE === "Yes" ||
          allStepData[1].uiData.damagePE === "有り"
        ) {
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["personalEffectDamage"]["locationWhereDamageOccurred"] = {
            name: allStepData[1].uiData.damageLocationInjury.name,
            code: allStepData[1].uiData.damageLocationInjury.id,
          };
          submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
            "injuryTreatmentDetails"
          ]["personalEffectDamage"]["schoolManagement"] =
            this.schoolManagementRule() !== ""
              ? {
                name: allStepData[1].uiData.schoolManagementInjury,
                code:
                  allStepData[1].uiData.schoolManagementInjury === "Yes" ||
                    allStepData[1].uiData.schoolManagementInjury === "はい"
                    ? "1"
                    : "2",
              }
              : undefined;
          const damageInjuryList =
            allStepData[1].uiData.addDamageInjuryMainList;
          damageInjuryList.forEach((damageInjury) => {
            const damageObj = {
              name: this.isLanguageJp
                ? damageInjury["損害が生じた物"]
                : damageInjury["Name"],
              purchaseTime: this.isLanguageJp
                ? moment(damageInjury["購入時期"]).format("YYYY-MM-DD")
                : moment(damageInjury["Purchase Time"]).format("YYYY-MM-DD"),
              purchasePrice: this.isLanguageJp
                ? damageInjury["購入金額"]
                : damageInjury["Purchase Price"],
              repairability: {
                name: this.isLanguageJp
                  ? damageInjury["修理の可否"].name
                  : damageInjury["Repairability"].name,
                code: this.isLanguageJp
                  ? damageInjury["修理の可否"].id
                  : damageInjury["Repairability"].id === "Can be repaired"
                    ? "1"
                    : "2",
              },
              repairCost: this.isLanguageJp
                ? damageInjury["修理費"]
                : damageInjury["Repair cost"],
            };
            submitClaim["claimsDetails"]["claimBenefitType"]["injuryDetails"][
              "injuryTreatmentDetails"
            ]["damagedItems"].push(damageObj);
          });
        }
      }
    }

    /* payment details step */
    submitClaim["claimsDetails"]["paymentDetails"]["selectionAccount"] = {
      name: allStepData[2].uiData.account,
      code:
        allStepData[2].uiData.account ===
          "Insurance premium transfer account" ||
          allStepData[2].uiData.account === "保険料振替口座"
          ? "1"
          : "2",
    };
    if (allStepData[2].uiData.account === "Insurance premium transfer account" ||
      allStepData[2].uiData.account === "保険料振替口座") {
      submitClaim["claimsDetails"]["paymentDetails"]["institutions"] = undefined;
    } else {
      submitClaim["claimsDetails"]["paymentDetails"]["institutions"] = {
        name: allStepData[2].uiData.institutions
        , code: allStepData[2].uiData.institutions === "Financial institutions other than Japan Post Bank" ||
          allStepData[2].uiData.institutions === 'ゆうちょ銀行以外の金融機関' ? '1' : '2'
      };
    }
    // submitClaim["claimsDetails"]["paymentDetails"]["institutions"] = {
    //      name : allStepData[2].uiData.institutions
    //      ,code:allStepData[2].uiData.institutions === 'ゆうちょ銀行以外の金融機関' ? '1':'2'};
    // allStepData[2].uiData.institutions;

    if (
      allStepData[2].uiData.institutions ===
      "Financial institutions other than Japan Post Bank" ||
      allStepData[2].uiData.institutions === "ゆうちょ銀行以外の金融機関"
    ) {
      submitClaim["claimsDetails"]["paymentDetails"]["finacialInstitutions"][
        "typeofFinancialInstitution"
      ] = {
        name: allStepData[2].uiData.typeFinancial.name,
        code: allStepData[2].uiData.typeFinancial.id,
      };
      submitClaim["claimsDetails"]["paymentDetails"]["finacialInstitutions"][
        "nameofFinancialInstitution"
      ] = allStepData[2].uiData.financialInstitution;
      //  { name : allStepData[2].uiData.financialInstitution
      //    ,code:allStepData[2].uiData.financialInstitution === 'ゆうちょ銀行以外の金融機関' ? '1':'2'};
      submitClaim["claimsDetails"]["paymentDetails"]["finacialInstitutions"][
        "otherBankDetails"
      ]["branchCode"] = allStepData[2].uiData.branchCode;
      submitClaim["claimsDetails"]["paymentDetails"]["finacialInstitutions"][
        "otherBankDetails"
      ]["branchName"] = allStepData[2].uiData.branchName;
      submitClaim["claimsDetails"]["paymentDetails"]["finacialInstitutions"][
        "otherBankDetails"
      ]["accountType"] = {
        name: allStepData[2].uiData.accountType,
        code:
          allStepData[2].uiData.accountType === "Normal/General" ||
            allStepData[2].uiData.accountType === "普通・総合"
            ? "1" : "2",
      };
      submitClaim["claimsDetails"]["paymentDetails"]["finacialInstitutions"][
        "otherBankDetails"
      ]["accountNo"] = allStepData[2].uiData.accountNumber;
      submitClaim["claimsDetails"]["paymentDetails"]["finacialInstitutions"][
        "otherBankDetails"
      ]["accountHolderName"] = allStepData[2].uiData.accountHolder;
    } else if (
      allStepData[2].uiData.institutions === "Japan Post Bank" ||
      allStepData[2].uiData.institutions === "ゆうちょ銀行"
    ) {
      submitClaim["claimsDetails"]["paymentDetails"]["japanBankDetails"][
        "symbol"
      ] = allStepData[2].uiData.symbol;
      submitClaim["claimsDetails"]["paymentDetails"]["japanBankDetails"][
        "number"
      ] = allStepData[2].uiData.number;
      submitClaim["claimsDetails"]["paymentDetails"]["japanBankDetails"][
        "accountNameKatakana"
      ] = allStepData[2].uiData.accountNumberKatakana;
    }

    /* doc upload info */
    // submitClaim['docUpload']['claimNumber'] = this.fnolModelService.getClaimNumber();
    //submitClaim['docUpload']['lossDate'] = moment(allStepData[0].uiData.accidentDate).format('YYYY-MM-DD');

    return submitClaim;
  }

  resumeSaveClaim(step1uiStructure, step3uiStructure, step4uiStructure) {
    this.fnolModelService?.setClaimNumber(this.CacheService?.alreadyGeneratedClaimNumber);
    this.fnolService.clickedFromDashboard = false;
    this.fnolService.countFlag = true;
    this.fnolService.saveCounter = true;
    if(this.fnolService?.getGridAvailable()?.length >= 1) {
      this.fnolService.showPolicyPopUP = false;
      step1uiStructure.sections.mainSectionStep1.data.fields[0].fields[10].gridModel.body.rows = this.fnolService?.getSavedClaimantDetails()?.gridData;
    }
    this.datastoreservice?.set(FNOL_STEPS_JP.CLAIMANT_INFO.name,{uiData: this.fnolService?.getSavedClaimantDetails(),uiStructure: step1uiStructure});
    this.fnolService.savedDOO = this.datastoreservice?.get(FNOL_STEPS_JP.CLAIMANT_INFO.name)?.uiData["accidentDate"];
    if(this.fnolService?.getSavedClaimtypeDetails()) {
      this.datastoreservice?.set(FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name,{uiData: this.fnolService?.getSavedClaimtypeDetails(),uiStructure: this.fnolService?.getSavedClaimTypeStructureDetails()});
      }
    if(this.fnolService?.getUploadDetails()) {
    this.datastoreservice?.set('fnol-upload-docs',{uiData: this.fnolService?.getUploadDetails(),uiStructure: step4uiStructure});
    }
    if(this.fnolService?.getpaymentdetails()) {
      this.datastoreservice?.set('fnol-payment-details',{uiData: this.fnolService?.getpaymentdetails(),uiStructure: step3uiStructure});
      }
    if(this.fnolService?.getFollowUpForSavedDraft() === "false" || !this.fnolService?.getFollowUpForSavedDraft()) {
    this.router.navigate(['cdp/jp/home']);
  } else {
    this.CacheService?.setFollowUpClaimNumber(this.fnolService?.getParentNumberForSaveDraft());
    this.router.navigate(['cdp/jp/home'], { queryParams: { followup: true } });
  }
  }

  prefectureMapping(allStepData) {
    if (
      allStepData[0].uiData.prefecture.hasOwnProperty("name") &&
      allStepData[0].uiData.prefecture.hasOwnProperty("code")
    ) {
      return {
        name: allStepData[0].uiData.prefecture.name,
        code: allStepData[0].uiData.prefecture.code,
      };
    } else {
      let res = Prefacture.filter((res) => {
        return (
          res["en - name"] === allStepData[0].uiData.prefecture ||
          res["jp - name"] === allStepData[0].uiData.prefecture
        );
      })[0];
      return {
        name:
          localStorage.getItem("language") === "jp"
            ? res["jp - name"]
            : res["en - name"],
        code: res["code"],
      };
    }
  }

  submitClaimRequestModificationBasedOnCSVFlow(submitClaimPostRequest) {
    let claimType = this.datastoreservice.get(
      FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name
    ).uiData.claimBenefits;

    if (
      claimType === "Injury" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType.injuryDetails
        .isTreatmentInfoAvailable.code === "2"
    ) {
      submitClaimPostRequest.claimsDetails.claimBenefitType.isNotice = "A";
      delete submitClaimPostRequest.claimsDetails.claimBenefitType.injuryDetails
        .injuryTreatmentDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .illnessDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails;
    } else if (
      claimType === "Injury" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType.injuryDetails
        .injuryTreatmentDetails.isthereDamagetoPersonalEffects.code === "2" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType.injuryDetails
        .isTreatmentInfoAvailable.code === "1"
    ) {
      submitClaimPostRequest.claimsDetails.claimBenefitType.isNotice = "B";
      delete submitClaimPostRequest.claimsDetails.claimBenefitType.injuryDetails
        .injuryTreatmentDetails.personalEffectDamage;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .illnessDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails;
    } else if (
      claimType === "Injury" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType.injuryDetails
        .injuryTreatmentDetails.isthereDamagetoPersonalEffects.code === "1" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType.injuryDetails
        .isTreatmentInfoAvailable.code === "1"
    ) {
      submitClaimPostRequest.claimsDetails.claimBenefitType.isNotice = "BE";
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .illnessDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails;
    } else if (
      claimType === "Illness" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType.illnessDetails
        .isTreatmentInfoAvailable.code === "2"
    ) {
      submitClaimPostRequest.claimsDetails.claimBenefitType.isNotice = "C";
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .illnessDetails.illnessTreatmentDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .injuryDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails;
    } else if (
      claimType === "Illness" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType.illnessDetails
        .isTreatmentInfoAvailable.code === "1"
    ) {
      submitClaimPostRequest.claimsDetails.claimBenefitType.isNotice = "D";
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .injuryDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails;
    } else if (
      claimType === "Personal Effects" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails.incidentPersonalEffectInfo.accidentSituation
        .code !== "Q10"
    ) {
      submitClaimPostRequest.claimsDetails.claimBenefitType.isNotice = "E";
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails.propertyDamageDetails.stolenItems;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .injuryDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .illnessDetails;
    } else if (
      claimType === "Personal Effects" &&
      submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails.incidentPersonalEffectInfo.accidentSituation
        .code === "Q10"
    ) {
      submitClaimPostRequest.claimsDetails.claimBenefitType.isNotice = "F";
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .personalEffectsDetails.propertyDamageDetails.damageItems;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .injuryDetails;
      delete submitClaimPostRequest.claimsDetails.claimBenefitType
        .illnessDetails;
    }

    return submitClaimPostRequest;
  }

  submitClaimRequestModificationPaymentBasedOnCSVFlow(submitClaimPostRequest) {
    if (
      submitClaimPostRequest["claimsDetails"]["paymentDetails"][
        "selectionAccount"
      ].code === "1"
    ) {
      delete submitClaimPostRequest.claimsDetails.paymentDetails
        .finacialInstitutions;
      delete submitClaimPostRequest.claimsDetails.paymentDetails
        .japanBankDetails;
    }
    return submitClaimPostRequest;
  }

  public BERule() {
    let grid = this.datastoreservice.get(FNOL_STEPS_JP.CLAIMANT_INFO.name)
      .uiData["gridData"];
    if (this.fnolService.getIsValidated() &&
      grid.length === 1 &&
      grid[0].cells.find(x => x.content == "DeleteButtonComponent").value.riderInfo["personalEffects"] === "N"
    ) {
      return "";
    } else {
      return "Y";
    }
  }
  public schoolManagementRule() {
    let grid = this.datastoreservice.get(FNOL_STEPS_JP.CLAIMANT_INFO.name)
      .uiData["gridData"];
    if (this.fnolService.getIsValidated() &&
      grid.length === 1 &&
      grid[0].cells.find(x => x.content == "DeleteButtonComponent").value.riderInfo["schoolManagement"] === "N"
    ) {
      return "";
    } else {
      return "Y";
    }
  }
  public medicalExpenseRule() {
    let grid = this.datastoreservice.get(FNOL_STEPS_JP.CLAIMANT_INFO.name)
      .uiData["gridData"];
    if (this.fnolService.getIsValidated() &&
      grid.length === 1 &&
      grid[0].cells.find(x => x.content == "DeleteButtonComponent").value.riderInfo["medicalReinbursement"] === "N"
    ) {
      return "";
    } else {
      return "Y";
    }
  }
  public accountRule(uiStructure) {
    let grid =this.datastoreservice.get(FNOL_STEPS_JP.CLAIMANT_INFO.name)
      .uiData["gridData"];
    if (this.fnolService.getIsValidated() &&  Utils.getFieldFromStructure(uiStructure, "account")&&
      grid.length === 1 &&
      grid[0].cells.find(x => x.content == "DeleteButtonComponent").value.riderInfo["orgPaymentMethod"] === "N"
    ) {
       
      Utils.getFieldFromStructure(uiStructure, "account").options = [
        accountPolicyJP[0],
      ];
    } else {
      if (Utils.getFieldFromStructure(uiStructure, "account")?.options !== null && Utils.getFieldFromStructure(uiStructure, "account")?.options !== undefined) {

        Utils.getFieldFromStructure(uiStructure, "account").options = [
          accountPolicyJP[0],
          accountPolicyJP[1],
        ];
      }
       
    }
  }
  calculateAge(birthday) {
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  public getClaimType(grid, type) {
    if (grid) {
      let types = grid[0].cells.find(x => x.content == "DeleteButtonComponent").value.riderInfo;
      let sequenceOf =
        types["injury"] + types["illness"] + types["personalEffects"];
      if (sequenceOf === "YNN") {
        return "Injury";
      } else if (sequenceOf === "NYN") {
        return "Illness";
      } else if (sequenceOf === "NNY") {
        return "Personal Effects";
      } else if (sequenceOf === "YYN") {
        return type === "Injury" || type === "Illness" ? type : "Injury";
      } else if (sequenceOf === "NYY") {
        return type === "Illness" || type === "Personal Effects"
          ? type
          : "Illness";
      } else if (sequenceOf === "YNY") {
        return type === "Injury" || type === "Personal Effects"
          ? type
          : "Injury";
      } else if (sequenceOf === "YYY") {
        return type;
      }
    }
  }

  public findClaimBenefit(item) {
    let cbtype = Object.keys(
      item.ClaimDetails.submitClaimJson.claimsDetails.claimBenefitType || {}
    )[1];
    if (cbtype === "personalEffectsDetails") {
      return $localize`:@@PersonalEffectIcon:Personal Effect`;
    } else if (cbtype === "injuryDetails") {
      return $localize`:@@InjuryIcon:Injury`;
    } else if (cbtype === "illnessDetails") {
      return $localize`:@@IllnessIcon:Illness`;
    }
  }

  public modifyDocUploadRequest(req) {
    let uploadCheck = this.datastoreservice.get(FNOL_STEPS_JP.UPLOAD_DOCS.name).uiData.customFileUpload;
    if (uploadCheck && uploadCheck["uploads"]) {
      let currentList = this.datastoreservice.get(
        FNOL_STEPS_JP.UPLOAD_DOCS.name
      ).uiData.customFileUpload.uploads;
      let docList = this.datastoreservice.get("docList");
      docList.forEach((val) => {
        let findDocId = currentList.filter((val1) => val1["docId"] === val);
        if (findDocId.length > 0) {
          req.docUpload.documentInfo.push({
            vendorDocID: val,
            documentStatusCd: "NE",
            claimType: findDocId[0]["claimBenefitType"]["code"],
          });
        } else {
          req.docUpload.documentInfo.push({
            vendorDocID: val,
            documentStatusCd: "DE",
            claimType: "",
          });
        }
        findDocId = [];
      });
    }
    return req;
  }

  private gridRequestMapping(gridData: Array<[]>) {
    const gridArray = [];
    if (this.fnolService.getIsValidated() && gridData.length == 1) {
      gridData.forEach((grid: any) => {
        let objPolicy = grid.cells.find(x => x.content == "DeleteButtonComponent").value;
        // let gridObj = {
        //   accountType:
        //     objPolicy.accountType === "P" ? "Policy Number" : "Insured Number",
        //   accountNumber:
        //     objPolicy.accountType == "I" ? objPolicy.jpPolicyNo : "",
        //   policyNumber:
        //     objPolicy.jpPolicyNo ? objPolicy.jpPolicyNo : "",
        //   insuredName: objPolicy.insuredName,
        //   inceptionDate: objPolicy.polInceptDate != "" ? moment(objPolicy.polInceptDate).format("YYYY-MM-DD") : "",
        //   expiryDate: objPolicy.polExpDate != ""
        //     ? moment(objPolicy.polExpDate).format("YYYY-MM-DD")
        //     : "",
        //   productName: this.fnolService.getProductName(objPolicy),
        //   riderInfo: objPolicy.riderInfo,
        //   policyIssuingCountry: "JP",
        //   jpItemNo: objPolicy.jpItemNo,
        //   sourceSystemID: objPolicy.sourceSystemId,

        // };
        gridArray.push(objPolicy);
      });
    }

    return gridArray;
  }

  private getGridPolicyData(gridData, date) {
    const gridArray = [];
    if(gridData){
      gridData.forEach((grid) => {
        const gridObj = {
          accountNumber: "",
          lastName: "",
          firstName: "",
          dol: date,
        };
        const replacementKey = { 'accountNumber': "policyNumber", "lastName": "insuredLastName", "firstName": "insuredFirstName", "dol": "dol" }
        grid.cells.forEach((field) => {
          if (field.mapTo !== "serialNumber" && field.mapTo !== "actions") {
            for (let key in gridObj) {
              if (key === field.mapTo) {
                gridObj[key] = field.val;
              }
            }
          }
        });
        let newObj = Object.keys(gridObj).map((key) => {
          const newKey = replacementKey[key] || key;
          return { [newKey]: gridObj[key] }
        })
        let reduceObj = newObj.reduce((a, b) => Object.assign({}, a, b))
        gridArray.push(reduceObj);
      });
    }
    return gridArray;
  }
  meanOfTransportBits(arr) {
    let options = ["電車・バス・タクシー", "自家用車（往復の場合は合計）", "徒歩または自転車"];
    let arr2 = ["0", "0", "0"];
    let re = [];
    arr.forEach((i) => {
      re.push(options.indexOf(i));
    });
    re.forEach((i) => {
      arr2[i] = "1";
    });
    return arr2.join("");
  }

  private getGridData(gridData): any {
    const gridArray = [];
    if(gridData){
      gridData.forEach((grid) => {
        const gridObj = {
          accountType: "",
          accountNumber: "",
          masterPolicyNumber: "",
          inceptionDate: "",
          expiryDate: "",
          productName: "",
          lob: "",
        };
        grid.cells.forEach((field) => {
          if (field.mapTo == "accountNumber") {
            for (let key in gridObj) {
              if (key === field.mapTo) {
                gridObj[key] = field.val;
              }
            }
          }
        });
        gridArray.push(gridObj);
      });
    }
    return gridArray;
  }

  private getValidatedGridData(gridData): any {
    const gridArray = [];
    if(gridData){
      gridData.forEach((grid: any) => {
        let objPolicy = grid.cells.find(x => x.content == "DeleteButtonComponent").value;
        const gridObj = {
          accountType: "",
          accountNumber: objPolicy?.jpPolicyNo ? objPolicy?.jpPolicyNo : "",
          masterPolicyNumber: "",
          inceptionDate: objPolicy?.jpPolicyNo ? objPolicy?.jpPolicyNo : "",
          expiryDate: objPolicy?.jpPolicyNo ? objPolicy?.jpPolicyNo : "",
          productName: this.fnolService.getProductName(objPolicy),
          lob: "",
        };
        // grid.cells.forEach((field) => {
        //   if (field.mapTo == "accountNumber") {
        //     for (let key in gridObj) {
        //       if (key === field.mapTo) {
        //         gridObj[key] = field.val;
        //       }
        //     }
        //   }
        // });
        gridArray.push(gridObj);
      });
    }
    return gridArray;
  }


  private removeEmptyArrays(submitClaim) {
    for (var key in submitClaim) {
      var item = submitClaim[key];
      if (Array.isArray(item)) {
        if (item.length > 0) {
          submitClaim[key] = [];
        }
      } else if (typeof item == "object") {
        console.log(item);
        this.removeEmptyArrays(item);
      }
    }
  }
}
