export const apiEndPoints = {
  userid: "",
  submitRegistrationJP: "fnol-core-c2a-ext-proxy/v1/non-auth/registration",
  verifyPolicyJP: "fnol-core-c2a-ext-proxy/v1/auth/users/", // userid needs to be updated some how
  searchNonAuthPolicyJP: "fnol-core-c2a-ext-proxy/v1/non-auth/policy/search",
  unrDocUploadURL: "apac-fnol-claims-c2a-ext-proxy/v1/insert-document",
  unrFinalSubmit: "apac-fnol-claims-c2a-ext-proxy/v1/submit-claim",
  unrFeedBackURL: "apac-fnol-claims-c2a-ext-proxy/v1/submit-feedback",
  geClaimNumber: "apac-fnol-claims-c2a-ext-proxy/v1/getClaimNumber",
  appStatus:"fnol-core-c2a-ext-proxy/v1/non-auth/appconfig/",
  uploadDocJP:"fnol-core-file-gateway-api/v1/auth/docs",
  policySearchJP: "fnol-core-c2a-ext-proxy/v1/auth/policies/search",
  submitClaimJP:"fnol-core-c2a-ext-proxy/v1/auth/claims",
  getClaimNumberJP:"fnol-core-c2a-ext-proxy/v1/auth/claims/generate-claim-number?countryCd=",
  fetchPolicies: "fnol-core-c2a-ext-proxy/v1/auth/users/",
  addPolicy: "fnol-core-c2a-ext-proxy/v1/auth/policies",
  deletePolicy: "fnol-core-c2a-ext-proxy/v1/auth/policies/12345630200",
  fetchClaimDetail: "fnol-core-c2a-ext-proxy/v1/auth/users/claims?countryCd=",
  deleteClaimDetail: "fnol-core-c2a-ext-proxy/v1/auth/claims/",
  addClaimDetail: "fnol-core-c2a-ext-proxy/v1/auth/users/claims",
  claimSearchJP: "fnol-core-c2a-ext-proxy/v1/auth/claims/search",
  updateDocUpload: "fnol-core-c2a-ext-proxy/v1/auth/docs/",
  deleteDocUpload: "c2a/jp-fnol-claims-c2a-ext-proxy/v1/al/delete-doc?x-resource-name=delete-doc",
  updateUserDataJP: "fnol-core-c2a-ext-proxy/v1/auth/users",
  getUserDataJP: "fnol-core-c2a-ext-proxy/v1/auth/profile/",
  checkUser:"fnol-core-c2a-ext-proxy/v1/non-auth/profile/",
  getEnrolledFactor :"fnol-core-c2a-ext-proxy/v1/auth/getEnrolledFactor",
  changePwdApi: 'fnol-core-c2a-ext-proxy/v1/auth/changePassword',
  getEntrollementList: `fnol-core-c2a-ext-proxy/v1/auth/getEnrolledFactor`,
  removeEnrollmentFactor: `fnol-core-c2a-ext-proxy/v1/auth/resetFactor`,
  postEnrollmentFactor: `fnol-core-c2a-ext-proxy/v1/auth/enrollFactor`,
  postActivateFactor: `fnol-core-c2a-ext-proxy/v1/auth/activateFactor`,
  postResendCode: `fnol-core-c2a-ext-proxy/v1/auth/resendCode`,
  updatePhoneNumber: `fnol-core-c2a-ext-proxy/v1/auth/changePhoneNumber`,
  deleteSavedTempClaim:"fnol-core-c2a-ext-proxy/v1/auth/claims",
  unlockAccount:`fnol-core-c2a-ext-proxy/v1/non-auth/unlockAccount`,
  forgetPassword:`fnol-core-c2a-ext-proxy/v1/non-auth/resetPassword`
};


