import { Injectable, Component, } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class FNOLUtilService {
private announceInitialisation = new Subject();
public announceInitialisationObservable = this.announceInitialisation.asObservable();
constructor() {

  }

  checkResponseType(response) {
    if (Array.isArray(response)) {
      return response;
    } else { //object
      return [response]
    }
  }

  needToPerformSMLogin(): boolean {
    let url: string = window.location.hostname;
    return url.indexOf('www-278') > -1;
  }

  getJsDateFromCdpDate(cdpDate: string): Date {
    let date = null;
    let dateReg = /^\d{4}[-]\d{2}[-]\d{2}$/;

    if (cdpDate && cdpDate.length == 10 && cdpDate.match(dateReg)) {
      date = new Date();
      date.setYear(cdpDate.substring(0, 4));
      date.setMonth(parseInt(cdpDate.substring(5, 7)) - 1);
      date.setDate(cdpDate.substring(8, 10));
    }

    return date;
  }
}
