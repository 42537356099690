import { Injectable } from "@angular/core";
// import { EncrDecrService } from './encr-decr.service';

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  set(name, data) {
    try {
      sessionStorage.setItem(name, JSON.stringify(data));
      // var encrypted = this.EncrDecr.set('cdp-5353', JSON.stringify(data));
      // sessionStorage.setItem(name, encrypted);
    } catch (error) {
      if (error.name.indexOf("QUOTA") !== -1) {
        alert("Local browser storage has been exceeded. Re-enter data.");
        console.error(
          "localStorage size in characters: " +
            JSON.stringify(sessionStorage).length
        ); // maximum is roughly 2636625 characters
      }
    }
  }

  get(name) {
    return JSON.parse(sessionStorage.getItem(name));
    // var decrypted =  this.EncrDecr.get('cdp-5353', sessionStorage.getItem(name));
    // return JSON.parse(decrypted);
  }

  remove(name) {
    sessionStorage.removeItem(name);
  }

  clear() {
    sessionStorage.clear();
  }
}
