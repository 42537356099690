import { ChangeDetectorRef, Component, ElementRef, Input, Output, EventEmitter } from "@angular/core";
import {
  IUploadDocumentReqModel,
  IUploadDocumentReqModelNewSG,
} from "../shared/model/upload-document.model";
import { FnolService } from "../services/fnol.service";
import {
  FNOL_COUNTRY,
  FNOL_STEPS,
  DISPLAY_MESSAGES,
  MARINE_FILE_ALLOWED_FORMAT,
  UPLOADTEXT,
  UtilsConstLabelsLocalization,
  APPLICATION_ID,
  NAME_DATE_LOSS,
  FNOL_STEPS_JP,
  IMG_ALLOWED_FORMAT,
} from "../config/fnol-constants";
import {
  FNOL_STEPS_SINGAPORE,
  UtilsConstSGLabelsRetrieve,
} from "../config/fnol-sg-constants";
import { URL_PARAMETERS } from "../config/urlParameters.constant";
import { FnolModelService } from "../services/fnol-model.service";
import { FnolSgModelService } from "../fnol-sg/fnol-sg-model.service";
import { LoadingService } from "axis-components";
import { BehaviorSubject } from "rxjs";
import { DataStoreService } from "../services/data-store.service";
import { CountryService } from "./../services/country.service";
import { CommonUtils } from "../shared/utils/common-utils";
import * as moment from "moment";
import { SubmitClaimModelService } from "../services/submit-claim-model.service";
import { FnolSgApiService } from "../fnol-sg/fnol-sg-api.service";
import { AppConfig } from "src/app/shared/config";
import { FnolJapanModelService } from '../services/fnol-japan-model-service';
import { CacheService } from "src/app/shared/services/cache.service";
import { updateDocModel, uploadModel } from "../shared/model/upload-model";

@Component({
  selector: "axis-custom-file-upload",
  styles: [
    `
      .axis-img-zoom {
        position: relative;
      }
      .axis-img-zoom img {
        height: auto;
        left: 100%;
        max-width: 400%;
        position: absolute;
        transform: translate(0, -100%);
      }
      .axis-upload-wrapper {
        overflow: visible;
      }
      .axis-upload-wrapper tbody td {
        vertical-align: middle;
      }
      .axis-upload-wrapper td > .axis-media > .axis-media-left {
        height: 52px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: center;
        width: 40px;
      }
      .axis-upload-wrapper tr.row-danger > td {
        animation: fade-danger 2s linear;
      }
      axis-alert a span.link {
        color: #ffffff;
        text-decoration: underline;
        font-weight: 600;
      }
      @keyframes fade-danger {
        0%,
        100% {
          background-color: transparent;
        }
        30%,
        60% {
          background-color: #fbe7eb;
        }
      }
      .axis-upload-wrapper tr.row-success > td {
        animation: fade-success 2s linear;
      }
      @keyframes fade-success {
        0%,
        100% {
          background-color: transparent;
        }
        30%,
        60% {
          background-color: #bdf0df;
        }
      }
      .axis-upload-wrapper tr.row-muted > td {
        background-color: #fafafa;
      }
    `,
  ],
  templateUrl: "./custom-file-upload.component.html",
})
export class FileUploadCustomComponent {
  /* properties */

  @Input() item;
  @Input() closeAction;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() uploadSucces = new EventEmitter<Boolean>();
  public customEvent: BehaviorSubject<any>;
  claimBenefit: string;
  editMultipleFields = false;
  claimNumberGenerated: string;
  docIdGenerated: string;
  fileLoading = false;
  fileToDelete;
  fileToEdit;
  dateVal;
  fileUploading = "";
  country: string;
  dropDownLabel: string;
  typePlaceholder: string;
  messageError: string;
  growlDelay = 10; // 10s
  imagePreview = true;
  isMulti = true;
  //   afterUploadButtonText = "Browse to Upload";
  afterUploadButtonText = $localize`:@@browseToUpload:Browse to Upload`;
  restrictFiles = "";
  uploadErrors: number;
  uploadErrorsList = [];
  uploadFileProgress = 0;
  uploadProgress;
  apiFailure: string;
  deleteFailure: string;
  docIdList = [];
  uploadRecords = [
    12, 35, 54, 22, 47, 39, 29, 18, 9, 22, 32, 49, 28, 51, 39, 19,
  ];
  uploadSelected = [];
  uploadSelectedInitial = [];
  uploaded = [];
  userName = "";
  fileUploadFailed = false;
  uploadText = null;
  supportingDocumentType;
  readonly FNOL_COUNTRY = FNOL_COUNTRY;
  readonly URL_PARAMETERS = URL_PARAMETERS;
  readonly isCountryAuOrNz = CommonUtils.isCountryAuOrNz;
  public claimBenefitTypes = [];
  uploadDocumentModel: any;
  errorMessage = false;
  zeroByteError = false;
  exceedSizeError = false;
  deleteFailedError = false;
  formatUnsupportedError = false;
  zeroByteErrorMsg;
  exceedSizeErrorMsg;
  formatUnsupportedErrorMsg;
  isNam = false;
  namSelectedClaimType;
  claimType;
  claimTypeChangedError = false;
  claimTypeChangedErrorMsg = "アップロードする書類の種類を選択してください"
  language = this.cacheService.get('language').toLowerCase();

  constructor(
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private fnolJapanModelService: FnolJapanModelService,
    private fnolService: FnolService,
    private fnolModelService: FnolModelService,
    private cacheService: CacheService,
    private fnolSgModelService: FnolSgModelService,
    private submitClaimModelService: SubmitClaimModelService,
    public loadingService: LoadingService,
    private _dataStoreService: DataStoreService
  ) {
    if ((URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode || this.language === 'jp') && this._dataStoreService.get(FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name)) {
      this.claimType = this._dataStoreService.get(FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name).uiData['claimBenefits'];
    }
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode || this.language === 'jp') {
      this.supportingDocumentType = UtilsConstLabelsLocalization.getClaimBenefitType(URL_PARAMETERS.COUNTRY.toLowerCase(), this.claimType);
    }
    if (
      URL_PARAMETERS.COUNTRY.toLowerCase() !== this.isCountryAuOrNz &&
      URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.SINGAPORENEW.countryCode
    ) {
      this.claimBenefitTypes = UtilsConstLabelsLocalization.getClaimBenefitType(
        URL_PARAMETERS.COUNTRY.toLowerCase()
      );
    }
    if (
      URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz ||
      URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode ||
      URL_PARAMETERS.COUNTRY.toLowerCase() ===
      FNOL_COUNTRY.SINGAPORE.countryCode
    ) {
      this.uploadText = UPLOADTEXT.uploadText;
    }
    if (
      URL_PARAMETERS.COUNTRY.toLowerCase() ===
      FNOL_COUNTRY.SINGAPORENEW.countryCode
    ) {
      this.uploadText = UPLOADTEXT.sg.uploadText;
    }
    if (
      URL_PARAMETERS.COUNTRY.toLowerCase() ===
      FNOL_COUNTRY.SINGAPORENEW.countryCode
    ) {
      let singaporeSelectedClaimType = this._dataStoreService.get(
        FNOL_STEPS_SINGAPORE.CLAIM_TYPE_DETAILS.name
      ).uiData["claimBenefits"];
      this.claimBenefitTypes = UtilsConstSGLabelsRetrieve.getClaimBenefitTypes(
        singaporeSelectedClaimType
      );
    }
    // if (URL_PARAMETERS.COUNTRY.toLowerCase() !== this.isCountryAuOrNz) {
    //   this.claimBenefitTypes = UtilsConstLabelsLocalization.getClaimBenefitType(URL_PARAMETERS.COUNTRY.toLowerCase());
    // }
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === this.isCountryAuOrNz ||
      URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORE.countryCode) {
      this.uploadText = UPLOADTEXT.uploadText;
    }
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.NAM.countryCode) {
      this.isNam = true;
      this.namSelectedClaimType = this._dataStoreService.get(
        FNOL_STEPS.CLAIM_TYPE_DETAILS.name
      ).uiData["claimBenefits"];
    }
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode || this.language === 'jp') {
      this.uploadText = this.language === 'jp' ? UPLOADTEXT.uploadTextJPLocalize : UPLOADTEXT.uploadTextJP;
    }
    if (
      URL_PARAMETERS.LANGUAGE === FNOL_COUNTRY.VIETNAM.countryCode &&
      URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.VIETNAM.countryCode
    ) {
      //this.fileDetails=this.fileDetails_vn;
      this.uploadText = UPLOADTEXT.vn.uploadText;
      this.afterUploadButtonText = UPLOADTEXT.vn.uploadButtonText;
    }
    if (
      URL_PARAMETERS.LANGUAGE === FNOL_COUNTRY.THAILAND.countryCode &&
      URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.THAILAND.countryCode
    ) {
      //this.fileDetails=this.fileDetails_th;
      this.uploadText = UPLOADTEXT.th.uploadText;
      this.afterUploadButtonText = UPLOADTEXT.th.uploadButtonText;
    }

    const data = this._dataStoreService.get(FNOL_STEPS.UPLOAD_DOCS.name);
    if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode || this.language === 'jp') {
      if (data && data.uiData.customFileUpload?.uploads !== undefined) {
        data.uiData.customFileUpload.uploads.forEach(val => {
          if (this.decodeClaimType(val.claimBenefitType) !== this.decodeClaimType(this.claimType)) {
            val.supportDocType = undefined;
            this.claimTypeChangedError = true;
          }
        });

        this.uploaded = data.uiData.customFileUpload.uploads;
        console.log(this.uploaded);
      }

    } else {
      if (data && data.uiData.customFileUpload?.uploads !== undefined) {
        this.uploaded = data.uiData.customFileUpload.uploads;
      }
    }
  }

  decodeClaimType(val) {

    if (val === 'injury') {
      return 'I';
    } else if (val === 'Personal Effects') {
      return 'P';

    } else if (val === 'Illness') {
      return 'I';
    }

  }

  ngOnChanges(): void {
    if (this.closeAction || this.language === 'jp' && this.item) {
      this.uploaded = [];
      this.fileLoading = false;
      this.messageEvent.emit('false');
    }
  }

  /* lifecycle hooks */
  ngOnInit(): void {
    this.country = new URLSearchParams(window.location.search).get('country')?.toLowerCase() || this.cacheService.get('country').toLowerCase();
    if (this.language === 'jp' && this.item) {
      this.uploaded = [];
    }
    this.dropDownLabel = this.country == "singapore" ? "Document Type" : "Claim Benefit Type";
    this.typePlaceholder =
      this.country == "singapore"
        ? "Select Document Type"
        : "Select Claim Benefit Type";

    this.messageError =
      this.country == "singapore"
        ? "Document Type is mandatory"
        : "Claim Benefit Type is mandatory";
    this.fnolService.continueClickEvent.subscribe((res) => {
      if (this.uploaded.length > 0) {
        this.fnolService.getUpdateDocTypeRequest(this.uploaded);
      }
      if (res && (res["stepNo"] === 5 ||
          (this.country === FNOL_COUNTRY.SINGAPORENEW.countryCode && res["stepNo"] && res["stepNo"]["slNo"] === 4) ||
          (this.country === FNOL_COUNTRY.JAPAN.countryCode && res["stepNo"] && res["stepNo"] === 4))
      ) {
        this.errorMessage = res["isContinueBtnClicked"];
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.uploadProgress);
  }

  /* methods */

  cancelFileEdit(file, editedNameVar, index): void {
    file.edit = !file.edit;
    editedNameVar.touched = false;
    editedNameVar.value = file.name;
    setTimeout(() => {
      this.elRef.nativeElement.querySelector("#editButton-" + index).focus();
    });
  }

  cancelUpload(index): void {
    clearInterval(this.uploadProgress);
    this.uploaded.splice(index, 1);
    this.uploadFileProgress = 0;
    this.customEvent.next({
      status: "valid",
      value: { uploads: this.uploaded },
    });
  }

  checkStatus(index): void {
    if (
      this.uploaded.length < 1 &&
      this.uploadErrorsList.length < 1 &&
      this.uploadSelectedInitial.length < 1
    ) {
      this.fileLoading = false; // show dragndrop if 0 files listed
    }
    this.update();
  }

  deleteError(file): void {
    const rL = this.uploadErrorsList.length;
    for (let index = 0; index < rL; index++) {
      if (file === this.uploadErrorsList[index]) {
        this.uploadErrorsList.splice(index, 1); // delete row
        this.checkStatus(index);
      }
    }
  }

  deleteFile(file): void {
    
    const rL = this.uploaded.length;
    for (let index = 0; index < rL; index++) {
      if (file === this.uploaded[index]) {
        this.fnolService
          .deleteDocRequest({
            claimNumber: this.fnolModelService.getClaimNumber() ?  (new URLSearchParams(window.location.search).get('followup')?this.cacheService.getFollowUpClaimNumber():this.fnolModelService.getClaimNumber()) : this.item.ClaimDetails.claimNo,
            documentId: this.uploaded[index].docId.toString(),
          })
          .subscribe((res) => {
            if (res.responseCode === 200) {
              if (this.uploaded.length === 1) {
                this.messageEvent.emit('false');
              }
              this.uploaded.splice(index, 1); // delete row
              this.deleteFailedError = false;
              this.checkStatus(index);
              this.customEvent.next({
                status: "valid",
                value: { uploads: this.uploaded },
              });
              this.changeDetectorRef.detectChanges();
            } else {
              this.deleteFailedError = true;
              this.deleteFailure =this.cacheService.get('country').toLowerCase() !== 'jp' ? DISPLAY_MESSAGES.API_FAILURE_DEL:'ファイルの削除に失敗しました。';
              this.customEvent.next({
                status: "invalid",
                value: { uploads: this.uploaded },
              });
              this.changeDetectorRef.detectChanges();
            }
          });
      }
    }
    if (!this.uploaded.length) {
      this.errorMessage = false;
    }
  }

  editFileName(file, index): void {
    file.edited = file.name;
    file.edit = !file.edit;
    setTimeout(() => {
      this.elRef.nativeElement.querySelector("#editedName-" + index).select();
    });
  }

  getIcons(uploadSelected): void {
    for (const axisFile of uploadSelected) {
      const file = axisFile.file;

      let icon = axisFile.icon,
        img = false,
        uploadIcon = "";
      if (icon === undefined) {
        if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          icon = "xlsx";
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          icon = "docx";
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          icon = "pptx";
        } else if (file.type.match("^audio/")) {
          icon = "music_note";
        } else if (file.type.match("^image/gif")) {
          icon = "image";
          img = true;
        } else if (file.type.match("^image/jpeg")) {
          icon = "jpg";
          img = true;
        } else if (file.type.match("^image/png")) {
          icon = "png";
          img = true;
        } else if (file.type.match("^image/")) {
          icon = "image";
        } else if (file.type.match("pdf")) {
          icon = "pdf";
        } else if (file.type.match("text/csv")) {
          icon = "csv";
        } else if (file.type.match("^video/")) {
          icon = "video";
        } else if (file.type.match("text") || file.type === undefined) {
          icon = "txt";
        } else if (file.name.match(".ppt$")) {
          icon = "ppt";
        } else if (file.name.match(".xls$")) {
          icon = "xls";
        } else {
          icon = "txt";
        }
      }

      switch (icon) {
        case "avi":
          uploadIcon = "video";
          break;
        case "doc":
        case "docx":
          uploadIcon = "doc";
          break;
        case "gif":
          img = true;
          uploadIcon = "image";
          break;
        case "flv":
          uploadIcon = "video";
          break;
        case "jpeg":
          img = true;
          uploadIcon = "jpg";
          break;
        case "m4a":
          uploadIcon = "video";
          break;
        case "mov":
          uploadIcon = "video";
          break;
        case "mp3":
          uploadIcon = "music_note";
          break;
        case "mp4":
          uploadIcon = "video";
          break;
        case "ogg":
          uploadIcon = "video";
          break;
        case "png":
          img = true;
          uploadIcon = "png";
          break;
        case "ppt":
        case "pptx":
          uploadIcon = "picture_as_ppt";
          break;
        case "txt":
          uploadIcon = "picture_as_doc";
          break;
        case "xls":
        case "xlsx":
          uploadIcon = "picture_as_xls";
          break;
        case "tif":
        case "tiff":
          uploadIcon = "image";
          break;
        case "webm":
        case "wmv":
          uploadIcon = "video";
          break;
        default:
          uploadIcon = icon;
          break;
      }
      axisFile.img = this.imagePreview ? img : false;
      axisFile.uploadIcon = uploadIcon;
    }

    return uploadSelected;
  }

  getNow(type): string {
    let value;
    const today = new Date();
    if (type === "date") {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      value =
        months[today.getMonth()] +
        " " +
        today.getDate() +
        ", " +
        today.getFullYear();
    } else {
      let hours = today.getHours();
      const minutesNum = today.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutes =
        minutesNum < 10 ? "0" + minutesNum.toString() : minutesNum;
      value = hours.toString() + ":" + minutes.toString() + " " + ampm;
    }
    return value;
  }

  openModalEdit(modal, file): void {
    file.edited = file.name;
    file.editedUploadedBy = file.uploadedBy;
    this.fileToEdit = file;
    modal.open();
    this.update();
  }

  renameFile(file, editedNameVar): void {
    if (editedNameVar.value.length > 0) {
      file.name = file.edited;
      file.edit = !file.edit;
    }
    this.customEvent.next({
      status: "valid",
      value: { uploads: this.uploaded },
    });
  }

  saveFile(): void {
    this.fileToEdit.name = this.fileToEdit.edited;
    this.fileToEdit.uploadedBy = this.fileToEdit.editedUploadedBy;
  }

  trackByIndex(index: number): number {
    return index;
  }

  update(): void {
    this.changeDetectorRef.detectChanges();
  }
  updateStatus;
  updateMsg;
  updateUpload(upload) {
    this.loadingService.start();
    this.updateStatus = false;
    updateDocModel.updateDocRequest["documentId"] = upload.docId;
    updateDocModel.updateDocRequest["claimNumber"] = upload.claimNo;
    updateDocModel.updateDocRequest["documentName"] = upload.name;
    updateDocModel.updateDocRequest["documentNotes"] = upload.documentNotes;
    updateDocModel.updateDocRequest["documentClassification"] = upload.supportDocType.code;
    updateDocModel.updateDocRequest["documentStatusCd"] = 'NE';
    this.fnolService.updateDoc(updateDocModel).subscribe((res: any) => {
      if (res && res['result'] === 'Success') {
        this.updateStatus = true;
        this.updateMsg = $localize`:@@docUpdate:Supporting Document type updated.`;
      }
      this.loadingService.stop();
    }, (err) => {
      this.loadingService.stop();

    })
    this.changeDetectorRef.detectChanges();
  }

  modelChanged(event, ind) {
    this.uploaded.forEach((upload, index) => {
      if (ind === index) {
        if (this.language === 'jp') {
          upload.supportDocType = event;
          upload.claimBenefitType = this.claimType;
          upload.documentNotes = event.name;

          // this.updateUpload(upload)
        } else {
          return upload.claimBenefitType = event.name;
        }

      }
    });
    if (!this.item) {
      this.customEvent.next({ status: 'valid', value: { uploads: this.uploaded } });
    } else {
      this.fnolService.upload.next({ upload: this.uploaded })
    }
    if (this.cacheService.get('country').toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode) {
      this.updateDoctypeJapan();
    }
  }

  updateDoctypeJapan() {
    this.fnolService.getUpdateDocTypeRequest(this.uploaded);
    this.fnolService.updateDocType().subscribe((res: any) => {
      if (res.responseCode === 200) {
        // this.uploadStatus = false;
        // this.isFormValid = true;
        // this.dataStoreService.set(this.step.current.name, {
        //   uiData: this.augFormService.mainUiData[this.currentSectionName],
        //   uiStructure: this.uiStructure,
        // });
        // this.goToNextStep();
      } else {
        // this.updateDocTypeFailed=true;
        // this.isFormValid = false;
        window.scroll({ top: 0, behavior: "smooth" });
      }

    }, (err) => {
      // this.updateDocTypeFailed=true;
      // this.isFormValid = false;
      window.scroll({ top: 0, behavior: "smooth" });
    })
  }

  getFileContent(fileSrc): string {
    return fileSrc.changingThisBreaksApplicationSecurity.split(",")[1];
  }

  uploadFiles(uploadSelected): void {
    this.fileUploadFailed = false;
    if (!this.item) {
      if (!this.fnolModelService.getClaimNumber()) {
        this.fnolModelService
          .generateClaimNumber()
          .then((res) => {
            this.fnolService.setTransactionId(res["transactionId"]);
            this.doUpload(uploadSelected);
          })
          .catch((err) => {
            this.fileUploadFailed = true;
            this.apiFailure = DISPLAY_MESSAGES.API_FAILURE;
          });
      } else {
        this.doUpload(uploadSelected);
      }
    } else {
      this.doUpload(uploadSelected);
    }

  }

  doUpload(uploadSelected) {
    console.log("About to file upload", uploadSelected);
    if (uploadSelected && uploadSelected.length > 0) {
      const uploadSelectedFirst = [];
      uploadSelectedFirst[0] = uploadSelected[0];
      uploadSelected.shift();
      this.uploadSelectedInitial = uploadSelected;
      this.uploadFilesIncremental(uploadSelectedFirst);
    }
  }

  uploadFilesIncremental(uploadSelected): void {
    this.uploadSucces.next(false);
    this.fileUploadFailed = false;
    this.zeroByteError = false;
    this.exceedSizeError = false;
    this.formatUnsupportedError = false;
    let fileCount = 0;
    let processingCount = 0;
    // const resultsDelay = 1200;
    // const uploadErrors = 0;
    const uploadTiming = [0, 25, 25, 25, 25]; // 33, 33, 34
    let uploadTimingLimits;
    this.uploadSelected = uploadSelected;
    if (uploadSelected[0].file.size === 0) {
      this.zeroByteError = true;
      this.zeroByteErrorMsg = DISPLAY_MESSAGES.ZEROBYTE_ERROR;
      this.changeDetectorRef.detectChanges();
      this.uploadFiles(this.uploadSelectedInitial);
      return;
    }
    if (uploadSelected[0].file.size / (1024 * 1024) > 25) {
      this.exceedSizeError = true;
      this.exceedSizeErrorMsg =this.cacheService.get('country').toLowerCase() !== 'jp' ? DISPLAY_MESSAGES.FILE_SIZE : 'アップロード可能なファイルサイズの上限は25 MBです。';
      this.changeDetectorRef.detectChanges();
      this.uploadFiles(this.uploadSelectedInitial);
      return;
    }
    if (
      (URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.JAPAN.countryCode && MARINE_FILE_ALLOWED_FORMAT.indexOf(this.getImgFormat(uploadSelected[0].file.name).toUpperCase()) <= -1) ||
      ((URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode || this.language === 'jp') && IMG_ALLOWED_FORMAT.indexOf(this.getImgFormat(uploadSelected[0].file.name).toUpperCase()) <= -1)
    ) {
      this.formatUnsupportedError = true;
      this.formatUnsupportedErrorMsg = DISPLAY_MESSAGES.FILE_TYPE;
      this.changeDetectorRef.detectChanges();
      this.uploadFiles(this.uploadSelectedInitial);
      return;
    }
    this.getIcons(uploadSelected);

    if (this.cacheService.get('country').toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode) {
      this.claimType = this.item ? this.item.ClaimDetails.submitClaimJson.claimsDetails.claimBenefitType?.personalEffectsDetails ? 'Personal Effects' : '' : this._dataStoreService.get(FNOL_STEPS_JP.CLAIM_TYPE_DETAILS.name)?.uiData['claimBenefits'];

      // this.claimType = this.item ? this.item.ClaimDetails.submitClaimJson.claimsDetails.claimBenefitType : '';
      // this.supportingDocumentType = UtilsConstLabelsLocalization.getClaimBenefitType(FNOL_COUNTRY.JAPAN.countryCode, this.claimType);
    }
    if (URL_PARAMETERS.COUNTRY.toLowerCase() !== FNOL_COUNTRY.SINGAPORENEW.countryCode) {
      this.dateVal = (URL_PARAMETERS.COUNTRY.toLowerCase() !== '' ? URL_PARAMETERS.COUNTRY.toLowerCase() : this.cacheService.get('country').toLowerCase()) !== 'jp' ?
        this._dataStoreService.get(FNOL_STEPS.INCIDENT_DETAILS.name)
          .uiData[NAME_DATE_LOSS[URL_PARAMETERS.COUNTRY.toUpperCase()]]
        : this.item ?  this.item.ClaimDetails.submitClaimJson.claimsDetails.incidentClaimInformation.dateofIncident : this._dataStoreService.get(FNOL_STEPS_JP.CLAIMANT_INFO.name).uiData['accidentDate']
    }
    for (const axisFile of uploadSelected) {
      if (axisFile.count !== undefined) {
        continue;
      }
      let period = axisFile.file.name.lastIndexOf('.')
      let reg = /[<>?\\//:|*‘’“”"']{1,}/gi;
      let removeSpecialCharSubString = axisFile.file.name.substring(0, period).replace(reg, '_') ;
      let fileExtension = axisFile.file.name.substring(period + 1);
      let  fileNameWithoutSpecial = removeSpecialCharSubString + '.' + fileExtension

      // let last = "";
      // let fileNameWithoutSpecial = "";
      // for (let i = 0; i < removeSpecialChar.length; i++) {
      //   let char = removeSpecialChar.charAt(i);
      //   if (char !== last) {
      //     fileNameWithoutSpecial += char;
      //     last = char;
      //   }
      // }
      // if (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORENEW.countryCode) {
     
      const uploadDocumentReqModelSG = {
        userId: (URL_PARAMETERS.COUNTRY.toLowerCase() !== '' ? URL_PARAMETERS.COUNTRY.toLowerCase() : this.cacheService.get('country').toLowerCase()) !== 'jp' ? AppConfig.userEmailID : this.cacheService.getUserId(),
        documentId: "",
        country:
          URL_PARAMETERS.COUNTRY.toLowerCase() ===
            FNOL_COUNTRY.SINGAPORENEW.countryCode
            ? "SG"
            :URL_PARAMETERS.COUNTRY,
        dateofIncident:
          URL_PARAMETERS.COUNTRY.toLowerCase() ===
            FNOL_COUNTRY.SINGAPORENEW.countryCode
            ? this.fnolSgModelService.getDetail("dateOfAccident", "doc")
            : moment(this.dateVal).format("YYYY-MM-DD"),
        // dateofIncident:URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.SINGAPORENEW.countryCode? this.fnolSgModelService.getDetail('dateOfAccident', 'doc'):'',
        claimNumber: this.item ? this.item.ClaimDetails.claimNo : (new URLSearchParams(window.location.search).get('followup')?this.cacheService.getFollowUpClaimNumber():this.fnolModelService.getClaimNumber()),
        policyNumber:
          URL_PARAMETERS.COUNTRY.toLowerCase() ===
            FNOL_COUNTRY.SINGAPORENEW.countryCode
            ? this.fnolSgModelService.getDetail("policyNo")
            : (URL_PARAMETERS.COUNTRY.toLowerCase() !== '' ? URL_PARAMETERS.COUNTRY.toLowerCase() : this.cacheService.get('country').toLowerCase()) !== 'jp' ? URL_PARAMETERS.POLICY_NO ||
              this._dataStoreService.get(FNOL_STEPS.CLAIMANT_INFO.name)
                .policyNumber + "" : ' ',
        documentSize: axisFile.file.size,
        documentMimeType: this.getImgFormat(axisFile.file.name),
        //  content: this.getFileContent(axisFile.src),
        documentName: fileNameWithoutSpecial,
        documentClassification:
          URL_PARAMETERS.COUNTRY.toLowerCase() ===
            FNOL_COUNTRY.SINGAPORENEW.countryCode
            ? "SGACHE"
            : "PNC",
        documentClassificationName: "",
        documentNotes: "",
        documentVisibilityInd: "",
        documentStatusCd: "New",
        vendorID: "AIG",
        insuredName:
          URL_PARAMETERS.COUNTRY.toLowerCase() ===
            FNOL_COUNTRY.SINGAPORENEW.countryCode
            ? "GOJEK"
            : (URL_PARAMETERS.COUNTRY.toLowerCase() !== '' ? URL_PARAMETERS.COUNTRY.toLowerCase() : this.cacheService.get('country').toLowerCase()) !== 'jp' ? this.submitClaimModelService.getDetail("policyHolderName") : ' ',
        batchName: CommonUtils.getBatchName(),
        branchCase: "REF:" + new URLSearchParams(window.location.search).get('followup')?this.cacheService.getFollowUpClaimNumber():this.fnolModelService.getClaimNumber() + "O",
        Origin: "O",
        subFolder: "FNOL",
        vendorDocType: "FNOL",
        cmDocType: "FNOL - Internet Report", 
        receivedFrom: "Claims Digital Portal",
      };
      this.uploadDocumentModel = uploadDocumentReqModelSG;
      //  }
      axisFile.count = fileCount;
      axisFile.failed = false;
      axisFile.uploadFileProgress = 0;
      axisFile.timing = uploadTiming;
      uploadTimingLimits = 99;

      this.fileLoading = true;
      this.update();
      let count = 0;


      (URL_PARAMETERS.COUNTRY.toLowerCase() === FNOL_COUNTRY.JAPAN.countryCode || this.language === 'jp') ? this.loadingService.start('ファイルをアップロードしています...') : this.loadingService.start('Files uploading...');
       this.fileUploadFailed = false;
      // if (axisFile.uploadFileProgress > uploadTimingLimits) {
      // clearInterval(this.uploadProgress);

      axisFile.clearVisible = false;

      const dateSubmittedDisplay = this.getNow("date");
      const dateSubmittedTime = this.getNow("time");
      // maxCount = Math.floor(Math.random() * Math.floor(this.uploadRecords.length)),
      // recordCount = this.uploadRecords[maxCount];
      var data = new FormData();
      data.append(
        "uploadDocRequest",
        JSON.stringify(this.uploadDocumentModel)
      );
      data.append("uploadFile", axisFile.file);

      this.fnolService.docUploadRequest(data).subscribe(
        (response) => {
          if (response && response.responseCode === 200) {
            this.messageEvent.emit('true');
            this.uploadSucces.emit(true)
            this.fnolService.logErrorAEM(
              "docupload-success",
              data,
              response,
              "docUpload"
            );
            this.docIdGenerated = response.response["documentId"];

            const newUpload = {
              claimNo: new URLSearchParams(window.location.search).get('followup')?this.cacheService.getFollowUpClaimNumber():this.fnolModelService.getClaimNumber(),
              docId: this.docIdGenerated,
              claimBenefitType: "",
              active: true,
              checked: false,
              edit: false,
              img: axisFile.img,
              name: fileNameWithoutSpecial,
              newHighlight: true,
              size: axisFile.file.size,
              src: axisFile.src,
              status: "Success",
              type: axisFile.file.type,
              uploadDate: dateSubmittedDisplay,
              uploadDateTime: dateSubmittedTime,
              uploadedBy: this.userName,
              uploadIcon: axisFile.uploadIcon,
              uploadReqObj: uploadDocumentReqModelSG,
            };
            this.loadingService.stop();
            this.uploaded.push(newUpload);
            if (!this.item) {
              this.customEvent.next({
                status: "valid",
                value: { uploads: this.uploaded },
              });
            }

            axisFile.progressVisible = false;
            this.update();
            setTimeout(() => {
              newUpload.newHighlight = false;
              this.update();
            },);
          } else {
            this.fnolService.logErrorAEM(
              "docupload-not-2000",
              data,
              response,
              "docUpload"
            );
            this.loadingService.stop();
            const newError = {
              active: true,
              errorHighlight: true,
              name: axisFile.file.name,
              reload: uploadSelected,
              status: "Failed",
              uploadIcon: axisFile.uploadIcon,
            };
            this.fileUploadFailed = true;
            this.apiFailure = DISPLAY_MESSAGES.API_FAILURE;
            this.changeDetectorRef.detectChanges();
            this.uploadErrorsList.push(newError);
            this.customEvent.next({
              status: "invalid",
              value: {
                uploads: this.uploaded,
                uploadError: this.uploadErrorsList,
              },
            });
            axisFile.progressVisible = false;
            this.update();
          }
        },
        (err) => {
          this.fnolService.logErrorAEM(
            "docupload-failure",
            data,
            err,
            "docUpload"
          );
          this.loadingService.stop();
          this.apiFailure = DISPLAY_MESSAGES.API_FAILURE;
          this.fileUploadFailed = true;
          this.changeDetectorRef.detectChanges();
        }
      );

      processingCount++;

      this.update();
      this.uploadFiles(this.uploadSelectedInitial);

      // } else {
      //   count++;
      //   axisFile.uploadFileProgress += axisFile.timing[count];
      // }


      fileCount++;
    }
  }

  getImgFormat(fileFormat: string): string {
    return fileFormat.substring(fileFormat.lastIndexOf(".") + 1).toLowerCase();
  }

  getBatchname(): string {
    if (
      URL_PARAMETERS.COUNTRY.toLowerCase() ===
      FNOL_COUNTRY.SINGAPORENEW.countryCode
    ) {
      const systemDate = new Date();
      const timeZone = "Asia/Singapore";
      const formattedSGTime = new Date(
        systemDate.toLocaleString("SG", {
          timeZone,
        })
      );
      var date = new Date(formattedSGTime);
      let day = ("0" + date.getDate()).slice(-2);
      let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear().toString().substring(2);
      let times = date.toLocaleTimeString().split(" ");
      return `AIG ${day}/${mnth}/${year} ${times[0]}${times[1]}`;
    } else {
      return moment().format("[AIG] MM[/]DD[/]YY h:mm:ssA");
    }
  }
}
